import { React, useEffect, useState, useContext } from "react";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { v4 as uuidv4 } from 'uuid';

dayjs.extend(utc);
dayjs.extend(timezone);

const AmplifyEventNBSamples = () => {
    const { eventCoreInformation,
            eventSampleInformation,
            eventMessageBodyInformation,
            eventPhoneInformation,
            updateEventSamplerecipients,
            postSampleMessage,
            eventNBSamplesChanged
        } = useContext(AmplifyEventContext);

    //SAMPLES
    type sampleNB = {id: 0, name: '', phone: '', status: '', msg_sent: ''};

    //eslint-disable-next-line
    const [campaignExistingSamples, setCampaignExistingSamples] = useState([]);
    //eslint-disable-next-line
    const [existingSamplesNBList, setExistingSamplesNBList] = useState([]);
    const [newSampleNBName, setNewSampleNBName] = useState('');
    const [newSampleNBPhone, setNewSampleNBPhone] = useState('');
    const [sampleNBPromptVisibility, setSampleNBPromptVisibility] = useState(true)
    //const [sampleNBClickMessage, setSampleNBClickMessage] =useState('Click me to show the non-branded samples for your message');
    const [newSampleNBClickMessage, setNewSampleNBClickMessage] =useState('Click me to create a New Non-Branded Sample Recipient');
    const [rotationFactorNewSampleNB, setRotationFactorNewSampleNB] = useState(0);

    // const [thisEventSampleInfo, setThisEventSampleInfo] = useState({statusId: null, sample_numbers: []});

    // // var thisEventSampleInfo = {
    // //     statusId: null,
    // //     sample_numbers: null
    // // }

    const newSampleNBStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorNewSampleNB + "deg)"
    }

    useEffect(() => {
        if (eventSampleInformation?.sample_numbers && eventSampleInformation.sample_numbers.length != 0)
        {
            setSampleNBPromptVisibility(false);

            // console.log("these are the event sample recipients => ", eventSampleInformation.sample_numbers);

            // if(!eventSampleInformation.sample_numbers){
            //     setThisEventSampleInfo({
            //         statusId: uuidv4(),
            //         sample_numbers: [...eventSampleInformation.sample_numbers]
            //     });
            // }
        }
        else
        {
            setSampleNBPromptVisibility(true);
        }
    }, [eventSampleInformation, eventNBSamplesChanged])


    // function postNewSampleRecipientGroup(){
    //     console.log("AmplifyEventNBSamples: entered postNewSampleRecipientGroup(): not doing anything here, yet");
    // }

    function handleSampleOnDrag(e, lsample){
        e.dataTransfer.setData("sample", lsample);
    }

    async function createNewSampleNB(){
        // console.log("AmplifyEventNBSamples: entered createNewSampleNB() (NEEDS TESTING)")
        var _a = []
        if (eventSampleInformation?.sample_numbers) {
            _a = [...eventSampleInformation.sample_numbers];
        }

        // edr - not sure why this is assigned here
        var _e = [...campaignExistingSamples];

        var sampleFound = _a.find(object => object.phone == newSampleNBName);
        if (!sampleFound)
        {
            var _new = {id: uuidv4(), name: newSampleNBName, phone: newSampleNBPhone, status: 'PENDING', msg_sent: ''};
            _a.splice(_a.length, 0, _new);
            _e.splice(_a.length, 0, _new);

            await updateEventSamplerecipients(_a, _new);
            setExistingSamplesNBList([..._e]);
        }
        else
        {
            //TODO Need Toast Alert Here
        }
    }

    function handleSampleNBOnDrop(e){
        // console.log("AmplifyEventNBSamples: entered handleSampleNBOnDrop() (NEEDS TESTING)")
        var _id = e.dataTransfer.getData("sample");
        var _existing;
        if (eventSampleInformation?.sample_numbers) {
            _existing = eventSampleInformation.sample_numbers.find((sample) => {
                return sample.id == _id;
            })
        }

        if (!_existing)
        {
            var _lnewSampleNB:sampleNB = campaignExistingSamples.find((sample) => {
                return sample.id == _id;
            })

            _lnewSampleNB.status = 'PENDING';
            _lnewSampleNB.msg_sent = '';

            var _a = eventSampleInformation ? eventSampleInformation.sample_numbers : [];
            var _b = [];
            _b.push(_lnewSampleNB);

            var newArray;
            if (_a) {
                newArray = [..._a, ..._b];
            }
            else{
                newArray = [..._b];
            }

            updateEventSamplerecipients(newArray);

            //post the new sample recipient to Mongo
            // console.log("AmplifyEventNBSamples: handleSampleNBOnDrop() calling postNewSampleRecipientGroup(newArray, eventCoreInformation.eventKey)", newArray, eventCoreInformation.eventKey)
            //postNewSampleRecipientGroup(newArray, eventCoreInformation.eventKey);
        }
    }

    async function SampleNB_SendSampleNBClick(sample){
        if (!eventPhoneInformation) {
            return;
        }

        var _msg = {
            targetPhone: sample.phone,
            mediaURL: eventMessageBodyInformation.media_get_url,
            messageBody: eventMessageBodyInformation.content,
            metaDataMessage: '',
            fromPhone: eventPhoneInformation.admin_phone_number,
            sample_id: sample.id,
            name: sample.name
        };

        // console.log("AmplifyEventNBSamples: entered SampleNB_SendSampleNBClick() (NEEDS TESTING) calling postSampleMessage(): sample, _msg ", sample, _msg)
        await postSampleMessage(
            _msg
        )
        .catch(function (error) {
            console.log(error);
        });
    }

    function SampleNB_RemoveSampleNBClick(sample){
        if (!eventSampleInformation) {
            return;
        }

        var _id = sample.id;

        //CALL SQL STORED PROC HERE

        var _s = [...eventSampleInformation.sample_numbers];

        var _i = 0;
        for (let i = 0; i < _s.length; i++){
            if(_s[i].id == _id)
            {
                _i = i;
                break;
            }
        }

        // console.log("AmplifyEventNBSamples: SampleNB_RemoveSampleNBClick() (NEEDS TESTING) calling updateEventSamplerecipients(): sample, _s ", sample, _s)
        _s.splice(_i, 1);
        updateEventSamplerecipients(_s);
    }

    function handleDragOver(e){
        e.preventDefault();
    }

    function applyRotationNewSampleNB(){
        if(rotationFactorNewSampleNB == 0)
        {
            setRotationFactorNewSampleNB(180);
            setNewSampleNBClickMessage("Click me to hide the New Non-Branded Sample Recipient");
        }
        else
        {
            setRotationFactorNewSampleNB(0);
            setNewSampleNBClickMessage("Click me to create a New Non-Branded Sample Recipient");
        }
    }

    return(
        <div className="flex" style={{marginLeft: "10px", marginBottom: "20px"}}>
            <div>
                <div className="eventEditApproverBounds grid">
                    <label className="userEditTitle" htmlFor="sampleLastName">Previous Samples:</label>
                    <div className="grid">
                        {campaignExistingSamples.map((samplesNB) => (
                            <div className="eventEditSampleNB"
                                key={samplesNB.id}
                                onDragStart={(e) => handleSampleOnDrag( e, samplesNB.id)}
                                draggable>{samplesNB.name}</div>
                        ))}
                    </div>

                </div>

                <div className="eventCreateTiming" style={{margin: "10px 0px 0px 0px", padding: "0px 20px 0px 0px", width: "500px"}}>
                    <div className="collapse border border-black group-open:rotate-180">
                        <input id="newSampleNBAccordion" type="checkbox"
                        onClick={applyRotationNewSampleNB}
                        />
                        <div className="collapse-title flex">
                            <div className="stepEllipse mt-3" style={{width: "50px"}} />
                            <label className="text-l font-bold" style={{margin: "18px 0px 0px -33px"}}>7.1.</label>
                            <div className="collapse-title text-xl font-bold">NEW SAMPLE - {newSampleNBClickMessage}</div>
                            <svg
                                style={newSampleNBStyle}
                                id="test" xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>
                        </div>

                        <div className="collapse-content">

                            <div className= "grid">

                                    <div className="grid">
                                        <label className="userEditTitle" htmlFor="newSampleName">Sample Name:</label>
                                        <input className="input input-bordered input-md" style={{width: "445px", margin: "0px 10px 0px 0px"}} type="text"
                                            id="newSampleName" name="newSampleName"
                                            value={newSampleNBName}
                                            onChange={e => setNewSampleNBName(e.target.value)}
                                            placeholder={'Non Branded Sample Name'}/>
                                    </div>

                                    <div className="grid">
                                        <label className="userEditTitle" htmlFor="newSampleNBCellPhone">Cell Phone:</label>
                                        <input className="input input-bordered input-md" style={{width: "445px", margin: "0px 0px 0px 0px"}} type="text" id="newSampleNBCellPhone"
                                            name="newSampleNBCellPhone"
                                            value={newSampleNBPhone}
                                            onChange={e => setNewSampleNBPhone(e.target.value)}
                                            placeholder={'Non Branded Sample Cell Phone Number'}/>
                                    </div>

                            </div>

                            <button className="btn btn-outline bg-blue-200 mt-5 ml-[205px] w-60"
                                onClick={() => createNewSampleNB()}
                                >Create</button>


                        </div>
                    </div>
                </div>
            </div>
            <div className="eventEditSamplesTarget"
                onDrop={handleSampleNBOnDrop} onDragOver={handleDragOver}
                style={{marginLeft: "-10px"}}>
                <div className="grid" style={{height: "25px"}}>


                </div>

                {
                    sampleNBPromptVisibility == true ?
                        (
                            <div className="appliedSampleTargetRectangle">
                                <div className="dropFileTitle mt-[80px]">drop non-branded sample recipients here</div>
                            </div>
                        )
                        :
                        (
                            <div className="grid">
                                <div className="appliedSampleTargetRectangle" style={{height: "100px"}}>
                                    <div className="dropFileTitle mt-[30px]" style={{fontSize: "24px"}}>drop non-branded sample recipients here</div>
                                </div>

                                <div style={{marginTop: "20px"}}>
                                    <div className="flex">
                                        <div className="mt-[7px]" style={{marginLeft: "102px"}}>Sample Name</div>
                                        <div className="mt-[7px]" style={{marginLeft: "41px"}}>Sample Phone</div>
                                        <div className="mt-[7px]" style={{marginLeft: "35px"}}>Status</div>
                                    </div>

                                    <hr/>
                                </div>
                            </div>
                        )
                }


                <div className="grid" style={{height: "45px"}}>
                    {
                    eventSampleInformation?.sample_numbers != null ?
                    (
                        eventSampleInformation.sample_numbers.map((sampleNB) => (
                        <div className="appliedSample flex" key={sampleNB.id}>
                            <div className="approverButtons" style={{margin: "0px 0px 0px 10px"}} onClick={() => SampleNB_SendSampleNBClick(sampleNB)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#0044ff"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><g fill="none" >
                                    <path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"/></g>
                                </svg>
                            </div>
                            {
                                sampleNB.status != 'SENT'
                                ?
                                    (
                                        <div className="approverButtons" style={{margin: "0px 0px 0px 10px"}} onClick={() => SampleNB_RemoveSampleNBClick(sampleNB)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#ff0000"
                                                strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline>
                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                                            </svg>
                                        </div>
                                    )
                                :
                                    (
                                        <div style={{width: "39px"}} />
                                    )

                            }

                            <div className="appliedSampleName" style={{fontSize: "12px", fontWeight: "bold", width: "110px"}}>{sampleNB.name}</div>
                            <div className="appliedSampleName" style={{width: "110px"}}>{sampleNB.phone}</div>
                            <div className="appliedSampleName" style={{width: "70px"}}>
                            { sampleNB.msg_sent == '' ?
                                (
                                    sampleNB.status
                                )
                                :
                                (
                                    "SENT"
                                )
                            }
                            </div>

                            <div className="appliedSampleName" style={{width: "100px"}}>
                            {
                                sampleNB.msg_sent != ''
                                ? (dayjs(sampleNB.msg_sent).format('MM/DD/YYYY HH:mm'))
                                : ('')
                            }
                            </div>
                        </div>

                    )))
                    :
                    <div />
                    }
                </div>
            </div>
        </div>
    );

};

export default AmplifyEventNBSamples;
