const CreateNewEvent = () => {

    return (

        <div className="navigationCreateEvent">
            <div className="flex">
                <div style={{margin: "23px 0px 0px 40px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none"
                        stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="2"></circle>
                        <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path>
                    </svg>
                </div>
                <div className="grid" style={{margin: "32px 0px 0px 20px"}}>
                    <div className="createItemTitle">Create New Event</div>
                </div>
            </div>
        </div>
    );
};

export default CreateNewEvent;
