import { useContext } from "react";
import { UnifyContext } from "../../context/UnifyContext";

import TextField from '@mui/material/TextField';

const UnifyCampaignCreate_EventsPage = () => {

    const {
            eventsTitle,setEventsTitle,
            eventsParagraph,setEventsParagraph,

            eventTitle_1,setEventTitle_1,
            eventDescription_1,setEventDescription_1,
            eventWhen_1,setEventWhen_1,
            eventWhere_1,setEventWhere_1,

            eventTitle_2,setEventTitle_2,
            eventDescription_2,setEventDescription_2,
            setEventWhen_2,
            eventWhere_2,setEventWhere_2,

            eventTitle_3,setEventTitle_3,
            eventDescription_3,setEventDescription_3,
            eventWhen_3,setEventWhen_3,
            eventWhere_3,setEventWhere_3,

            eventTitle_4,setEventTitle_4,
            eventDescription_4,setEventDescription_4,
            eventWhen_4,setEventWhen_4,
            eventWhere_4,setEventWhere_4,

            eventTitle_5,setEventTitle_5,
            eventDescription_5,setEventDescription_5,
            eventWhen_5,setEventWhen_5,
            eventWhere_5,setEventWhere_5,
        } = useContext(UnifyContext);

    return (

       <div className="unifyComponentPage" style={{margin: "0px 30px 20px 30px"}}>
            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Tell the world where they can meet you</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div style={{marginTop: "20px", background: "white", padding: "20px"}}>
                <div className="flex">
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Events Title"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventsTitle(e.target.value)}
                            value={eventsTitle}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate First Name</div>*/}
                        <TextField
                            type="text"
                            label="Events Description"
                            style={{width: "1260px"}}
                            multiline
                            rows={12}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventsParagraph(e.target.value)}
                            value={eventsParagraph}
                        />
                    </div>
                </div>
            </div>
            {/*EVENT LISTING SECTION*/}

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Your first event</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div style={{marginTop: "20px", background: "#f5f5f5", padding: "20px"}}>
                <div className="flex">
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 1 Title"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventTitle_1(e.target.value)}
                            value={eventTitle_1}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 1 Description"
                            style={{width: "1260px"}}
                            multiline
                            rows={6}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventDescription_1(e.target.value)}
                            value={eventDescription_1}
                        />
                    </div>
                </div>

                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 1 When"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhen_1(e.target.value)}
                            value={eventWhen_1}
                        />
                    </div>
                    <div className="grid"  style={{marginLeft: "20px"}}>
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 1 Where"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhere_1(e.target.value)}
                            value={eventWhere_1}
                        />
                    </div>
                </div>


            </div>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Your second event</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div style={{marginTop: "20px", background: "white", padding: "20px"}}>
                <div className="flex">
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 2 Title"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventTitle_2(e.target.value)}
                            value={eventTitle_2}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 2 Description"
                            style={{width: "1260px"}}
                            multiline
                            rows={6}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventDescription_2(e.target.value)}
                            value={eventDescription_2}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 2 When"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhen_2(e.target.value)}
                            value={eventWhen_1}
                        />
                    </div>
                    <div className="grid"  style={{marginLeft: "20px"}}>
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 2 Where"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhere_2(e.target.value)}
                            value={eventWhere_2}
                        />
                    </div>
                </div>


            </div>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Your third event... catch your breath!!!!</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div style={{marginTop: "20px", background: "#f5f5f5", padding: "20px"}}>
                <div className="flex">
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 3 Title"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventTitle_3(e.target.value)}
                            value={eventTitle_3}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 3 Description"
                            style={{width: "1260px"}}
                            multiline
                            rows={6}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventDescription_3(e.target.value)}
                            value={eventDescription_3}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 3 When"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhen_3(e.target.value)}
                            value={eventWhen_3}
                        />
                    </div>
                    <div className="grid"  style={{marginLeft: "20px"}}>
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 3 Where"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhere_3(e.target.value)}
                            value={eventWhere_3}
                        />
                    </div>
                </div>


            </div>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Your fourth event... you are a MACHINE!!!!</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div style={{marginTop: "20px", background: "white", padding: "20px"}}>
                <div className="flex">
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 4 Title"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventTitle_4(e.target.value)}
                            value={eventTitle_4}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 4 Description"
                            style={{width: "1260px"}}
                            multiline
                            rows={6}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventDescription_4(e.target.value)}
                            value={eventDescription_4}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 4 When"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhen_4(e.target.value)}
                            value={eventWhen_4}
                        />
                    </div>
                    <div className="grid"  style={{marginLeft: "20px"}}>
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 4 Where"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhere_4(e.target.value)}
                            value={eventWhere_4}
                        />
                    </div>
                </div>


            </div>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Your fifth event... sleep AFTER election day! Am I right?????</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div style={{marginTop: "20px", background: "#f5f5f5", padding: "20px"}}>
                <div className="flex">
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 5 Title"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventTitle_5(e.target.value)}
                            value={eventTitle_5}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 5 Description"
                            style={{width: "1260px"}}
                            multiline
                            rows={6}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventDescription_5(e.target.value)}
                            value={eventDescription_5}
                        />
                    </div>
                </div>
                <div className="flex" style={{marginTop: "20px"}}>
                    <div className="grid">
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 5 When"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhen_5(e.target.value)}
                            value={eventWhen_5}
                        />
                    </div>
                    <div className="grid"  style={{marginLeft: "20px"}}>
                        {/*<div>Candidate Last Name</div>*/}
                        <TextField
                            type="text"
                            label="Event 5 Where"
                            style={{width: "620px"}}
                            //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                            onChange={(e) => setEventWhere_5(e.target.value)}
                            value={eventWhere_5}
                        />
                    </div>
                </div>


            </div>

            <div className="unifyCallOut" style={{width: "90%", margin: "40px 60px 40px 0px"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>Just one page left to go! Let&lsquo;s tell people why they should volunteer for you and help get you into office!</div>
            </div>


        </div>

    );
};

export default UnifyCampaignCreate_EventsPage;
