import { React, useEffect, useState, useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";
import { DataMapperContext } from "../../context/DataMapperContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import CheckIcon from '@mui/icons-material/Check';

dayjs.extend(utc);
dayjs.extend(timezone);

const AmplifyEventStepsCalloutMenu = () => {
    const { eventCoreInformation,
            eventMessageBodyInformation,
            eventPhoneInformation,
            eventApprovalInformation,
            eventMessagePricingInformation,
            newEventPhoneInformation,
            newEventApprovalInformation,
            newEventMessageBodyInformation,
            newEventMessagePricingInformation,
            eventCoreChanged, eventApproversChanged,
            eventPhoneInformationChanged
         } = useContext(AmplifyEventContext);

    const { campaignName, campaignStateAbbr } = useContext(CampaignContext);
    const { mosaicUniverseSize } = useContext(DataMapperContext);

    const [messageEventCoreInformationFill, setMessageEventCoreInformationFill] = useState('lightgrey');
    const [messageUniverseFill, setMessageUniverseFill] = useState('lightgrey');
    const [messageTimingFill, setMessageTimingFill] = useState('lightgrey');
    const [messageTypeFill, setMessageTypeFill] = useState('lightgrey');
    const [messageTransmissionFill, setMessageTransmissionFill] = useState('lightgrey');
    const [messagePriceFill, setMessagePriceFill] = useState('lightgrey');
    const [approverCountFill, setApproverCountFill] = useState('lightgrey');

    const [messageEventCoreInformationCheck, setMessageEventCoreInformationCheck] = useState('hidden');
    const [messageUniverseCheck, setMessageUniverseCheck] = useState('hidden');
    const [messageTimingCheck, setMessageTimingCheck] = useState('hidden');
    const [messageTypeCheck, setMessageTypeCheck] = useState('hidden');
    const [messageTransmissionCheck, setMessageTransmissionCheck] = useState('hidden');
    const [messagePriceCheck, setMessagePriceCheck] = useState('hidden');
    const [approverCountCheck, setApproverCountCheck] = useState("hidden");

    var eventInfoStatusId = null;

    // edr - this doesnt appear to be set anywhere else for any reason
    //var eventMsgBodyId = null;

    //CORE INFORMATION
    //eslint-disable-next-line
    const [eventKey, setEventKey] = useState(null);
    const [status, setStatus] = useState('');
    //eslint-disable-next-line
    const [status_legacy, setStatus_Legacy] = useState('');
    const [eventName, setEventName] = useState('');
    const [project_code, setProject_Code] = useState('');
    const [timezoneUniv, setTimezoneUniv] = useState('');
    const [timezone, setTimezone] = useState('');
    const [start_datetime, setStart_DateTime] = useState(null);

    //MSG BODY INFORMATION
    const [sms, setSMS] = useState('');
    //eslint-disable-next-line
    const [mmsName, setMMSName] = useState('');
    const [smsCharacterCount, setSMSCharacterCount] = useState(0);
    const [sms_segments, setSMS_Segments] = useState(0);
    const [media_type, setMedia_Type] = useState('');
    const [crystalSegment, setCrystalSegment] = useState(0);

    //PHONE INFORMATION

    // EDR - TODO
    // move all the logic out of HTML and into anything writing the values here
    const [thisEventPhoneInfo, setThisEventPhoneInfo] = useState(newEventPhoneInformation);
    const [thisEventApprovalInfo, setThisEventApprovalInfo] = useState(newEventApprovalInformation);
    const [thisEventMessageBodyInfo, setThisEventMessageBodyInfo] = useState(newEventMessageBodyInformation);
    const [thisEventMessagePricingInfo, setThisEventMessagePricingInfo] = useState(newEventMessagePricingInformation);


    // Update relevant local vars if eventCoreInformation.statusId has changed
    useEffect(() => {

        if (!eventCoreInformation || (eventCoreInformation.statusId == eventInfoStatusId)) {
            // console.log("*********************** DEM *******************************");
            // console.log("*********************** EXITING *******************************");
            return;
        }
        //console.log("AmplifyEventStepsCalloutMenu: useEffect()[eventCoreInformation] :", eventCoreInformation)

        // console.log("*********************** DEM *******************************");
        // console.log("*********************** CONTINUE *******************************");
        // console.log("CALLOUT: this is the new core info => ", eventCoreInformation);

        eventInfoStatusId = eventCoreInformation.statusId;

        setEventKey(eventCoreInformation.key);
        setStatus(eventCoreInformation.status);
        setStatus_Legacy(eventCoreInformation.status_legacy);
        setEventName(eventCoreInformation.name);
        setProject_Code(eventCoreInformation.project_code);
        setTimezoneUniv(eventCoreInformation.timezoneUniv);
        setTimezone(eventCoreInformation.timezone);
        setStart_DateTime(eventCoreInformation.start_datetime);

        //status indicators
        if( eventCoreInformation.name.trim().length > 0 &&
            eventCoreInformation.project_code.trim().length > 0)
        {
            setMessageEventCoreInformationFill('#49df00');
            setMessageEventCoreInformationCheck('visible');
        }
        else
        {
            setMessageEventCoreInformationFill('lightgrey');
            setMessageEventCoreInformationCheck('hidden');
        }

        if (eventCoreInformation.timezoneUniv.trim().length > 0)
        {
            setMessageTimingFill('#49df00');
            setMessageTimingCheck('visible');
        }
        else
        {
            setMessageTimingFill('lightgrey');
            setMessageTimingCheck('hidden');
        }


    }, [eventCoreInformation, eventCoreChanged])

    // useEffect(() => {
    //     console.log("THE CORE INFORMATION CHANGED! =>", eventCoreInformation);
    // }, [eventCoreChanged])

    // useEffect(() => {
    //
    //     if(eventCoreInformation?.name){
    //         console.log("found the name change => ", eventCoreInformation.name);
    //     }
    //
    // }, [eventCoreInformation])


    // Update relevant local vars if eventMessageBodyInformation.statusId has changed
    useEffect(() => {
        //console.log("AmplifyEventStepsCalloutMenu: useEffect()[eventMessageBodyInformation]: ", eventMessageBodyInformation)
        //if (!eventMessageBodyInformation || (eventMsgBodyId == eventMessageBodyInformation.statusId))
        if (!eventMessageBodyInformation) {
            return;
        }

        //console.log("AmplifyEventStepsCalloutMenu: useEffect()[eventMessageBodyInformation] EDR - this isnt getting called when user is actively editing a message. so the useEffect()[eventMessagePricingInformation] function is updating the character count for now")
        updateLocalMessageBodyInformation();
    }, [eventMessageBodyInformation])

    function updateLocalMessageBodyInformation() {
        //console.log("AmplifyEventStepsCalloutMenu: updateLocalMessageBodyInformation():", eventMessageBodyInformation)
        //if (!eventMessageBodyInformation || (eventMsgBodyId == eventMessageBodyInformation.statusId))
        if (!eventMessageBodyInformation) {
            return;
        }

        // edr - changed html code to use "thisEventMessageBodyInfo" instead of "eventMessageBodyInformation"
        //       so the page can function without errors until AmplifyEventContext is fully populated
        // Hopefully, just updating whenever "eventMessageBodyInformation" changes will be enough to work
        setThisEventMessageBodyInfo(eventMessageBodyInformation);

        setSMS(eventMessageBodyInformation.content);
        setMMSName(eventMessageBodyInformation.media_name);
        setSMSCharacterCount(eventMessageBodyInformation.smsCharacterCount);
        setSMS_Segments(eventMessageBodyInformation.sms_segments);
        setMedia_Type(eventMessageBodyInformation.media_type);
        setCrystalSegment(eventMessageBodyInformation.CrystalSegment);

        //status indicators
        if(eventMessageBodyInformation.smsReadyForTransmission == true){
            setMessageTypeFill('#49df00');
            setMessageTypeCheck('visible');
        }
        else{
            setMessageTypeFill('lightgrey');
            setMessageTypeCheck('hidden');
        }
    }

    useEffect(() => {
        if(mosaicUniverseSize > 0){
            setMessageUniverseFill('#49df00');
            setMessageUniverseCheck('visible');
        }
        else{
            setMessageUniverseFill('lightgrey');
            setMessageUniverseCheck('hidden');
        }
    }, [mosaicUniverseSize])

    useEffect(() => {
        // edr - changed html code to use "thisEventPhoneInfo" instead of "eventPhoneInformation"
        //       so the page can function without errors until AmplifyEventContext is fully populated
        // Hopefully, just updating whenever "eventPhoneInformation" changes will be enough to work


        // console.log("inbound new event phone information => ", eventPhoneInformation);

        if (eventPhoneInformation) {
            // console.log("updating phone information");
            setThisEventPhoneInfo(eventPhoneInformation);
        }

        if (eventPhoneInformation &&
            (eventPhoneInformation.area_code != '' || eventPhoneInformation.area_code != '999') &&
            eventPhoneInformation.admin_phone_number &&
            eventPhoneInformation.customer_phone_number)
        {
            setMessageTransmissionFill('#49df00');
            setMessageTransmissionCheck('visible');
        } else {
            setMessageTransmissionFill('lightgrey');
            setMessageTransmissionCheck('hidden');
        }
    }, [eventPhoneInformation, eventPhoneInformationChanged])

    useEffect(() => {
        // edr - changed html code to use "thisEventApprovalInfo" instead of "eventApprovalInformation"
        //       so the page can function without errors until AmplifyEventContext is fully populated
        // Hopefully, just updating whenever "eventApprovalInformation" changes will be enough to work
        if (eventApprovalInformation) {
            setThisEventApprovalInfo(eventApprovalInformation);
        }

        if (eventApprovalInformation &&
            eventApprovalInformation.approval_numbers &&
            eventApprovalInformation.approval_numbers.length)
        {
            setApproverCountFill('#49df00');
            setApproverCountCheck('visible');
        }
        else{
            setApproverCountFill('lightgrey');
            setApproverCountCheck('hidden');
        }
    }, [eventApprovalInformation, eventApproversChanged])

    useEffect(() => {
        // edr - changed html code to use "thisEventMessagePricingInfo" instead of "eventMessagePricingInformation"
        //       so the page can function without errors until AmplifyEventContext is fully populated
        // Hopefully, just updating whenever "eventMessagePricingInformation" changes will be enough to work
        if (eventMessagePricingInformation) {
            setThisEventMessagePricingInfo(eventMessagePricingInformation);
        }

        if (eventMessagePricingInformation && eventMessagePricingInformation.unitPrice)
        {
            setMessagePriceFill('#49df00');
            setMessagePriceCheck('visible');
        }
        else{
            setMessagePriceFill('lightgrey');
            setMessagePriceCheck('hidden');
        }

        // edr - this is only here, temprorarily, because I cant get it triggered any other way
        //console.log("AmplifyEventStepsCalloutMenu: (EDR fix this) useEffect()[eventMessagePricingInformation] calling updateLocalMessageBodyInformation()")
        updateLocalMessageBodyInformation();
    }, [eventMessagePricingInformation])

	return(
        <div>
        {/*<div className="eventCreateDetailsBehind" style={{marginLeft: "-460px", visibility: savingEventAnim}}>
            <div style={{width: "300px", margin: "450px 0px 0px 75px"}} className="loading loading-bars loading-lg"></div>
        </div>*/}
		<div className="eventCreateDetailsBehind">
            <div className="flex" style={{marginTop: "20px"}}>
                <div style={{margin: "80px 30px 0px 15px"}}>
                    <div className="vl_button" style={{margin: "35px 0px 0px -25px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill={messageEventCoreInformationFill}
                            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                        <CheckIcon style={{color: "white", marginTop: "-80px", marginLeft: "5px", visibility: messageEventCoreInformationCheck}}
                            sx={{ fontSize: 48 }}/>
                    </div>
                    <div className="vl_button" style={{margin: "250px 0px 0px -25px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill={messageUniverseFill}
                            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                        <CheckIcon style={{color: "white", marginTop: "-80px", marginLeft: "5px", visibility: messageUniverseCheck}} sx={{ fontSize: 48 }}/>
                    </div>
                    <div className="vl_button" style={{margin: "355px 0px 0px -25px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill={messageTimingFill}
                            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                        <CheckIcon style={{color: "white", marginTop: "-80px", marginLeft: "5px", visibility: messageTimingCheck
                        }} sx={{ fontSize: 48 }}/>
                    </div>
                    <div className="vl_button" style={{margin: "450px 0px 0px -25px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill={messageTypeFill}
                            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                        <CheckIcon style={{color: "white", marginTop: "-80px", marginLeft: "5px", visibility: messageTypeCheck}} sx={{ fontSize: 48 }}/>
                    </div>
                    <div className="vl_button" style={{margin: "645px 0px 0px -25px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill={messageTransmissionFill}
                            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                        <CheckIcon style={{color: "white", marginTop: "-80px", marginLeft: "5px", visibility: messageTransmissionCheck}} sx={{ fontSize: 48 }}/>
                    </div>
                    <div className="vl_button" style={{margin: "795px 0px 0px -25px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill={approverCountFill}
                            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                        <CheckIcon style={{color: "white", marginTop: "-80px", marginLeft: "5px", visibility: approverCountCheck}} sx={{ fontSize: 48 }}/>
                    </div>
                    <div className="vl_button" style={{margin: "895px 0px 0px -25px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill={messagePriceFill}
                            stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                        <CheckIcon style={{color: "white", marginTop: "-80px", marginLeft: "5px", visibility: messagePriceCheck}} sx={{ fontSize: 48 }}/>
                    </div>
                    <div className="vl_button" style={{margin: "1075px 0px 0px -25px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="black"
                            stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                    <div className="vl"></div>

                </div>
                <div style={{marginLeft: "10px", width: "370px"}}>
                    <label className="eventEditCalloutTitle">Message Event Overview</label>

                    <div className="grid" style={{border: "1px black solid", padding: "5px", borderRadius: "10px", marginRight: "20px"}}>
                        <label className="userEditTitle">Event Status:</label>
                        <label className="eventEditCalloutInformation" style={{maxWidth: "330px", fontSize: "20px", overflowY: "hidden"}}
                            id="eventStatus" name="eventStatus">{
                                status.length != 0 ? status : "new"
                            }</label>
                    </div>

                    {/*} MESSAGE CORE INFO *********************************** {*/}
                    <div style={{margin:"15px 0px 0px 0px"}}>
                        <label className="eventEditCalloutSectionTitle">Message Event Core Information</label>
                    </div>
                    <div className="grid">
                        <label className="userEditTitle">Event Name:</label>
                        <label className="eventEditCalloutInformation" style={{maxWidth: "430px"}} id="eventName" name="eventName">{eventName}</label>
                    </div>
                    <div className="grid" style={{margin: "15px 0px 0px 0px"}}>
                        <label className="userEditTitle">Campaign Name:</label>
                        <label className="eventEditCalloutInformation" id="campaignName" name="campaignName" style={{maxWidth: "400px", width: "400px"}}>{campaignName}</label>
                    </div>
                    <div className="flex">
                        <div className="grid" style={{margin: "15px 0px 0px 0px"}}>
                            <label className="userEditTitle">Campaign Location (state):</label>
                            <label className="eventEditCalloutInformation" id="campaignStateAbbr" name="campaignStateAbbr">{campaignStateAbbr}</label>
                        </div>
                        <div className="grid" style={{margin: "15px 0px 0px 0px"}}>
                            <label className="userEditTitle">Project Code:</label>
                            <label className="eventEditCalloutInformation" id="projectCode" name="projectCode">{project_code}</label>
                        </div>

                    </div>
                    <hr className="eventEditCalloutHR" />

                    {/*} MESSAGE UNIVERSE *********************************** {*/}
                    <div style={{margin:"20px 0px 0px 0px"}}>
                        <label className="eventEditCalloutSectionTitle">Message Universe</label>
                    </div>
                    <div className="flex">
                    <div className="grid">
                        <label className="userEditTitle">Mosaic Universe Size:</label>
                        <label className="eventEditCalloutInformation" id="cleanedCount" name="cleanedCount">{mosaicUniverseSize}</label>
                    </div>
                    {/*}
                    <div className="grid" style={{marginLeft: "0px"}}>
                        <label className="userEditTitle">Original Universe Size:</label>
                        <label className="eventEditCalloutInformation" id="cleanedCount" name="cleanedCount">{cleanedCount}</label>
                    </div>
                    {*/}
                    </div>
                    <hr className="eventEditCalloutHR" />

                    {/*} TIMING ********************************************** {*/}
                    <div style={{margin:"20px 0px 0px 0px"}}>
                        <label className="eventEditCalloutSectionTitle">Timing/Scheduling</label>
                    </div>
                      <div className="flex">
                        <div className="grid">
                            <label className="userEditTitle">Start Date/Time:</label>
                            <label className="eventEditCalloutInformation" id="eventStartDateTime" name="eventStartDateTime">
                                {
                                    timezoneUniv == 'system'
                                        ? ('Not specified yet')
                                        : dayjs.utc(start_datetime).tz(timezoneUniv).format('MM/DD/YYYY hh:mm a')

                                }
                            </label>
                        </div>
                        <div className="grid" style={{marginLeft: "0px"}}>
                            <label className="userEditTitle">Time Zone:</label>
                            <label className="eventEditCalloutInformation" id="timeZone" name="timeZone">
                                {
                                    timezone == 'system'
                                        ? ('Not specified yet')
                                        : (timezone)
                                }
                            </label>
                        </div>
                    </div>


                    <hr className="eventEditCalloutHR" />

                    {/*} MESSAGE LENGTH INFO ********************************************** {*/}
                    <label className="eventEditCalloutSectionTitle">Message Type/Length Information</label>

                    <div className="flex">
                        <div className="grid">
                            <label className="userEditTitle">Message Type:</label>
                            <label className="eventEditCalloutInformation" id="messageType" name="messageType">
                                {
                                    sms != null && sms.length > 0 ?
                                    (
                                        media_type != null && media_type.length > 0
                                        ? ( media_type == 'video/mp4'
                                                ? ('MMS + Crystal')
                                                : ('MMS')
                                          )
                                        : ('SMS')
                                    )
                                    :
                                    (
                                        media_type != null && media_type.length > 0
                                        ? ( media_type == 'video/mp4'
                                                ? ('MMS + Crystal')
                                                : ('MMS')
                                          )
                                        :
                                          ('n/a')
                                    )
                                }
                            </label>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="grid">
                            <label className="userEditTitle">SMS Character Count:</label>
                            <label className="eventEditCalloutInformation" id="characterCount" name="smsCharacterCount">{smsCharacterCount}</label>
                        </div>
                        <div className="grid" style={{marginLeft: "0px"}}>
                            <label className="userEditTitle">SMS Segment Count:</label>
                            <label className="eventEditCalloutInformation" id="segmentCount" name="smsSegmentCount">{sms_segments}</label>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="grid">
                            <label className="userEditTitle">Crystal Segment Count:</label>
                            <label className="eventEditCalloutInformation" id="segmentCount" name="smsSegmentCount">
                                {
                                    crystalSegment
                                }
                            </label>
                        </div>
                    </div>
                    <hr className="eventEditCalloutHR" />

                    {/*} TRANSMISSION INFORMATION ********************************************** {*/}
                    <div style={{margin:"20px 0px 0px 0px"}}>
                        <label className="eventEditCalloutSectionTitle">Transmission Information</label>
                    </div>
                    <div className="flex">
                        <div className="grid">
                            <label className="userEditTitle">Selected Area Code</label>
                            <label className="eventEditCalloutInformation" id="preferredAreaCodeDisp" name="preferredAreaCodeDisp">
                                {
                                    (!thisEventPhoneInfo.area_code || thisEventPhoneInfo.area_code.length == 0 || thisEventPhoneInfo.area_code == '999')
                                        ? ('Not specified yet')
                                        : (thisEventPhoneInfo.area_code)
                                }
                            </label>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="grid">
                            <label className="userEditTitle">Admin Phone:</label>
                            <label className="eventEditCalloutInformation" id="adminPhoneNumber" name="adminPhoneNumber">
                                {
                                    (thisEventPhoneInfo.admin_phone_number && thisEventPhoneInfo.admin_phone_number.trim().length)
                                        ? thisEventPhoneInfo.admin_phone_number
                                        : "Not provisioned yet"
                                }</label>
                        </div>

                        <div className="grid" style={{marginLeft: "0px"}}>
                            <label className="userEditTitle">Transmission Phone:</label>
                            <label className="eventEditCalloutInformation" id="transmissionPhoneNumber" name="transmissionPhoneNumber">
                                {
                                    (thisEventPhoneInfo.customer_phone_number && thisEventPhoneInfo.customer_phone_number.length)
                                        ? thisEventPhoneInfo.customer_phone_number
                                        : "Not provisioned yet"}</label>
                        </div>
                    </div>



                    <hr className="eventEditCalloutHR" />

                    {/*} APPROVER COUNT INFORMATION ********************************************** {*/}
                    <div style={{margin:"20px 0px 0px 0px"}}>
                        <label className="eventEditCalloutSectionTitle">Approvers</label>
                    </div>
                    <div className="flex">
                        <div className="grid">
                            <label className="userEditTitle">Approver Count</label>
                            <label className="eventEditCalloutInformation" id="ApproverCount" name="ApproverCount">
                                {
                                    thisEventApprovalInfo.approval_numbers ?
                                        (thisEventApprovalInfo.approval_numbers.length)
                                    :
                                        ("0")
                                }
                            </label>
                        </div>
                    </div>

                    <hr className="eventEditCalloutHR" />

                    {/*} PRICE PER MESSAGE ********************************************** {*/}
                    <label className="eventEditCalloutSectionTitle">Price Per Message</label>


                    <div className="grid">
                        <label className="userEditTitle">Crystal Message Processing:</label>
                        <label className="eventEditCalloutInformation" id="crystalMessageProcessing" name="crystalMessageProcessing">
                        {
                         thisEventMessageBodyInfo && thisEventMessageBodyInfo.crystalSegment
                            ? (thisEventMessageBodyInfo.media_type == "video/mp4"
                                    ? .005
                                    : 0
                              )
                              :
                              (
                                    0
                              )
                        }
                        </label>
                    </div>

                    <div className="grid">
                        <label className="userEditTitle">MSG Unit Price:</label>
                        <label className="eventEditCalloutInformation" id="msgUnitPrice" name="msgUnitPrice">
                            {
                                thisEventMessagePricingInfo.unitPrice > 0
                                    ? ("$" + thisEventMessagePricingInfo.unitPrice)
                                    : ("$0.00")
                            }</label>

                    </div>
                    <hr className="eventEditCalloutHR" />

                    <div style={{background: "white", padding: "10px 10px 1px 10px", margin: "-17px 0px 0px -10px", borderRadius: "10px"}}>
                        <label className="eventEditCalloutSectionTitle">TOTAL EVENT PRICE</label>


                        <div className="grid">
                            <label className="eventEditFinalPriceInformation" id="eventPrice" name="eventPrice">
                            {
                                thisEventMessagePricingInfo.unitPrice > 0 && mosaicUniverseSize > 0
                                    ? (Intl.NumberFormat('en-US', {style:'currency', currency:'USD'}).format(thisEventMessagePricingInfo.unitPrice * mosaicUniverseSize))
                                    : (Intl.NumberFormat('en-US', {style:'currency', currency:'USD'}).format(0))
                            }
                            </label>
                        </div>
                        <hr className="eventEditCalloutHR" style={{marginTop: "-7px"}}/>
                        <hr className="eventEditCalloutHR" style={{marginTop: "-10px"}}/>
                    </div>
                </div>
            </div>
        </div>
        </div>

	);

};

export default AmplifyEventStepsCalloutMenu;
