import { useContext } from "react";
import PropTypes from "prop-types";
import { EventReportContext } from "../../context/EventReportContext";
import { ApplicationContext } from "../../context/ApplicationContext";

//eslint-disable-next-line
const EventResultsHeader = ({ CampaignName, EventID, EventName, EventStatus, EventDate }) => {
    const { eventDetails, eventStatus } = useContext(EventReportContext);
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <div>
        { appRenderType == "desktop"?
        (
        <div className="dashboardFullWidthHalfHeightTile" style={{padding: "20px 00px 00px 30px"}}>

            <table >
                <tbody>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Name:</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <div className="eventResultsHeaderEventTitle">{eventDetails.name}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Campaign Name:</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Status:</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderBody" style={{width: "300px"}}>{eventDetails.campaign}</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderBody">{
                                eventStatus
                                //eventDetails.status
                            }</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Event ID:</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Date/Time:</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderBody">{eventDetails.id}</div>
                        </td>

                        <td>
                            <div className="eventResultsHeaderBody">{eventDetails.dtz_string}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        )
        : appRenderType == "tablet"?
        (
        <div className="dashboardDoubleWidthHalfHeightTile" style={{padding: "20px 00px 00px 30px"}}>

            <table >
                <tbody>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Name:</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <div className="eventResultsHeaderEventTitle">{eventDetails.name}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Campaign Name:</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Status:</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderBody" style={{width: "300px"}}>{eventDetails.campaign}</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderBody">{
                                eventStatus
                                //eventDetails.status
                            }</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Event ID:</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Date/Time:</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderBody">{eventDetails.id}</div>
                        </td>

                        <td>
                            <div className="eventResultsHeaderBody">{eventDetails.dtz_string}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        )
        : appRenderType == "phone"?
        (
        <div className="dashboardDoubleWidthHalfHeightTile" style={{padding: "20px 00px 00px 30px"}}>

            <table >
                <tbody>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Name:</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4">
                            <div className="eventResultsHeaderEventTitle_Phone">{eventDetails.name}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Campaign Name:</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Status:</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderBody_Phone">{eventDetails.campaign}</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderBody_Phone">{
                                eventStatus
                                //eventDetails.status
                            }</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderTitle">Event ID:</div>
                        </td>
                        <td>
                            <div className="eventResultsHeaderTitle">Event Date/Time:</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="eventResultsHeaderBody_Phone">{eventDetails.id}</div>
                        </td>

                        <td>
                            <div className="eventResultsHeaderBody_Phone">{eventDetails.dtz_string}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        )
        :
        (
            <div></div>
        )
        }
        </div>
    );
};

EventResultsHeader.propTypes = {
    CampaignName: PropTypes.string.isRequired,
    EventID: PropTypes.number.isRequired,
    EventName: PropTypes.string.isRequired,
    EventStatus: PropTypes.string.isRequired,
    EventDate: PropTypes.string.isRequired,
};

export default EventResultsHeader;
