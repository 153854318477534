import { useEffect, useContext, useState, useRef} from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { CampaignContext } from "../../context/CampaignContext";
import { UnifyContext } from "../../context/UnifyContext";
import { ApplicationContext } from "../../context/ApplicationContext";

import { useNavigate } from "react-router-dom";

import Topbar from "../../components/topbar/Topbar";

import Hierarchy_navigation from "../../components/navigation/Hierarchy_navigation";

import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';

export default function Unify_Home() {

    const { jwt, roles } = useContext(AuthContext);
    const { companyName } = useContext(CompanyContext);
    const { campaignName } = useContext(CampaignContext);
    const { reportWindowSize, appRenderType, updateApplicationSegment } = useContext(ApplicationContext);
    const { createNewId } = useContext(UnifyContext);

    //eslint-disable-next-line
    const [displayWidth, setDisplayWidth] = useState(0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    //eslint-disable-next-line
    const [isAFCUser, setIsAFCUser] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        updateApplicationSegment("Unify");
        createNewId();
        reportWindowSize();
        setDisplayWidth(windowSize.current[0]);
        validateAdmin();
    }, []);

    if (jwt === null) {
        window.location.href = "/login";
    }

    useEffect(() => {
        document.title = "Alliance Forge | Unify Home";
    //console.log("JWT = ", jwt);
    }, [jwt]);

    function validateAdmin() {
        for (let i=0; i < roles.length; i++) {
            if (roles[i].startsWith("sec:afc") || roles[i].startsWith("sec:globaladmin")) {
                setIsAFCUser(true);
                return true;
            }
        }
        return false;
    }

    function gotoTemplates(){
        navigate("/unifyTemplateSelection");
    }

    return (
        <div>
            {appRenderType == "desktop" ?
            <div className="mainPageDiv">

                <div className="accountHeader">

                </div>
                <div className="accountHeaderImage" style={{ position: "relative", zIndex: 10 }}>
                    <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "100px", height: "100px", border: "5px solid #555"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/skunkWorks.jpg"/>
                </div>
                <div className="accountHeaderCompanyName">
                    <div className="accountHeaderCompanyNameTitle_Mobile">{"//"}{(companyName != null) ? (companyName.toLowerCase()) : ''}{"/"}{(companyName != null) ? (campaignName.toLowerCase()) : ''}</div>
                </div>

                <Topbar displayAdmin={false} />

                <div className="flex">

                    <div className="hierarchyNavigationTileFull">
                        <Hierarchy_navigation status={"campaign"} objectWidth={"full"}/>
                    </div>
                </div>

                <div className="mainBody">
                    <div style={{height:" 2000px"}}>

                        <div className="flex" style={{marginLeft: "30px", marginTop: "50px"}}>
                            <div style={{width: "500px", height: "800px", borderColor: "lightgray", borderWidth: "1px", borderRadius: "10px", boxShadow: "5px 3px 10px lightgray"}}>
                                <img style={{width: "500px", height: "800px", objectFit: "none"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/working_man_dark.png"/>
                                <div style={{fontWeight: "bold", fontSize: "36px", marginTop: "-255px", marginLeft: "30px", marginRight: "30px", color: "white", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif"}}>Unify</div>
                                <div style={{fontSize: "32px", marginLeft: "30px", marginRight: "30px", color: "white", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif"}}>you are now ten minutes away from victory</div>
                            </div>

                            <div style={{marginLeft: "20px", width: "940px", height: "800px", borderColor: "lightgray", borderWidth: "2px", borderRadius: "10px", boxShadow: "5px 3px 10px lightgray"}}>
                                <img style={{width: "940px", height: "800px", opacity: ".1"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/tiles.png"/>
                                <div style={{marginTop: "-750px", marginLeft: "30px", marginRight: "30px"}}>
                                    <div style={{fontWeight: "bold", fontSize: "28px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif"}}>Introducing: Unify</div>
                                    <span style={{fontSize: "24px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif"}}>
                                        You signed up for this race... now go win it! Unify is the world&lsquo;s fastest and easiest way to launch your campaign,
                                        schedule your communications, and drive engagement with your constituents.
                                        In ten minutes you can build a website and schedule tens of thousands of voter contacts.
                                        <br/><br/>get back the one thing you can&lsquo;t get enough of... time. Unify will let you reach tens of thousands of voters in less time than it will take you to make a cup of coffee.
                                        <br/><br/>Win more time. Win more connections. Win.
                                    </span>
                                </div>
                            </div>

                            <div style={{marginLeft: "20px", width: "500px", height: "800px", borderColor: "lightgray", borderWidth: "1px", borderRadius: "10px", boxShadow: "5px 3px 10px lightgray"}}>
                                <img style={{width: "500px", height: "800px", objectFit: "none"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/Unify_main_logo_1.png"/>

                            </div>
                        </div>

                        <div className="flex" style={{marginTop: "40px"}}>
                            <div style={{width: "0px"}}></div>
                            <div style={{marginLeft: "20px", width: "400px", height: "800px", borderColor: "lightgray", borderWidth: "1px", borderRadius: "10px", boxShadow: "5px 3px 10px lightgray"}}>
                                <div style={{height: "100px", background: "#102948", borderRadius: "10px 10px 0px 0px", fontSize: "36px", fontWeight: "bold",
                                    padding: "20px 0px 0px 80px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif", color: "white"}}>
                                    GRASSROOTS
                                </div>

                                <div className="flex" style={{marginTop: "50px"}}>
                                    <div style={{fontSize: "36px", fontWeight: "bold",
                                    padding: "0px 0px 0px 105px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif",
                                    color: "#102948"}}>$999.99</div><div style={{color: "#102948", marginTop: "20px"}}>/month</div>
                                </div>

                                <Button variant="outlined" //startIcon={<SaveIcon fontSize="large" />}
                                    style={{height: "60px", width: "220px", margin: "20px 0px 0px 85px", background: "white"}}
                                    size="large"
                                    onClick={() => gotoTemplates()}
                                    >START NOW</Button>

                                <div style={{marginLeft: "30px", marginTop: "30px", marginRight: "30px"}}>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px"}}>Fully functional communication strategy built on the LightningConnect platform</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Custom Landing Page URL</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Customizable Content Areas</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div className= "flex" style={{ marginLeft: "5px", marginTop: "5px"}}><div style={{fontWeight: "bold", color: "red"}}>32,000</div>&nbsp;direct monthly voter contacts</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Full access to the Amplify event reporting suite</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Fully integrated reporting across email, MMS, and microsite traffic</div>  </div>
                                    <div className="flex" style={{marginLeft: "60px"}}><CircleIcon sx={{fontSize: "10px", marginTop: "7px"}}/>&nbsp;&nbsp;<div style={{fontWeight: "bold"}}>4</div>&nbsp;MMS Text Messaging Events for up to 500 recipients per event</div>
                                    <div className="flex" style={{marginLeft: "60px"}}><CircleIcon sx={{fontSize: "10px", marginTop: "7px"}}/>&nbsp;&nbsp;<div style={{fontWeight: "bold"}}>6</div>&nbsp;EMail Message Events for up to 500 recipients per event</div>

                                </div>
                            </div>

                            <div style={{marginLeft: "20px", width: "400px", height: "800px", borderColor: "lightgray", borderWidth: "1px", borderRadius: "10px", boxShadow: "5px 3px 10px lightgray"}}>
                                <div style={{height: "100px", background: "#102948", borderRadius: "10px 10px 0px 0px", fontSize: "36px", fontWeight: "bold",
                                    padding: "20px 0px 0px 90px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif", color: "white"}}>
                                    ORGANIZER
                                </div>

                                <div className="flex" style={{marginTop: "50px"}}>
                                    <div style={{fontSize: "36px", fontWeight: "bold",
                                    padding: "0px 0px 0px 105px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif",
                                    color: "#102948"}}>$1999.99</div><div style={{color: "#102948", marginTop: "20px"}}>/month</div>
                                </div>

                                <Button variant="outlined" //startIcon={<SaveIcon fontSize="large" />}
                                    style={{height: "60px", width: "220px", margin: "20px 0px 0px 85px", background: "white"}}
                                    size="large"
                                    onClick={() => gotoTemplates()}
                                    >START NOW</Button>

                                <div style={{marginLeft: "30px", marginTop: "30px", marginRight: "30px"}}>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px"}}>Fully functional communication strategy built on the LightningConnect platform</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Custom Landing Page URL</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Customizable Content Areas</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div className= "flex" style={{ marginLeft: "5px", marginTop: "5px"}}><div style={{fontWeight: "bold", color: "red"}}>160,000</div>&nbsp;direct monthly voter contacts</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Full access to the Amplify event reporting suite</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Fully integrated reporting across email, MMS, and microsite traffic</div>  </div>
                                    <div className="flex" style={{marginLeft: "60px"}}><CircleIcon sx={{fontSize: "10px", marginTop: "7px"}}/>&nbsp;&nbsp;<div style={{fontWeight: "bold"}}>4</div>&nbsp;MMS Text Messaging Events for up to 10,000 recipients per event</div>
                                    <div className="flex" style={{marginLeft: "60px"}}><CircleIcon sx={{fontSize: "10px", marginTop: "7px"}}/>&nbsp;&nbsp;<div style={{fontWeight: "bold"}}>6</div>&nbsp;EMail Message Events for up to 150,000 recipients per event</div>

                                </div>
                            </div>

                            <div style={{marginLeft: "20px", width: "400px", height: "800px", borderColor: "lightgray", borderWidth: "1px", borderRadius: "10px", boxShadow: "5px 3px 10px lightgray"}}>
                                <div style={{height: "100px", background: "#102948", borderRadius: "10px 10px 0px 0px", fontSize: "36px", fontWeight: "bold",
                                    padding: "20px 0px 0px 120px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif", color: "white"}}>
                                    VICTORY
                                </div>

                                <div className="flex" style={{marginTop: "50px"}}>
                                    <div style={{fontSize: "36px", fontWeight: "bold",
                                    padding: "0px 0px 0px 105px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif",
                                    color: "#102948"}}>$2999.99</div><div style={{color: "#102948", marginTop: "20px"}}>/month</div>
                                </div>

                                <Button variant="outlined" //startIcon={<SaveIcon fontSize="large" />}
                                    style={{height: "60px", width: "220px", margin: "20px 0px 0px 85px", background: "white"}}
                                    size="large"
                                    onClick={() => gotoTemplates()}
                                    >START NOW</Button>

                                <div style={{marginLeft: "30px", marginTop: "30px", marginRight: "30px"}}>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px"}}>Fully functional communication strategy built on the LightningConnect platform</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Custom Landing Page URL</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Customizable Content Areas</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div className= "flex" style={{ marginLeft: "5px", marginTop: "5px"}}><div style={{fontWeight: "bold", color: "red"}}>320,000</div>&nbsp;direct monthly voter contacts</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Full access to the Amplify event reporting suite</div></div>
                                    <div className="flex"><CheckIcon sx={{fontSize: "36px"}}/><div style={{ marginLeft: "5px", marginTop: "5px"}}>Fully integrated reporting across email, MMS, and microsite traffic</div>  </div>
                                    <div className="flex" style={{marginLeft: "60px"}}><CircleIcon sx={{fontSize: "10px", marginTop: "7px"}}/>&nbsp;&nbsp;<div style={{fontWeight: "bold"}}>4</div>&nbsp;MMS Text Messaging Events for up to 20,000 recipients per event</div>
                                    <div className="flex" style={{marginLeft: "60px"}}><CircleIcon sx={{fontSize: "10px", marginTop: "7px"}}/>&nbsp;&nbsp;<div style={{fontWeight: "bold"}}>6</div>&nbsp;EMail Message Events for up to 300,000 recipients per event</div>

                                </div>
                            </div>

                            <div style={{marginLeft: "20px", width: "400px", height: "800px", borderColor: "lightgray", borderWidth: "1px", borderRadius: "10px", boxShadow: "5px 3px 10px lightgray"}}>
                                <div style={{height: "100px", background: "#102948", borderRadius: "10px 10px 0px 0px", fontSize: "36px", fontWeight: "bold",
                                    padding: "20px 0px 0px 120px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif", color: "white"}}>
                                    CUSTOM
                                </div>

                                <div className="flex" style={{marginTop: "50px"}}>
                                    <div style={{fontSize: "36px", fontWeight: "bold",
                                    padding: "0px 0px 0px 125px", fontFamily: "Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif",
                                    color: "#102948"}}>CALL US</div>
                                </div>

                                <Button variant="outlined" //startIcon={<SaveIcon fontSize="large" />}
                                    style={{height: "60px", width: "220px", margin: "20px 0px 0px 85px", background: "white"}}
                                    size="large"
                                    //onClick={() => saveEvent()}
                                    >CONTACT SALES</Button>

                                <div style={{marginLeft: "30px", marginTop: "30px", marginRight: "30px"}}>
                                    <div style={{ marginLeft: "5px"}}>Like what you see but need something bigger? Something more specific to your needs?
                                        Contact our knowledgable team to get started today.</div>
                                </div>
                            </div>

                        </div>




                    </div>
                </div>

                <footer>
                    <div className="signinFooter bg-neutral align-bottom" style={{height: "200px", background: "#102948"}}>
                        <div className="flex">
                            <div className="mt-8" >
                                <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                            </div>
                            <div className="signinFooterTagLineRight" >
                                <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/unify_rwu_transparent.png"/>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>
            : appRenderType=="tablet" ?

            <div>

            </div>
            :
            <div>

            </div>

            }
        </div>

        );

}
