import { useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

const PerSuccessful = ({ PerSuccess }) => {
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <div>
            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="dashboardHalfHeightTile flex">
                <div style={{margin: "50px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none"
                        stroke="#0b1e91" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                </div>
                <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                    <div className="dashboardTileTitle">Percentage Successful</div>
                    <div className="dashboardTileMainData">{PerSuccess}</div>
                    <div className="dashboardTileSubtext">Percentage of successfully delivery</div>
                </div>

            </div>
            :
            <div className="dashboardHalfHeightTile_Phone flex">
                <div style={{margin: "22px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 24 24" fill="none"
                        stroke="#0b1e91" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                </div>
                <div className="grid" style={{margin: "10px 0px 0px 20px", height: "80px"}}>
                    <div className="dashboardTileTitle_Phone">Percentage Successful</div>
                    <div className="dashboardTileMainData_Phone">{PerSuccess}</div>
                    <div className="dashboardTileSubtext_Phone">Percentage of successfully delivery</div>
                </div>

            </div>
            }
        </div>
    );
};

PerSuccessful.propTypes = {
    PerSuccess: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default PerSuccessful;
