import { useContext, useState, useEffect } from "react";

import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import { useNavigate } from "react-router-dom";

import { AmplifyEventContext } from "../../context/AmplifyEventContext";

import Avatar from "@mui/material/Avatar";
import red from "@mui/material/colors/red";
import HomeIcon from "@mui/icons-material/Home";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AFCAdminMenu from "./AFCAdminMenu";
import PropTypes from "prop-types";


//FOR THE PHONE, BRING IN A HAMBURGER MENU
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from '@mui/icons-material/Dashboard';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';

const Topbar = ({displayAdmin}) => {
    const { jwt, dispatch, roles, first_name, last_name } = useContext(AuthContext);
    const { clearAllExistingEventValues } = useContext(AmplifyEventContext);
    const { appRenderType, applicationSegmment } = useContext(ApplicationContext);

    const navigate = useNavigate();

    const [openMenu, setOpenMenu] = useState(false);

    // Account menu
    const [isAFCUser, setIsAFCUser] = useState(false);
    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    const accountOpen = Boolean(accountAnchorEl);
    const handleAccountClick = (event) => {
        setAccountAnchorEl(event.currentTarget);
    };
    const handleAccountClose = () => {
        setAccountAnchorEl(null);
    };

    const logoutClick = async (event) => {
        event.preventDefault();
        dispatch({ type: "LOGOUT" });
        handleAccountClose();
    };

    async function navigateHome(){
        clearAllExistingEventValues();
        navigate("/");
    }

    async function navigateToAmplifyDashboard(){
        clearAllExistingEventValues();
        navigate("/amplifyDashboard");
    }

    async function navigateToUnifyHome(){
        clearAllExistingEventValues();
        navigate("/unifyHome");
    }

    useEffect(() => {

        switch(applicationSegmment){
        case "Amplify":
            break;
        }

    }, [applicationSegmment])

    useEffect(() => {
        validateAdmin();
    }, [roles]);

    function AvatarNameFunction() {
        if (jwt != null) {
            // Build the Avatar value. First letter of both first and last name
            let nameBuilder = "";
            if (first_name.length > 0) {
                nameBuilder += first_name[0].toUpperCase();
            }
            if (last_name.length > 0) {
                nameBuilder += last_name[0].toUpperCase();
            }

            return nameBuilder;
        }

        return "";
    }

    function validateAdmin() {
        for (let i=0; i < roles.length; i++) {
            if (roles[i].startsWith("sec:afc") || roles[i].startsWith("sec:globaladmin")) {
                setIsAFCUser(true);
                return true;
            }
        }
        return false;
    }

    //DRAWER MENU CONTROL
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        //changes the function state according to the value of open
        setOpenMenu(open);
    };


    return (
        <div className={` ${
                appRenderType == "desktop" ?
                    ("topBar w-full h-12 z-30 bg-navbar-bg shadow sticky top-0")
                : appRenderType == "tablet" ?
                    ("topBarTablet w-full h-12 z-30 bg-navbar-bg shadow sticky top-0")
                :
                    ("topBar_Phone w-full h-12 z-30 bg-navbar-bg shadow sticky top-0")

        }`} style={{ zIndex: 1 }}>
        { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="h-full flex px-5 py-0 items-center justify-between">
                {appRenderType == "desktop" ?
                <div className="flex">
                    <div className="grid" style={{margin: "0px 0px 0px 185px", cursor: "pointer"}} onClick={() => navigateHome()}>
                        <HomeIcon className="mr-2 h-9 w-9 text-btnmain-text" aria-hidden="true" style={{marginTop: "8px"}} />
                        <div style={{fontSize: "8px", marginLeft: "2px"}}>home</div>
                    </div>
                    {applicationSegmment == "Amplify" ?
                    <div className="flex" style={{cursor: "pointer"}} onClick={() => navigateToAmplifyDashboard()}>
                        <div className="menuDivider"/>
                        <div className="grid">
                            <DashboardIcon className="mr-2 ml-2 h-9 w-9 text-btnmain-text" aria-hidden="true" style={{marginTop: "8px"}} />
                            <div style={{fontSize: "8px", marginLeft: "7px"}}>amplify</div>
                        </div>
                    </div>
                    : applicationSegmment == "Unify" ?
                    <div className="flex" style={{cursor: "pointer"}} onClick={() => navigateToUnifyHome()}>
                        <div className="menuDivider"/>
                        <div className="grid" >
                            <DisplaySettingsIcon className="mr-2 ml-2 h-9 w-9 text-btnmain-text" aria-hidden="true" style={{marginTop: "8px"}} />
                            <div style={{fontSize: "8px", marginLeft: "12px"}}>unify</div>
                        </div>
                    </div>
                    :
                    <div/>
                    }
                </div>
                : appRenderType == "tablet" ?
                <div className="flex" style={{margin: "0px 0px 0px 150px"}}>
                    <div className="grid" style={{margin: "0px 4px 0px 0px", cursor: "pointer"}} onClick={() => navigateHome()}>
                        <HomeIcon
                            className="mr-2 h-9 w-9 text-btnmain-text"
                            aria-hidden="true"  style={{marginTop: "8px", cursor: "pointer"}} onClick={() => navigateHome()}
                        />
                        <div style={{fontSize: "8px", marginLeft: "2px"}}>home</div>
                    </div>
                    {applicationSegmment == "Amplify" ?
                    <div className="flex" style={{cursor: "pointer"}} onClick={() => navigateToAmplifyDashboard()}>
                        <div className="menuDivider"/>
                        <div className="grid" >
                            <DashboardIcon className="mr-2 ml-2 h-9 w-9 text-btnmain-text" aria-hidden="true" style={{marginTop: "8px"}} />
                            <div style={{fontSize: "8px", marginLeft: "8px"}}>amplify</div>
                        </div>
                    </div>
                    : applicationSegmment == "Unify" ?
                    <div className="flex" style={{cursor: "pointer"}} onClick={() => navigateToUnifyHome()}>
                        <div className="menuDivider"/>
                        <div className="flex" >
                            <DisplaySettingsIcon className="mr-2 ml-2 h-9 w-9 text-btnmain-text" aria-hidden="true" style={{marginTop: "8px"}} />
                        </div>
                    </div>
                    :
                    <div/>
                    }
                </div>
                :
                <div style={{margin: "0px 0px 0px 100px", cursor: "pointer"}} onClick={() => navigateHome()}>
                    <HomeIcon
                        className="mr-2 h-9 w-9 text-btnmain-text"
                        aria-hidden="true"
                    />
                </div>
                }
                {/*}
                {displayAdmin && isAFCUser === true ?
                <div className="justify-between">
                    <AFCAdminMenu/>
                </div>
                :
                <div />
                }
                {*/}
                <div className="flex items-center">
                    {/*
                    <div className="mr-2.5 relative cursor-pointer">
                        <NotificationsNoneIcon />
                        <span className="flex justify-center h-4 absolute right-0 text-white w-4 rounded-full top-n5 bg-red-900 text-xs">
                            2
                        </span>
                    </div>
                    <div className="mr-2.5 relative cursor-pointer">
                        <LanguageIcon />
                    </div>
                    <div className="mr-2.5 relative cursor-pointer">
                        <SettingsIcon />
                    </div>
                    */}

                    <Avatar
                        className="h-10 w-10 cursor-pointer rounded-full"
                        sx={{ bgcolor: red[500] }}
                        aria-controls={accountOpen ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={accountOpen ? "true" : undefined}
                        onClick={handleAccountClick}
                    >
                        <AvatarNameFunction />
                    </Avatar>
                    <ArrowDropDownIcon
                        className="ml-2 h-5 w-5 text-blue-900"
                        aria-controls={accountOpen ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={accountOpen ? "true" : undefined}
                        onClick={handleAccountClick}
                    />

                    <Menu
                        id="account-menu"
                        anchorEl={accountAnchorEl}
                        open={accountOpen}
                        onClose={handleAccountClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={handleAccountClose}>
                            <PersonIcon
                                className="mr-2 h-5 w-5 text-btnmain-text"
                                aria-hidden="true"
                            />
                            Profile
                        </MenuItem>
                        {displayAdmin && isAFCUser === true ?
                            (
                            <div>
                                <Divider sx={{mb: 2, ml: 2}} />
                                <div className="justify-between" style={{marginLeft: "20px", marginRight: "20px"}}>
                                    <AFCAdminMenu/>
                                </div>
                                <Divider sx={{mb: 2, mt: 3, ml: 2}} />
                            </div>
                            )
                            :
                            <div />
                        }
                        <MenuItem onClick={logoutClick}>
                            <LogoutIcon
                                className="mr-2 h-5 w-5 text-btnmain-text"
                                aria-hidden="true"
                            />
                            Logout
                        </MenuItem>
                    </Menu>
                </div>

            </div>
        :
            <div>
                <div style={{marginLeft: "calc(100vw - 35px"}}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleDrawer(true)}
                      sx={{
                        mr: 2,
                        display: {
                          xs: 'block',
                          sm: 'none',
                        }
                      }}
                    >
                      <MenuIcon />
                    </IconButton>

                    <Drawer
                      //from which side the drawer slides in
                      anchor="right"
                      //if open is true --> drawer is shown
                      open={openMenu}
                      //function that is called when the drawer should close
                      onClose={toggleDrawer(false)}
                      //function that is called when the drawer should open
                      onOpen={toggleDrawer(true)}
                    >
                        {/* The inside of the drawer */}
                        <Box sx={{
                          p: 2,
                          height: 1,
                          backgroundColor: "white",
                        }}>

                          {/*
                          when clicking the icon it calls the function toggleDrawer
                          and closes the drawer by setting the variable open to false
                          */}
                          <IconButton sx={{mb: 2}}>
                            <CloseIcon onClick={toggleDrawer(false)} />
                          </IconButton>

                          <Divider sx={{mb: 2}} style={{marginBottom: "5px", marginTop: "5px"}}/>

                          <Box sx={{mb: 2}}>
                            <ListItemButton>
                              <ListItemIcon>
                                <HomeIcon onClick={() => navigateHome()} sx={{color: "black"}}/>
                              </ListItemIcon>
                              <ListItemText primary="Home" />
                            </ListItemButton>

                            {applicationSegmment == "Amplify" ?
                            <div>
                                <Divider style={{marginBottom: "5px", marginTop: "5px"}}/>
                                <ListItemButton>
                                  <ListItemIcon>
                                    <DashboardIcon onClick={() => navigateToAmplifyDashboard()} sx={{color: "black"}}/>
                                  </ListItemIcon>
                                  <ListItemText primary="Amplify Dashboard" />
                                </ListItemButton>
                            </div>
                            : applicationSegmment == "Unify" ?
                            <div>
                                <Divider style={{marginBottom: "5px", marginTop: "5px"}}/>
                                <ListItemButton>
                                  <ListItemIcon>
                                    <DisplaySettingsIcon onClick={() => navigateToUnifyHome()} sx={{color: "black"}}/>
                                  </ListItemIcon>
                                  <ListItemText primary="Unify Home" />
                                </ListItemButton>
                            </div>
                            :
                            <div />
                            }

                            <ListItemButton>
                              <ListItemIcon>
                                <PersonIcon sx={{color: "black"}}/>
                              </ListItemIcon >
                              <ListItemText primary="Profile" />
                            </ListItemButton>

                            {displayAdmin && isAFCUser === true ?
                                (
                                <div>
                                    <Divider sx={{mb: 2}} />
                                    <div className="justify-between">
                                        <AFCAdminMenu/>
                                    </div>
                                    <Divider sx={{mb: 2, mt: 3}} />
                                </div>
                                )
                                :
                                <div />
                            }

                            <Box sx={{
                                display: "flex",
                                justifyContent:"center",
                                position: "absolute",
                                bottom: "0",
                                left: "35%",
                                transform: "translate(-50%, 0)"}}
                            >
                                <ListItemButton>
                                  <ListItemIcon>
                                    <LogoutIcon onClick={logoutClick} sx={{color: "black"}} />
                                  </ListItemIcon>
                                  <ListItemText primary="Logout" />
                                </ListItemButton>
                            </Box>
                          </Box>


                        </Box>

                    </Drawer>


                </div>
            </div>
        }
        </div>
    );
};

Topbar.propTypes = {
    displayAdmin: PropTypes.bool,
};

export default Topbar;
