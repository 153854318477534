import { React, useContext } from "react";
import { DataMapperContext } from "../../context/DataMapperContext";

import DataMapper_MapperStatsDonutChart from "./DataMapper_MapperStatsDonutChart";

const DataMapperStats = () => {
    const { statsFile, closeDataMapperInitialStats } = useContext(DataMapperContext);

    function closeChildForm(){
        closeDataMapperInitialStats();
    }

    return (
        <div>
            <div className="datamapperFileRemove" style={{marginTop: "10px", marginLeft: "600px"}} onClick={(e) => closeChildForm(e)}>X</div>
            <div className="collapse-title text-xl font-bold" style={{marginTop: "-40px", maxWidth: "600px"}}>
                MOSAIC PHONE NUMBER MAPPING RESULTS
            </div>

            <hr style={{marginTop: "-10px"}}/>

            <div>
                <div className="dataMapperInitialStats grid">
                    <div className="flex" style={{height: "40px"}}>
                        <div className="dataMapperInitialStatsTitle">Original Total Phone Numbers ..................................................................</div>
                        <div className="dataMapperInitialStatsData">
                        {
                            statsFile.data == null ?
                                (0)
                            :
                            (   statsFile.data != null && statsFile.data.incoming_count != null ?
                                    Number(statsFile.data.incoming_count.total).toLocaleString()
                                :
                                    (0)
                            )
                        }
                        </div>
                    </div>
                    <div className="flex" style={{marginTop: "0px", height: "40px"}}>
                        <div className="dataMapperInitialStatsTitle">Removed Count ..........................................................................................</div>
                        <div className="dataMapperInitialStatsData">
                        {
                            statsFile.data != null && statsFile.data.removed_count != null ?
                                Number(statsFile.data.removed_count.total).toLocaleString()
                            :
                                (0)
                        }
                        </div>
                    </div>
                    <hr style={{marginTop: "-10px"}}/>
                    <div className="flex" style={{marginTop: "0px", height: "40px"}}>
                        <div className="dataMapperInitialStatsTitle">Final Cleaned Count ...................................................................................</div>
                        <div className="dataMapperInitialStatsData">
                        {
                            statsFile.data != null && statsFile.data.cleaned_count != null ?
                                Number(statsFile.data.cleaned_count.total).toLocaleString()
                            :
                                (0)
                        }
                        </div>
                    </div>
                    <hr style={{marginTop: "-5px"}}/>
                    <hr style={{marginTop: "-8px"}}/>

                </div>

            </div>

            <div style={{width: "600px"}}>
                <DataMapper_MapperStatsDonutChart />
            </div>

        </div>

    );


};

export default DataMapperStats;
