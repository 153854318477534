import { React, useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import { AuthContext } from "../../context/AuthContext";

import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';

const Session_LogoutImminent = () => {
    const { timeDisplay, setCloseLogoutModalAndReset } = useContext(ApplicationContext);
    const { dispatch } = useContext(AuthContext);

    // let warningInactiveInterval_Imminent = useRef();

    function closeChildForm(){
        setCloseLogoutModalAndReset(uuidv4());
    }

    function logout(){
        dispatch({ type: "LOGOUT" });
    }

    return (
        <div >
            <div className="datamapperFileRemove" style={{marginTop: "10px", marginLeft: "455px"}} onClick={(e) => closeChildForm(e)}>X</div>
            <div className="LogoffImminent_modalWindow_title">
                Session Timeout/Logoff
            </div>

            <div style={{marginTop: "40px"}}>

                <div className="flex" style={{marginLeft: "30px"}}>
                    <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify/clock_icon_black.gif" style={{height: "50px", width: "55px"}}/>
                    <div className="LogoffImminent_modalWindow_text" style={{marginTop: "0px", marginLeft: "20px", fontSize: "24px"}}>
                        Your session will expire in
                    </div>
                </div>
                <hr style={{marginTop: "20px"}}/>
                <div className="LogoffImminent_modalWindow_countdown">
                    <div>{timeDisplay}</div>
                </div>
                <hr/>

                <div className="LogoffImminent_modalWindow_text">
                    <div>
                        Please click &quot;stay signed in&quot; to keep working or &quot;sign out&quot; to end our session now.
                    </div>
                </div>

                <div className="flex" style={{background: "lightgray", height: "130px", marginTop: "20px"}}>
                    <Button variant="outlined"
                        style={{height: "60px", width: "200px", margin: "32px 0px 0px 35px", background: "darkgray", color: "white"}}
                        size="medium" onClick={() => logout()}>Sign Out</Button>

                    <Button variant="outlined"
                        style={{height: "60px", width: "200px", margin: "32px 0px 0px 20px", background: "#0099ff", color: "white"}}
                        size="medium" onClick={() => closeChildForm()}>Stay Signed In</Button>
                </div>

            </div>
        </div>
    );


};

export default Session_LogoutImminent;
