import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { AdminContext } from "./AdminContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

export const CompanyContext = createContext();

export const CompanyContextProvider = ({ children }: any) => {
    const { jwt, roles, dispatch } = useContext(AuthContext);
    const { businesses, getBusiness } = useContext(AdminContext);

    const [companyID, setCompanyID] = useState(-1);
    const [companyName, setCompanyName] = useState('');
    const [logoURL, setLogoURL] = useState('');
    const [companyKey, setCompanyKey] = useState('');
    const [allBusinessCampaigns, setAllBusinessCampaigns] = useState([]);
    const [campaignDataDirty, setCampaignDataDirty] = useState(false);

    // toggle this to force re-reading and re-calculating info from DBs
    // (typically because an event changed in the database)
    const [refreshCompanyContext, setRefreshCompanyContext] = useState(false);

    // groups of campaign keys to reduce the number of queries
    const [allCampaignKeysGrouped, setAllCampaignKeysGrouped] = useState([]);

    // used by components/events/CompanyMsgTypeCounts for main dashboard
    const [msgTypeCountsFromSQL, setMsgTypeCountsFromSQL] = useState([]);

    // used by components/events/CompanyCampaignMsgCounts for main dashboard
    const [campaignMsgCountsFromSQL, setCampaignMsgCountsFromSQL] = useState([]);

    // edr - We're reading and setting these values
    //       but the error about 'unused' makes me think others aren't using the values.
    //eslint-disable-next-line
    const [loadingCompanyStatsFromSQL, setLoadingCompanyStatsFromSQL] = useState(false);

    //eslint-disable-next-line
    const [companyStatsFromSQL, setCompanyStatsFromSQL] = useState(0);

    //eslint-disable-next-line
    const [projectCodeMsgCountsFromSQL, setProjectCodeMsgCountsFromSQL] = useState([]);

    //type campaignState = {stateID: 0, stateAbbr: ''};
    //eslint-disable-next-line
    const [campaignStates, setCampaignStates] = useState([]);

    // edr - this is fixed value for now, later add setting as a user preference
    //eslint-disable-next-line
    const [companyHistoryDaySpan, setCompanyHistoryDaySpan] = useState(30);

    //type campaign = {campaignName: '', latestEventStart: '', pendingCount: 0, stateAbbr: '', stateId: 0, stateName: '', totalEventCount: 0, campaignId: 0, firstEventStart: ''};
    const [companyCampaignsFromSQL, setCompanyCampaignsFromSQL] = useState([]);

    useEffect(() => {
        var tempObject = getInfoFromRoles();
        changeCompany(tempObject.key, tempObject.id);
    }, [roles]);

    // edr - this won't be effective until we rewrite getCompanyCampaigns()
    //       to call Mongo with a business key
    useEffect(() => {
        if (!jwt || !companyKey) {
            return;
        }

        getBusinessCampaigns();
        getCompanyCampaignsFromSQL();

        // hard coded to 30 days for now
        setCompanyHistoryDaySpan(30)
    }, [companyKey]);

    // The list of businesses in AdminContext has changed,
    // only update elements here if they're missing
    useEffect(() => {
        var business;

        if ( (!companyKey || companyKey == '') && (!companyID || companyID < 0) ) {
            var tempObject = getInfoFromRoles();
            business = getBusiness(tempObject.key ? tempObject.key : tempObject.id);
        } else {
            business = getBusiness(companyKey ? companyKey : companyID);
        }

        if (!business) {
            return
        }

        if (!companyKey || companyKey == '') {
            setCompanyKey(business.key);
        }

        if (!companyID || companyID < 0) {
            setCompanyID(business.account_id_legacy);
        }

        if (!companyName || companyName == '') {
            setCompanyName(business.name);
        }

        if (!logoURL || logoURL == '') {
            setLogoURL(business.logo_url);
        }

        if (!companyCampaignsFromSQL || !companyCampaignsFromSQL.length) {
            getCompanyCampaignsFromSQL();
        }
    }, [businesses]);


    useEffect(() => {
        getCompanyStats();
    }, [companyID]);


    // Called by outside elements to check if data is dirty and needs to be re-read
    function checkCompanyContextRefresh() {
        setRefreshCompanyContext(!refreshCompanyContext);
    }

    useEffect(() => {
        if (campaignDataDirty) {
            getBusinessCampaigns();
            getCompanyCampaignsFromSQL();
        }
    }, [refreshCompanyContext]);

    const getCompanyStats = async () => {
        if (!jwt || !companyID || companyID < 0) {
            return;
        }

        getCompanyStatsFromSQL(companyID);
        getMsgTypeCountsFromSQL(companyID);
        getProjectCodeCountsFromSQL(companyID);
        getCampaignCountsFromSQL(companyID);
    };

    // deprecate this (SQL)
    const getCompanyStatsFromSQL = async (companyID) => {
        if (!jwt || !companyID || companyID < 0) {
            return;
        }

        setLoadingCompanyStatsFromSQL(true);
        var new_stats;
        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectAmplifyKeyStatsPerCompany?dayspan=${companyHistoryDaySpan}&company_id=${companyID}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            //console.log("CompanyContext: getCompanyStatsFromSQL response: ", response)
            if (response.data.values.length > 0) {
                new_stats = response.data.values[0];
            }
        });
        setCompanyStatsFromSQL(new_stats);
        setLoadingCompanyStatsFromSQL(false);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    const getMsgTypeCountsFromSQL = async () => {
        if (!jwt || !companyID || companyID < 0 || !companyHistoryDaySpan) {
            return;
        }

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectCompanyMsgTypeCounts?time_window=${companyHistoryDaySpan}&company_id=${companyID}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            // EDR - this looks unnecessary
            // if (res.data.values.length > 0) {
            //     var _a = [];
            //     setMsgTypeCounts([]);
            //     for (let i=0; i < res.data.values.length; i++) {
            //         _a.push(res.data.values[i]);
            //     }
            //     setMsgTypeCounts(_a);
            // }

            // EDR - this should be the same thing
            setMsgTypeCountsFromSQL(response.data.values);
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    const getProjectCodeCountsFromSQL = async () => {
        if (!jwt || !companyID || companyID < 0 || !companyHistoryDaySpan) {
            return;
        }

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectTargetCountsByProjectCode?time_window=${companyHistoryDaySpan}&company_id=${companyID}`,
             {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            // EDR - this looks unnecessary
            // if (res.data.values.length > 0) {
            //     var _p = [];
            //     setProjectCodeMsgCounts([]);
            //     for (let i=0; i < res.data.values.length; i++) {
            //         _p.push(res.data.values[i]);
            //     }
            //     setProjectCodeMsgCounts(_p);
            // }

            // EDR - this should be the same thing
            setProjectCodeMsgCountsFromSQL(response.data.values);
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    const getCampaignCountsFromSQL = async () => {
        if (!jwt || !companyID || companyID < 0 || !companyHistoryDaySpan) {
            return;
        }

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectTargetCountsByCampaignForCompany?time_window=${companyHistoryDaySpan}&company_id=${companyID}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            // EDR - this looks unnecessary
            // if (res.data.values.length > 0) {
            //     var _c = [];
            //     setCampaignMsgCounts([]);
            //     for (let i=0; i < res.data.values.length; i++) {
            //         _c.push(res.data.values[i]);
            //     }
            //     setCampaignMsgCounts(_c);
            // }

            // EDR - this should be the same thing
            setCampaignMsgCountsFromSQL(response.data.values);
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    function getInfoFromRoles() {
        var id;
        var key;

        for (let i=0; i < roles.length; i++) {
            if (roles[i].startsWith("company:")) {
                id = roles[i].split(':',2)[1];
            } else if (roles[i].startsWith("company_key:")) {
                key = roles[i].split(':',2)[1];
            }
        }

        return {id: id, key:key};
    }

    function changeCompany(key, id) {
        if (!key && !id) {
            return;
        }

        var business = getBusiness(key ? key : id);
        if (business) {
            setCompanyKey(business.key);
            setCompanyID(business.account_id_legacy);
            setCompanyName(business.name);
            setLogoURL(business.logo_url);
        }
    }

    //const getCompanyCampaigns = async () => {
    async function getCompanyCampaignsFromSQL() {
        if (!jwt || !companyID || companyID < 0) {
            return;
        }

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectCampaignsByCompanyId?company_id=${companyID}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            var c = [];
            response.data.values.forEach(object => {
                var _c = {
                            campaignName: object['CampaignName'],
                            latestEventStart: object['LatestEventStart'],
                            pendingCount: object['PendingCount'],
                            stateAbbr: object['StateAbbr'],
                            stateId: object['StateID'],
                            stateName: object['StateName'],
                            totalEventCount: object['TotalEventCount'],
                            campaignId: object['campaignID'],
                            firstEventStart: object['FirstStartDate'],
                            LegacyAmplify: object['LegacyAmplify']
                         };
                c.push(_c);
            });
            setCompanyCampaignsFromSQL(c);

            var uniqueStates = Array.from(new Set(c.map((campaign) => campaign.stateAbbr)));
            setCampaignStates(uniqueStates.sort());
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function getBusinessCampaigns() {
        if (!jwt || !companyKey) {
            return;
        }

        var _all_campaigns = [];
        var _args_for_campaigns =
            [
                "business_key=" + companyKey,
                "limit=1000",
                "status=create", "status=creating",
                "status=active"
            ];
        var _arg_string_campaigns = _args_for_campaigns.join("&");
        await axios.get(
            `${config.accountapi.url}/api/v1/campaigns?${_arg_string_campaigns}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            const _group_size = 15;
            var _groups = [];
            var _a_group = [];

            response.data.values.forEach(object => {
                _all_campaigns.push(
                    {
                        key: object['key'],
                        name: object['name'],
                        // latestEventStart
                        // pendingCount
                        stateAbbr: object['state'],
                        // stateId
                        // stateName
                        // totalEventCount
                        legacy_id: object['campaign_id_legacy'],
                        // LegacyAmplify
                        // firstEventStart
                        status: object['status'],
                    }
                );

                // build up campaign groups at the same time
                _a_group.push(object['key']);
                if (_a_group.length == _group_size) {
                    _groups.push(_a_group);
                    _a_group = [];
                }

            });
            // add remainder
            if (_a_group.length > 0) {
                _groups.push(_a_group);
            }

            // save the campaigns and the campaign groups
            setAllBusinessCampaigns(_all_campaigns);
            setAllCampaignKeysGrouped(_groups);
            setCampaignDataDirty(false);
        });

    }

    return (
        <CompanyContext.Provider value={{
                // we know these are used:
                companyStatsFromSQL:                   companyStatsFromSQL,
                companyCampaignsFromSQL:               companyCampaignsFromSQL,
                campaignStates:                        campaignStates,
                changeCompany:                         changeCompany,
                allBusinessCampaigns:                  allBusinessCampaigns,
                allCampaignKeysGrouped:                allCampaignKeysGrouped,
                logoURL:                               logoURL,
                companyKey:                            companyKey,
                companyID:                             companyID,
                companyHistoryDaySpan:                 companyHistoryDaySpan,
                companyName:                           companyName,
                msgTypeCountsFromSQL:                  msgTypeCountsFromSQL,
                campaignMsgCountsFromSQL:              campaignMsgCountsFromSQL,

                setCampaignDataDirty:                  setCampaignDataDirty,
                checkCompanyContextRefresh:            checkCompanyContextRefresh,

                // unsure if these are used

                // projectCodeMsgCounts:                  projectCodeMsgCounts,
                // getCompanyCampaigns:                   getCompanyCampaigns,
            }}
                >
            {children}
        </CompanyContext.Provider>
    );
}
