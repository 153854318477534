import { useEffect, useState, useContext } from "react";
import { DataMapperContext } from "../../context/DataMapperContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);

const DataMapper_MapperStatsDonutChart = () => {
    const { statsFile } = useContext(DataMapperContext);
    const [preppedUniverse, setPreppedUniverse] = useState([]);
    const [detailsUniverse, setDetailsUniverse] = useState([]);

    const colors = Highcharts.getOptions().colors.map((c, i) =>
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        Highcharts.color(Highcharts.getOptions().colors[0])    //"#293f78")//
            .brighten((i - 3) / 7)
            .get()
    );

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    useEffect(() => {

        if(statsFile.data != null){

            var colors = Highcharts.getOptions().colors;
            let errList = Object.keys(statsFile.data.removed_details);
            let loadList = Object.keys(statsFile.data.cleaned_count);

            var _prepped = [];

            var _p = {
                y: statsFile.data.mosaic_count.total,
                color: colors[2],
                name: 'prepped'
            }

            var _p2 = {
                y: statsFile.data.removed_count.total,
                color: colors[4],
                name: 'removed'
            }

            _prepped.push(_p);
            _prepped.push(_p2);

            var _details = [];

            for(let iSuccess=0; iSuccess < loadList.length; iSuccess++){
                var _fileName = loadList[iSuccess];
                var brightness = 0.2 - (iSuccess / loadList.length) / 1.5;
                if(_fileName != "total"){
                    var _success = {
                        y: statsFile.data.cleaned_count[_fileName],
                        name: _fileName,
                        color: Highcharts.color(colors[2]).brighten(brightness).get(),
                    }

                    _details.push(_success);
                }
            }

            for(let i=0; i < errList.length; i++){
                var _o = statsFile.data.removed_details[errList[i]];
                var brightness2 = 0.2 - (i / loadList.length) / 1.5;
                var _r = {
                    y: _o,
                    color: Highcharts.color(colors[4]).brighten(brightness2).get(),
                    name: errList[i]
                }

                _details.push(_r);
            }

            setPreppedUniverse([..._prepped]);
            setDetailsUniverse([..._details]);
        }

    }, [statsFile])

    return (
        <div>

            <HighchartsReact  highcharts={Highcharts} options={
                {
                    chart: {
                        renderTo: 'container',
                        type: 'pie'
                    },
                    title: {
                        text: 'Mapped Number Breakdown'
                    },
                    yAxis: {
                        title: {
                            // text: 'Total percent messages'
                        }
                    },
                    plotOptions: {
                        pie: {
                            shadow: false,
                            startAngle: 180,
                            colors,
                        }
                    },
                    series: [
                        {
                            name: 'Messages',
                            size: '60%',
                            data: preppedUniverse,
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}: {point.percentage:.1f}%',
                                distance: 10
                            }
                        },
                        {
                            name: 'Details',
                            size: '80%',
                            innerSize: '60%',
                            data: detailsUniverse,
                            dataLabels: {
                                distance: 15,
                                enabled: true,
                                format: '<b>{point.name}:</b> <span style="opacity: 0.5">{point.percentage:.1f}%</span>',
                                filter: {
                                    property: 'y',
                                    operator: '>',
                                    value: 1
                                },
                                style: {
                                    fontWeight: 'normal'
                                }

                            },
                            id: 'details',
                        },
                    ]
                }
            }/>
        </div>
    );
};

export default DataMapper_MapperStatsDonutChart;
