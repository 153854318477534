import { useEffect, useContext, useState  } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";

const Hierarchy_Navigation = (status) => {
    const { appRenderType, reportWindowSize } = useContext(ApplicationContext);
    const [StatusCircleLocation, setStatusCircleLocation] = useState("19px");
    const [locationMargin, setLocationMargin] = useState("");
    //eslint-disable-next-line
    const [stateLevel, setStateLevel] = useState(0);

    useEffect(() => {
        adjustStatusCircle(status.status);
        adjustLeftMargin(status.objectWidth);
    }, [], [appRenderType], [status.objectWidth], [status.status]);

    const adjustLeftMargin = async(objectWidth) => {
        var _sizes = reportWindowSize();
        if(_sizes.appRenderType == "desktop" || _sizes.appRenderType == "tablet"){
            switch(objectWidth){
            case "full":
                setLocationMargin("hierarchy_nav_full");
                break;
            case "standard":
                setLocationMargin("hierarchy_nav");
                break;
            }
        }
        else{
            setLocationMargin("hierarchy_nav_phone");
        }
    };

    const adjustStatusCircle = async(status) => {
        var _sizes = reportWindowSize();
        if(_sizes.appRenderType == "desktop" || _sizes.appRenderType == "tablet"){
            switch(status){
                case "business":
                    setStatusCircleLocation("19px");
                    setStateLevel(0);
                    break;
                case "campaign":
                    setStatusCircleLocation("174px");
                    setStateLevel(1);
                    break;
                case "event":
                    setStatusCircleLocation("331px");
                    setStateLevel(2);
                    break;
                case "report":
                    setStatusCircleLocation("487px");
                    setStateLevel(3);
                    break;
            }
        }
        else{
            switch(status){
                case "business":

                    setStatusCircleLocation("24px");
                    setStateLevel(0);
                    break;
                case "campaign":
                    setStatusCircleLocation("119px");
                    setStateLevel(1);
                    break;
                case "event":
                    setStatusCircleLocation("219px");
                    setStateLevel(2);
                    break;
                case "report":
                    setStatusCircleLocation("319px");
                    setStateLevel(3);
                    break;
            }
        }
    };

    //eslint-disable-next-line
    const navigateToLevel = async(levelDesc) => {
        // console.log("Hierarchy_navigation: navigateToLevel entered but does nothing");
        // switch(levelDesc){
        //     case "business":
        //         if(stateLevel > 0)
        //         {
        //             navigate("/Dashboard");
        //         }
        //         break;
        //     case "campaign":
        //         if(stateLevel > 1)
        //         {
        //             navigate("/CampaignDashboard");
        //         }
        //         break;
        //     case "event":
        //         break;
        //     case "report":
        //         break;
        // }
    }

    return (
        <>
            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className={locationMargin}>
                <div className="flex" style={{margin: "15px 0px 0px 30px"}}>
                    <div className="navigationStops" onClick={() => navigateToLevel("business")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="56" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="-10" y1="12" x2="38" y2="12"></line>
                        </svg>
                    </div>
                    <div className="navigationStops" onClick={() => navigateToLevel("campaign")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="56" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="-10" y1="12" x2="38" y2="12"></line>
                        </svg>
                    </div>
                    <div className="navigationStops" onClick={() => navigateToLevel("event")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="56" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="-10" y1="12" x2="38" y2="12"></line>
                        </svg>
                    </div>
                    <div className="navigationStops" onClick={() => navigateToLevel("report")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                </div>
                <div className="flex" style={{margin: "8px 0px 0px 0px"}}>
                    <label className="hierarchy_label" style={{margin: "3px 0px 0px 38px"}}>business</label>
                    <label className="hierarchy_label" style={{margin: "3px 0px 0px 190px"}}>campaign</label>
                    <label className="hierarchy_label" style={{margin: "3px 0px 0px 355px"}}>event</label>
                    <label className="hierarchy_label" style={{margin: "3px 0px 0px 512px"}}>report</label>
                </div>
                <div style={{position: "absolute", margin: "-76px 0px 0px " + StatusCircleLocation}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 24 24" fill="white"
                        stroke="#17326f" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <circle cx="12" cy="12" r="6" strokeWidth="2" ></circle>
                    </svg>
                </div>
            </div>
        :
            <div className={locationMargin}>
                <div className="flex" style={{margin: "15px 0px 0px 30px"}}>
                    <div className="navigationStops" onClick={() => navigateToLevel("business")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="40" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="-10" y1="12" x2="38" y2="12"></line>
                        </svg>
                    </div>
                    <div className="navigationStops" onClick={() => navigateToLevel("campaign")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="40" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="-10" y1="12" x2="38" y2="12"></line>
                        </svg>
                    </div>
                    <div className="navigationStops" onClick={() => navigateToLevel("event")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="40" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="-10" y1="12" x2="38" y2="12"></line>
                        </svg>
                    </div>
                    <div className="navigationStops" onClick={() => navigateToLevel("report")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none"
                            stroke="#17326f" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                    </div>
                </div>
                <div className="flex" style={{margin: "8px 0px 0px 0px"}}>
                    <label className="hierarchy_label_phone" style={{margin: "1px 0px 0px 38px"}}>business</label>
                    <label className="hierarchy_label_phone" style={{margin: "1px 0px 0px 133px"}}>campaign</label>
                    <label className="hierarchy_label_phone" style={{margin: "1px 0px 0px 240px"}}>event</label>
                    <label className="hierarchy_label_phone" style={{margin: "1px 0px 0px 338px"}}>report</label>
                </div>
                <div style={{position: "absolute", margin: "-59px 0px 0px " + StatusCircleLocation}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="white"
                        stroke="#17326f" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <circle cx="12" cy="12" r="6" strokeWidth="2" ></circle>
                    </svg>
                </div>
            </div>
        }
        </>
    );
};

export default Hierarchy_Navigation;
