import { useContext, useCallback } from "react";
import { UnifyContext } from "../../context/UnifyContext";

import TextField from '@mui/material/TextField';

//MMS drop location
import { useDropzone } from 'react-dropzone';

const UnifyCampaignCreate_IssuesPage = () => {

    const { unifyKey, uploadImage,
            issueTitle_1,setIssueTitle_1,
            issueParagraph_1,setIssueParagraph_1,
            issueImage_1_GetURL,

            issueTitle_2,setIssueTitle_2,
            issueParagraph_2,setIssueParagraph_2,
            issueImage_2_GetURL,

            issueTitle_3,setIssueTitle_3,
            issueParagraph_3,setIssueParagraph_3,
            issueImage_3_GetURL,

            issueTitle_4,setIssueTitle_4,
            issueParagraph_4,setIssueParagraph_4,
            issueImage_4_GetURL,

            issueTitle_5,setIssueTitle_5,
            issueParagraph_5,setIssueParagraph_5,
            issueImage_5_GetURL,

            issueTitle_6,setIssueTitle_6,
            issueParagraph_6,setIssueParagraph_6,
            issueImage_6_GetURL,

        } = useContext(UnifyContext);

    //image Drag and drop
    const onDrop = useCallback((acceptedFiles:FileList, dropType, unifyCampaignKey) => {
        //mmsFileTypes
        if(acceptedFiles.length > 0){

            //NOTE: remove unwanted characters
            //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
            //eslint-disable-next-line
            var _imageCorrectedName = acceptedFiles[0].name.replace(/[^a-z0-9_.\ ]/gi, '');

            var imagePackage = {name: '', image: '', type: '', target: '', unifyCampaignKey: ''};
            imagePackage.name = _imageCorrectedName;
            imagePackage.image = acceptedFiles[0];
            imagePackage.type = acceptedFiles[0].type;
            imagePackage.target = dropType;
            imagePackage.unifyCampaignKey = unifyCampaignKey;

            // console.log("package to save", imagePackage);

            uploadImage(imagePackage);
        }
    }, []);

    const {getRootProps: getRootProps_Issue1, getInputProps: getInputProps_Issue1, isDragActive: isDragActive_Issue1} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "issue1", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Issue2, getInputProps: getInputProps_Issue2, isDragActive: isDragActive_Issue2} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "issue2", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Issue3, getInputProps: getInputProps_Issue3, isDragActive: isDragActive_Issue3} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "issue3", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Issue4, getInputProps: getInputProps_Issue4, isDragActive: isDragActive_Issue4} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "issue4", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Issue5, getInputProps: getInputProps_Issue5, isDragActive: isDragActive_Issue5} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "issue5", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Issue6, getInputProps: getInputProps_Issue6, isDragActive: isDragActive_Issue6} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "issue6", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    return (

        <div className="unifyComponentPage" style={{margin: "0px 30px 20px 30px"}}>

            <div className="unifyCallOut" style={{width: "90%", margin: "20px 60px 40px 0px"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>You are running for office for a reason. Tell everyone why!  Keep in mind that we will use these stances for your email and text messages.  Let&lsquo;go!</div>
            </div>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Let&lsquo;s tell people what you are passionate about</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div className="grid" style={{marginTop: "0px", background: "white", padding: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 1 - Title"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueTitle_1(e.target.value)}
                        value={issueTitle_1}
                    />
                </div>

                <div className="grid" style={{marginTop: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 1 - Your Stance"
                        style={{width: "1200px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueParagraph_1(e.target.value)}
                        value={issueParagraph_1}
                    />
                </div>

                <div style={{marginTop: "20px"}}>
                    { issueImage_1_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "620px", height: "620px"}}>
                        <img src={issueImage_1_GetURL} style={{width: "620px", height: "620px", objectFit: "cover"}}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Issue1()} style={{width: "620px", height: "620px"}}>
                          <input {...getInputProps_Issue1()} />
                          {
                            isDragActive_Issue1 ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your &quot;issue supporting image&quot; here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <hr style={{marginTop:"20px"}}/>
            </div>

            <div className="grid" style={{marginTop: "20px", background: "#f5f5f5", padding: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 2 - Title"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueTitle_2(e.target.value)}
                        value={issueTitle_2}
                    />
                </div>

                <div className="grid" style={{marginTop: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 2 - Your Stance"
                        style={{width: "1200px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueParagraph_2(e.target.value)}
                        value={issueParagraph_2}
                    />
                </div>

                <div style={{marginTop: "20px"}}>
                    { issueImage_2_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "620px", height: "620px"}}>
                        <img src={issueImage_2_GetURL} style={{width: "620px", height: "620px", objectFit: "cover"}}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Issue2()} style={{width: "620px", height: "620px"}}>
                          <input {...getInputProps_Issue2()} />
                          {
                            isDragActive_Issue2 ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your &quot;issue supporting image&quot; here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <hr/>
            </div>

            <div className="grid" style={{marginTop: "20px", background: "white", padding: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 3 - Title"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueTitle_3(e.target.value)}
                        value={issueTitle_3}
                    />
                </div>

                <div className="grid" style={{marginTop: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 3 - Your Stance"
                        style={{width: "1200px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueParagraph_3(e.target.value)}
                        value={issueParagraph_3}
                    />
                </div>

                <div style={{marginTop: "20px"}}>
                    { issueImage_3_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "620px", height: "620px"}}>
                        <img src={issueImage_3_GetURL} style={{width: "620px", height: "620px", objectFit: "cover"}}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Issue3()} style={{width: "620px", height: "620px"}}>
                          <input {...getInputProps_Issue3()} />
                          {
                            isDragActive_Issue3 ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your &quot;issue supporting image&quot; here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <hr/>
            </div>

            <div className="grid" style={{marginTop: "20px", background: "#f5f5f5", padding: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 4 - Title"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueTitle_4(e.target.value)}
                        value={issueTitle_4}
                    />
                </div>

                <div className="grid" style={{marginTop: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 4 - Your Stance"
                        style={{width: "1200px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueParagraph_4(e.target.value)}
                        value={issueParagraph_4}
                    />
                </div>

                <div style={{marginTop: "20px"}}>
                    { issueImage_4_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "620px", height: "620px"}}>
                        <img src={issueImage_4_GetURL} style={{width: "620px", height: "620px", objectFit: "cover"}}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Issue4()} style={{width: "620px", height: "620px"}}>
                          <input {...getInputProps_Issue4()} />
                          {
                            isDragActive_Issue4 ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your &quot;issue supporting image&quot; here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <hr/>
            </div>

            <div className="grid" style={{marginTop: "20px", background: "white", padding: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 5 - Title"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueTitle_5(e.target.value)}
                        value={issueTitle_5}
                    />
                </div>

                <div className="grid" style={{marginTop: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 5 - Your Stance"
                        style={{width: "1200px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueParagraph_5(e.target.value)}
                        value={issueParagraph_5}
                    />
                </div>

                <div style={{marginTop: "20px"}}>
                    { issueImage_5_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "620px", height: "620px"}}>
                        <img src={issueImage_5_GetURL} style={{width: "620px", height: "620px", objectFit: "cover"}}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Issue5()} style={{width: "620px", height: "620px"}}>
                          <input {...getInputProps_Issue5()} />
                          {
                            isDragActive_Issue5 ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your &quot;issue supporting image&quot; here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <hr/>
            </div>

            <div className="grid" style={{marginTop: "20px", background: "#f5f5f5", padding: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 6 - Title"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueTitle_6(e.target.value)}
                        value={issueTitle_6}
                    />
                </div>

                <div className="grid" style={{marginTop: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Issue 6 - Your Stance"
                        style={{width: "1200px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssueParagraph_6(e.target.value)}
                        value={issueParagraph_6}
                    />
                </div>

                <div style={{marginTop: "20px"}}>
                    { issueImage_6_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "620px", height: "620px"}}>
                        <img src={issueImage_6_GetURL} style={{width: "620px", height: "620px", objectFit: "cover"}}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Issue6()} style={{width: "620px", height: "620px"}}>
                          <input {...getInputProps_Issue6()} />
                          {
                            isDragActive_Issue6 ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your &quot;issue supporting image&quot; here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <hr/>
            </div>


        </div>

    );
};

export default UnifyCampaignCreate_IssuesPage;
