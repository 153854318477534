import { useEffect, useContext, useState, useRef} from "react";
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import { CompanyContext } from "../../context/CompanyContext";
import { CampaignContext } from "../../context/CampaignContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";
import { FacetContext } from "../../context/FacetContext";
import { CrystalContext } from "../../context/CrystalContext"

import { useNavigate } from "react-router-dom";

import Topbar from "../../components/topbar/Topbar";
import { v4 as uuidv4 } from 'uuid';

import Hierarchy_navigation from "../../components/navigation/Hierarchy_navigation";
import CreateNewCampaign from "../../components/navigation/CreateNewCampaign";
import CreateNewEvent from "../../components/navigation/CreateNewEvent";
import CancelActivity from "../../components/navigation/CancelActivity";

import TotalMessages from "../../components/events/TotalMessages";
import EventActivityBarChart from "../../components/events/EventActivityBarChart";
import EventList from "../../components/events/EventList";
import PerSuccessful from "../../components/events/PerSuccessful";
import TotalCleaned from "../../components/events/TotalCleaned";
import DaysInDisplay from "../../components/events/DaysInDisplay";

import MsgTypeCounts from "../../components/events/CompanyMsgTypeCounts";
import CompanyCampaignMsgCounts from "../../components/events/CompanyCampaignMsgCounts";

import Box from '@mui/material/Box';
//import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

//FOR TIMEOUT/LOGOFF
import SessionTimeout from "../../components/session/SessionTimeout";
import Session_LogoutImminent from "../../components/session/Session_LogoutImminent";
import Modal from '@mui/material/Modal';

export default function Dashboard() {
    // Validate authentication
    const { jwt, roles, dispatch } = useContext(AuthContext);
    if (jwt === null) {
        window.location.href = "/login";
    }

    const { companyID, companyName, logoURL, companyStatsFromSQL, loading, companyCampaignsFromSQL, campaignStates, companyHistoryDaySpan} = useContext(CompanyContext);

    const { setCampaignID, setCampaignEvents, setCampaignName, setResponsesStart,
            resetCampaignVals, setLegacyAmplify, checkCampaignContextRefresh, getCampaignStatsFromSQL,
            setEventDataDirty
            } = useContext(CampaignContext);

    const { initializeNewEvent, setRefreshEventList } = useContext(AmplifyEventContext);

    const { reportWindowSize, appRenderType, updateApplicationSegment, openLogoutImminent,
        setOpenLogoutImminent, setLogoutCountdownStarted, closeLogoutModalAndReset } = useContext(ApplicationContext);
    const { crystalClearMMSFileInfo } = useContext(CrystalContext);
    const { facetClearMMSFileInfo } = useContext(FacetContext);

    const navigate = useNavigate();

    const [totMemberCountFormatted, setTotalSent] = useState(0);
    const [perSuccessfulFormatted, setPerSuccesful] = useState(0);
    const [totCleanedFormatted, setTotalCleaned] = useState(0);

    const [showCampaignSelector, setShowCampaignSelector] = useState("hidden");

    const [campaigns, setCampaigns] = useState([]);

    const [campaignStateFilter, setCampaignStateFilter] = useState([]);
    const [displayCampaigns, setDisplayCampaigns] = useState([]);
    const [displayCampaignsHeight, setDisplayCampaignsHeight]= useState([]);

    const [disableCampaignCreate, setDisableCampaignCreate] = useState("hidden");
    const [disableEventCreate, setDisableEventCreate] = useState("hidden");

    //eslint-disable-next-line
    const [displayWidth, setDisplayWidth] = useState(0);

    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const campaignSelected = async(eventType, targetCampaignID, targetCampaignName, targetEventsStart, legacyAmplify) => {
        if (appRenderType == "phone")
            return;

        switch(eventType) {
            case "campaign":
                resetCampaignVals();
                setCampaignEvents([]);
                setResponsesStart(targetEventsStart);
                setCampaignName(targetCampaignName);
                setCampaignID(targetCampaignID);
                setLegacyAmplify(legacyAmplify);

                // EDR
                // these aren't necessary because they're called immediately
                // after navigating to /campaignDashboard
                //getStats(targetCampaignID);
                //getEvents(companyID, targetCampaignID);
                navigate("/campaignDashboard");
                break;

            case "newEvent":
                setCampaignEvents([]);
                facetClearMMSFileInfo();
                crystalClearMMSFileInfo();

                setResponsesStart(targetEventsStart);
                setCampaignName(targetCampaignName);
                setCampaignID(targetCampaignID);
                setLegacyAmplify(legacyAmplify);
                // console.log("GETTING CAMPAIGN INFORMATION FROM SQL AND SETTING IN TEH CAMPAIGN CONTEXT => ", targetCampaignID);
                await getCampaignStatsFromSQL(targetCampaignID);

                // EDR
                // this isn't necessary because it's called immediately
                // after navigating to /eventcreate
                //getStats(targetCampaignID);

                // EDR init instead of calling with null
                //getExistingEvent(null);
                initializeNewEvent();

                navigate("/eventcreate");
                break;
        }
    };

    const createNewEventClick = async() => {
        setShowCampaignSelector("visible");
    }

    const stateFilterChanged = async(event) => {
        var _wip = campaignStateFilter;

        if(event.target.checked == true){
            _wip.push(event.target.id);
        }
        else{
            var _foundID = 0;
            var f = false;

            for (let i=0; i < _wip.length; i++){
                if(_wip[i] == event.target.id){
                    _foundID = i;
                    f = true;
                    break;
                }
            }

            if(f == true){
                _wip.splice(_foundID, 1);
            }

            setCampaignStateFilter(_wip);
        }

        applyCampaignStateFilter(_wip);
    };

    const applyCampaignStateFilter = async(stateFilter) => {
        var dispCampaigns = [];

        if(stateFilter.length > 0){
            dispCampaigns = campaigns.filter(campaign => stateFilter.includes(campaign.stateAbbr));
        }
        else{
            dispCampaigns = campaigns;
        }

        setDisplayCampaigns(dispCampaigns);
        setDisplayCampaignsHeight((Math.ceil(dispCampaigns.length/3) * 5) + "px");
    }

    const filterTextChanged = async(event) => {
        var dispCampaigns = [];

        if(campaignStateFilter.length > 0){
            dispCampaigns = campaigns.filter(campaign => campaignStateFilter.includes(campaign.stateAbbr));
        }
        else{
            dispCampaigns = campaigns;
        }

        setDisplayCampaigns(dispCampaigns);
        setDisplayCampaignsHeight((Math.ceil(dispCampaigns.length/3) * 5) + "px");

        var _s = event.target.value.trim();
        if(_s.length > 0){
            var _c = dispCampaigns;
            let _r = _c.filter(campaign => campaign.campaignName.toLowerCase().indexOf(_s) == 0);
            setDisplayCampaigns(_r);
        }
    }

    useEffect(() => {
        setCampaignID(0);
        updateApplicationSegment("Amplify");
        reportWindowSize();
        setDisplayWidth(windowSize.current[0]);
        checkCampaignContextRefresh()
    }, [])

    useEffect(() => {
        document.title = "Alliance Forge | Dashboard";

        if(roles.indexOf("sec:globaladmin") > -1 || roles.indexOf("sec:companyadmin") > -1 ){
            setDisableCampaignCreate("visible");
            setDisableEventCreate("visible");
        }
        else{
            setDisableCampaignCreate("hidden");
            setDisableEventCreate("hidden");
        }
    }, [jwt]);

    useEffect(() => {
        if (!companyStatsFromSQL) {
            return;
        }

        setTotalSent(companyStatsFromSQL.totMemberCountFormatted);
        setPerSuccesful(companyStatsFromSQL.perSuccessfulFormatted);
        setTotalCleaned(companyStatsFromSQL.totCleanedFormatted);
    }, [companyStatsFromSQL]);

    useEffect(() => {
        if (!companyCampaignsFromSQL) {
            return;
        }

        setCampaigns(companyCampaignsFromSQL);
        setDisplayCampaigns(companyCampaignsFromSQL);
        setDisplayCampaignsHeight((Math.ceil(companyCampaignsFromSQL.length/3) * 5) + "px")
    }, [companyCampaignsFromSQL]);

    async function eventList_Refresh(){
        // setRefreshEventList(uuidv4());
        setEventDataDirty(true);
        checkCampaignContextRefresh();
    }

    useEffect(() => {
        if(openLogoutImminent == true){
            handleModalOpen();
        }
        else{
            dispatch({ type: "CHECK_AUTH" });  // refresh jwt
            handleModalClose();
        }
    }, [openLogoutImminent])

    useEffect(() => {
        // console.log("modalOpen => ", modalOpen);
        if(modalOpen == false){
            setLogoutCountdownStarted(false);
            setOpenLogoutImminent(false);
        }

    }, [modalOpen])

    useEffect(() => {
        handleModalClose();
    }, [closeLogoutModalAndReset])

    return (
        <div id="mainBody">
            <SessionTimeout/>
            {appRenderType == "desktop" ?
                <div className="mainPageDiv">
                    <div className="accountHeader">

                    </div>
                    <div className="accountHeaderImage" style={{ position: "relative", zIndex: 10 }}>
                        <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "140px", height: "140px", border: "5px solid #555"}} src={(logoURL)}/>
                    </div>
                    <div className="accountHeaderCompanyName">
                        <div className="accountHeaderCompanyNameTitle">{"//"}{(companyName != null) ? (companyName.toLowerCase()) : ''}</div>
                    </div>

                    <Topbar displayAdmin={true} />

                    <div className="grid">
                        <div className="flex">
                            {
                                companyID != -1 ?
                                (
                                    <div className="hierarchyNavigationTileCreateCampaign" style={{visibility: disableCampaignCreate}}>
                                        <CreateNewCampaign />
                                    </div>
                                )
                                :
                                (
                                    <div style={{width: "400px"}}></div>
                                )
                            }

                            <div className="hierarchyNavigationTileDouble">
                                <Hierarchy_navigation status={"business"} objectWidth={"standard"}/>
                            </div>
                            {
                                companyID != -1 ?
                                (
                                    <div className="hierarchyNavigationTileCreateEvent" onClick={() => createNewEventClick()} style={{visibility: disableEventCreate}}>
                                        <CreateNewEvent />
                                    </div>
                                )
                                :
                                (
                                    <div></div>
                                )
                            }

                        </div>

                        <div className="flex" style={{margin: "0px 0px 0px 0px"}}>
                            <TotalMessages totalSent={totMemberCountFormatted || 0} />
                            <PerSuccessful PerSuccess={perSuccessfulFormatted || 0} />
                            <TotalCleaned totalCleaned={totCleanedFormatted || 0} />
                            <DaysInDisplay days={companyHistoryDaySpan || 0} />
                        </div>

                        <div className="flex">
                            <div className="dashboardDoubleHeightTile">
                                <div className="dashboardTileTitle" style={{margin: "12px 10px 11px 20px"}}>active campaigns</div>
                                <hr/>
                                <div className="dashboardCampaignList grid" style={{overflowX:'hidden'}}>
                                    {
                                        campaigns.map((campaign) => (
                                            <div className="dashboardCampaign flex" key={campaign.campaignId} onClick={() => {
                                                campaignSelected("campaign", campaign.campaignId, campaign.campaignName, campaign.firstEventStart, campaign.LegacyAmplify)
                                                }
                                            }>
                                                <div className="dashboardCampaignHighlight" style={{height: "90px"}}/>
                                                <div className="flex" style={{padding: "5px 10px 10px 10px", width: "350px"}}>
                                                    <div className="grid">
                                                        <div className="flex">
                                                            <div className="dashboardCampaignTitle">{campaign.campaignName}</div>
                                                            {campaign.LegacyAmplify == true ?
                                                                (
                                                                    <div className="dashboardCampaignTitle" style={{marginLeft: "10px"}}>(LEGACY)</div>
                                                                )
                                                            :
                                                                (
                                                                    <div></div>
                                                                )
                                                            }
                                                        </div>

                                                        <hr className="dashboardTileHR"/>
                                                        <div className="flex">
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">State:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.stateName}</div>
                                                            </div>
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">Latest Event:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.latestEventStart}</div>
                                                            </div>
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">Total Events:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.totalEventCount}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { campaign.pendingCount != 0 ?
                                                        (
                                                            <div className="dashboardCampaignPendingEvents">{campaign.pendingCount}</div>
                                                        )
                                                        :
                                                        (
                                                            <div/>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="dashboardDoubleWidthDoubleHeightTile" style={{overflowY: "scroll"}}>
                                <div className="flex">
                                    <div className="dashboardTileTitle" style={{margin: "10px 10px 5px 20px", width: "400px"}}>event listing</div>
                                    <Box sx={{width: "800px"}}/>
                                    <Button onClick={() => eventList_Refresh()} startIcon={<RefreshIcon fontSize="large" />} sx={{width: 50, height: 50}}></Button>
                                </div>
                                <hr/>
                                <div style={{margin: "0px 10px 10px 10px"}}>
                                    <EventList company_id={companyID || 0}/>
                                </div>

                            </div>

                            <div className="grid">
                                <div>
                                    <MsgTypeCounts/>
                                </div>
                                <div>
                                    <CompanyCampaignMsgCounts/>
                                </div>
                            </div>

                        </div>

                        <div className="dashboardFullWidthTile" style={{width: "1590px"}}>
                            <EventActivityBarChart company_id={companyID || 0} />
                        </div>

                    </div>
                </div>
            : appRenderType=="tablet" ?
                <div className="mainPageDiv_Mobile">
                    <div className="accountHeader_Mobile">

                    </div>
                    <div className="accountHeaderImage_Mobile" style={{ position: "relative", zIndex: 10 }}>
                        <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "100px", height: "100px", border: "5px solid #555"}} src={(logoURL)}/>
                    </div>
                    <div className="accountHeaderCompanyName_Mobile">
                        <div className="accountHeaderCompanyNameTitle_Mobile">{"//"}{(companyName != null) ? (companyName.toLowerCase()) : ''}</div>
                    </div>

                    <Topbar displayAdmin={true}/>

                    <div className="hierarchyNavigationTileDouble">
                        <Hierarchy_navigation status={"business"} objectWidth={"standard"}/>
                    </div>

                    <div className="grid">
                        <div className="flex" style={{margin: "0px 0px 0px 0px"}}>
                            <TotalMessages totalSent={totMemberCountFormatted || 0} />
                            <PerSuccessful PerSuccess={perSuccessfulFormatted || 0} />
                        </div>
                        <div className="flex">
                            <TotalCleaned totalCleaned={totCleanedFormatted || 0} />
                            <DaysInDisplay days={companyHistoryDaySpan || 0} />
                        </div>

                        <div className="dashboardDoubleWidthDoubleHeightTile" style={{overflowY: "scroll"}}>
                            <div className="flex">
                                <div className="dashboardTileTitle" style={{margin: "10px 10px 5px 20px", width: "400px"}}>event listing</div>
                                <Box sx={{width: "800px"}}/>
                                <Button onClick={() => eventList_Refresh()} startIcon={<RefreshIcon fontSize="large" />} sx={{width: 50, height: 50}}></Button>
                            </div>
                            <hr/>
                            <div style={{margin: "0px 10px 10px 10px"}}>
                                <EventList company_id={companyID || 0}/>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="dashboardDoubleHeightTile">
                                <div className="dashboardTileTitle" style={{margin: "12px 10px 11px 20px"}}>active campaigns</div>
                                <hr/>
                                <div className="dashboardCampaignList grid" style={{overflowX:'hidden'}}>
                                    {
                                        campaigns.map((campaign) => (
                                            <div className="dashboardCampaign flex" key={campaign.campaignId} onClick={() =>
                                                campaignSelected("campaign", campaign.campaignId, campaign.campaignName, campaign.firstEventStart, campaign.LegacyAmplify)}>
                                                <div className="dashboardCampaignHighlight" style={{height: "90px"}}/>
                                                <div className="flex" style={{padding: "5px 10px 10px 10px", width: "350px"}}>
                                                    <div className="grid">
                                                        <div className="flex">
                                                            <div className="dashboardCampaignTitle">{campaign.campaignName}</div>
                                                            {campaign.LegacyAmplify == true ?
                                                                (
                                                                    <div className="dashboardCampaignTitle" style={{marginLeft: "10px"}}>(LEGACY)</div>
                                                                )
                                                            :
                                                                (
                                                                    <div></div>
                                                                )
                                                            }
                                                        </div>

                                                        <hr className="dashboardTileHR"/>
                                                        <div className="flex">
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">State:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.stateName}</div>
                                                            </div>
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">Latest Event:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.latestEventStart}</div>
                                                            </div>
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">Total Events:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.totalEventCount}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { campaign.pendingCount != 0 ?
                                                        (
                                                            <div className="dashboardCampaignPendingEvents">{campaign.pendingCount}</div>
                                                        )
                                                        :
                                                        (
                                                            <div/>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="grid">
                                <div>
                                    <MsgTypeCounts/>
                                </div>
                                <div>
                                    <CompanyCampaignMsgCounts/>
                                </div>
                            </div>
                        </div>

                        <div className="dashboardFullWidthTile" style={{width: "790px"}}>
                            <EventActivityBarChart company_id={companyID || 0} />
                        </div>
                    </div>
                </div>
            :
                <div className="mainPageDiv_Phone">
                    <div className="accountHeader_Phone">

                    </div>
                    <div className="accountHeaderImage_Phone" style={{ position: "relative", zIndex: 10 }}>
                        <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "100px", height: "100px", border: "5px solid #555"}} src={(logoURL)}/>
                    </div>
                    <div className="accountHeaderCompanyName_Phone">
                        <div className="accountHeaderCompanyNameTitle_Phone">{"//"}{(companyName != null) ? (companyName.toLowerCase()) : ''}</div>
                    </div>

                    <Topbar displayAdmin={true}/>

                    <div className="hierarchyNavigationTileDouble_Phone">
                        <Hierarchy_navigation status={"business"} objectWidth={""}/>
                    </div>

                    <div className="grid">
                        <div className="grid" style={{margin: "0px 0px 0px 0px"}}>
                            <TotalMessages totalSent={totMemberCountFormatted || 0} />
                            <PerSuccessful PerSuccess={perSuccessfulFormatted || 0} />
                            <TotalCleaned totalCleaned={totCleanedFormatted || 0} />
                            <DaysInDisplay days={companyHistoryDaySpan || 0} />
                        </div>

                        <div className="flex">
                            <MsgTypeCounts/>
                            <CompanyCampaignMsgCounts/>
                        </div>

                        <div className="dashboardQuadHeightTile_Phone" style={{overflowY: "scroll"}}>
                            <div className="flex">
                                <div className="dashboardTileTitle" style={{margin: "10px 10px 5px 20px", width: "400px"}}>event listing</div>
                                <Box sx={{width: "800px"}}/>
                                <Button onClick={() => eventList_Refresh()} startIcon={<RefreshIcon fontSize="large" />} sx={{width: 50, height: 50}}></Button>
                            </div>
                            <hr/>
                            <div style={{margin: "0px 10px 10px 10px"}}>
                                <EventList company_id={companyID || 0}/>
                            </div>

                        </div>

                        <div className="grid">
                            <div className="dashboardQuadHeightTile_Phone">
                                <div className="dashboardTileTitle" style={{margin: "12px 10px 11px 20px"}}>active campaigns</div>
                                <hr/>
                                <div className="dashboardCampaignList_Phone grid" style={{overflowX:'hidden'}}>
                                    {
                                        campaigns.map((campaign) => (
                                            <div className="dashboardCampaign_Phone flex" key={campaign.campaignId} onClick={() =>
                                                campaignSelected("campaign", campaign.campaignId, campaign.campaignName, campaign.firstEventStart, campaign.LegacyAmplify)}>
                                                <div className="dashboardCampaignHighlight" style={{height: "90px"}}/>
                                                <div className="flex" style={{padding: "5px 10px 10px 10px", width: "350px"}}>
                                                    <div className="grid">
                                                        <div className="flex">
                                                            <div className="dashboardCampaignTitle">{campaign.campaignName}</div>
                                                            {campaign.LegacyAmplify == true ?
                                                                (
                                                                    <div className="dashboardCampaignTitle" style={{marginLeft: "10px"}}>(LEGACY)</div>
                                                                )
                                                            :
                                                                (
                                                                    <div></div>
                                                                )
                                                            }
                                                        </div>

                                                        <hr className="dashboardTileHR"/>
                                                        <div className="flex">
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">State:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.stateName}</div>
                                                            </div>
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">Latest Event:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.latestEventStart}</div>
                                                            </div>
                                                            <div className="grid">
                                                                <div className="dashboardCampaignSubTitle">Total Events:</div>
                                                                <div className="dashboardCampaignSubData">{campaign.totalEventCount}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { campaign.pendingCount != 0 ?
                                                        (
                                                            <div className="dashboardCampaignPendingEvents">{campaign.pendingCount}</div>
                                                        )
                                                        :
                                                        (
                                                            <div/>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="dashboardFullWidthTile_Phone" style={{marginBottom: "20px"}}>
                            <EventActivityBarChart company_id={companyID || 0} />
                        </div>
                    </div>
                </div>
            }
                {loading ?
                    (
                        (<></>)
                    // <div>
                    //     <CircularProgressRing
                    //         className="loadingEventReport
                    //         inset-2/4 absolute"
                    //         color="inherit"
                    //         size={700}
                    //     />
                    // </div>
                        // <div className="fullScreenLoading" style={{margin: "0px 0px 0px 0px"}}>
                        //     <span className="loading loading-bars loading-lg" style={{marginTop: "300px", marginLeft: "755px"}} />
                        //     {/*<label>hello World</label>*/}
                        // </div>
                    ) : (<></>) }

            <div style={{visibility: showCampaignSelector}}>
                <div className="shadowBoxBackground" />
                <div className="shadowBoxContent" style={{marginTop: "60px"}}>
                    <div className="text-[64px]" style={{color: "black"}}>select the campaign for your new event</div>

                    <div className="flex">
                        <div style={{margin: "0px 0px 0px 1020px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="#00b8ff"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                        </div>
                        <input className="searchText" onChange={filterTextChanged}></input>
                    </div>

                    <div className="flex">
                        <div className="dashboardCampaignListFilters_Modal">
                            <Box sx={{ display: 'flex'}}>
                                <FormControl component="fieldset" variant="standard">
                                    <label className="dashboardCampaignListFiltersTitle_Modal" >Filter By State</label>
                                    <hr/>
                                        <FormGroup className="dashboardCampaignStates_ModalList" style={{overflowY: "hidden"}}>
                                        {
                                            campaignStates.map((campaignState) => (
                                                <FormControlLabel key={campaignState.stateAbbr}
                                                    control={
                                                      <Checkbox onChange={stateFilterChanged} id={campaignState} name={"campaignStateFilter_" + campaignState} />
                                                    }
                                                    label={campaignState}
                                                  />
                                            ))
                                        }
                                        </FormGroup>
                                </FormControl>
                            </Box>

                        </div>

                        <div className="dashboardCampaignList_Modal flex">
                            <div className="dashboardCampaignListContents_Modal flex" style={{height: displayCampaignsHeight}}>
                                {
                                    displayCampaigns.filter(c => (c.LegacyAmplify == false)).map((campaign) => (
                                        <div className="dashboardCampaign_Modal flex" style={{margin: "0px 10px 10px 0px"}} key={campaign.campaignId}
                                            onClick={() => campaignSelected("newEvent", campaign.campaignId, campaign.campaignName, campaign.firstEventStart)}>
                                            <div className="grid">
                                                <div className="dashboardCampaignTitle_Modal">{campaign.campaignName}</div>
                                                <hr className="dashboardTileHR_Modal"/>
                                                <div className="flex">
                                                    <div className="grid">
                                                        <div className="dashboardCampaignSubTitle_Modal">State:</div>
                                                        <div className="dashboardCampaignSubData">{campaign.stateName}</div>
                                                    </div>
                                                    <div className="grid">
                                                        <div className="dashboardCampaignSubTitle_Modal">Latest Event:</div>
                                                        <div className="dashboardCampaignSubData">{campaign.latestEventStart}</div>
                                                    </div>
                                                    <div className="grid">
                                                        <div className="dashboardCampaignSubTitle_Modal">Total Events:</div>
                                                        <div className="dashboardCampaignSubData_Modal">{campaign.totalEventCount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="campaignListNavButtons flex">
                        <div className="navigationCancelDiv" onClick={() => setShowCampaignSelector("hidden")}>
                            <CancelActivity />
                        </div>
                        <div className="navigationCreateCampaignDiv">
                            <CreateNewCampaign />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <div className="LogoffImminent_modalWindow">
                        <Session_LogoutImminent />
                    </div>
                </Modal>
            </div>
        </div>
    );
}
