import { createContext, useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import { CampaignContext } from "./CampaignContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const AmplifyAreaCodeContext = createContext();

export const AmplifyAreaCodeContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { areaCodeList } = useContext(CampaignContext);

    const [areaCodeTNCounts, setAreaCodeTNCounts] = useState([]);
    const [allStateAreaCodes, setAllStateAreaCodes] = useState([]);

    const [collectingAreaCodeQtys, setCollectingAreaCodeQtys] = useState([false]);

    // EDR - this can take quite a while to get a response
    // We need some way to notify user (or at least us) if/when this fails
    // and maybe imlement some kind of retry for busy days
    const getAreaCodeTNCounts = async() => {
        if (!areaCodeList || !areaCodeList.length) {
            //console.log("AmplifyAreaCodeContext: entered & exiting getAreaCodeTNCounts() because areaCodeList = ", areaCodeList)
            return;
        }
        //console.log("AmplifyAreaCodeContext: entered getAreaCodeTNCounts(): areaCodeList = ", areaCodeList)
        var localAreaCodeList = [...areaCodeList];

        setCollectingAreaCodeQtys(true);
        setAllStateAreaCodes(areaCodeList);

        var _complete = [];
        const _countPerGroup = 5;       //maximum number of AC per REST call
        var startIndex = 0;
        while (startIndex < areaCodeList.length) {
            let tempSlice = localAreaCodeList.slice(startIndex, startIndex + _countPerGroup);
            let acGroupString = "areacode=" + tempSlice.join("&areacode=")

            // Query SQL
            await axios.get(
                `${config.bandwidthapi.url}/api/v1/bandwidth/availablenumbers?${acGroupString}`,
                {headers: { Authorization: "Bearer " + jwt }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                //console.log("AmplifyAreaCodeContext: getAreaCodeTNCounts(): response = ", response)
                var _c = [];
                response.data.values.forEach(object => {
                    var _controlWidth = 0;
                    if (object["quantity"]) {
                        _controlWidth = object["quantity"] < 1500 ? 150 : object["quantity"]/10;
                    }
                    _c.push(
                        {
                            area_code: object["area_code"],
                            tn_count: object["quantity"],
                            tn_count_display: object["quantity"] == 5000 ? object["quantity"]+"+" : object["quantity"],
                            check_status: object["status"],
                            control_width: _controlWidth
                        }
                    );
                });

                _complete = [..._complete, ..._c];
            });
            startIndex += _countPerGroup;
        }
        var _f = _complete.sort((a,b) => b.tn_count - a.tn_count)
        setAreaCodeTNCounts(_f);
        //console.log("AmplifyAreaCodeContext: getAreaCodeTNCounts(): final setting setAreaCodeTNCounts(_f) = ", _f);
        setCollectingAreaCodeQtys(false);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    return (
        <AmplifyAreaCodeContext.Provider value={{
            areaCodeTNCounts:                           areaCodeTNCounts,
            allStateAreaCodes:                          allStateAreaCodes,
            getAreaCodeTNCounts:                        getAreaCodeTNCounts,
            collectingAreaCodeQtys:                     collectingAreaCodeQtys
        }}>
            {children}
        </AmplifyAreaCodeContext.Provider>
    );

}
