import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationContext } from "../../context/ApplicationContext";
import { CampaignContext } from "../../context/CampaignContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext"

import PropTypes from "prop-types";

// Datatables
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import JSZip from 'jszip';
window.JSZip = JSZip;
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone)

//NOTIFICATIONS OF COMPLETION
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

$.Datatable = require("datatables.net");
$.DataTable = require('datatables.net-select');
$.DataTable = require('datatables.net-buttons');
$.DataTable = require('datatables.net-buttons-dt');
$.DataTable = require('datatables.net-buttons/js/buttons.colVis');
$.DataTable = require('datatables.net-buttons/js/buttons.html5');
$.DataTable = require('datatables.net-buttons/js/buttons.print');
$.DataTable = require('pdfmake');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

$.Datatable = require("datatables.net");

const EventList_Home = ({ company_id }) => {
    const { appRenderType, reportWindowSize } = useContext(ApplicationContext);
    const { setCampaignID, allBusinessEvents, allBusinessEventsLoading} = useContext(CampaignContext);
    const { getExistingEvent } = useContext(AmplifyEventContext);

    const navigate = useNavigate();
    const tableRef = useRef();
    const [isMounted, setIsMounted] = useState(false);
    const [eventList, setEventList] = useState(0);

    const [openErrorSnackAlert, setOpenErrorSnackAlert] = useState(false);
    const [errorSnackAlertMessage, setErrorSnackAlertMessage] = useState('');

    var eventLoading = false;

    var dayOfYear = require('dayjs/plugin/dayOfYear')
    dayjs.extend(dayOfYear)

    useEffect(() => {
        reportWindowSize();
        setEventList(allBusinessEvents);
    }, [allBusinessEvents]);

    useEffect(() => {
        if (isMounted)
            return;

        var _renderType = "desktop";
        if(window.innerWidth >= 1600){
            _renderType = "desktop";
        }
        else if(window.innerWidth < 1600 && window.innerWidth > 450){
            _renderType = "tablet";
        }
        else{
            _renderType ="phone";
        }

        setIsMounted(true);
        if (_renderType == "phone") {
            $(tableRef.current).DataTable({
                data: [],
                dom: 'tlip',
                pageLength: 5,
                autoWidth: false,
                columns: [
                    { defaultContent: '<i>(undefined)</i>', width: '200px', title: 'Campaign', accessor: 'campaignName', visible: false },
                    { defaultContent: '<i>(undefined)</i>', width: '325px', title: 'Project', visible: false },
                    { defaultContent: '<i>(undefined)</i>', width: '375px', title: 'Event Name' },
                    { defaultContent: '<i>(undefined)</i>', width: '200px', className: 'dt-body-right', title: 'Count'},
                    { defaultContent: '<i>(undefined)</i>', width: '100px', className: 'dt-body-right', title: 'Start', visible: false },
                    { defaultContent: '<i>(undefined)</i>', width: '50px', className: 'dt-body-right', title: '', visible: false },
                    { defaultContent: '<i>(undefined)</i>', width: '150px', className: 'dt-body-right', title: 'Status' },
                    { defaultContent: '<i>(undefined)</i>', width: '150px', className: 'dt-body-right', title: 'UTC Start Time', visible: false },
                ],
                order: [[7, 'desc'], [2, 'desc'],[6, 'desc']],
            });
        } else  {
            $(tableRef.current).DataTable({
                data: [],
                dom: 'tip',
                pageLength: 7,
                autoWidth: false,
                columns: [
                    { defaultContent: '<i>(undefined)</i>', width: '100px', title: 'Campaign' },
                    { defaultContent: '<i>(undefined)</i>', width: '100px', title: 'Project' },
                    { defaultContent: '<i>(undefined)</i>', width: '350px', title: 'Event Name' },
                    { defaultContent: '<i>(undefined)</i>', width: '75px', className: 'dt-body-right', title: 'Count'},
                    { defaultContent: '<i>(undefined)</i>', width: '50px', className: 'dt-body-right', title: 'Start' },
                    { defaultContent: '<i>(undefined)</i>', width: '35px', className: 'dt-body-right', title: '' },
                    { defaultContent: '<i>(undefined)</i>', width: '75px', className: 'dt-body-right', title: 'Status' },
                    { defaultContent: '<i>(undefined)</i>', width: '100px', className: 'dt-body-right', title: 'UTC Start Time', visible: false },
                ],
                order: [[7, 'desc'], [2, 'desc'],[6, 'desc']],
            });
        }
    });


    // Called anytime eventList is modified
    useEffect(() => {
        // clear out any previous data
        if (isMounted) {
            $(tableRef.current).dataTable().fnClearTable();
        }

        // push data rows into Datatable
        if (eventList.length > 0) {
            $(tableRef.current).dataTable().fnAddData(eventList);
        }

        // define 'onClick' event handler for each row
        $(tableRef.current).dataTable().off().on('click', 'tr', function(){
            var rowdata = $(tableRef.current).dataTable().api().row(this).data();
            if (typeof rowdata !== 'undefined') {
                var _status = rowdata[6];
                var _id = rowdata[8];
                var _compId = company_id;
                var _campId = rowdata[11];
                var _key = rowdata[12];

                var _legacyAmplify = false;
                if (rowdata.length >= 14) {
                    _legacyAmplify = rowdata[13];
                }

                setExistingEventAndLoad(_status, _id, _compId, _campId, _key, _legacyAmplify);
            }
        });
    }, [eventList]);

    async function setExistingEventAndLoad(status, eventID, companyID, campaign_id, eventKey, legacyAmplify){
        //this will only happen with Mongo based events
        if (status.toLowerCase() == "complete"
            // ||
            // status.toLowerCase() == "committed" ||
            // status.toLowerCase() == "commit" ||
            // status.toLowerCase() == "committing"
            )
            // || appRenderType == "tablet" || appRenderType == "phone")
        {
            navigate("/eventreport?event_key="+eventID+"&event_status="+status.toUpperCase()+"&company_id="+companyID);
        } else {
            if (legacyAmplify == false && appRenderType == "desktop") {
                if (eventLoading == false) {
                    eventLoading = true;
                    setCampaignID(campaign_id);

                    await getExistingEvent(eventKey);

                    navigate("/eventcreate");
                    eventLoading = false;
                }
            }
            else if (legacyAmplify == false && (appRenderType == "tablet" || appRenderType == "phone")){
                navigate("/EventEditDesktopOnly");
            } else {
                setErrorSnackAlertMessage("unable to open legacy events in a state other than 'complete'");
                setOpenErrorSnackAlert(true);
            }
        }
    }


    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpenErrorSnackAlert(false);
    };

    return (

        <div className="grid" style={{height: "300px"}}>
            {appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="eventList">

                <div className="eventListTable">
                    <table ref={tableRef} className="compact hover stripe dataTable_wrapper" style={{cursor: "pointer", padding: "0px 10px 0px 0px"}}></table>
                </div>
            </div>
            :
            <div className="eventList_Phone" style={{marginTop: "-10px"}}>

                <div className="eventListTable_Phone">
                    <table ref={tableRef} className="compact hover stripe dataTable_wrapper" style={{cursor: "pointer", padding: "0px 10px 0px 0px"}}></table>
                </div>
            </div>
            }
            {allBusinessEventsLoading == true ?
                (
                    appRenderType == "desktop" || appRenderType == "tablet" ?
                    (
                    <div className="homeComponentLoading" style={{margin: "0px 0px 0px 0px"}}>
                        <div className="loading loading-bars loading-sm" style={{ marginTop: "40px", marginLeft: "300px",
                            width: "200px", height: "200px"}} />
                        {/*<label>hello World</label>*/}
                    </div>
                    )
                    :
                    (
                    <div className="homeComponentLoading_Phone" style={{margin: "0px 0px 0px 0px"}}>
                        <div className="loading loading-bars loading-sm" style={{ marginTop: "-35px", marginLeft: "100px",
                            width: "250px", height: "350px"}} />
                        {/*<label>hello World</label>*/}
                    </div>
                    )
                )
                :
                (
                    <div></div>
                )
            }
            <Snackbar open={openErrorSnackAlert} autoHideDuration={6000} onClose={handleSnackBarClose}>
                <Alert
                      onClose={handleSnackBarClose}
                      severity="error"
                      variant="filled"
                      sx={{ width: '100%' }}
                    >
                  {errorSnackAlertMessage}
                </Alert>
            </Snackbar>
        </div>

    );
};

EventList_Home.propTypes = {
    company_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EventList_Home;
