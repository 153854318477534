import { useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

const TotalCleaned = ({ totalCleaned }) => {
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <div>
            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="dashboardHalfHeightTile flex">
                <div style={{margin: "50px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#f47a02"
                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="18" y1="8" x2="23" y2="13"></line>
                        <line x1="23" y1="8" x2="18" y2="13"></line>
                    </svg>
                </div>
                <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                    <div className="dashboardTileTitle">Total Numbers Cleaned</div>
                    <div className="dashboardTileMainData">{totalCleaned}</div>
                    <div className="dashboardTileSubtext">These numbers were automatically removed</div>
                </div>

            </div>
            :
            <div className="dashboardHalfHeightTile_Phone flex">
                <div style={{margin: "17px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#f47a02"
                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="18" y1="8" x2="23" y2="13"></line>
                        <line x1="23" y1="8" x2="18" y2="13"></line>
                    </svg>
                </div>
                <div className="grid" style={{margin: "10px 0px 0px 10px", height: "80px"}}>
                    <div className="dashboardTileTitle_Phone">Total Numbers Cleaned</div>
                    <div className="dashboardTileMainData_Phone">{totalCleaned}</div>
                    <div className="dashboardTileSubtext_Phone">These numbers were automatically removed</div>
                </div>

            </div>
            }
        </div>
    );
};

TotalCleaned.propTypes = {
    totalCleaned: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default TotalCleaned;
