import { useEffect, useState, useContext } from "react";
import { CompanyContext } from "../../context/CompanyContext";
import { ApplicationContext } from "../../context/ApplicationContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);

const CompanyMsgTypeCounts = () => {
    const { msgTypeCountsFromSQL } = useContext(CompanyContext);
    const { appRenderType } = useContext(ApplicationContext);

    const [ reportTitle, setReportTitle ] = useState('Message Counts By Service Type');

    const [pieColors, setPieColors] = useState([]);

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    useEffect(() =>{
        var colors = [];
        colors.push("#346793");
        colors.push("#4081ba");
        colors.push("#639aca");
        colors.push("#88b3d8");
        colors.push("#aecbe5");

        setPieColors(colors);
    }, [msgTypeCountsFromSQL])

    useEffect(() => {
        if(appRenderType == "desktop" || appRenderType == "tablet"){
            setReportTitle("Message Counts By Service Type");
        }
        else{
            setReportTitle("");
        }
    }, [appRenderType])

    return (
        <div>
            <div className={`stats ${
                appRenderType=="desktop" || appRenderType == "tablet" ?
                    "dashboardTile"
                :
                    "dashboardTile_Phone"
                }`}>

                <HighchartsReact  highcharts={Highcharts} options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'pie'
                        },
                        title: {
                            text: reportTitle,
                        },
                        yAxis: {
                            title: {
                                text: 'Total percent messages'
                            }
                        },
                        plotOptions: {
                            pie: {
                                shadow: false,
                                startAngle: 180,
                                colors: pieColors
                            }
                        },
                        series: [
                            {
                                name: 'Message Counts By Service Type',
                                size: '90%',
                                data: msgTypeCountsFromSQL,
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.name}: {point.percentage:.1f}%',
                                    distance: 25
                                },
                            }
                        ]
                    }
                }/>
            </div>

        </div>
    );
};

export default CompanyMsgTypeCounts;
