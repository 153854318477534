import { useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

const TotalMessages = ({ totalSent }) => {
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <div>
            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="dashboardHalfHeightTile flex">
                <div style={{margin: "50px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#59df25"
                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
                        <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                    </svg>
                </div>
                <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                    <div className="dashboardTileTitle">Total Messages Sent</div>
                    <div className="dashboardTileMainData">{totalSent}</div>
                    <div className="dashboardTileSubtext">Total number of messages sent across all events</div>
                </div>

            </div>
            :
            <div className="dashboardHalfHeightTile_Phone flex">
                <div style={{margin: "17px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#59df25"
                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
                        <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                    </svg>
                </div>
                <div className="grid" style={{margin: "10px 0px 0px 10px", height: "80px"}}>
                    <div className="dashboardTileTitle_Phone">Total Messages Sent</div>
                    <div className="dashboardTileMainData_Phone">{totalSent}</div>
                    <div className="dashboardTileSubtext_Phone">Total number of messages sent across all events</div>
                </div>

            </div>
            }
        </div>
    );
};

TotalMessages.propTypes = {
    totalSent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default TotalMessages;
