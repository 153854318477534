import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
//import { CampaignContext } from "./CampaignContext";
//import { CompanyContext } from "./CompanyContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

import { v4 as uuidv4 } from 'uuid';

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const UnifyContext = createContext();

export const UnifyContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);
    //const { campaignKey, setCampaignKey, adminBWApplicationID, campaignID, setCampaignID, defaultTimeZone, campaignApprovers, setCampaignApprovers } = useContext(CampaignContext);
    //const { companyKey, companyID } = useContext(CompanyContext);

    const [unifyKey, setUnifyKey] = useState('');

    const [processingUnifyEventCreate, setProcessingUnifyEventCreate] = useState(false);

    //General Info Properties
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [fullName, setFullName] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [paidForBy, setPaidForBy] = useState('');

    const [donationLink, setDonationLink] = useState('');

    const [mailingAddress, setMailingAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [phone, setPhone] = useState('');

    const [fb, setFb] = useState('');
    const [instagram, setInstagram] = useState('');
    const [x, setX] = useState('');
    const [snapChat, setSnapChat] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const [tikTok, setTikTok] = useState('');

    const [webSiteName, setWebSiteName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    const [largeLogo, setLargeLogo] = useState('');
    const [largeLogo_GetURL, setLargeLogo_GetURL] = useState('');
    const [smallLogo, setSmallLogo] = useState('');
    const [smallLogo_GetURL, setSmallLogo_GetURL] = useState('');
    const [favIcon, setFavIcon] = useState('');
    const [favIcon_GetURL, setFavIcon_GetURL] = useState('');

    //HOME PAGE
    const [heroTitle, setHeroTitle] = useState('');
    const [heroParagraph, setHeroParagraph] = useState('');
    const [heroBanner, setHeroBanner] = useState('');
    const [heroBanner_GetURL, setHeroBanner_GetURL] = useState('');

    const [issuesTitle, setIssuesTitle] = useState('');
    const [issuesIntroduction, setIssuesIntroduction] =  useState('');

    const [donateNowTitle, setDonateNowTitle] = useState('');
    const [donationImage, setDonationImage] = useState('');
    const [donationImage_GetURL, setDonationImage_GetURL] = useState('');
    const [donateNowTitle_Home2, setDonateNowTitle_Home2] = useState('');


    const [spreadTheWordTitle, setSpreadTheWordTitle] = useState('');
    const [spreadTheWordDesc, setSpreadTheWordDesc] = useState('');
    const [spreadTheWord1, setSpreadTheWord1] = useState('');
    const [spreadTheWord1_GetURL, setSpreadTheWord1_GetURL] = useState('');
    const [spreadTheWord2, setSpreadTheWord2] = useState('');
    const [spreadTheWord2_GetURL, setSpreadTheWord2_GetURL] = useState('');
    const [spreadTheWord3, setSpreadTheWord3] = useState('');
    const [spreadTheWord3_GetURL, setSpreadTheWord3_GetURL] = useState('');

    //ABOUT
    const [aboutParagraph1, setAboutParagraph1] = useState('');
    const [aboutParagraph2, setAboutParagraph2] = useState('');
    const [aboutParagraph3, setAboutParagraph3] = useState('');
    const [aboutParagraph4, setAboutParagraph4] = useState('');

    const [aboutImage1, setAboutImage1] = useState('');
    const [aboutImage1_GetURL, setAboutImage1_GetURL] = useState('');
    const [aboutImage2, setAboutImage2] = useState('');
    const [aboutImage2_GetURL, setAboutImage2_GetURL] = useState('');

    const [aboutDonateNowTitle, setAboutDonateNowTitle] = useState('');
    const [aboutDonateNowImage, setAboutDonateImage] = useState('');

    //eslint-disable-next-line
    const [aboutDonateNowImage_GetURL, setAboutDonateImage_GetURL] = useState('');

    //ISSUES
    const [issueTitle_1, setIssueTitle_1] = useState('')
    const [issueParagraph_1, setIssueParagraph_1] = useState('');
    const [issueImage_1, setIssueImage_1] = useState('');
    const [issueImage_1_GetURL, setIssueImage_1_GetURL] = useState('');

    const [issueTitle_2, setIssueTitle_2] = useState('')
    const [issueParagraph_2, setIssueParagraph_2] = useState('');
    const [issueImage_2, setIssueImage_2] = useState('');
    const [issueImage_2_GetURL, setIssueImage_2_GetURL] = useState('');

    const [issueTitle_3, setIssueTitle_3] = useState('')
    const [issueParagraph_3, setIssueParagraph_3] = useState('');
    const [issueImage_3, setIssueImage_3] = useState('');
    const [issueImage_3_GetURL, setIssueImage_3_GetURL] = useState('');

    const [issueTitle_4, setIssueTitle_4] = useState('')
    const [issueParagraph_4, setIssueParagraph_4] = useState('');
    const [issueImage_4, setIssueImage_4] = useState('');
    const [issueImage_4_GetURL, setIssueImage_4_GetURL] = useState('');

    const [issueTitle_5, setIssueTitle_5] = useState('')
    const [issueParagraph_5, setIssueParagraph_5] = useState('');
    const [issueImage_5, setIssueImage_5] = useState('');
    const [issueImage_5_GetURL, setIssueImage_5_GetURL] = useState('');

    const [issueTitle_6, setIssueTitle_6] = useState('')
    const [issueParagraph_6, setIssueParagraph_6] = useState('');
    const [issueImage_6, setIssueImage_6] = useState('');
    const [issueImage_6_GetURL, setIssueImage_6_GetURL] = useState('');

    //EVENTS
    const [eventsTitle, setEventsTitle] = useState('');
    const [eventsParagraph, setEventsParagraph] = useState('');

    const [eventTitle_1, setEventTitle_1] = useState('');
    const [eventDescription_1, setEventDescription_1] = useState('');
    const [eventWhen_1, setEventWhen_1] = useState('');
    const [eventWhere_1, setEventWhere_1] = useState('');

    const [eventTitle_2, setEventTitle_2] = useState('');
    const [eventDescription_2, setEventDescription_2] = useState('');
    const [eventWhen_2, setEventWhen_2] = useState('');
    const [eventWhere_2, setEventWhere_2] = useState('');

    const [eventTitle_3, setEventTitle_3] = useState('');
    const [eventDescription_3, setEventDescription_3] = useState('');
    const [eventWhen_3, setEventWhen_3] = useState('');
    const [eventWhere_3, setEventWhere_3] = useState('');

    const [eventTitle_4, setEventTitle_4] = useState('');
    const [eventDescription_4, setEventDescription_4] = useState('');
    const [eventWhen_4, setEventWhen_4] = useState('');
    const [eventWhere_4, setEventWhere_4] = useState('');

    const [eventTitle_5, setEventTitle_5] = useState('');
    const [eventDescription_5, setEventDescription_5] = useState('');
    const [eventWhen_5, setEventWhen_5] = useState('');
    const [eventWhere_5, setEventWhere_5] = useState('');

    //VOLUNTEER
    const [volunteerParagraph, setVolunteerParagraph] = useState('');

    //SAMPLE UNIVERSE
    const [sampleFirstName_1, setSampleFirstName_1] = useState('');
    const [sampleLastName_1, setSampleLastName_1] = useState('');
    const [sampleEmail_1, setSampleEmail_1] = useState('');
    const [samplePhone_1, setSamplePhone_1] = useState('');

    const [sampleFirstName_2, setSampleFirstName_2] = useState('');
    const [sampleLastName_2, setSampleLastName_2] = useState('');
    const [sampleEmail_2, setSampleEmail_2] = useState('');
    const [samplePhone_2, setSamplePhone_2] = useState('');

    const [sampleFirstName_3, setSampleFirstName_3] = useState('');
    const [sampleLastName_3, setSampleLastName_3] = useState('');
    const [sampleEmail_3, setSampleEmail_3] = useState('');
    const [samplePhone_3, setSamplePhone_3] = useState('');

    const [sampleFirstName_4, setSampleFirstName_4] = useState('');
    const [sampleLastName_4, setSampleLastName_4] = useState('');
    const [sampleEmail_4, setSampleEmail_4] = useState('');
    const [samplePhone_4, setSamplePhone_4] = useState('');

    const [sampleFirstName_5, setSampleFirstName_5] = useState('');
    const [sampleLastName_5, setSampleLastName_5] = useState('');
    const [sampleEmail_5, setSampleEmail_5] = useState('');
    const [samplePhone_5, setSamplePhone_5] = useState('');

    useEffect(() => {
        setUnifyKey(createNewId);
    }, []);

    async function test(){
        setUnifyKey(uuidv4());
    }

    async function createNewId(){
        // setUnifyKey(uuidv4());
        // setUnifyKey("a");
        setUnifyKey("AFCTestingKey");
    }

    function clearData(){
        //GENERAL INFO
        if(fName.trim().length != 0){setFName("");}
        if(lName.trim().length != 0){setLName("");}
        if(fullName.trim().length != 0){setFullName("");}
        if(campaignName.trim().length != 0){setCampaignName("");}
        if(paidForBy.trim().length != 0){setPaidForBy("");}

        if(donationLink.trim().length != 0){setDonationLink("");}
        if(mailingAddress.trim().length != 0){setMailingAddress("");}
        if(city.trim().length != 0){setCity("");}
        if(state.trim().length != 0){setState("");}
        if(zip.trim().length != 0){setZip("");}

        if(phone.trim().length != 0){setPhone("");}
        if(fb.trim().length != 0){setFb("");}
        if(instagram.trim().length != 0){setInstagram("");}
        if(x.trim().length != 0){setX("");}
        if(snapChat.trim().length != 0){setSnapChat("");}

        if(linkedIn.trim().length != 0){setLinkedIn("");}
        if(tikTok.trim().length != 0){setTikTok("");}
        if(webSiteName.trim().length != 0){setWebSiteName("");}
        if(emailAddress.trim().length != 0){setEmailAddress("");}


        //HOME
        if(heroTitle.trim().length != 0){setHeroTitle("");}
        if(heroParagraph.trim().length != 0){setHeroParagraph("");}
        if(heroBanner.trim().length != 0){setHeroBanner("");}
        if(heroBanner_GetURL.trim().length != 0){setHeroBanner_GetURL("");}
        if(issuesTitle.trim().length != 0){setIssuesTitle("");}

        if(issuesIntroduction.trim().length != 0){setIssuesIntroduction("");}
        if(donateNowTitle.trim().length != 0){setDonateNowTitle("");}
        if(donateNowTitle_Home2.trim().length != 0){setDonateNowTitle_Home2("");}
        if(spreadTheWordTitle.trim().length != 0){setSpreadTheWordTitle("");}
        if(spreadTheWordDesc.trim().length != 0){setSpreadTheWordDesc("");}


        //ABOUT
        if(aboutParagraph1.trim().length != 0){setAboutParagraph1("");}

        if(aboutParagraph2.trim().length != 0){setAboutParagraph2("");}

        if(aboutParagraph3.trim().length != 0){setAboutParagraph3("");}

        if(aboutParagraph4.trim().length != 0){setAboutParagraph4("");}

        if(aboutDonateNowTitle.trim().length != 0){setAboutDonateNowTitle("");}

        //ISSUES
        if(issueTitle_1.trim().length != 0){setIssueTitle_1("");}
        if(issueParagraph_1.trim().length != 0){setIssueTitle_2("");}

        if(issueTitle_2.trim().length != 0){setAboutParagraph3("");}
        if(issueParagraph_2.trim().length != 0){setIssueParagraph_2("");}

        if(issueTitle_3.trim().length != 0){setIssueTitle_3("");}
        if(issueParagraph_3.trim().length != 0){setIssueParagraph_3("");}

        if(issueTitle_4.trim().length != 0){setIssueTitle_4("");}
        if(issueParagraph_4.trim().length != 0){setIssueParagraph_4("");}

        if(issueTitle_5.trim().length != 0){setIssueTitle_5("");}
        if(issueParagraph_5.trim().length != 0){setIssueParagraph_5("");}

        if(issueTitle_6.trim().length != 0){setIssueTitle_6("");}
        if(issueParagraph_6.trim().length != 0){setIssueParagraph_6("");}

        //EVENTS
        if(eventsTitle.trim().length != 0){setEventsTitle("");}
        if(eventsParagraph.trim().length != 0){setEventsParagraph("");}

        if(eventTitle_1.trim().length != 0){setEventTitle_1("");}
        if(eventDescription_1.trim().length != 0){setEventDescription_1("");}
        if(eventWhen_1.trim().length != 0){setEventWhen_1("");}
        if(eventWhere_1.trim().length != 0){setEventWhere_1("");}

        if(eventTitle_2.trim().length != 0){setEventTitle_2("");}
        if(eventDescription_2.trim().length != 0){setEventDescription_2("");}
        if(eventWhen_2.trim().length != 0){setEventWhen_2("");}
        if(eventWhere_2.trim().length != 0){setEventWhere_2("");}

        if(eventTitle_3.trim().length != 0){setEventTitle_3("");}
        if(eventDescription_3.trim().length != 0){setEventDescription_3("");}
        if(eventWhen_3.trim().length != 0){setEventWhen_3("");}
        if(eventWhere_3.trim().length != 0){setEventWhere_3("");}

        if(eventTitle_4.trim().length != 0){setEventTitle_4("");}
        if(eventDescription_4.trim().length != 0){setEventDescription_4("");}
        if(eventWhen_4.trim().length != 0){setEventWhen_4("");}
        if(eventWhere_4.trim().length != 0){setEventWhere_4("");}

        if(eventTitle_5.trim().length != 0){setEventTitle_5("");}
        if(eventDescription_5.trim().length != 0){setEventDescription_5("");}
        if(eventWhen_5.trim().length != 0){setEventWhen_5("");}
        if(eventWhere_5.trim().length != 0){setEventWhere_5("");}

        //VOLUNTEER
        if(volunteerParagraph.trim().length != 0){setVolunteerParagraph("");}
    }


    function populateSampleData(){
        var _fullName = "Ronald Reagan";

        //GENERAL INFO
        if(fName.trim().length == 0){setFName("Ronald");}
        if(lName.trim().length == 0){setLName("Reagan");}
        if(fullName.trim().length == 0){setFullName(_fullName);}
        if(campaignName.trim().length == 0){setCampaignName("Ronald Reagan for America");}
        if(paidForBy.trim().length == 0){setPaidForBy("Paid for By Friends of Ronald Reagan");}

        if(donationLink.trim().length == 0){setDonationLink("https://secure.winred.com/rnc/support-the-rnc?sc=winred-directory&money_bomb=false&recurring=false");}
        if(mailingAddress.trim().length == 0){setMailingAddress("12345 Main Street");}
        if(city.trim().length == 0){setCity("Las Vegas");}
        if(state.trim().length == 0){setState("NV");}
        if(zip.trim().length == 0){setZip("89108");}

        if(phone.trim().length == 0){setPhone("(702) 555-1212");}
        if(fb.trim().length == 0){setFb("FB@"+_fullName);}
        if(instagram.trim().length == 0){setInstagram("instagram@"+_fullName);}
        if(x.trim().length == 0){setX("@" + _fullName);}
        if(snapChat.trim().length == 0){setSnapChat("snapChat@" + _fullName);}

        if(linkedIn.trim().length == 0){setLinkedIn("LinkedIn@"+_fullName);}
        if(tikTok.trim().length == 0){setTikTok("TikTok@" + _fullName);}
        if(webSiteName.trim().length == 0){setWebSiteName("www.RonaldReaganForAmerica.com");}
        if(emailAddress.trim().length == 0){setEmailAddress("RonaldReagan@RonaldReaganForAmerica.com");}

        //HOME
        if(heroTitle.trim().length == 0){setHeroTitle("It's Morning In America");}
        if(heroParagraph.trim().length == 0){setHeroParagraph("Thanks to President Reagan, the nation is back on track. Jobs, small businesses, families… they are all doing better today than they were four short years ago, when President Reagan first took office.");}

        if(issuesTitle.trim().length == 0){setIssuesTitle("Our Plan");}
        if(issuesIntroduction.trim().length == 0){setIssuesIntroduction("President Reagan’s plan ensures that we are all safer and can create the opportunities for ourselves and our children that we all aspire to");}

        if(donateNowTitle.trim().length == 0){setDonateNowTitle("Support Your President Today");}
        if(donateNowTitle_Home2.trim().length == 0){setDonateNowTitle_Home2("President Reagan is Asking for Your Support");}

        if(spreadTheWordTitle.trim().length == 0){setSpreadTheWordTitle("Tell Your Friends");}
        if(spreadTheWordDesc.trim().length == 0){setSpreadTheWordDesc("Together, we can make tomorrow better for all of us");}

        //ABOUT
        if(aboutParagraph1.trim().length == 0){setAboutParagraph1("Ronald Wilson Reagan, the 40th President of the United States, was born on February 6, 1911, in Tampico, Illinois. Before his foray into politics, Reagan had a successful career in the entertainment industry, earning fame as a radio personality, film actor, and television host. His journey from Hollywood to the Oval Office began when he served as the President of the Screen Actors Guild, showcasing his leadership skills and commitment to the welfare of his fellow actors.");}
        if(aboutParagraph2.trim().length == 0){setAboutParagraph2("Reagan's political career truly took flight in 1966 when he was elected Governor of California, a position he held for two terms. As governor, he implemented conservative policies, emphasizing fiscal responsibility, tax cuts, and a tough stance on law and order. This paved the way for his bid for the presidency in 1980. In a landslide victory, Reagan defeated the incumbent President Jimmy Carter, ushering in an era that would come to be known as the Reagan Revolution.");}
        if(aboutParagraph3.trim().length == 0){setAboutParagraph3("Reagan's presidency, from 1981 to 1989, left an indelible mark on American history. He championed a conservative agenda, advocating for free-market principles, smaller government, and a strong national defense. His economic policies, commonly referred to as Reaganomics, aimed at reducing government intervention and stimulating economic growth. Internationally, Reagan played a pivotal role in the end of the Cold War, engaging in strategic negotiations with Soviet leader Mikhail Gorbachev, which eventually led to the collapse of the Soviet Union.");}
        if(aboutParagraph4.trim().length == 0){setAboutParagraph4("Now, President Reagan wants your help to keep this revolution moving forward for ourselves, our families, and our friends and neighbors.");}
        if(aboutDonateNowTitle.trim().length == 0){setAboutDonateNowTitle("Support Your President Today");}

        //ISSUES
        if(issueTitle_1.trim().length == 0){setIssueTitle_1("Crime");}
        if(issueParagraph_1.trim().length == 0){setIssueParagraph_1("Crime poses a serious threat to the fabric of our society, and during my presidency, we took a stand against lawlessness to ensure the safety of all Americans. Recognizing that a secure nation is built upon the foundation of law and order, we implemented policies that emphasized the importance of a strong criminal justice system. By supporting law enforcement, implementing tougher sentencing for violent criminals, and cracking down on drug-related offenses, we aimed to create safer communities. It was our belief that a society that upholds the rule of law and values individual responsibility can deter crime and protect the well-being of its citizens. Through a commitment to the principles of justice and accountability, we sought to foster an environment where Americans could live free from the fear of crime, confident that their communities would remain secure and prosperous.");}

        if(issueTitle_2.trim().length == 0){setIssueTitle_2("American Values");}
        if(issueParagraph_2.trim().length == 0){setIssueParagraph_2("American values are the bedrock of our exceptional nation, and they are deeply rooted in freedom, individualism, and a steadfast belief in the limitless potential of the American people. During my presidency, we celebrated these values, recognizing that it is the spirit of individual initiative and personal responsibility that propels our great nation forward. The principles of limited government and free-market capitalism guided our policies, fostering an environment where innovation and entrepreneurial spirit could flourish. We upheld traditional values such as hard work, family, and faith, understanding that they are the pillars that sustain the fabric of our society. It is the adherence to these timeless values that has defined America's unique character and ensured its enduring success as a beacon of liberty for the world.");}

        if(issueTitle_3.trim().length == 0){setIssueTitle_3("Education");}
        if(issueParagraph_3.trim().length == 0){setIssueParagraph_3("Education is the cornerstone of a strong and prosperous America. During my presidency, we championed the ideals of academic excellence, emphasizing the importance of local control in education. I believed that decisions about our children's education should be made closer to home, by parents, teachers, and communities, not by a distant bureaucracy. By promoting choice and competition in education, we sought to empower parents with the ability to make the best decisions for their children's futures. A well-educated citizenry is crucial for the success of our nation, ensuring that future generations are equipped with the knowledge and skills needed to face the challenges of a rapidly evolving world. Through our commitment to educational freedom, we aimed to unleash the potential of every American student and lay the foundation for a brighter and more prosperous future.");}

        if(issueTitle_4.trim().length == 0){setIssueTitle_4("Communities");}
        if(issueParagraph_4.trim().length == 0){setIssueParagraph_4("Communities are the heartbeat of America, and they thrive when individuals are free to unleash their potential. During my presidency, we celebrated the strength of local communities and recognized the power of self-reliance and civic responsibility. By reducing federal government interference, we aimed to empower communities to address their unique challenges and opportunities. The voluntary efforts of citizens and the vibrant tapestry of local organizations showcased the true spirit of American exceptionalism. In this vision, communities were not just geographical locations but vibrant hubs of self-governance, where individuals came together to solve problems, support each other, and preserve the values that make our nation great. By championing individual freedoms and encouraging a sense of local pride, we believed in fostering resilient, self-sufficient communities that contribute to the broader tapestry of the United States.");}

        if(issueTitle_5.trim().length == 0){setIssueTitle_5("Jobs");}
        if(issueParagraph_5.trim().length == 0){setIssueParagraph_5("In the spirit of freedom and individual opportunity, I firmly believe that the key to a thriving nation lies in the hands of its hardworking citizens. During my presidency, we pursued policies that unleashed the entrepreneurial spirit, reducing government regulations and cutting taxes to stimulate economic growth. By fostering a business-friendly environment, we aimed to empower job creators to invest, innovate, and hire more Americans. Our commitment to free-market principles and limited government interference led to significant job creation and a revitalized economy. It is my steadfast belief that when individuals are free to pursue their ambitions, the result is a nation where employment opportunities abound, prosperity flourishes, and the American dream becomes attainable for all.");}

        if(issueTitle_6.trim().length == 0){setIssueTitle_6("Energy");}
        if(issueParagraph_6.trim().length == 0){setIssueParagraph_6("Energy independence is a critical pillar of our nation's strength and prosperity. During my presidency, we recognized the significance of reducing our dependence on foreign oil and embracing a comprehensive approach to energy policy. By advocating for free-market principles, technological innovation, and a commitment to domestic resources, we aimed to unleash the potential of America's energy sector. We pursued policies that encouraged the exploration and development of our own natural resources, fostering energy self-sufficiency and bolstering our national security. Our belief in the power of the free market and limited government intervention guided our efforts to create an environment where energy producers could thrive, ultimately ensuring a reliable and affordable energy supply for the American people. Through a commitment to energy innovation and self-reliance, we sought to secure a brighter and more resilient future for our great nation.");}

        //EVENTS
        if(eventsTitle.trim().length == 0){setEventsTitle("I want to meet you!");}

        if(eventsParagraph.trim().length == 0){setEventsParagraph("You are my driving force! Come meet me at an event near you.  Would love to see you soon.");}

        if(eventTitle_1.trim().length == 0){setEventTitle_1("Reno, NV");}
        if(eventDescription_1.trim().length == 0){setEventDescription_1("Time for us to get together in Reno... at the GSR! Come by, have fun, meet me and your local candidates as we work to win your vote and save America!");}
        if(eventWhen_1.trim().length == 0){setEventWhen_1("2/13/2024 18:00");}
        if(eventWhere_1.trim().length == 0){setEventWhere_1("Grand Sierra Resort");}

        if(eventTitle_2.trim().length == 0){setEventTitle_2("Los Angeles, CA");}
        if(eventDescription_2.trim().length == 0){setEventDescription_2("Time for us to get together in LA! Come by, have fun, meet me and your local candidates as we work to win your vote and save America!");}
        if(eventWhen_2.trim().length == 0){setEventWhen_2("2/14/2024 18:00");}
        if(eventWhere_2.trim().length == 0){setEventWhere_2("Los Angeles Amphetheatre");}

        if(eventTitle_3.trim().length == 0){setEventTitle_3("Las Vegas, NV");}
        if(eventDescription_3.trim().length == 0){setEventDescription_3("Time for us to get together in Las Vegas! Come by, have fun, meet me and your local candidates as we work to win your vote and save America!");}
        if(eventWhen_3.trim().length == 0){setEventWhen_3("2/15/2024 18:00");}
        if(eventWhere_3.trim().length == 0){setEventWhere_3("Sam Boyd Silver Bowl");}

        if(eventTitle_4.trim().length == 0){setEventTitle_4("Phoenix, AZ");}
        if(eventDescription_4.trim().length == 0){setEventDescription_4("Time for us to get together in Phoenix! Come by, have fun, meet me and your local candidates as we work to win your vote and save America!");}
        if(eventWhen_4.trim().length == 0){setEventWhen_4("2/16/2024 18:00");}
        if(eventWhere_4.trim().length == 0){setEventWhere_4("AZ GOP Headquarters");}

        if(eventTitle_5.trim().length == 0){setEventTitle_5("Colorado Springs, CO");}
        if(eventDescription_5.trim().length == 0){setEventDescription_5("Time for us to get together in CO! Come by, have fun, meet me and your local candidates as we work to win your vote and save America!");}
        if(eventWhen_5.trim().length == 0){setEventWhen_5("2/17/2024 18:00");}
        if(eventWhere_5.trim().length == 0){setEventWhere_5("Air Force Academy");}

        //VOLUNTEER
        if(volunteerParagraph.trim().length == 0){setVolunteerParagraph("Fellow Americans, our nation stands at a crucial crossroads, and the time has come for us to rally together to safeguard the principles that make America exceptional. I invite you to join me in volunteering for this pivotal political campaign for the presidency, inspired by the enduring values that have defined our great nation. Your dedication and passion can help us champion the ideals of freedom, limited government, and a belief in the limitless potential of the American people. By volunteering, you become an essential part of a movement dedicated to preserving the foundations of our democracy. Let us work together to shape a future where individual initiative, personal responsibility, and a commitment to a brighter tomorrow guide our path. Join us in this noble endeavor, and together, let's ensure that the flame of liberty continues to shine brightly in the land of the free.");}
    }

    async function uploadImage(imagePackage){

        var _respVals = {fileName: '', deleteUrl: '', getUrl: '', putUrl: '', unifyCampaignKey: ''};

        // console.log("image package", imagePackage);

        try{

            var _imageName = imagePackage.name;
            var _imageFile = imagePackage.image;
            //var _type = imagePackage.type;
            var _target = imagePackage.target;
            var _unifyCampaignKey = imagePackage.unifyCampaignKey;

            var _processingFileName = "unify/" + _unifyCampaignKey.toString() + "/" + _imageName.toString();

            // console.log("Processing File Name", _processingFileName);

            const headers = { headers: { Authorization: "Bearer " + jwt, accept: "application/json" } };
            var _urlGet = `${config.s3api.url}/api/v1/file?campaign_key=df7252623de77e782c98267dde9eb1d8d4bf9c22&business_key=c18cc89bf7c6bd3655c33a8c2338c81f9d8f58c0&file_name=${_processingFileName}`;

            const res = await axios.get(
                _urlGet,
                headers
            );

            // console.log("url get resp", res);
            // console.log("url get", _urlGet);

            if(res != null && _unifyCampaignKey != ''){
                _respVals.fileName = res.data['file_name'];
                _respVals.deleteUrl = res.data['delete_url'];
                _respVals.getUrl = res.data['get_url'];
                _respVals.putUrl = res.data['put_url'];
                _respVals.unifyCampaignKey = _unifyCampaignKey;

                // console.log("response values", _respVals);

                const _s3_configs_1 = { headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "multipart/form-data" }};
                const resPut = await axios.put(
                    _respVals.putUrl,
                    _imageFile,
                    _s3_configs_1
                );

                // console.log("disposition", resPut);

                switch(_target){
                    case "large":
                        setLargeLogo(_imageName.toString());
                        setLargeLogo_GetURL(_respVals.getUrl);
                        break;
                    case "small":
                        setSmallLogo(_imageName.toString());
                        setSmallLogo_GetURL(_respVals.getUrl);
                        break;
                    case "favIcon":
                        setFavIcon(_imageName.toString());
                        setFavIcon_GetURL(_respVals.getUrl);
                        break;
                    case "hero":
                        setHeroBanner(_imageName.toString());
                        setHeroBanner_GetURL(_respVals.getUrl);
                        break;
                    case "donate":
                        setDonationImage(_imageName.toString());
                        setDonationImage_GetURL(_respVals.getUrl);
                        break;
                    case "spread1":
                        setSpreadTheWord1(_imageName.toString());
                        setSpreadTheWord1_GetURL(_respVals.getUrl);
                        break;
                    case "spread2":
                        setSpreadTheWord2(_imageName.toString());
                        setSpreadTheWord2_GetURL(_respVals.getUrl);
                        break;
                    case "spread3":
                        setSpreadTheWord3(_imageName.toString());
                        setSpreadTheWord3_GetURL(_respVals.getUrl);
                        break;
                    case "about1":
                        setAboutImage1(_imageName.toString());
                        setAboutImage1_GetURL(_respVals.getUrl);
                        break;
                    case "about2":
                        setAboutImage2(_imageName.toString());
                        setAboutImage2_GetURL(_respVals.getUrl);
                        break;
                    case "issue1":
                        setIssueImage_1(_imageName.toString());
                        setIssueImage_1_GetURL(_respVals.getUrl);
                        break;
                    case "issue2":
                        setIssueImage_2(_imageName.toString());
                        setIssueImage_2_GetURL(_respVals.getUrl);
                        break;
                    case "issue3":
                        setIssueImage_3(_imageName.toString());
                        setIssueImage_3_GetURL(_respVals.getUrl);
                        break;
                    case "issue4":
                        setIssueImage_4(_imageName.toString());
                        setIssueImage_4_GetURL(_respVals.getUrl);
                        break;
                    case "issue5":
                        setIssueImage_5(_imageName.toString());
                        setIssueImage_5_GetURL(_respVals.getUrl);
                        break;
                    case "issue6":
                        setIssueImage_6(_imageName.toString());
                        setIssueImage_6_GetURL(_respVals.getUrl);
                        break;
                }
            }
        }catch(err){
            console.log("error posting up image", err);
        }

        return _respVals;
    }

    async function generateConfigJson(){

        var _unifyCampaignKey = unifyKey;

        var _respVals = {fileName: '', deleteUrl: '', getUrl: '', putUrl: '', unifyCampaignKey: ''};

        try{

            var _config = {
                        "microsite" : {
                            "common" : {
                                "context" : {
                                    "favicon": favIcon,
                                    "header_logo": smallLogo,
                                    "footer_logo": largeLogo,
                                    "footer_menu_links": [],
                                    "footer_sm_facebook": fb,
                                    "footer_sm_instagram": instagram,
                                    "footer_twitter": x,
                                    "footer_sm_tiktok": tikTok,
                                    "footer_sm_linkedin": linkedIn,
                                    "footer_mailing_address": mailingAddress,
                                    "footer_city": city,
                                    "footer_state": state,
                                    "footer_zip": zip,
                                    "footer_phone_number": phone,
                                    "footer_paid_for_text": paidForBy,
                                    "first_name": fName,
                                    "last_name": lName,
                                    "full_name": fullName,
                                    "campaign_name": campaignName,
                                    "cta_phrase": donateNowTitle,
                                    "cta_donate_link": donationLink,
                                }
                            },
                            "index" : {
                                "context" : {
                                    "block_1_title": heroTitle,
                                    "block_1_text":  heroParagraph,
                                    "hero_img": heroBanner,
                                    "block_2_title": issuesTitle,
                                    "block_2_text" : issuesIntroduction,
                                    "block_2_img_1_title" : issueTitle_1,
                                    "block_2_img_1": issueImage_1,
                                    "block_2_img_2_title" : issueTitle_2,
                                    "block_2_img_2": issueImage_2,
                                    "block_2_img_3_title" : issueTitle_3,
                                    "block_2_img_3": issueImage_3,
                                    "block_2_img_4_title" : issueTitle_4,
                                    "block_2_img_4": issueImage_4,
                                    "block_2_img_5_title" : issueTitle_5,
                                    "block_2_img_5": issueImage_5,
                                    "block_2_img_6_title" : issueTitle_6,
                                    "block_2_img_6": issueImage_6,
                                    "block_3_phrase": donateNowTitle,
                                    "block_3_img": donationImage,
                                    "block_4_title" : spreadTheWordTitle,
                                    "block_4_text" : spreadTheWordDesc,
                                    "block_4_img_1": spreadTheWord1,
                                    "block_4_img_2": spreadTheWord2,
                                    "block_4_img_3": spreadTheWord3,
                                    "block_5_phrase": donateNowTitle_Home2
                                }
                            },
                            "about" : {
                                "context" : {
                                    "title": "Meet " + fullName,
                                    "p1_text": aboutParagraph1,
                                    "p2_text": aboutParagraph2,
                                    "p3_text": aboutParagraph3,
                                    "p4_text": aboutParagraph4,
                                    "img_1": aboutImage1,
                                    "img_2": aboutImage2
                                }
                            },
                            "issues" : {
                                "context" : {
                                    "block_1_title": issueTitle_1,
                                    "block_1_text": issueParagraph_1,
                                    "block_1_img" : issueImage_1,
                                    "block_2_title": issueTitle_2,
                                    "block_2_text": issueParagraph_2,
                                    "block_2_img" : issueImage_2,
                                    "block_3_title": issueTitle_3,
                                    "block_3_text": issueParagraph_3,
                                    "block_3_img" : issueImage_3,
                                    "block_4_title": issueTitle_4,
                                    "block_4_text": issueParagraph_4,
                                    "block_4_img" : issueImage_4,
                                    "block_5_title": issueTitle_5,
                                    "block_5_text": issueParagraph_5,
                                    "block_5_img" : issueImage_5,
                                    "block_6_title": issueTitle_6,
                                    "block_6_text": issueParagraph_6,
                                    "block_6_img" : issueImage_6,
                                }
                            },
                            "issue1" : {
                                "context" : {
                                    "title": issueTitle_2 + ' & ' + issueTitle_3,
                                    "text_1": issueParagraph_2,
                                    "img_1" : issueImage_2,
                                    "text_2": issueParagraph_3,
                                    "img_2" : issueImage_3,
                                }
                            },
                            "events" : {
                                "context" : {
                                    "title": eventsTitle,
                                    "title_text": eventsParagraph,
                                    "block_1_title": eventTitle_1,
                                    "block_1_text": eventDescription_1,
                                    "block_1_when": eventWhen_1,
                                    "block_1_where": eventWhere_1,
                                    "block_2_title": eventTitle_2,
                                    "block_2_text": eventDescription_2,
                                    "block_2_when": eventWhen_2,
                                    "block_2_where": eventWhere_2,
                                    "block_3_title": eventTitle_3,
                                    "block_3_text": eventDescription_3,
                                    "block_3_when": eventWhen_3,
                                    "block_3_where": eventWhere_3,
                                    "block_4_title": eventTitle_4,
                                    "block_4_text": eventDescription_4,
                                    "block_4_when": eventWhen_4,
                                    "block_4_where": eventWhere_4,
                                    "block_5_title": eventTitle_5,
                                    "block_5_text": eventDescription_5,
                                    "block_5_when": eventWhen_5,
                                    "block_5_where": eventWhere_5,
                                }
                            },
                            "volunteer" : {
                                "context" : {
                                    "block_1_text": volunteerParagraph,
                                    "block_2_title": donateNowTitle
                                }
                            }
                        },
                        "email": {
                            "common" : {
                                "context" : {
                                    "email_template": "",
                                    "email_header_image": heroBanner,
                                    "email body_block_1": issueParagraph_1,
                                    "email_body_bio_block_1": aboutParagraph1,
                                    "email_body_bio_block_2": aboutParagraph2,
                                    "email_body_bio_block_3": aboutParagraph3,
                                    "email_body_bio_block_4": aboutParagraph4,
                                    "email_body_bio_image": aboutImage1,
                                    "donate_now_link": donationLink,
                                    "mailing_address": mailingAddress,
                                    "city": city,
                                    "state": state,
                                    "zip": zip,
                                    "footer_phone_number": phone,
                                    "url": "https://micrositetest01.hyperscaleaf.com",
                                }
                            },
                            "recipients" : [
                                {"first_name": "Rick","last_name": "Ross","emailAddress": "rick.ross@allianceforge.net"},
                                {"first_name": "David","last_name": "Espinosa","emailAddress": "david.espinosa@allianceforge.net"},
                                {"first_name": sampleFirstName_1,"last_name": sampleLastName_1,"emailAddress": sampleEmail_1},
                                {"first_name": sampleFirstName_2,"last_name": sampleLastName_2,"emailAddress": sampleEmail_2},
                                {"first_name": sampleFirstName_3,"last_name": sampleLastName_3,"emailAddress": sampleEmail_3},
                                {"first_name": sampleFirstName_4,"last_name": sampleLastName_4,"emailAddress": sampleEmail_4},
                                {"first_name": sampleFirstName_5,"last_name": sampleLastName_5,"emailAddress": sampleEmail_5}
                            ]
                        },
                        "sms" : {
                            "common" : {
                                "context" : {
                                    "sms_message": "",
                                }
                            },
                            "recipients" : [
                                {"first_name": "Rick","last_name": "Ross","emailAddress": "+17752256204"},
                                {"first_name": "David","last_name": "Espinosa","emailAddress": "+17752339418"},
                                {"first_name": sampleFirstName_1,"last_name": sampleLastName_1,"phoneNumber": samplePhone_1},
                                {"first_name": sampleFirstName_2,"last_name": sampleLastName_2,"phoneNumber": samplePhone_2},
                                {"first_name": sampleFirstName_3,"last_name": sampleLastName_3,"phoneNumber": samplePhone_3},
                                {"first_name": sampleFirstName_4,"last_name": sampleLastName_4,"phoneNumber": samplePhone_4},
                                {"first_name": sampleFirstName_5,"last_name": sampleLastName_5,"phoneNumber": samplePhone_5}
                            ]
                        }
                    };

            // console.log("uploading unify config file", _config);

            var _processingFileName = "unify/" + _unifyCampaignKey.toString() + "/config.json";

            // console.log("Processing File Name", _processingFileName);

            var headers = { headers: { Authorization: "Bearer " + jwt, accept: "application/json" } };
            var _urlGet = `${config.s3api.url}/api/v1/file?campaign_key=df7252623de77e782c98267dde9eb1d8d4bf9c22&business_key=c18cc89bf7c6bd3655c33a8c2338c81f9d8f58c0&file_name=${_processingFileName}`;

            var res = await axios.get(
                _urlGet,
                headers
            );

            // console.log("url get resp", res);
            // console.log("url get", _urlGet);

            // console.log("config file", _config);

            if(res != null && _unifyCampaignKey != ''){
                _respVals.fileName = res.data['file_name'];
                _respVals.deleteUrl = res.data['delete_url'];
                _respVals.getUrl = res.data['get_url'];
                _respVals.putUrl = res.data['put_url'];
                _respVals.unifyCampaignKey = _unifyCampaignKey;

                // console.log("response values", _respVals);

                var _s3_configs_1 = { headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json" }};
                var resPut = await axios.put(
                    _respVals.putUrl,
                    _config,
                    _s3_configs_1
                );

                // console.log("disposition", resPut);
            }

            /////////////////////////KICK OFF THE MICROSITE CREATION AND START LISTENING!!!! ////////////////////////////////////////////////////

            try{
                await axios.get(
                    `${config.accountapi.url}/api/v1/campaigns/df7252623de77e782c98267dde9eb1d8d4bf9c22/action/micrositeinit?unify_id=${unifyKey}`,
                    {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
                );

                //TODO: ADD ERROR CATCH HERE
                setProcessingUnifyEventCreate(true);
                // console.log("unify init process COMPLETE");
            }catch(err){
                console.log("there was an error kicking off the unify event creation process", err);
            }
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        }catch(err){
            console.log("error posting up file", err);
        }
        return _respVals;
    }

    /////////////// ALPHA DEMO ONLY CODE //////////////////////////////////////////////////////////////////////////

    async function sendTextMessageSamples(){

        // console.log("sending sample SMS messages for demo");

        //SAMPLE CODE ONLY --  NOT PRODUCTION READY!!!!!! - DEM - 01/29/2024
        var _sampleMessageBody = issueParagraph_1 + `\r\n\r\n` + "Learn more about my campaign at: " + `\r\n\r\n` + "https://micrositetest01.hyperscaleaf.com"+ `\r\n\r\n` + "Reply STOP to Unsubscribe";

        try{
            var _afc_sample1 = {targetPhone: "+17752339418", mediaURL: largeLogo, messageBody: _sampleMessageBody, targetName: "Dave" + ' ' + "Espinosa"};
            //var _sample1 = {targetPhone: samplePhone_1, mediaURL: smallLogo_GetURL, messageBody: issueParagraph_1, targetName: sampleFirstName_1 + ' ' + sampleLastName_1};
            await postSampleMessage(_afc_sample1);
        }catch(err){
            console.log("error sending sms to AFC target 1", err);
        }

        try{
            var _afc_sample2 = {targetPhone: "+17752256204", mediaURL: largeLogo, messageBody: _sampleMessageBody, targetName: "Rick" + ' ' + "Ross"};
            //var _sample1 = {targetPhone: samplePhone_1, mediaURL: smallLogo_GetURL, messageBody: issueParagraph_1, targetName: sampleFirstName_1 + ' ' + sampleLastName_1};
            await postSampleMessage(_afc_sample2);
        }catch(err){
            console.log("error sending sms to AFC target 2", err);
        }

        if(samplePhone_1.trim().length != 0){
             try{
                var sample1 = {targetPhone: samplePhone_1, mediaURL: largeLogo, messageBody: _sampleMessageBody, targetName: sampleFirstName_1 + ' ' + sampleLastName_1};
                await postSampleMessage(sample1);
            }catch(err){
                console.log("error sending sms to target 1", err);
            }
        }

        if(samplePhone_2.trim().length != 0){
             try{
                var sample2 = {targetPhone: samplePhone_2, mediaURL: largeLogo, messageBody: _sampleMessageBody, targetName: sampleFirstName_2 + ' ' + sampleLastName_2};
                await postSampleMessage(sample2);
            }catch(err){
                console.log("error sending sms to target 2", err);
            }
        }

        if(samplePhone_3.trim().length != 0){
             try{
                var sample3 = {targetPhone: samplePhone_3, mediaURL: largeLogo, messageBody: _sampleMessageBody, targetName: sampleFirstName_3 + ' ' + sampleLastName_3};
                await postSampleMessage(sample3);
            }catch(err){
                console.log("error sending sms to target 3", err);
            }
        }

        if(samplePhone_4.trim().length != 0){
             try{
                var sample4 = {targetPhone: samplePhone_4, mediaURL: largeLogo, messageBody: _sampleMessageBody, targetName: sampleFirstName_4 + ' ' + sampleLastName_4};
                await postSampleMessage(sample4);
            }catch(err){
                console.log("error sending sms to target 4", err);
            }
        }

        if(samplePhone_5.trim().length != 0){
             try{
                var sample5 = {targetPhone: samplePhone_5, mediaURL: largeLogo, messageBody: _sampleMessageBody, targetName: sampleFirstName_5 + ' ' + sampleLastName_5};
                await postSampleMessage(sample5);
            }catch(err){
                console.log("error sending sms to target 5", err);
            }
        }


    }

    async function postSampleMessage(messageBundle){

        try{
            var targetPhone = '';
            var mediaURL = '';
            var messageBody = '';
            var fromPhone = '+17752342872';
            //var targetName = '';

            targetPhone = messageBundle.targetPhone;
            mediaURL =  "https://micrositetest01.hyperscaleaf.com/img/" + messageBundle.mediaURL;   //messageBundle.mediaURL;
            messageBody = messageBundle.messageBody + `\r\n\r\n` + "****PROOF****";
            //targetName = messageBundle.name;

            var _id = dayjs().unix();

            if(targetPhone.length == 10){
                targetPhone = '+1' + targetPhone;
            }

            // console.log("media", mediaURL);

            var _msg = {
                  "name": uuidv4(),
                  "event_id": _id.toString(),
                  "bandwidth_campaign_id": "TBD",
                  "application_id": '0914ecb6-a746-474f-9630-ba7d7558b1aa',
                  "admin_phone_number": fromPhone,
                  "admin_phone_number_area_code": "775",
                  "admin_phone_number_status": "COMPLETE",
                  "tasks": [
                    {
                      "task_type": "ADMIN_MESSAGE",
                      "data": {
                        "message": {
                            "body": messageBody,
                            "media_url": mediaURL,
                            "tag": null
                        },
                        "customer": {
                            "phone": targetPhone,
                            "data_message": null,
                            "tag": null
                        }
                      },
                      "status": 'pending',
                      "object_type": null,
                      "created_dt": null,
                      "created_user": null,
                      "updated_dt": null,
                      "updated_user": null
                    }
                  ]
                }

            // console.log("msg posted:", messageBody);

            const headers = { headers: { Authorization: "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZmFwcHNlcnZpY2UiLCJyb2xlcyI6WyJzZWM6Z2xvYmFsYWRtaW4iXSwiZXhwIjoyMDMwMjA3Mjg4fQ.C-aubpVMsd-PRx01FpJwDc3pNKcNN5LPDsRhkVMcQAs' } };
            await axios.put(
                `https://afevent.roosevelt.hyperscaleaf.com/api/v1/adminevents/${_id}`,
                //`${config.eventapi.url}/api/v1/adminevents/${_id}`,
                _msg,
                headers
            );

        }catch(err){
            console.log("error posting sample message:", err);
        }

        //check if the token needs to be refreshed
        dispatch({ type: "CHECK_AUTH" });

    }

    /////////////// ALPHA DEMO ONLY CODE //////////////////////////////////////////////////////////////////////////

    async function collectStatusOfUnifyEventCreate(){


        //084e400d-2018-4b32-ac19-53456ffc4f7b
        var _unifyKey = unifyKey;

        //var _fileName = "unify/" + _unifyKey.toString() + "/stuff.txt";

        const headers = { headers: { Authorization: "Bearer " + jwt, accept: "application/json" } };
        const res = await axios.get(
            `${config.accountapi.url}/api/v1/campaigns/df7252623de77e782c98267dde9eb1d8d4bf9c22/action/micrositeinit?unify_id=${_unifyKey}`,
            headers
        );

        // console.log("init resp", res);

    }


    return (
        <UnifyContext.Provider value={{
            unifyKey:                           unifyKey,
            setUnifyKey:                        setUnifyKey,
            test:                               test,
            uploadImage:                        uploadImage,

            processingUnifyEventCreate:         processingUnifyEventCreate,
            setProcessingUnifyEventCreate:      setProcessingUnifyEventCreate,

            createNewId:                        createNewId,
            generateConfigJson:                 generateConfigJson,
            populateSampleData:                 populateSampleData,
            clearData:                          clearData,
            collectStatusOfUnifyEventCreate:    collectStatusOfUnifyEventCreate,
            sendTextMessageSamples:             sendTextMessageSamples,

            fName:                              fName,
            setFName:                           setFName,
            lName:                              lName,
            setLName:                           setLName,
            fullName:                           fullName,
            setFullName:                        setFullName,
            campaignName:                       campaignName,
            setCampaignName:                    setCampaignName,
            paidForBy:                          paidForBy,
            setPaidForBy:                       setPaidForBy,

            donationLink:                       donationLink,
            setDonationLink:                    setDonationLink,

            mailingAddress:                     mailingAddress,
            setMailingAddress:                  setMailingAddress,
            city:                               city,
            setCity:                            setCity,
            state:                              state,
            setState:                           setState,
            zip:                                zip,
            setZip:                             setZip,
            phone:                              phone,
            setPhone:                           setPhone,

            fb:                                 fb,
            setFb:                              setFb,
            instagram:                          instagram,
            setInstagram:                       setInstagram,
            x:                                  x,
            setX:                               setX,
            snapChat:                           snapChat,
            setSnapChat:                        setSnapChat,
            linkedIn:                           linkedIn,
            setLinkedIn:                        setLinkedIn,
            tikTok:                             tikTok,
            setTikTok:                          setTikTok,

            webSiteName:                        webSiteName,
            setWebSiteName:                     setWebSiteName,
            emailAddress:                       emailAddress,
            setEmailAddress:                    setEmailAddress,

            largeLogo:                          largeLogo,
            setLargeLogo:                       setLargeLogo,
            largeLogo_GetURL:                   largeLogo_GetURL,
            smallLogo:                          smallLogo,
            setSmallLogo:                       setSmallLogo,
            smallLogo_GetURL:                   smallLogo_GetURL,
            favIcon:                            favIcon,
            setFavIcon:                         setFavIcon,
            favIcon_GetURL:                     favIcon_GetURL,

            heroTitle:                          heroTitle,
            setHeroTitle:                       setHeroTitle,
            heroParagraph:                      heroParagraph,
            setHeroParagraph:                   setHeroParagraph,
            heroBanner:                         heroBanner,
            setHeroBanner:                      setHeroBanner,
            heroBanner_GetURL:                  heroBanner_GetURL,

            issuesTitle:                        issuesTitle,
            setIssuesTitle:                     setIssuesTitle,
            issuesIntroduction:                 issuesIntroduction,
            setIssuesIntroduction:              setIssuesIntroduction,

            donateNowTitle:                     donateNowTitle,
            setDonateNowTitle:                  setDonateNowTitle,
            donationImage:                      donationImage,
            setDonationImage:                   setDonationImage,
            donationImage_GetURL:               donationImage_GetURL,
            donateNowTitle_Home2:               donateNowTitle_Home2,
            setDonateNowTitle_Home2:            setDonateNowTitle_Home2,

            spreadTheWordTitle:                 spreadTheWordTitle,
            setSpreadTheWordTitle:              setSpreadTheWordTitle,
            spreadTheWordDesc:                  spreadTheWordDesc,
            setSpreadTheWordDesc:               setSpreadTheWordDesc,
            spreadTheWord1:                     spreadTheWord1,
            setSpreadTheWord1:                  setSpreadTheWord1,
            spreadTheWord1_GetURL:              spreadTheWord1_GetURL,
            spreadTheWord2:                     spreadTheWord2,
            setSpreadTheWord2:                  setSpreadTheWord2,
            spreadTheWord2_GetURL:              spreadTheWord2_GetURL,
            spreadTheWord3:                     spreadTheWord3,
            setSpreadTheWord3:                  setSpreadTheWord3,
            spreadTheWord3_GetURL:              spreadTheWord3_GetURL,

            aboutParagraph1:                    aboutParagraph1,
            setAboutParagraph1:                 setAboutParagraph1,
            aboutParagraph2:                    aboutParagraph2,
            setAboutParagraph2:                 setAboutParagraph2,
            aboutParagraph3:                    aboutParagraph3,
            setAboutParagraph3:                 setAboutParagraph3,
            aboutParagraph4:                    aboutParagraph4,
            setAboutParagraph4:                 setAboutParagraph4,

            aboutImage1:                        aboutImage1,
            setAboutImage1:                     setAboutImage1,
            aboutImage1_GetURL:                 aboutImage1_GetURL,
            aboutImage2:                        aboutImage2,
            setAboutImage2:                     setAboutImage2,
            aboutImage2_GetURL:                 aboutImage2_GetURL,

            aboutDonateNowTitle:                aboutDonateNowTitle,
            setAboutDonateNowTitle:             setAboutDonateNowTitle,
            aboutDonateNowImage:                aboutDonateNowImage,
            setAboutDonateImage:                setAboutDonateImage,
            aboutDonateNowImage_GetURL:         aboutDonateNowImage_GetURL,

            issueTitle_1:                       issueTitle_1,
            setIssueTitle_1:                    setIssueTitle_1,
            issueParagraph_1:                   issueParagraph_1,
            setIssueParagraph_1:                setIssueParagraph_1,
            issueImage_1:                       issueImage_1,
            setIssueImage_1:                    setIssueImage_1,
            issueImage_1_GetURL:                issueImage_1_GetURL,

            issueTitle_2:                       issueTitle_2,
            setIssueTitle_2:                    setIssueTitle_2,
            issueParagraph_2:                   issueParagraph_2,
            setIssueParagraph_2:                setIssueParagraph_2,
            issueImage_2:                       issueImage_2,
            setIssueImage_2:                    setIssueImage_2,
            issueImage_2_GetURL:                issueImage_2_GetURL,

            issueTitle_3:                       issueTitle_3,
            setIssueTitle_3:                    setIssueTitle_3,
            issueParagraph_3:                   issueParagraph_3,
            setIssueParagraph_3:                setIssueParagraph_3,
            issueImage_3:                       issueImage_3,
            setIssueImage_3:                    setIssueImage_3,
            issueImage_3_GetURL:                issueImage_3_GetURL,

            issueTitle_4:                       issueTitle_4,
            setIssueTitle_4:                    setIssueTitle_4,
            issueParagraph_4:                   issueParagraph_4,
            setIssueParagraph_4:                setIssueParagraph_4,
            issueImage_4:                       issueImage_4,
            setIssueImage_4:                    setIssueImage_4,
            issueImage_4_GetURL:                issueImage_4_GetURL,

            issueTitle_5:                       issueTitle_5,
            setIssueTitle_5:                    setIssueTitle_5,
            issueParagraph_5:                   issueParagraph_5,
            setIssueParagraph_5:                setIssueParagraph_5,
            issueImage_5:                       issueImage_5,
            setIssueImage_5:                    setIssueImage_5,
            issueImage_5_GetURL:                issueImage_5_GetURL,

            issueTitle_6:                       issueTitle_6,
            setIssueTitle_6:                    setIssueTitle_6,
            issueParagraph_6:                   issueParagraph_6,
            setIssueParagraph_6:                setIssueParagraph_6,
            issueImage_6:                       issueImage_6,
            setIssueImage_6:                    setIssueImage_6,
            issueImage_6_GetURL:                issueImage_6_GetURL,

            eventsTitle:                        eventsTitle,
            setEventsTitle:                     setEventsTitle,
            eventsParagraph:                    eventsParagraph,
            setEventsParagraph:                 setEventsParagraph,

            eventTitle_1:                       eventTitle_1,
            setEventTitle_1:                    setEventTitle_1,
            eventDescription_1:                 eventDescription_1,
            setEventDescription_1:              setEventDescription_1,
            eventWhen_1:                        eventWhen_1,
            setEventWhen_1:                     setEventWhen_1,
            eventWhere_1:                       eventWhere_1,
            setEventWhere_1:                    setEventWhere_1,

            eventTitle_2:                       eventTitle_2,
            setEventTitle_2:                    setEventTitle_2,
            eventDescription_2:                 eventDescription_2,
            setEventDescription_2:              setEventDescription_2,
            eventWhen_2:                        eventWhen_2,
            setEventWhen_2:                     setEventWhen_2,
            eventWhere_2:                       eventWhere_2,
            setEventWhere_2:                    setEventWhere_2,

            eventTitle_3:                       eventTitle_3,
            setEventTitle_3:                    setEventTitle_3,
            eventDescription_3:                 eventDescription_3,
            setEventDescription_3:              setEventDescription_3,
            eventWhen_3:                        eventWhen_3,
            setEventWhen_3:                     setEventWhen_3,
            eventWhere_3:                       eventWhere_3,
            setEventWhere_3:                    setEventWhere_3,

            eventTitle_4:                       eventTitle_4,
            setEventTitle_4:                    setEventTitle_4,
            eventDescription_4:                 eventDescription_4,
            setEventDescription_4:              setEventDescription_4,
            eventWhen_4:                        eventWhen_4,
            setEventWhen_4:                     setEventWhen_4,
            eventWhere_4:                       eventWhere_4,
            setEventWhere_4:                    setEventWhere_4,

            eventTitle_5:                       eventTitle_5,
            setEventTitle_5:                    setEventTitle_5,
            eventDescription_5:                 eventDescription_5,
            setEventDescription_5:              setEventDescription_5,
            eventWhen_5:                        eventWhen_5,
            setEventWhen_5:                     setEventWhen_5,
            eventWhere_5:                       eventWhere_5,
            setEventWhere_5:                    setEventWhere_5,

            volunteerParagraph:                 volunteerParagraph,
            setVolunteerParagraph:              setVolunteerParagraph,

            sampleFirstName_1:                  sampleFirstName_1,
            setSampleFirstName_1:               setSampleFirstName_1,
            sampleLastName_1:                   sampleLastName_1,
            setSampleLastName_1:                setSampleLastName_1,
            sampleEmail_1:                      sampleEmail_1,
            setSampleEmail_1:                   setSampleEmail_1,
            samplePhone_1:                      samplePhone_1,
            setSamplePhone_1:                   setSamplePhone_1,

            sampleFirstName_2:                  sampleFirstName_2,
            setSampleFirstName_2:               setSampleFirstName_2,
            sampleLastName_2:                   sampleLastName_2,
            setSampleLastName_2:                setSampleLastName_2,
            sampleEmail_2:                      sampleEmail_2,
            setSampleEmail_2:                   setSampleEmail_2,
            samplePhone_2:                      samplePhone_2,
            setSamplePhone_2:                   setSamplePhone_2,

            sampleFirstName_3:                  sampleFirstName_3,
            setSampleFirstName_3:               setSampleFirstName_3,
            sampleLastName_3:                   sampleLastName_3,
            setSampleLastName_3:                setSampleLastName_3,
            sampleEmail_3:                      sampleEmail_3,
            setSampleEmail_3:                   setSampleEmail_3,
            samplePhone_3:                      samplePhone_3,
            setSamplePhone_3:                   setSamplePhone_3,

            sampleFirstName_4:                  sampleFirstName_4,
            setSampleFirstName_4:               setSampleFirstName_4,
            sampleLastName_4:                   sampleLastName_4,
            setSampleLastName_4:                setSampleLastName_4,
            sampleEmail_4:                      sampleEmail_4,
            setSampleEmail_4:                   setSampleEmail_4,
            samplePhone_4:                      samplePhone_4,
            setSamplePhone_4:                   setSamplePhone_4,

            sampleFirstName_5:                  sampleFirstName_5,
            setSampleFirstName_5:               setSampleFirstName_5,
            sampleLastName_5:                   sampleLastName_5,
            setSampleLastName_5:                setSampleLastName_5,
            sampleEmail_5:                      sampleEmail_5,
            setSampleEmail_5:                   setSampleEmail_5,
            samplePhone_5:                      samplePhone_5,
            setSamplePhone_5:                   setSamplePhone_5,

        }}>
        {children}
        </UnifyContext.Provider>
    );
}
