import { useContext, useCallback } from "react";
import { UnifyContext } from "../../context/UnifyContext";

import TextField from '@mui/material/TextField';

//MMS drop location
import { useDropzone } from 'react-dropzone';

const UnifyCampaignCreate_AboutPage = () => {

    const { unifyKey, uploadImage,
            aboutParagraph1,setAboutParagraph1,
            aboutParagraph2,setAboutParagraph2,
            aboutParagraph3,setAboutParagraph3,
            aboutParagraph4,setAboutParagraph4,
            aboutImage1_GetURL, aboutImage2_GetURL,
        } = useContext(UnifyContext);

    const onDrop = useCallback((acceptedFiles:FileList, dropType, unifyCampaignKey) => {
        //mmsFileTypes
        if(acceptedFiles.length > 0){
            //NOTE: remove unwanted characters
            //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
            //eslint-disable-next-line
            var _imageCorrectedName = acceptedFiles[0].name.replace(/[^a-z0-9_.\ ]/gi, '');

            var imagePackage = {name: '', image: '', type: '', target: '', unifyCampaignKey: ''};
            imagePackage.name = _imageCorrectedName;
            imagePackage.image = acceptedFiles[0];
            imagePackage.type = acceptedFiles[0].type;
            imagePackage.target = dropType;
            imagePackage.unifyCampaignKey = unifyCampaignKey;

            // console.log("package to save", imagePackage);

            uploadImage(imagePackage);
        }
    }, []);

    const {getRootProps: getRootProps_About1, getInputProps: getInputProps_About1, isDragActive: isDragActive_About1} = useDropzone(
        {onDrop: (acceptedFile) => { onDrop(acceptedFile, "about1", unifyKey) },
        accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_About2, getInputProps: getInputProps_About2, isDragActive: isDragActive_About2} = useDropzone(
        {onDrop: (acceptedFile) => { onDrop(acceptedFile, "about2", unifyKey) },
        accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    return (

        <div className="unifyComponentPage" style={{margin: "0px 30px 20px 30px"}}>
            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Tell us a bit about yourself... time to shine</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="First Paragraph"
                        style={{width: "1300px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setAboutParagraph1(e.target.value)}
                        value={aboutParagraph1}
                    />
                </div>


            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Second Paragraph"
                        style={{width: "1300px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setAboutParagraph2(e.target.value)}
                        value={aboutParagraph2}
                    />
                </div>


            </div>

            {/*FAMILY PICS!*/}
            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Add some family pics or bio pics of yourself</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div style={{marginTop: "0px", marginLeft: "0px"}}>
                    { aboutImage1_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "640px", height: "310px"}}>
                        <img src={aboutImage1_GetURL}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_About1()} style={{width: "640px", height: "310px"}}>
                          <input {...getInputProps_About1()} />
                          {
                            isDragActive_About1 ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your great bio image here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <div style={{marginTop: "0px", marginLeft: "20px"}}>
                    { aboutImage2_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "640px", height: "310px"}}>
                        <img src={aboutImage2_GetURL}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_About2()} style={{width: "640px", height: "310px"}}>
                          <input {...getInputProps_About2()} />
                          {
                            isDragActive_About2 ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your great bio image here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

            </div>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Round out your story!</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Third Paragraph"
                        style={{width: "1300px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setAboutParagraph3(e.target.value)}
                        value={aboutParagraph3}
                    />
                </div>


            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Fourth Paragraph"
                        style={{width: "1300px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setAboutParagraph4(e.target.value)}
                        value={aboutParagraph4}
                    />
                </div>


            </div>

            {/*DONATE NOW*/}

            {/*<div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Let&lsquo;s get some money moving</div>
                <hr style={{width: "1000px"}}/>
            </div>*/}
            {/*<div className="flex">
                <div className="grid">
                    <TextField
                        type="text"
                        label="Donate Now Title"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setAboutDonateNowTitle(e.target.value)}
                        //value={selectedMin}
                    />
                </div>
            </div>*/}
            {/*<div className="flex" style={{marginTop: "20px"}}>
                <div style={{marginTop: "0px", marginLeft: "0px"}}>
                    <div className="dropFileRectangle_unify" {...getRootProps_Donate()} style={{width: "620px", height: "310px"}}>
                          <input {...getInputProps_Donate()} />
                          {
                            isDragActive_Donate ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your donation image here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>

                </div>


            </div>*/}

            <div className="unifyCallOut" style={{width: "90%", margin: "40px 60px 40px 0px"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>Already a third done! Time flies when you are having fun, eh? Now... let&lsquo;s let people know what you stand for. &nbsp;On to the &quot;Issues&quot; section!</div>
            </div>

        </div>

    );
};

export default UnifyCampaignCreate_AboutPage;
