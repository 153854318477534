import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { CampaignContext } from "../../context/CampaignContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import Topbar from "../../components/topbar/Topbar";
import Hierarchy_navigation from "../../components/navigation/Hierarchy_navigation";
import AmplifyEventManagement from "../../components/amplifyEvents/AmplifyEventManagement";

//FOR TIMEOUT/LOGOFF
import SessionTimeout from "../../components/session/SessionTimeout";
import Session_LogoutImminent from "../../components/session/Session_LogoutImminent";
import Modal from '@mui/material/Modal';

export default function EventCreate() {
    const { jwt } = useContext(AuthContext);
    const { companyName, logoURL } = useContext(CompanyContext);
    const { campaignName } = useContext(CampaignContext);
    const { openLogoutImminent, setOpenLogoutImminent, setLogoutCountdownStarted, closeLogoutModalAndReset } = useContext(ApplicationContext);

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          // Custom logic to handle the refresh
          // Display a confirmation message or perform necessary actions
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    if (jwt === null) {
        window.location.href = "/login";
    }

    useEffect(() => {
        document.title = "Alliance Forge | New Event";
    }, [jwt]);

    useEffect(() => {
        if(openLogoutImminent == true){
            handleModalOpen();
        }
        else{
            handleModalClose();
        }
    }, [openLogoutImminent])

    useEffect(() => {
        // console.log("modalOpen => ", modalOpen);
        if(modalOpen == false){
            setLogoutCountdownStarted(false);
            setOpenLogoutImminent(false);
        }

    }, [modalOpen])

    useEffect(() => {
        handleModalClose();
    }, [closeLogoutModalAndReset])

    return (
        <div>
            <SessionTimeout/>
            <div className="mainPageDiv">
    {/*         <div className="accountHeader">
                    <div className="adminHeaderName flex" >
                        <img className="adminHeaderImage" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Flame_Icon_ShapeFile_BW.png" />

                        <div className="adminHeaderTitle">Event</div>
                        <div className="adminHeaderTitle2">Management</div>
                    </div>
                    <div className="adminHeaderDescription">create event</div>
                </div>*/}

                <div className="accountHeader">

                </div>
                <div className="accountHeaderImage" style={{ position: "relative", zIndex: 10 }}>
                    <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "140px", height: "140px", border: "5px solid #555"}} src={(logoURL)}/>
                </div>
                <div className="accountHeaderCompanyName">
                    <div className="accountHeaderCompanyNameTitle">{"//"}{(companyName != null) ? (companyName.toLowerCase()) : ''}{"/"}{(companyName != null) ? (campaignName.toLowerCase()) : ''}</div>
                </div>

                <Topbar className="topBarMenu"/>

                <div className="flex">
    {/*                <div className="hierarchyNavigationTileCreateCampaign">
                        <CreateNewCampaign />
                    </div>*/}
                    <div className="hierarchyNavigationTileFull">
                        <Hierarchy_navigation status={"event"} objectWidth={"full"}/>
                    </div>
    {/*                <div className="hierarchyNavigationTileCreateEvent">
                        <CreateNewEvent />
                    </div>*/}
                </div>

                <div className="mainBody">
                    <AmplifyEventManagement/>
                </div>
            </div>
            <div>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <div className="LogoffImminent_modalWindow">
                        <Session_LogoutImminent />
                    </div>
                </Modal>
            </div>
        </div>
    );
}
