import { useState, useEffect, useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import { ProfileManagementContext } from "../../context/ProfileManagementContext";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";

const PasswordReset = () => {
    const { windowWidth, reportWindowSize, appRenderType } = useContext(ApplicationContext);
    const { resetPassword } = useContext(ProfileManagementContext);

    //eslint-disable-next-line
    const [userName, setUserName] = useState("");
    //eslint-disable-next-line
    const [userEMail, setUserEMail] = useState("");

    const [password, setPassword] = useState("");
    const [passwordVerification, setPasswordVerification] = useState("");

    const [displayType, setDisplayType] = useState("password_set");

    const [lCaseVerificationOK, setLCaseVerificationOK] = useState('collapse');
    const [uCaseVerificationOK, setUCaseVerificationOK] = useState('collapse');
    const [numVerificationOK, setNumCaseVerificationOK] = useState('collapse');
    const [specVerificationOK, setSpecVerificationOK] = useState('collapse');
    const [lenVerificationOK, setLenVerificationOK] = useState('collapse');

    const [lCaseVerificationNo, setLCaseVerificationNo] = useState('visible');
    const [uCaseVerificationNo, setUCaseVerificationNo] = useState('visible');
    const [numVerificationNo, setNumCaseVerificationNo] = useState('visible');
    const [specVerificationNo, setSpecVerificationNo] = useState('visible');
    const [lenVerificationNo, setLenVerificationNo] = useState('visible');

    const [verificationOK, setVerificationOK] = useState('collapse');
    const [passwordComplexityOK, setPasswordComplexityOK] = useState("collapse");

    const [inboundToken, setInboundToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        reportWindowSize();
        setUserName("");
        setUserEMail("");

        try{
            const parsed = queryString.parse(location.search);
            setInboundToken(parsed.token);
        }
        catch(err){
            console.log("***** ERROR *****", "token is missing");
        }
        setVerificationOK('hidden');
        setDisplayType("password_set");
    }, [])

    useEffect(() => {
        const _lcasePattern = /^(?=.*?[a-z]).{1,32}$/;
        var _lcaseTest = _lcasePattern.test(password);
        if(_lcaseTest == true){
            setLCaseVerificationOK('visible');
            setLCaseVerificationNo('collapse');
        }
        else{
            setLCaseVerificationOK('collapse');
            setLCaseVerificationNo('visible');
        }

        const _ucasePattern = /^(?=.*?[A-Z]).{1,32}$/;
        var _ucaseTest = _ucasePattern.test(password);
        if(_ucaseTest == true){
            setUCaseVerificationOK('visible');
            setUCaseVerificationNo('collapse');
        }
        else{
            setUCaseVerificationOK('collapse');
            setUCaseVerificationNo('visible');
        }

        const _numPattern = /^(?=.*?[0-9]).{1,32}$/;
        var _numTest = _numPattern.test(password);
        if(_numTest == true){
            setNumCaseVerificationOK('visible');
            setNumCaseVerificationNo('collapse');
        }
        else{
            setNumCaseVerificationOK('collapse');
            setNumCaseVerificationNo('visible');
        }

        const _specPattern = /^(?=.*?[^0-9A-Za-z]).{1,32}$/;
        var _specTest = _specPattern.test(password);
        if(_specTest == true){
            setSpecVerificationOK('visible');
            setSpecVerificationNo('collapse');
        }
        else{
            setSpecVerificationOK('collapse');
            setSpecVerificationNo('visible');
        }

        // var _lenTest = password.length >= 8 && password.length <= 32;
        const _lenPattern = /^.{8,32}$/;
        var _lenTest = _lenPattern.test(password);
        // console.log("len test", _lenTest);
        if(_lenTest == true){
            setLenVerificationOK('visible');
            setLenVerificationNo('collapse');
        }
        else{
            setLenVerificationOK('collapse');
            setLenVerificationNo('visible');
        }

        const _validPattern = /^(?=.*?[0-9])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^0-9A-Za-z]).{8,32}$/;
        var _passwordCheck = _validPattern.test(password);
        if(_passwordCheck == false){
            setPasswordComplexityOK("hidden");
        }
        else{
            setPasswordComplexityOK("visible");
        }
    }, [password])

    useEffect(() => {
        if(passwordVerification == password && passwordComplexityOK == "visible"){
            setVerificationOK("visible");
        }
        else{
            setVerificationOK("collapse")
        }
    }, [passwordVerification])

    function close(){
        navigate("/login");
    }


    function sendPassword(){
        //SET THE PASSWORD IN MONGO HERE
        var _pwdBundle = {user_token : "", password: ""};

        _pwdBundle.user_token = inboundToken;
        _pwdBundle.password = password;


        resetPassword(_pwdBundle);

        setDisplayType("PWDResetComplete");
    }


    return (
        <div>
            { inboundToken != null ?
            <div>
                {appRenderType == "desktop"  || appRenderType == "tablet" ?

                <div className="passwordManagementBackground">
                    <div className="bg-neutral" style={{height: "150px"}}>
                        <div style={{height: "35px"}}>&nbsp;</div>
                        <div style={{marginLeft: "20px"}}>
                            <img style={{width: "200px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_transaprent.png"/>
                        </div>
                    </div>
                    <div className="passwordManagementBackgroundBorder" style={{marginLeft: (windowWidth/2)-375 + "px", marginTop: "-30px", zIndex: "99"}}>
                        <div style={{height: "50px"}}></div>
                        <div style={{marginLeft: "290px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="175" height="175" viewBox="0 0 24 24"
                                fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
                                <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                            </svg>
                        </div>

                        { displayType == "password_set" ?
                        <div style={{marginTop: "50px", marginLeft: "130px"}}>
                            <div className="flex">
                                <div style={{width: "130px", marginTop: "12px"}}><label style={{width: "200px", fontSize: "12px"}}>Password</label></div>
                                <div className="grid">
                                    <input className="input input-bordered input-md" type="password" id="password" name="password" value={password}
                                        style={{width: "300px", marginLeft: "10px"}}
                                        onChange={e => setPassword(e.target.value.trim())}/>
                                    {/*}
                                    <label style={{color: "red", fontSize: "10px", width: "300px", marginLeft: "15px", marginTop: "10px"}}>
                                        New passwords MUST be between 8 and 32 characters in length, contain at least 1 lowercase letter, at least 1 upper case letter, at least 1 number, and at least 1 special character
                                    </label>
                                    {*/}
                                    <div className="grid" style={{marginTop: "10px", marginLeft: "15px", fontSize: "12px"}}>
                                        <div className="flex">
                                            <div style={{visibility: lenVerificationNo}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                    fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </div>
                                            <div style={{visibility: lenVerificationOK}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                    strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                </svg>
                                            </div>
                                            <label style={{marginLeft: "10px"}}>Between 8 and 32 characters</label>
                                        </div>
                                        <div className="flex">
                                            <div style={{visibility: lCaseVerificationNo}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                    fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </div>
                                            <div style={{visibility: lCaseVerificationOK}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                    strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                </svg>
                                            </div>
                                            <label style={{marginLeft: "10px"}}>At least 1 lower case character</label>
                                        </div>
                                        <div className="flex">
                                            <div style={{visibility: uCaseVerificationNo}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                    fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </div>
                                            <div style={{visibility: uCaseVerificationOK}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                    strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                </svg>
                                            </div>
                                            <label style={{marginLeft: "10px"}}>At least 1 upper case character</label>
                                        </div>
                                        <div className="flex">
                                            <div style={{visibility: numVerificationNo}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                    fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </div>
                                            <div style={{visibility: numVerificationOK}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                    strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                </svg>
                                            </div>
                                            <label style={{marginLeft: "10px"}}>At least 1 number</label>
                                        </div>
                                        <div className="flex">
                                            <div style={{visibility: specVerificationNo}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                    fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                                </svg>
                                            </div>
                                            <div style={{visibility: specVerificationOK}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                    strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                </svg>
                                            </div>
                                            <label style={{marginLeft: "10px"}}>At least 1 special character (e.g. !,@,#.$,%,^)</label>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginLeft: "10px", visibility: passwordComplexityOK}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                        strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                    </svg>
                                </div>
                            </div>

                            <div className="flex" style={{marginTop: "20px"}}>
                                <div style={{width: "130px", marginTop: "12px"}}><label style={{width: "200px", fontSize: "12px"}}>Verify Password</label></div>
                                <div className="grid">
                                    <input className="input input-bordered input-md" type="password" id="password" name="password" value={passwordVerification}
                                        style={{width: "300px", marginLeft: "10px"}}
                                        onChange={e => setPasswordVerification(e.target.value.trim())}/>
                                    <label style={{marginLeft: "45px", fontSize: "12px"}}>Re-enter your password exactly as above</label>
                                </div>
                                <div style={{marginLeft: "10px", visibility: verificationOK}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                        strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                    </svg>
                                </div>
                            </div>

                             <div className="card-actions justify-center mt-10" style={{visibility: verificationOK}}>
                                <button className="btn btn-neutral btn-wide" onClick={e => sendPassword(e)}>Set New Password</button>
                            </div>
                        </div>
                        :
                        <div style={{marginTop: "50px", marginLeft: "130px"}}>
                            <div className="flex">
                                <div style={{width: "500px", marginTop: "12px"}}>
                                    <label style={{width: "500px"}}>
                                        Your password has now be reset and you are ready to get started. Thank you!
                                    </label>
                                </div>
                            </div>

                            <div className="card-actions justify-center mt-10">
                              <button className="btn btn-neutral btn-wide" onClick={e => close(e)}>Close</button>
                            </div>
                        </div>
                        }

                    </div>
                    <footer style={{zIndex: "1"}}>
                        <div className="signinFooter bg-neutral align-bottom" style={{maxHeight: "200px", overflow: "hidden"}}>
                            <div className="flex">
                                <div className="mt-8" >
                                    <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                                </div>
                                {windowWidth >= 650 ?
                                    <div className="signinFooterTagLineRight" >
                                        <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/RUN%20WITH%20US%20-%20INVERSE.png"/>
                                    </div>
                                :
                                    <div/>
                                }
                            </div>
                        </div>
                    </footer>
                </div>
                :
                <div className="passwordManagementBackground">
                    <div className="bg-neutral" style={{height: "150px"}}>
                        <div style={{height: "35px"}}>&nbsp;</div>
                        <div style={{marginLeft: "20px"}}>
                            <img style={{width: "200px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_transaprent.png"/>
                        </div>
                    </div>
                    <div className="passwordManagementBackgroundBorder_Phone" style={{marginTop: "-30px", zIndex: "99"}}>
                        <div style={{height: "10px"}}></div>
                        <div style={{marginLeft: "195px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24"
                                fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
                                <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                            </svg>
                        </div>

                        { displayType == "password_set" ?
                        <div style={{marginTop: "0px", marginLeft: "15px"}}>
                            <div className="flex">
                                <div className="grid">
                                    <div style={{width: "130px", marginTop: "0px", marginLeft: "10px"}}><label style={{width: "200px", fontSize: "12px"}}>Password</label></div>
                                    <div className="grid">
                                        <input className="input input-bordered input-md" type="password" id="password" name="password" value={password}
                                            style={{width: "350px", marginLeft: "10px"}}
                                            onChange={e => setPassword(e.target.value.trim())}/>
                                        {/*}
                                        <label style={{color: "red", fontSize: "10px", width: "300px", marginLeft: "15px", marginTop: "10px"}}>
                                            New passwords MUST be between 8 and 32 characters in length, contain at least 1 lowercase letter, at least 1 upper case letter, at least 1 number, and at least 1 special character
                                        </label>
                                        {*/}
                                        <div >
                                            <div className="flex" style={{marginTop: "10px", fontSize: "12px"}}>
                                                <div className="grid" style={{marginLeft: "15px"}}>
                                                    <div className="flex">
                                                        <div style={{visibility: lenVerificationNo}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                                fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                        <div style={{visibility: lenVerificationOK}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                                strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                            </svg>
                                                        </div>
                                                        <label style={{marginLeft: "10px", width: "120px"}}>Between 8 and 32 characters</label>
                                                    </div>
                                                    <div className="flex">
                                                        <div style={{visibility: lCaseVerificationNo}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                                fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                        <div style={{visibility: lCaseVerificationOK}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                                strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                            </svg>
                                                        </div>
                                                        <label style={{marginLeft: "10px", width: "120px"}}>At least 1 lower case character</label>
                                                    </div>
                                                    <div className="flex">
                                                        <div style={{visibility: uCaseVerificationNo}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                                fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                        <div style={{visibility: uCaseVerificationOK}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                                strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                            </svg>
                                                        </div>
                                                        <label style={{marginLeft: "10px", width: "120px"}}>At least 1 upper case character</label>
                                                    </div>
                                                </div>
                                                <div style={{marginLeft: "20px"}}>
                                                    <div className="flex">
                                                        <div style={{visibility: numVerificationNo}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                                fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                        <div style={{visibility: numVerificationOK}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                                strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                            </svg>
                                                        </div>
                                                        <label style={{marginLeft: "10px", width: "120px"}}>At least 1 number</label>
                                                    </div>
                                                    <div className="flex">
                                                        <div style={{visibility: specVerificationNo}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                                                fill="none" stroke="#ff0006" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"                                                >
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                        <div style={{visibility: specVerificationOK}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                                                strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                            </svg>
                                                        </div>
                                                        <label style={{marginLeft: "10px", width: "120px"}}>At least 1 special character (e.g. !,@,#.$,%,^)</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginLeft: "0px", visibility: passwordComplexityOK}}>{/*}{*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                        strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" style={{marginTop: "20px"}}>
                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                    </svg>
                                </div>
                            </div>

                            <div className="grid">
                                <div className="flex" style={{marginTop: "-10px"}}>
                                    <div className="grid">
                                        <div style={{width: "130px", marginTop: "12px", marginLeft: "10px"}}><label style={{width: "200px", fontSize: "12px"}}>Verify Password</label></div>
                                        <div className="grid">
                                            <input className="input input-bordered input-md" type="password" id="password" name="password" value={passwordVerification}
                                                style={{width: "350px", marginLeft: "10px"}}
                                                onChange={e => setPasswordVerification(e.target.value.trim())}/>
                                            <label style={{marginLeft: "45px", fontSize: "12px"}}>Re-enter your password exactly as above</label>
                                        </div>
                                    </div>
                                    <div style={{marginLeft: "13px", visibility: verificationOK}}>{/*}{*/}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                            strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" style={{marginTop: "35px"}}>
                                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-actions justify-center mt-5 -ml-3" style={{visibility: verificationOK}}>
                                    <button className="btn btn-neutral btn-wide" onClick={e => sendPassword(e)}>Set New Password</button>
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{marginTop: "50px", marginLeft: "130px"}}>
                            <div className="flex">
                                <div style={{width: "500px", marginTop: "12px"}}>
                                    <label style={{width: "500px"}}>
                                        Your password has now be reset and you are ready to get started. Thank you!
                                    </label>
                                </div>
                            </div>

                            <div className="card-actions justify-center mt-10">
                              <button className="btn btn-neutral btn-wide" onClick={e => close(e)}>Close</button>
                            </div>
                        </div>
                        }

                    </div>
                    <footer style={{zIndex: "1"}}>
                        <div className="signinFooter bg-neutral align-bottom" style={{maxHeight: "200px", overflow: "hidden"}}>
                            <div className="flex">
                                <div className="mt-8" >
                                    <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                                </div>
                                {windowWidth >= 650 ?
                                    <div className="signinFooterTagLineRight" >
                                        <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/RUN%20WITH%20US%20-%20INVERSE.png"/>
                                    </div>
                                :
                                    <div/>
                                }
                            </div>
                        </div>
                    </footer>
                </div>
                }
            </div>
            :
            <div>
                {appRenderType == "desktop"  || appRenderType == "tablet" ?
                <div className="passwordManagementBackground">
                    <div className="bg-neutral" style={{height: "150px"}}>
                        <div style={{height: "35px"}}>&nbsp;</div>
                        <div style={{marginLeft: "20px"}}>
                            <img style={{width: "200px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_transaprent.png"/>
                        </div>
                    </div>
                    <div className="passwordManagementBackgroundBorder" style={{marginLeft: (windowWidth/2)-375 + "px", marginTop: "-30px", zIndex: "99"}}>
                        <div style={{height: "50px"}}></div>
                        <div style={{marginLeft: "290px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="175" height="175" viewBox="0 0 24 24"
                                fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
                                <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                            </svg>
                        </div>
                        <div>
                            <div style={{marginTop: "50px"}}>
                                <div className="grid">
                                    <div style={{display: "flex", width: "100%", marginTop: "12px", justifyContent: "center", alignItems: "center"}}>
                                        <label style={{fontSize: "24px", marginLeft: "50px", marginRight: "50px"}}>
                                            There was en error generating your password reset email. Please contact support.
                                        </label>
                                    </div>
                                    <div style={{display: "flex", width: "100%", marginTop: "100px", justifyContent: "center", alignItems: "center"}}>
                                        <label style={{fontSize: "14px", marginLeft: "50px", marginRight: "50px"}}>
                                            error: no reset token provided
                                        </label>
                                    </div>

                                    <div className="card-actions justify-center" style={{marginTop: "100px"}}>
                                      <button className="btn btn-neutral btn-wide" onClick={e => close(e)}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="passwordManagementBackground">
                    <div className="bg-neutral" style={{height: "150px"}}>
                        <div style={{height: "35px"}}>&nbsp;</div>
                        <div style={{marginLeft: "20px"}}>
                            <img style={{width: "200px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_transaprent.png"/>
                        </div>
                    </div>
                    <div className="passwordManagementBackgroundBorder_Phone" style={{marginTop: "-30px", zIndex: "99"}}>
                        <div style={{height: "30px"}}></div>
                        <div style={{marginLeft: "175px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"
                                fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
                                <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                            </svg>
                        </div>
                        <div>
                            <div style={{marginTop: "50px"}}>
                                <div className="grid">
                                    <div style={{display: "flex", width: "100%", marginTop: "12px", justifyContent: "center", alignItems: "center"}}>
                                        <label style={{fontSize: "24px", marginLeft: "50px", marginRight: "50px"}}>
                                            There was en error generating your password reset email. Please contact support.
                                        </label>
                                    </div>
                                    <div style={{display: "flex", width: "100%", marginTop: "50px", justifyContent: "center", alignItems: "center"}}>
                                        <label style={{fontSize: "14px", marginLeft: "50px", marginRight: "50px"}}>
                                            error: no reset token provided
                                        </label>
                                    </div>

                                    <div className="card-actions justify-center" style={{marginTop: "50px"}}>
                                      <button className="btn btn-neutral btn-wide" onClick={e => close(e)}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            }
        </div>
    )

}

export default PasswordReset;
