import { useEffect, useState, useContext } from "react";
import { CompanyContext } from "../../context/CompanyContext";
import { ApplicationContext } from "../../context/ApplicationContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTreeChart from "highcharts/modules/treemap";
import HighchartsData from "highcharts/modules/data";

HighchartsData(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsTreeChart(Highcharts);
HighchartsData(Highcharts);

const CompanyCampaignMsgCounts = () => {
    const { campaignMsgCountsFromSQL } = useContext(CompanyContext);
    const { appRenderType } = useContext(ApplicationContext);
    const [ reportTitle, setReportTitle ] = useState('Message Counts By Campaign');

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    useEffect(() => {
        if(appRenderType == "desktop" || appRenderType == "tablet"){
            setReportTitle("Message Counts By Campaign");
        }
        else{
            setReportTitle("");
        }
    }, [appRenderType])

    useEffect(() =>{
        Highcharts.getOptions().colors.push("#293f78")
    })

    return (
        <div className={`stats ${
                appRenderType=="desktop" || appRenderType == "tablet" ?
                    "dashboardTile"
                :
                    "dashboardTile_Phone"
                }`}>

            <HighchartsReact  highcharts={Highcharts} options={
                {
                    colorAxis: {
                        minColor: '#FFFFFF',
                        maxColor: Highcharts.getOptions().colors[Highcharts.getOptions().colors.length - 1]
                    },
                    chart: {
                        renderTo: 'container',
                        type: 'treemap'
                    },
                    title: {
                        text: reportTitle
                    },
                    series: [
                        {
                            layoutAlgorithm: 'squarified',
                            name: 'Message Counts By Project Code',
                            size: '90%',
                            data: campaignMsgCountsFromSQL

                        }
                    ]
                }
            }/>
        </div>
    );
};

export default CompanyCampaignMsgCounts;
