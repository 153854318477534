import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { EventReportContext } from "../../context/EventReportContext";
import { ApplicationContext } from "../../context/ApplicationContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);

const EventSuccessBarChart = () => {
    const { isMobileDisplay } = useContext(AuthContext);
    const { eventDetails, eventStats } = useContext(EventReportContext);
    const { appRenderType } = useContext(ApplicationContext);

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    const dataChartBar = [
        { label: 'Universe Size', count: eventStats.msg_sent_count, color: 'Blue' },
        { label: 'Successful', count: eventStats.msg_success_count, color: 'Green' },
        { label: 'Error', count: eventStats.msg_err_count, color: 'Orange' },
        { label: 'Opt Out', count: eventStats.msg_opt_out_count, color: 'Red' }
    ];

    return (
            <div>
                {appRenderType == "desktop" || appRenderType == "tablet" ?
                <div className={`stats ${
                                        isMobileDisplay
                                            ? "eventReportChartMobile rounded-none pl-5 pt-5 pb-5 pr-5"
                                            : "dashboardDoubleWidthTile"
                                        }`}>

                    <HighchartsReact highcharts={Highcharts} options={
                        {
                            chart: {
                                renderTo: 'container',
                                type: 'column'
                            },
                            title: {
                                text: eventDetails.name
                            },
                            colors: ["#346793", "#4081ba", "#639aca", "#88b3d8", "#aecbe5"],
                            xAxis: {
                                categories: dataChartBar.map(d => d.label)
                            },
                            yAxis: {
                                title: {
                                    text: 'Messages'
                                }
                            },
                            series: [
                                {
                                    data: dataChartBar.map(d => d.count),
                                    dataLabels: {
                                        enabled: true,
                                    }
                                }
                            ],
                            legend: {
                                enabled: false
                            }
                        }
                    }/>
                </div>
                :
                <div className={`stats dashboardDoubleWidthTile_Phone`}>

                    <HighchartsReact highcharts={Highcharts} options={
                        {
                            chart: {
                                renderTo: 'container',
                                type: 'column'
                            },
                            title: {
                                text: ''
                            },
                            colors: ["#346793", "#4081ba", "#639aca", "#88b3d8", "#aecbe5"],
                            xAxis: {
                                categories: dataChartBar.map(d => d.label)
                            },
                            yAxis: {
                                title: {
                                    text: 'Messages'
                                }
                            },
                            series: [
                                {
                                    data: dataChartBar.map(d => d.count),
                                    dataLabels: {
                                        enabled: true,
                                    }
                                }
                            ],
                            legend: {
                                enabled: false
                            }
                        }
                    }/>
                </div>
            }
        </div>
    );
};

export default EventSuccessBarChart;
