

//eslint-disable-next-line
export const AFCFieldNames = ['UID','FName','LName','Cell_Phone','Party','State','Precinct','County','URL'];


// =============================================================================
//  MosaicMap_VoterSet
// -----------------------------------------------------------------------------
export function MosaicMap_VoterSet() {
    this.version = new MosaicMap_VersionInfo();
    this.metaData = new MosaicMap_MetaData();
    this.filesGroup = new MosaicMap_FilesGroup();
    this.postProcess = new MosaicMap_PostProcess();
}

MosaicMap_VoterSet.prototype.toString = function() {
    let jsonObjectString = `{ ` +
        `${this.version.toString()}, ` +
        `${this.metaData.toString()}, ` +
        `${this.filesGroup.toString()}, ` +
        `${this.postProcess.toString()}` +
        ` }`;

    return JSON.stringify(jsonObjectString);
}

MosaicMap_VoterSet.prototype.toJSON = function() {
    let jsonObjectString = `{ ` +
        `${this.version.toString()}, ` +
        `${this.metaData.toString()}, ` +
        `${this.filesGroup.toString()}, ` +
        `${this.postProcess.toString()}` +
        ` }`;
    let stringified = JSON.stringify(jsonObjectString);

    return JSON.parse(stringified);
}


// =============================================================================
//  MosaicMap_VersionInfo
// -----------------------------------------------------------------------------
export function MosaicMap_VersionInfo() {
    // Mosaic doesn't recognize different 'versions' yet
    this.version = '0.10';
}

MosaicMap_VersionInfo.prototype.toJSON = function() {
}

MosaicMap_VersionInfo.prototype.toString = function() {
    return `mosaic: { filetype: "universe map file", version: ${this.version} }`
}


// =============================================================================
//  MosaicMap_MetaData
// -----------------------------------------------------------------------------
export function MosaicMap_MetaData() {
    // These tell the Mosaic processor where to direct it's output
    this.s3Key = null; // s3 key prefix for the Mosaic processor to write output files
    this.mosaicFileName = null; // the final output filename from the Mosaic processor
    this.mosaicFileURL = null;

    // Mosaic processor will populate these
    this.mosaicName = null;
    this.tags = null;
    this.totalRows = null;
    this.totalRemoved = null;
    this.totalOutput = null;
    this.processingTime = null;
}

MosaicMap_MetaData.prototype.toJSON = function() {
}

MosaicMap_MetaData.prototype.toString = function() {
    let jsonObjectString = `metadata: { ` +
        `name: ${this.mosaicName}, ` +
        `mosaic_filename: ${this.mosaicFileName}, ` +
        `mosaic_file_url: ${this.mosaicFileURL}, ` +
        `tags: ${this.tags}, ` +
        `s3_key: ${this.s3Key}, ` +
        `total_rows: ${this.totalRows}, ` +
        `total_removed: ${this.totalRemoved}, ` +
        `total_optout: ${this.totalOutput}, ` +
        `processing_time: ${this.processingTime}` +
        ` }`;

    return jsonObjectString;
}


// =============================================================================
//  MosaicMap_FilesGroup
// -----------------------------------------------------------------------------
export function MosaicMap_FilesGroup() {
    this.fileMaps = [];
}

MosaicMap_FilesGroup.prototype.addFileMap = function(fileMapObject) {
    this.fileMaps.push(fileMapObject);
}

MosaicMap_FilesGroup.prototype.createNewFileMap = function(fileName) {
    this.fileMaps.push(new MosaicMap_FileMap(fileName));
}

MosaicMap_FilesGroup.prototype.toJSON = function() {
}

MosaicMap_FilesGroup.prototype.toString = function() {
    let fileMapStrings = [];
    this.fileMaps.forEach(object => {
        fileMapStrings.push(object.toString());
    });

    let jsonObjectString = `files: { ${fileMapStrings.join(', ')} }`;
    return jsonObjectString;
}


// =============================================================================
//  MosaicMap_FileMap
// -----------------------------------------------------------------------------
export function MosaicMap_FileMap(mapName) {
    this.mapName = mapName;
    this.s3_root = 'https://universe-file-test.us-lax-1.linodeobjects.com/';
    this.bucket = 'af-votersets';
    this.csv_get_url = null;
    this.csv_get_prepped_url = null;
    this.csv_get_removed_url = null;
    this.csv_put_url = null;

    this.fieldMap_UID = new MosaicMap_UID();
    this.fieldMap_FName = new MosaicMap_FName();
    this.fieldMap_LName = new MosaicMap_LName();
    this.fieldMap_CellPhone = new MosaicMap_CellPhone();
    this.fieldMap_Party = new MosaicMap_Party();
    this.fieldMap_State = new MosaicMap_State();
    this.fieldMap_Precinct = new MosaicMap_Precinct();
    this.fieldMap_County = new MosaicMap_County();
    this.fieldMap_URL = new MosaicMap_URL();

    this.fieldsOperations = new MosaicMap_FieldsOperations();
}

MosaicMap_FileMap.prototype.getFieldMapsList = function() {
    return [
        this.fieldMap_UID,
        this.fieldMap_FName,
        this.fieldMap_LName,
        this.fieldMap_CellPhone,
        this.fieldMap_Party,
        this.fieldMap_State,
        this.fieldMap_Precinct,
        this.fieldMap_County,
        this.fieldMap_URL
    ];
}

MosaicMap_FileMap.prototype.toString = function() {
    let jsonObjectString = `${this.mapName}: { ` +
        `s3_root: ${this.s3_root}, ` +
        `bucket: ${this.bucket}, ` +
        `mapping: { ` +
            `${this.fieldMap_UID}, ` +
            `${this.fieldMap_FName}, ` +
            `${this.fieldMap_LName}, ` +
            `${this.fieldMap_CellPhone}, ` +
            `${this.fieldMap_Party}, ` +
            `${this.fieldMap_State}, ` +
            `${this.fieldMap_Precinct}, ` +
            `${this.fieldMap_County}, ` +
            `${this.fieldMap_URL} ` +
            `}` +
        ` }`;
    return jsonObjectString;
}


// =============================================================================
//  MosaicMap_FieldsMapping
// -----------------------------------------------------------------------------
export function MosaicMap_FieldsMapping() {
    this.mapUID = new MosaicMap_UID(null);
    this.mapFName = new MosaicMap_FName(null);
    this.mapLName = new MosaicMap_LName(null);
    this.mapCellPhone = new MosaicMap_CellPhone(null);
    this.mapParty = new MosaicMap_Party(null);
    this.mapState = new MosaicMap_State(null);
    this.mapPrecinct = new MosaicMap_Precinct(null);
    this.mapCounty = new MosaicMap_County(null);
    this.mapURL = new MosaicMap_URL(null);

    this.mapFields = [
        this.mapUID,
        this.mapFName,
        this.mapLName,
        this.mapCellPhone,
        this.mapParty,
        this.mapState,
        this.mapPrecinct,
        this.mapCounty,
        this.mapURL
    ];
}

MosaicMap_FieldsMapping.prototype.toJSON = function() {
    let jsonObject = {};
    jsonObject[this.mapUID.afcFieldName] = this.mapUID.userFieldName;
    jsonObject[this.mapFName.afcFieldName] = this.mapFName.userFieldName;
    jsonObject[this.mapLName.afcFieldName] = this.mapLName.userFieldName;
    jsonObject[this.mapCellPhone.afcFieldName] = this.mapCellPhone.userFieldName;
    jsonObject[this.mapParty.afcFieldName] = this.mapParty.userFieldName;
    jsonObject[this.mapState.afcFieldName] = this.mapState.userFieldName;
    jsonObject[this.mapPrecinct.afcFieldName] = this.mapPrecinct.userFieldName;
    jsonObject[this.mapCounty.afcFieldName] = this.mapCounty.userFieldName;
    jsonObject[this.mapURL.afcFieldName] = this.mapURL.userFieldName;

    console.log("RICK - jsonObject: ", jsonObject);

    let stringifiedJSONObject = JSON.stringify(jsonObject);
    console.log("RICK - stringifiedJSONObject: ", stringifiedJSONObject);

    let parsedJSONObject = JSON.parse(stringifiedJSONObject);
    console.log("RICK - parsedJSONObject: ", parsedJSONObject);

    return JSON.stringify(parsedJSONObject);
};


// =============================================================================
//  MosaicMap_Field
// -----------------------------------------------------------------------------
export function MosaicMap_Field(afcFieldName, userFieldName) {
    // don't bother validating this one
    // if it's undefined or null then we won't put out anything
    // for toString or toJSON
    this.afcFieldName = afcFieldName;

    if (userFieldName === undefined) {
        userFieldName = null;
    } else {
        this.userFieldName = userFieldName;
    }

    this.type = "field";
}

MosaicMap_Field.prototype.set = function(userFieldName) {
    if (userFieldName === undefined) {
        this.userFieldName = null;
    } else {
        this.userFieldName = userFieldName;
    }
}

MosaicMap_Field.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}


// =============================================================================
//  MosaicMap_UID
// -----------------------------------------------------------------------------
MosaicMap_UID.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_UID.prototype.constructor = MosaicMap_UID;
export function MosaicMap_UID(userFieldName) {
    MosaicMap_Field.call(this, 'UID', userFieldName);
}
MosaicMap_UID.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}

// =============================================================================
//  MosaicMap_FName
// -----------------------------------------------------------------------------
MosaicMap_FName.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_FName.prototype.constructor = MosaicMap_FName;
export function MosaicMap_FName(userFieldName) {
    MosaicMap_Field.call(this, 'FName', userFieldName);
}
MosaicMap_FName.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}

// =============================================================================
//  MosaicMap_LName
// -----------------------------------------------------------------------------
MosaicMap_LName.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_LName.prototype.constructor = MosaicMap_LName;
export function MosaicMap_LName(userFieldName) {
    MosaicMap_Field.call(this, 'LName', userFieldName);
}
MosaicMap_LName.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}

// =============================================================================
//  MosaicMap_CellPhone
// -----------------------------------------------------------------------------
MosaicMap_CellPhone.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_CellPhone.prototype.constructor = MosaicMap_CellPhone;
export function MosaicMap_CellPhone(userFieldName) {
    MosaicMap_Field.call(this, 'Cell_Phone', userFieldName);
}
MosaicMap_CellPhone.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}

// =============================================================================
//  MosaicMap_Party
// -----------------------------------------------------------------------------
MosaicMap_Party.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_Party.prototype.constructor = MosaicMap_Party;
export function MosaicMap_Party(userFieldName) {
    MosaicMap_Field.call(this, 'Party', userFieldName);
}
MosaicMap_Party.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}

// =============================================================================
//  MosaicMap_State
// -----------------------------------------------------------------------------
MosaicMap_State.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_State.prototype.constructor = MosaicMap_State;
export function MosaicMap_State(userFieldName) {
    MosaicMap_Field.call(this, 'State', userFieldName);
}
MosaicMap_State.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}

// =============================================================================
//  MosaicMap_Precinct
// -----------------------------------------------------------------------------
MosaicMap_Precinct.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_Precinct.prototype.constructor = MosaicMap_Precinct;
export function MosaicMap_Precinct(userFieldName) {
    MosaicMap_Field.call(this, 'Precinct', userFieldName);
}
MosaicMap_Precinct.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}

// =============================================================================
//  MosaicMap_County
// -----------------------------------------------------------------------------
MosaicMap_County.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_County.prototype.constructor = MosaicMap_County;
export function MosaicMap_County(userFieldName) {
    MosaicMap_Field.call(this, 'County', userFieldName);
}
MosaicMap_County.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}

// =============================================================================
//  MosaicMap_URL
// -----------------------------------------------------------------------------
MosaicMap_URL.prototype = Object.create(MosaicMap_Field.prototype);
MosaicMap_URL.prototype.constructor = MosaicMap_URL;
export function MosaicMap_URL(userFieldName) {
    MosaicMap_Field.call(this, 'URL', userFieldName);
}
MosaicMap_URL.prototype.toString = function() {
    if (!this.afcFieldName) {
        return;
    }

    if (this.userFieldName === undefined) {
        return `${this.afcFieldName}: null`;
    }

    return `${this.afcFieldName}: ${this.userFieldName}`;
}


// =============================================================================
//  MosaicMap_FieldsOperations
// -----------------------------------------------------------------------------
export function MosaicMap_FieldsOperations() {
    // Mosaic doesn't recognize different 'operations' yet
}


// =============================================================================
//  MosaicMap_PostProcess
// -----------------------------------------------------------------------------
export function MosaicMap_PostProcess() {
    this.postProcesses = [];
}

MosaicMap_PostProcess.prototype.addProcess = function(processObject) {
    this.postProcesses.push(processObject);
}

MosaicMap_PostProcess.prototype.toObject = function() {
    let jsonObject = {};
    jsonObject.postprocess = {};
    this.postProcesses.forEach(object => {
        switch (object.jsonName) {
            case "range":
                jsonObject.postprocess.range = {}
                jsonObject.postprocess.range.start = object.start;
                jsonObject.postprocess.range.end = object.end;
                break;
            case "random":
                jsonObject.random = object.numRows;
                break;
        }
    });
    return jsonObject;
}

MosaicMap_PostProcess.prototype.toJSON = function() {
    let jsonObject = {};
    jsonObject.postprocess = {};
    this.postProcesses.forEach(object => {
        switch (object.jsonName) {
            case "range":
                jsonObject.postprocess.range = {}
                jsonObject.postprocess.range.start = object.start;
                jsonObject.postprocess.range.end = object.end;
                break;
            case "random":
                jsonObject.random = object.numRows;
                break;
        }
    });
    let stringifiedJSONObject = JSON.stringify(jsonObject);
    return JSON.parse(stringifiedJSONObject);
}

MosaicMap_PostProcess.prototype.toString = function() {
    let postProcessStrings = [];
    this.postProcesses.forEach(object => {
        switch (object.jsonName) {
            case "range":
                postProcessStrings.push(`range: { start: ${object.start}, end: ${object.end} }`);
                break;
            case "random":
                postProcessStrings.push(`random: ${object.numRows}`);
                break;
        }
    });

    let jsonObjectString = `postprocess: { ${postProcessStrings.join(', ')} }`;
    return jsonObjectString;
}


// =============================================================================
//  MosaicMap_ProcessRange
// -----------------------------------------------------------------------------
export function MosaicMap_ProcessRange(start, end) {
    this.jsonName = "range"

    if (start === undefined) {
        this.start = null;
    } else {
        this.start = start;
    }

    if (end === undefined) {
        this.end = null;
    } else {
        this.end = end;
    }

}

MosaicMap_ProcessRange.prototype.set = function(start, end) {
    if (start === undefined) {
        this.start = null;
    } else {
        this.start = start;
    }

    if (end === undefined) {
        this.end = null;
    } else {
        this.end = end;
    }
}

MosaicMap_ProcessRange.prototype.setStart = function(start) {
    if (start === undefined) {
        this.start = null;
    } else {
        this.start = start;
    }
}

MosaicMap_ProcessRange.prototype.setEnd = function(end) {
    if (end === undefined) {
        this.end = null;
    } else {
        this.end = end;
    }
}

MosaicMap_ProcessRange.prototype.toJSON = function() {
    let jsonObject = {};
    jsonObject.range = {}
    jsonObject.range.start = this.start;
    jsonObject.range.end = this.end;

    let stringifiedJSONObject = JSON.stringify(jsonObject);
    let parsedJSONObject = JSON.parse(stringifiedJSONObject);
    return JSON.stringify(parsedJSONObject);
}

MosaicMap_ProcessRange.prototype.toString = function() {
    return `range: { start: ${this.start}, end: ${this.end} }`;
}


// =============================================================================
//  MosaicMap_ProcessRandom
// -----------------------------------------------------------------------------
export function MosaicMap_ProcessRandom(numRows) {
    this.jsonName = "random"
    if (numRows === undefined) {
        this.numRows = null;
    } else {
        this.numRows = numRows;
    }
}

MosaicMap_ProcessRandom.prototype.set = function(numRows) {
    if (numRows === undefined) {
        this.numRows = null;
    } else {
        this.numRows = numRows;
    }
}

MosaicMap_ProcessRandom.prototype.toJSON = function() {
    return `random: ${this.numRows}`;
}

MosaicMap_ProcessRandom.prototype.toString = function() {
    return `random: ${this.numRows}`;
}
