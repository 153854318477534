import { useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";

const Campaign_TotalMessagesSent = () => {
    const { campaignStats } = useContext(CampaignContext);

    return (
        <>
            <div>
                <div className="flex" style={{margin: "10px 0px 0px 30px"}}>
                    <div style={{margin: "50px 0px 0px 20px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"/>
                            <path d="M16 16l-4-4-4 4"/>
                        </svg>
                    </div>
                    <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                        <div className="dashboardTileTitle">Total Universe</div>
                        <div className="dashboardTileMainData">{campaignStats.totMemberCountFormatted}</div>
                        <div className="dashboardTileSubtext">Total messages sent for this campaign</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Campaign_TotalMessagesSent;
