/* eslint-disable react/prop-types */

import { React, useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../context/AuthContext";
import { DataMapperContext } from "../../context/DataMapperContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

import { v4 as uuidv4 } from 'uuid';

import { useDropzone } from 'react-dropzone';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';

import 'react-tabs/style/react-tabs.css';

import DataMapperColumnMapping from "../../components/datamapper/DataMapperColumnMapping";
import DataMapperRowFilter from "../../components/datamapper/DataMapperRowFilter";
import DataMapperStats from "../../components/datamapper/DataMapperStats";

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const DataMapper2 = () => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { eventCoreInformation, updateCoreInformationVoterSet, patchEventVoterSetClearMapping } = useContext(AmplifyEventContext);
    const {
        mapperFiles,
        setCurrentFileID,
        attachNewFile,
        closeColumnMapperControl, removeDataFile,
        getMapperFile, closeRowFilterControl,
        finalUnivSize, clearMapping,
        randomSelection, enableRandom, enableRange,
        patchMosaicMap2, dataMapperProcessingVoterSet, dataMapperVoterSetStatus,
        collectDataMapperProcessedVoterSetFiles, setDataMapperProcessingVoterSet,
        getDataMapperVoterSetFirstRecord, dataMapperResults, dataMapperLoadingPreviousMap,
        mosaicUniverseSize, setMosaicUniverseSize,
        voterSetKey,
        dataMapperMappingsEdited, setDataMapperResults,
        setStatsFile, closeDataMapperStats,
        newFileInfo, modalColumnMappingOpen, datamapApplied
    } = useContext(DataMapperContext);

    const [displayProgress, setDisplayProgress] = useState('collapse');
    const [disableDataMapApply, setDisableDataMapApply] = useState(true);
    const [disableClearMapButton, setDisableClearMapButton] = useState(true);

    const [dataFileUniverseName, setDataFileUniverseName] = useState('');
    const [dataFileTags, setDataFileTags] = useState('');

    //eslint-disable-next-line
    const [selectedDataFileName, setSelectedFileName] = useState('');

    //eslint-disable-next-line
    const [modalErrorDesc, setModalErrorDesc] = useState('');

    //UX Messaging
    const [mosaicMessage, setMosaicMessage] = useState('');

    //eslint-disable-next-line
    const [estimatedProcessingTime, setEstimatedProcessingTime] = useState(0);

    //MODAL MANAGMENT
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenRowFilter, setModalOpenRowFilter] = useState(false);
    const [modalOpenMapStats, setModalOpenMapStats] = useState(false);

    //eslint-disable-next-line
    const [modalSystemMessageOpen, setModalSystemMessageOpen] = useState(false);

    const [dataMappingOpsVisibility, setDataMappingOpsVisibility] = useState("visible");

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleModalOpenRowFilter = () => setModalOpenRowFilter(true);
    const handleModalCloseRowFilter = () => setModalOpenRowFilter(false);

    const handleModalOpenMapStats = () => setModalOpenMapStats(true);
    const handleModalCloseMapStats = () => setModalOpenMapStats(false);

    const [maximumFilesReached, setMaximumFilesReached] = useState(false);

    const [applyButtonColor, setApplyButtonColor] = useState('');
    const [applyButtonBGColor, setApplyButtonBGColor] = useState('');

    var removeTileTransId = "";

    // var modalOpenId = 'init';

    const [coreInformationStateId, setCoreInformationStateId] = useState(null);

    useEffect(() => {

        // console.log("this is the current datamapApplied value => ", datamapApplied);

        if(datamapApplied == true){
            // console.log("disable apply");
            setDisableDataMapApply(true);
        }
        else{
            // console.log("enable apply");
            setDisableDataMapApply(false);
        }

    }, [datamapApplied])

    useEffect(() => {
        clear();
    }, [])

    useEffect(() => {

        if(coreInformationStateId != eventCoreInformation?.statusId){
            setDataMapperResults(null);
            setCoreInformationStateId(eventCoreInformation?.statusId);
        }
    }, [eventCoreInformation])

    useEffect(() => {
        if(document.getElementById("fileOutputName") != null){
            document.getElementById("fileOutputName").innerText = dataFileUniverseName;
        }
    }, [dataFileUniverseName])

    useEffect(() => {
        drawDataMapperStructure(mapperFiles, dataFileUniverseName);
    }, [finalUnivSize, randomSelection])

    useEffect(() => {
        var _newUniverseName = dataFileUniverseName;

        // console.log("dataFiles changed");
        // console.log("data file length", mapperFiles.length);

        if(mapperFiles.length > 0){
            //output management
            if(_newUniverseName.length == 0){

                //REMOVE THE FILE EXTENSION
                var _fileName = mapperFiles[0].fileDisplayName.trim().substring(0, mapperFiles[0].fileDisplayName.trim().length - 5);

                _newUniverseName = _fileName + "_Mosaic_Processed"; //mapperFiles[0].fileDisplayName + "_mosaicProcessed";
                setDataFileUniverseName(_newUniverseName);
            }

            // console.log("calling the draw function");

            drawDataMapperStructure(mapperFiles, _newUniverseName);

        }
        else{
            // console.log("prestage 1");
            clear(null);
        }
    }, [mapperFiles])

    useEffect(() => {
        if(dataMapperMappingsEdited == true && datamapApplied == false){

            setApplyButtonColor("white");
            setApplyButtonBGColor("darkorange");
            // console.log("enable apply");
            setDisableDataMapApply(false);
        }
        else{
            setApplyButtonColor("lightgrey");
            setApplyButtonBGColor("white");
            // console.log("disable apply");
            setDisableDataMapApply(true);
        }
    }, [dataMapperMappingsEdited])

    useEffect(() => {
        if(mapperFiles.length >= 3){
            setMaximumFilesReached(true);
        }
        else{
            setMaximumFilesReached(false);
        }
    }, [mapperFiles])

    useEffect(() => {
        if(mapperFiles.length == 0){
            setDisableClearMapButton(true);
        }
        else{
            setDisableClearMapButton(false);
        }
    }, [mapperFiles])

    useEffect(() => {
        if (closeColumnMapperControl.trim().length > 0) {
            setModalOpen(false);
        }
    }, [closeColumnMapperControl])

    useEffect(() => {
        handleModalCloseRowFilter();
    }, [closeRowFilterControl])

    useEffect(() => {
        // console.log("this is the initial state for the modal close =>", closeDataMapperInitialStats);
        handleModalCloseMapStats();
    }, [closeDataMapperStats])

    // EDR - it appears that this only executes when DataMapperContext::patchMosaicMap2()
    //       patches a voterset and sets dataMapperProcessingVoterSet == true
    useEffect(() => {
        if (!dataMapperProcessingVoterSet) {
            //console.log("DataMapper2: entered & exiting useEffect()[dataMapperProcessingVoterSet, dataMapperVoterSetStatus] because (!dataMapperProcessingVoterSet) ", dataMapperProcessingVoterSet);
            return;
        }
        //console.log("DataMapper2: entered useEffect()[dataMapperProcessingVoterSet, dataMapperVoterSetStatus] (NEEDS REWRITING && TESTING) ", dataMapperProcessingVoterSet, dataMapperVoterSetStatus);

        var _totalRecs = mapperFiles.reduce((sum, obj) => sum + obj.qty, 0);
        var _estimatedProcessingTime = Math.ceil(_totalRecs/2750);
        setEstimatedProcessingTime(_estimatedProcessingTime);

        var _loopS = 2.5;

        var _loadingDur = Math.ceil(_estimatedProcessingTime * .3);
        var _processingDur = _estimatedProcessingTime - _loadingDur;

        var _loadingLoops = Math.round(_loadingDur/_loopS);
        var _checkingLoops = Math.round((_processingDur/_loopS)/2);
        var _cleaningLoops = Math.round((_processingDur/_loopS)/2);

        setMosaicMessage(`Let's get started! Step 1... Prepping your Mosaic file. Total estimated process duration: ${_loadingDur}s`);

        var _status = 'processing';
        //eslint-disable-next-line
        var i = 1;
        var iLoops = 1;

        var t = setInterval(async function() {

            if(iLoops > _loadingLoops && iLoops < (_loadingLoops + _checkingLoops)){
                setMosaicMessage(`Step 2... Checking your file contents and applying your specified data map. [estimated duration: ${Math.round(_processingDur/2)}s]`);
            }
            else if(iLoops > (_loadingLoops + _cleaningLoops) && iLoops < (_loadingLoops + _checkingLoops + _cleaningLoops)){
                setMosaicMessage(`Almost done. Step 3... Now we are cleaning your file against the previously Opted-Out and Known-Bad numbers [estimated duration: ${Math.round(_processingDur/2)}s]`);
            }

            if (_status != 'active') {
                //console.log("DataMapper2: useEffect()[dataMapperProcessingVoterSet, dataMapperVoterSetStatus] (_status != active) ", _status);
                //console.log("DataMapper2: useEffect()[dataMapperProcessingVoterSet, dataMapperVoterSetStatus] calling collectDataMapperProcessedVoterSetFiles()");
                var _r = await collectDataMapperProcessedVoterSetFiles();
                _status = _r;
            }
            else{
                //console.log("DataMapper2: useEffect()[dataMapperProcessingVoterSet, dataMapperVoterSetStatus] _status = ", _status);

                // console.log("EXITING: voter set status (" + i + ")", _status);
                clearInterval(t);

                // console.log("************************************************************** redraw mappr files =>", mapperFiles);

                var _newMosaicSize = 0

                const res = await axios.get(
                    `${config.voterapi.url}/api/v1/votersets/${voterSetKey}`,
                    {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
                );

                if(res.data != null){

                    _newMosaicSize = Number(res.data.voter_map.metadata.total_rows);
                    setMosaicUniverseSize(_newMosaicSize);

                    try{
                        var statsUrl = res.data.voter_map.metadata.csv_get_stats_url;

                        if(statsUrl != null){
                            const res_stats = await axios.get(
                                statsUrl,
                                {headers: { accept: "application/json" }}
                            );

                            setStatsFile(res_stats);
                        }
                    }
                    catch(error_dataMapperMeta){
                        console.log("unable to get the voter set stats =>", error_dataMapperMeta)
                    }

                    try{
                        getDataMapperVoterSetFirstRecord(voterSetKey);
                    }
                    catch(err_firstrecord){
                        console.log("unable to collect the first record from the data set => ", err_firstrecord);
                    }

                }

                drawDataMapperStructure(mapperFiles, dataFileUniverseName, _newMosaicSize);

                // console.log("made it here... now what is the event key...", eventCoreInformation.key);

                if(eventCoreInformation?.key != null && eventCoreInformation.key.length > 0){
                    // console.log("update core info source 2");
                    updateCoreInformationVoterSet(voterSetKey,coreInformationStateId);
                }

                setDataMapperProcessingVoterSet(false);
            }

            i++;
            iLoops++;

        }, _loopS * 1000);

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }, [dataMapperProcessingVoterSet, dataMapperVoterSetStatus]);

    useEffect(() => {
        if(dataMapperResults != null){
            if(dataMapperResults.cleaningDetails != null  && dataMapperResults.voterSetKey != null){
                getDataMapperVoterSetFirstRecord(dataMapperResults.voterSetKey);
                updateCoreInformationVoterSet(dataMapperResults.voterSetKey, coreInformationStateId);
            }
        }
    }, [dataMapperResults])

    useEffect(() => {
        if(dataMapperProcessingVoterSet == false){
            // console.log("CLOSE!");
            setDisplayProgress("collapse");
        }
    }, [dataMapperProcessingVoterSet])

    useEffect(() => {
        if(dataMapperLoadingPreviousMap == false && mapperFiles.length > 0){
            // console.log("**************** REDRAW *********************");
            // console.log("mosaic Universe Size: ", mosaicUniverseSize);
            // console.log("voter set key: ", voterSetKey);
            drawDataMapperStructure(mapperFiles, "");
        }
    }, [dataMapperLoadingPreviousMap, mapperFiles])  //, mosaicUniverseSize, voterSetKey

    useEffect(() => {
        if(displayProgress=="visible" || dataMapperLoadingPreviousMap == true){
            setDataMappingOpsVisibility("hidden");
        }
        else{
            setDataMappingOpsVisibility("visible");
        }
    }, [displayProgress, dataMapperLoadingPreviousMap])

    useEffect(() => {
        manageModalOpenForNewFiles();
    }, [modalColumnMappingOpen])

    async function manageModalOpenForNewFiles(){
        // console.log("THERE HAS BEEN A CHANGE TO THE MODAL OPEN USE EFFECT => ", modalColumnMappingOpen);
        // console.log("THIS IS THE LOCAL MODAL OPEN ID => ", modalOpenId);

        if(modalColumnMappingOpen != null && modalColumnMappingOpen == 'drop'){
            // console.log("************************************** OPENNING THE MODAL *********************************");
            handleModalOpen();
            // modalOpenId = uuidv4();

            for(let i = 0; i < (mapperFiles.length); i++){
                // set the file's modalOpenId back to null to prevent open the modal window again unless tehre is a deliberate click of the tile
                mapperFiles[i]['modalOpenId'] = null;
            }
        }
    }

    // this is called after a file is selected (for both, dialog box or drag & drop)
    const onDrop = useCallback((acceptedFiles) => {
        //console.log("DataMapper2: entered onDrop - useCallback((acceptedFiles): ", acceptedFiles)
        var f = acceptedFiles[0];

        //NOTE: remove unwanted characters
        //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
        //eslint-disable-next-line
        var fileName = f.name.replace(/[^a-z0-9_.\ ]/gi, '');

        if (f.size < 512000000 && f.size > 0) {  // allow up to 500MB

            if (fileName.toLowerCase().trim().substr(fileName.toLowerCase().trim().length - 4) === '.csv') {

                // modalOpenId = uuidv4();

                var fileInfo = newFileInfo(uuidv4());
                fileInfo['fileName'] = fileName;
                fileInfo['fileDisplayName'] = fileName.toLowerCase().trim().substr(0, fileName.trim().length - 4);
                fileInfo['fileSize'] = f.size;
                fileInfo['file'] = f;
                fileInfo['qty'] = 0;
                fileInfo['saved'] = false;
                fileInfo['columnMappings'] = null;
                fileInfo['modalOpenId'] = 'drop';

                //console.log("DataMapper2: onDrop - useCallback((acceptedFiles) calling DataMapperContext::attachNewFile(fileInfo) ", fileInfo)
                // instruct DataMapperContext to attach this new file

                attachNewFile(fileInfo);
                // NOTE: HANDLING OF THE COLUMN MAPPING MODAL HAS BEEN MOVED TO A USE EFFECT TRIGGERED FROM THE DATA MAPPER CONTEXT (modalColumnMappingOpen)

            }
            else{
                //TODO: NEED TO RAISE TOAST ALERT HERE

                //setFileColumns([]);
            }

        }

    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: {'text/csv': [], 'application/vnd.ms-excel': []}, validator: dropFileValidator})

    function determineFinalFileRowCount(){

        var finalRowCount = 0;

        for(let i = 0; i < (mapperFiles.length); i++){
            var _m = mapperFiles[i];

            if(_m != null){
                finalRowCount+=_m.qty;
            }
        }

        return finalRowCount;
    }

    function drawDataMapperStructure(files, newUniverseName, inFinalUniverseCount){

        var _qty = files.length;
        var _totalHeight = 0;
        var _rowCount = determineFinalFileRowCount();

        var _simpleLineHeight = 0;

        if(_qty > 0){
            _totalHeight = 120 * (files.length - 1);
        }

        // console.log(files);

        document.getElementById('mainFileParent').replaceChildren();

        if(files.length > 1){

            /******************* main connector *********************************/
            var iDiv = document.createElement("div");
            iDiv.className = "datamapperFileMainConnector";
            iDiv.style.width = "200px";
            iDiv.style.height = (_totalHeight).toString() + "px";
            iDiv.style.marginLeft = "180px";
            iDiv.style.marginTop = "70px";

            // console.log(iDiv);

            document.getElementById('mainFileParent').appendChild(iDiv);

            /******************* connector lines *********************************/

            for(let i = 0; i < (files.length); i++){ //files.length

                if(i != 0 && i < files.length - 1){
                    var iDivL = document.createElement("div");
                    iDivL.className = "datamaperFileConnector";
                    iDivL.style.width = "200px";
                    iDivL.style.marginLeft = "180px";

                    if(i == 1){
                        // console.log("line margin top", (_totalHeight));
                        _simpleLineHeight = (-1*_totalHeight) + 115;
                        iDivL.style.marginTop = ((-1*_totalHeight) + 115).toString() + "px" // "150px";
                    }
                    else{
                        iDivL.style.marginTop = "115px" // "150px";
                        _simpleLineHeight += 115;
                    }

                    document.getElementById('mainFileParent').appendChild(iDivL);
                }


            }

            /******************* file tiles *********************************/

            for(let i = 0; i < files.length; i++){ //files.length
                var _f = files[i];

                if(_f != null || _f != undefined){
                    try{
                        var _id = _f.id; //files[i].id;

                        // console.log("************************************************************************ mappper file id => ", _id);

                        var iDivT = document.createElement("div");
                        iDivT.className = "dataMapperFileTile";
                        iDivT.onclick = ((e) => TileClicked(e, _id));
                        iDivT.name = _id;
                        // console.log("added click for the following id", _id);

                        //file name
                        var iDivName = document.createElement("div");
                        iDivName.className = "datamapperFileTileTitle";
                        iDivName.innerText = _f.fileDisplayName;
                        iDivName.name = _id;

                        iDivT.appendChild(iDivName);

                        if(datamapApplied == false){
                            //remove file icon
                            var iDivRemoveTile = document.createElement("div");
                            iDivRemoveTile.className = "datamapperFileRemove";
                            iDivRemoveTile.innerText = "X"
                            iDivRemoveTile.onclick = ((e) => RemoveFile(e, _id));
                            iDivRemoveTile.name = _id;
                            iDivT.appendChild(iDivRemoveTile);
                        }

                        if(i == 0){
                            // console.log("lineHeight", _simpleLineHeight);
                            // console.log("top", ((-120 * (files.length - 1)) - 70));
                            iDivT.style.marginTop = (-1 * _simpleLineHeight) - ((122 * (files.length - 1)) + 50).toString() + "px";
                            // console.log("tiles top", iDivT.style.marginTop);
                        }
                        else{
                            iDivT.style.marginTop = "20px";
                        }

                        var _qtyTile = 0;
                        if(_f.qty != null){
                            _qtyTile = _f.qty.toLocaleString();
                        }

                        var iDivFileQtyTitle = document.createElement("div");
                        iDivFileQtyTitle.className = "datamapperFileTileQtyTitle";
                        iDivFileQtyTitle.innerText = "file row count"
                        iDivFileQtyTitle.id = "fileOutputQtyTitle";
                        iDivFileQtyTitle.name = _id;
                        iDivT.appendChild(iDivFileQtyTitle);

                        var iDivFileRowQty = document.createElement("div");
                        iDivFileRowQty.className = "datamapperFileTileQty";
                        iDivFileRowQty.innerText = _qtyTile;
                        iDivFileRowQty.id = "fileOutputQty";
                        iDivFileRowQty.name = _id;
                        iDivT.appendChild(iDivFileRowQty);

                        // console.log("tile", iDivT);

                        document.getElementById('mainFileParent').appendChild(iDivT);
                    }
                    catch(err){
                        console.log("cannot build the tile -> ", err);
                    }
                }

            }


            /******************* final connector line *********************************/

            var _singleLineOutput = document.createElement("div");
            _singleLineOutput.className = "datamaperFileConnector";
            _singleLineOutput.style.width = "400px";
            _singleLineOutput.style.marginLeft = "378px";

            var _singleLineOutputMarginTop = 0

            //this is a work around for a rendering issue
            if(files.length != 5){
                _singleLineOutputMarginTop = (-1 * (_totalHeight/2)) - 63;
            }
            else{
                _singleLineOutputMarginTop = (-1 * (_totalHeight/2)) - 66;
            }

            _singleLineOutput.style.marginTop = _singleLineOutputMarginTop.toString() + "px";
            document.getElementById('mainFileParent').appendChild(_singleLineOutput);

            //******************* final output tile ************************************/

            var iDivOutput = document.createElement("div");
            iDivOutput.className = "dataMapperFileTile";

            var iDivOutputName = document.createElement("div");
            iDivOutputName.className = "datamapperFileTileTitle";
            iDivOutputName.innerText = newUniverseName; //"good bye";
            iDivOutputName.id = "fileOutputName";
            iDivOutput.appendChild(iDivOutputName);

            var iDivOutputRowQtyTitle = document.createElement("div");
            iDivOutputRowQtyTitle.className = "datamapperFileTileQtyTitle";
            iDivOutputRowQtyTitle.innerText = "final mosaic row count"
            iDivOutputRowQtyTitle.id = "fileOutputQtyTitle";
            iDivOutput.appendChild(iDivOutputRowQtyTitle);

            var iDivOutputRowQty = document.createElement("div");
            iDivOutputRowQty.className = "datamapperFileTileQty";
            iDivOutputRowQty.innerText = Number(finalUnivSize).toLocaleString();
            iDivOutputRowQty.id = "fileOutputQty";
            iDivOutput.appendChild(iDivOutputRowQty);

            var _OutputMarginTop = 0

            _OutputMarginTop = (_singleLineOutputMarginTop + (60 * files.length)) - 50;

            iDivOutput.style.marginLeft = "700px";
            iDivOutput.style.marginTop = (_OutputMarginTop).toString() + "px"; //((-1 * (_totalHeight/2)) - 110) + "px";
            // console.log("margin top", _OutputMarginTop + 70);

            document.getElementById('mainFileParent').appendChild(iDivOutput);

            //******************* filter icon ************************************/
            var _finalFilter = document.createElement("div");
            _finalFilter.className = "datamaperFinalFilter";
            _finalFilter.style.width = "100px";
            _finalFilter.style.height = "100px";
            _finalFilter.style.borderRadius = "50%";
            _finalFilter.style.marginLeft = "490px"
            _finalFilter.style.marginTop = "-108px"
            _finalFilter.onclick = ((e) => filterOpenClicked(e));
            document.getElementById('mainFileParent').appendChild(_finalFilter);

            var _prelimQtyLabel = document.createElement("div");
            _prelimQtyLabel.className = "datamapperPrelimQty";
            _prelimQtyLabel.innerText = Number(_rowCount).toLocaleString();
            document.getElementById('mainFileParent').appendChild(_prelimQtyLabel);

            var _finalQtyLabel = document.createElement("div");
            _finalQtyLabel.className = "datamapperFinalQty";
            _finalQtyLabel.innerText = Number(finalUnivSize).toLocaleString();
            document.getElementById('mainFileParent').appendChild(_finalQtyLabel);

            //******************* filter flags ********************************/
            var _filterFlags = document.createElement("div");
            _filterFlags.className = "datamapperFilterSettings";

            if(enableRandom == true){
                // console.log("here for the icon 1");
                var _shuffleIcon = document.createElement("div");
                _shuffleIcon.className = "datamapperFilterSettings_ShuffleActive";
                _filterFlags.appendChild(_shuffleIcon);
                // console.log(_randomSelection);
            }

            //******** add when a filter of the selection is set
            if(enableRandom == true || (enableRange == true && _rowCount != finalUnivSize)){
                // console.log("here for the icon 2");
                var _filterIcon = document.createElement("div");
                _filterIcon.className = "datamapperFilterSettings_FilterActive";
                _filterFlags.appendChild(_filterIcon);
                // console.log(_randomSelection);
            }

            document.getElementById("mainFileParent").appendChild(_filterFlags);

            document.getElementById("mainFileParent").style.height = (mapperFiles.length * 130) + "px";
            // document.getElementById("mainFileParent").style.background = "red";

            //************** FINAL RESULTS ******************************************************************
            /******************** mosaic processor connector line **********/
            var MosaicMapperLine = document.createElement("div");
            MosaicMapperLine.className = "datamaperMosaicResultConnector";
            MosaicMapperLine.style.height = "175px";
            MosaicMapperLine.style.width = "20px";
            MosaicMapperLine.style.marginLeft = "820px";
            MosaicMapperLine.style.marginTop = "-39px";
            document.getElementById('mainFileParent').appendChild(MosaicMapperLine);

            //************** FINAL MAP RESULT TILE
            var iDivFinalOutput = document.createElement("div");
            iDivFinalOutput.className = "dataMapperProcessedResultTile";

            var iDivFinalOutputName = document.createElement("div");
            iDivFinalOutputName.className = "datamapperFileTileTitle";
            iDivFinalOutputName.innerText = newUniverseName; //"good bye";
            iDivFinalOutputName.id = "fileOutputName";
            iDivFinalOutputName.style.marginTop = "7px";
            iDivFinalOutput.appendChild(iDivFinalOutputName);

            var iDivFinalOutputRowQtyTitle = document.createElement("div");
            iDivFinalOutputRowQtyTitle.className = "datamapperFileTileQtyTitle";
            iDivFinalOutputRowQtyTitle.innerText = "final mosaic processed row count"
            iDivFinalOutputRowQtyTitle.id = "fileOutputQtyTitle";
            iDivFinalOutput.appendChild(iDivFinalOutputRowQtyTitle);

            var _finalUniverseCount = 0;

            if(inFinalUniverseCount != null){
                _finalUniverseCount = inFinalUniverseCount;
            }
            else{
                if(mosaicUniverseSize > 0){
                    _finalUniverseCount = mosaicUniverseSize;
                }
            }

            var iDivFinalOutputRowQty = document.createElement("div");
            iDivFinalOutputRowQty.className = "datamapperFileTileQty";

            if(_finalUniverseCount > 0){
                iDivFinalOutputRowQty.innerText = Number(_finalUniverseCount).toLocaleString();
            }
            else{
                iDivFinalOutputRowQty.innerText = "PENDING";
            }

            iDivFinalOutputRowQty.id = "fileOutputQty";
            iDivFinalOutput.appendChild(iDivFinalOutputRowQty);

            iDivFinalOutput.style.marginLeft = "700px";
            iDivFinalOutput.style.marginTop = "-50px";

            document.getElementById('mainFileParent').appendChild(iDivFinalOutput);

            /******************* processed result icon/tile *********************************/
            var _finalProcessed = document.createElement("div");
            _finalProcessed.className = "datamaperFinalProcessed";  //tooltip
            _finalProcessed.style.width = "100px";
            _finalProcessed.style.height = "100px";
            _finalProcessed.style.borderRadius = "50%";
            _finalProcessed.style.marginLeft = "788px"
            _finalProcessed.style.marginTop = "-222px"
            _finalProcessed.onclick = ((e) => statsOpenClicked(e));

            document.getElementById('mainFileParent').appendChild(_finalProcessed);


        }
        else{

            if(files.length != 0){
                var _fSingle = files[0];

                /******************* connector line *********************************/
                var _singleLine = document.createElement("div");
                _singleLine.className = "datamaperFileConnector";
                _singleLine.style.width = "700px";
                _singleLine.style.marginLeft = "180px";
                _singleLine.style.marginTop = "70px";
                document.getElementById('mainFileParent').appendChild(_singleLine);

                /******************* file tile *********************************/
                var iDivTSingle = document.createElement("div");
                iDivTSingle.className = "dataMapperFileTile";
                // iDivTSingle.style.background = "red";
                // iDivTSingle.style.position = "relative";
                // iDivTSingle.style.marginTop = "80px";
                iDivTSingle.name = _fSingle.id;
                iDivTSingle.onclick = ((e) => TileClicked(e, _fSingle.id));

                //title
                var iDivNameSingle = document.createElement("div");
                iDivNameSingle.className = "datamapperFileTileTitle";
                iDivNameSingle.innerText = _fSingle.fileDisplayName; //"hello";
                iDivNameSingle.name = _fSingle.id;
                iDivTSingle.appendChild(iDivNameSingle);
                iDivTSingle.style.marginTop = "-52px";

                if(datamapApplied == false){
                    //remove file icon
                    var iDivRemoveTileSingle = document.createElement("div");
                    iDivRemoveTileSingle.className = "datamapperFileRemove";
                    iDivRemoveTileSingle.innerText = "X"
                    iDivRemoveTileSingle.onclick = ((e) => RemoveFile(e, _fSingle.id));
                    iDivRemoveTileSingle.name = _fSingle.id;
                    iDivTSingle.appendChild(iDivRemoveTileSingle);
                }

                var iDivFileQtyTitleSingle = document.createElement("div");
                iDivFileQtyTitleSingle.className = "datamapperFileTileQtyTitle";
                iDivFileQtyTitleSingle.innerText = "file row count"
                iDivFileQtyTitleSingle.id = "fileOutputQtyTitle";
                iDivFileQtyTitleSingle.name = _fSingle.id;
                iDivTSingle.appendChild(iDivFileQtyTitleSingle);

                var _qtyTileSingle = 0;
                if(_fSingle.qty != null){
                    _qtyTileSingle = _fSingle.qty.toLocaleString();
                }

                var iDivFileRowQtySingle = document.createElement("div");
                iDivFileRowQtySingle.className = "datamapperFileTileQty";
                iDivFileRowQtySingle.innerText = _qtyTileSingle;
                iDivFileRowQtySingle.id = "fileOutputQty";
                iDivFileRowQtySingle.name = _fSingle.id;
                iDivTSingle.appendChild(iDivFileRowQtySingle);

                // console.log("tile", iDivTSingle);
                document.getElementById('mainFileParent').appendChild(iDivTSingle);

                /******************* output tile *********************************/
                var iDivOutputSingle = document.createElement("div");
                iDivOutputSingle.className = "dataMapperFileTile";

                var iDivOutputNameSingle = document.createElement("div");
                iDivOutputNameSingle.className = "datamapperFileTileTitle";
                iDivOutputNameSingle.innerText = newUniverseName; //"good bye";
                iDivOutputNameSingle.id = "fileOutputName";
                iDivOutputSingle.appendChild(iDivOutputNameSingle);

                var iDivOutputRowQtyTitleSingle = document.createElement("div");
                iDivOutputRowQtyTitleSingle.className = "datamapperFileTileQtyTitle";
                iDivOutputRowQtyTitleSingle.innerText = "final mosaic row count"
                iDivOutputRowQtyTitleSingle.id = "fileOutputQtyTitle";
                iDivOutputSingle.appendChild(iDivOutputRowQtyTitleSingle);

                var iDivOutputRowQtySingle = document.createElement("div");
                iDivOutputRowQtySingle.className = "datamapperFileTileQty";
                iDivOutputRowQtySingle.innerText = Number(finalUnivSize).toLocaleString();
                iDivOutputRowQtySingle.id = "fileOutputQty";
                iDivOutputSingle.appendChild(iDivOutputRowQtySingle);

                iDivOutputSingle.style.marginLeft = "700px";
                iDivOutputSingle.style.marginTop = "-110px";

                document.getElementById('mainFileParent').appendChild(iDivOutputSingle);

                /******************* filter tile *********************************/
                var _finalFilterSingle = document.createElement("div");
                _finalFilterSingle.className = "datamaperFinalFilter";  //tooltip
                _finalFilterSingle.style.width = "100px";
                _finalFilterSingle.style.height = "100px";
                _finalFilterSingle.style.borderRadius = "50%";
                _finalFilterSingle.style.marginLeft = "448px"
                _finalFilterSingle.style.marginTop = "-108px"
                _finalFilterSingle.onclick = ((e) => filterOpenClicked(e));

                /******************* filter icon *********************************/
                var _finalFilterLogo = document.createElement("div");
                // _finalFilterLogo.className = "datamaperFinalFilter";
                _finalFilterLogo.style.width = "100px";
                _finalFilterLogo.style.height = "100px";
                _finalFilterLogo.style.borderRadius = "50%";

                // var _filterTooltipSingle = document.createElement("span");
                // _filterTooltipSingle.className = "tooltiptext";
                // _filterTooltipSingle.innerText = "[click here] to narrow down your universe to precisely who you want";
                // _finalFilterSingle.appendChild(_filterTooltipSingle);

                document.getElementById('mainFileParent').appendChild(_finalFilterSingle);

                var _prelimQtyLabelSingle = document.createElement("div");
                _prelimQtyLabelSingle.className = "datamapperPrelimQtySingle";
                _prelimQtyLabelSingle.innerText = Number(_rowCount).toLocaleString();
                document.getElementById('mainFileParent').appendChild(_prelimQtyLabelSingle);

                var _finalQtyLabelSingle = document.createElement("div");
                _finalQtyLabelSingle.className = "datamapperFinalQtySingle";
                _finalQtyLabelSingle.innerText = Number(finalUnivSize).toLocaleString();
                document.getElementById('mainFileParent').appendChild(_finalQtyLabelSingle);

                //******************* filter flags ********************************/
                var _filterFlagsSingle = document.createElement("div");
                _filterFlagsSingle.className = "datamapperFilterSettingsSingle";

                //******** add when the random selection flag is set
                if(enableRandom == true){
                    // console.log("here for the icon 1");
                    var _shuffleIconSingle = document.createElement("div");
                    _shuffleIconSingle.className = "datamapperFilterSettings_ShuffleActive";
                    _filterFlagsSingle.appendChild(_shuffleIconSingle);
                    // console.log(_randomSelectionSingle);
                }

                //******** add when a filter of the selection is set
                if(enableRandom == true || (enableRange == true && _rowCount != finalUnivSize)){
                    // console.log("here for the icon 2");
                    var _filterIconSingle = document.createElement("div");
                    _filterIconSingle.className = "datamapperFilterSettings_FilterActive";
                    _filterFlagsSingle.appendChild(_filterIconSingle);
                    // console.log(_randomSelectionSingle);
                }

                document.getElementById("mainFileParent").appendChild(_filterFlagsSingle);

                //************* set the display height
                document.getElementById("mainFileParent").style.height = "145px";
                // document.getElementById("mainFileParent").style.background = "blue";

                //************** FINAL RESULTS ******************************************************************
                /******************** mosaic processor connector line **********/
                var singleMosaicMapperLine = document.createElement("div");
                singleMosaicMapperLine.className = "datamaperMosaicResultConnector";
                singleMosaicMapperLine.style.height = "175px";
                singleMosaicMapperLine.style.width = "20px";
                singleMosaicMapperLine.style.marginLeft = "820px";
                singleMosaicMapperLine.style.marginTop = "-37px";
                document.getElementById('mainFileParent').appendChild(singleMosaicMapperLine);

                //************** FINAL MAP RESULT TILE
                var iDivFinalOutputSingle = document.createElement("div");
                iDivFinalOutputSingle.className = "dataMapperProcessedResultTile";

                var iDivFinalOutputNameSingle = document.createElement("div");
                iDivFinalOutputNameSingle.className = "datamapperFileTileTitle";
                iDivFinalOutputNameSingle.innerText = newUniverseName; //"good bye";
                iDivFinalOutputNameSingle.id = "fileOutputName";
                iDivFinalOutputSingle.appendChild(iDivFinalOutputNameSingle);

                var iDivFinalOutputRowQtyTitleSingle = document.createElement("div");
                iDivFinalOutputRowQtyTitleSingle.className = "datamapperFileTileQtyTitle";
                iDivFinalOutputRowQtyTitleSingle.innerText = "final mosaic processed row count"
                iDivFinalOutputRowQtyTitleSingle.id = "fileOutputQtyTitle";
                iDivFinalOutputSingle.appendChild(iDivFinalOutputRowQtyTitleSingle);

                var _finalUniverseCountSingle = 0;

                if(inFinalUniverseCount != null){
                    _finalUniverseCountSingle = inFinalUniverseCount;
                }
                else{
                    if(mosaicUniverseSize > 0){
                        _finalUniverseCountSingle = mosaicUniverseSize;
                    }
                }

                var iDivFinalOutputRowQtySingle = document.createElement("div");
                iDivFinalOutputRowQtySingle.className = "datamapperFileTileQty";

                if(_finalUniverseCountSingle > 0){
                    iDivFinalOutputRowQtySingle.innerText = Number(_finalUniverseCountSingle).toLocaleString();
                }
                else{
                    iDivFinalOutputRowQtySingle.innerText = "PENDING";
                }

                iDivFinalOutputRowQtySingle.id = "fileOutputQty";
                iDivFinalOutputSingle.appendChild(iDivFinalOutputRowQtySingle);

                iDivFinalOutputSingle.style.marginLeft = "700px";
                iDivFinalOutputSingle.style.marginTop = "-50px";

                document.getElementById('mainFileParent').appendChild(iDivFinalOutputSingle);

                /******************* processed result tile *********************************/
                var _finalProcessedSingle = document.createElement("div");
                _finalProcessedSingle.className = "datamaperFinalProcessed";  //tooltip
                _finalProcessedSingle.style.width = "100px";
                _finalProcessedSingle.style.height = "100px";
                _finalProcessedSingle.style.borderRadius = "50%";
                _finalProcessedSingle.style.marginLeft = "788px"
                _finalProcessedSingle.style.marginTop = "-222px"
                _finalProcessedSingle.onclick = ((e) => statsOpenClicked(e));

                document.getElementById('mainFileParent').appendChild(_finalProcessedSingle);

            }
        }
    }

    function dropFileValidator(){
        // EMPTY
    }

    //this is called from the button
    function clearDBMapping(){
        patchEventVoterSetClearMapping();
        clear();
    }

    function clear(){
        clearMapping();
        document.getElementById('mainFileParent').replaceChildren();

        updateCoreInformationVoterSet(null, coreInformationStateId);
        setDisableClearMapButton(true);
    }

    function TileClicked(source, id){
        try{
            id = source.srcElement.name;

            var _mp = getMapperFile(id);

            if(_mp != null && removeTileTransId.trim().length == 0){
                setCurrentFileID(id);
                handleModalOpen();
            }
            else{
                removeTileTransId = "";
            }
        }
        catch(err){
            console.log(`unable to process the click process for this tile -> ${id}`, err);
        }
    }

    function RemoveFile(source, id){
        try{
            // console.log("remove mapped file =>", id);

            //specify a new id for the current removal transaction
            removeTileTransId = uuidv4();

            removeDataFile(id);
        }
        catch(err){
            console.log("unable to process the requested file removal -> ", err);
        }
    }

    //eslint-disable-next-line
    function filterOpenClicked(source){
        handleModalOpenRowFilter();
    }

    //eslint-disable-next-line
    function statsOpenClicked(source){
        if(voterSetKey != null && voterSetKey.length > 0){
            handleModalOpenMapStats();
        }
    }

    // async function applyUpdatedDataMap(colUniqueID, colFName, colLName, colCellPhone, colParty, colState, colPrecinct, colCounty, colURL, data){
    // }

    // edr - this is only called as the onClick handler for the "APPLY" button
    function postToMosaic() {
        // console.log("\n\n\n\nDataMapper2: entered postToMosaic()")

        setDisplayProgress("visible");
        setMosaicMessage("Posting Data To Mosaic");

        //console.log("data mapper file information -> ", selectedDataFile);
        // patchMosaicMap(selectedDataFileName, selectedDataFile);

        // tell the DataMapperContext to patch the voterset map in Mongo
        // console.log("DataMapper2: postToMosaic() calling patchMosaicMap2(dataFileUniverseName, dataFileTags)", dataFileUniverseName, dataFileTags)
        patchMosaicMap2(dataFileUniverseName, dataFileTags);
    }


    return (
        <div style={{height: "1045px"}}>
            {displayProgress=="visible" || dataMapperLoadingPreviousMap == true ?
            (
            <div className="dataMapperLoading" style={{margin: "0px 0px 0px 0px", visibility: "visible", height: "900px"}} id="loading">
                <div style={{marginTop: "50px", display: "flex", justifyContent: "center", verticalAlign: "middle", alignItems: "center", width: "133%", background: "white", lineHeight: "50px"}}>
                    <label>{mosaicMessage}</label>
                </div>
                <span className="loading loading-bars loading-lg" style={{marginTop: "50px", marginLeft: "320px", width: "430px"}} />
                {/*<label>hello World</label>*/}
            </div>
            )
            :
            (
            <div/>
            )
            }
            <div style={{visibility: dataMappingOpsVisibility}}>
                <div>
                    <div className="flex">
                        <div className="grid" style={{margin: "10px 0px 00px 15px"}}>
                            <div className="flex">
                                <label className="userEditTitle" htmlFor="tags">Universe Name:</label>
                                <label className="userEditTitle" htmlFor="tags" style={{color: "red", fontSize: "24px", marginLeft: "2px", marginTop: "-10px", marginBottom: "-10px"}}>*</label>
                            </div>
                            <input className="input input-bordered input-md w-[296px]" type="text" id="univName"
                                name="univName" style={{background: "white", color: "black"}}
                                //value={eventName} onChange={e => setEventName(e.target.value)}
                                value={dataFileUniverseName} onChange={e => setDataFileUniverseName(e.target.value)}
                                placeholder={'specify the name of the voter set for future reference'} readOnly={false}/>

                        </div>

                        <div className="grid" style={{margin: "10px 0px 00px 20px"}}>
                            <label className="userEditTitle" htmlFor="tags">Data File Tags (Optional):</label>
                            <input className="input input-bordered input-md w-[296px]" type="text" id="tags" onChange={e => setDataFileTags(e.target.value)}
                                style={{background: "white", color: "black"}}
                                value={dataFileTags} name="url" placeholder={'create tags to quickly identify your data file for future messages and campaigns'}
                                readOnly={false}/>

                        </div>

                        <Button variant="outlined" startIcon={<DeleteOutlinedIcon fontSize="large" />} disabled={disableClearMapButton}
                            style={{height: "60px", width: "200px", margin: "20px 0px 0px 15px", background: "white"}}
                            size="medium" onClick={(e) => {clearDBMapping(e)}}>Clear Mapping</Button>

                        <Button variant="outlined" startIcon={<PlaylistAddCheckRoundedIcon />} disabled={disableDataMapApply}
                            style={{height: "60px", width: "200px", margin: "20px 0px 0px 15px", background: applyButtonBGColor, color: applyButtonColor, fontWeight: "bold", fontSize: "18px"}}
                            size="medium" onClick={() => postToMosaic()}>Apply</Button>
                        {/*<button className="btn btn-outline bg-orange-400 mt-5 w-[200px] h-[63px] mt-[20px] ml-[10px]" disabled={disableDataMapApply} style={{color: "white"}}
                            onClick={() => postToMosaic()}>Apply</button>*/}
                        <br/>

                    </div>
                    {
                        (selectedDataFileName == null ||  selectedDataFileName == undefined || selectedDataFileName == '') && maximumFilesReached == false ?
                        (
                            <div className="datamapperDropFileRectangle" {...getRootProps()} style={{margin: "30px 15px 0px 15px"}}>
                                {/*<div className="dropFileTitle">drop csv universe file here</div>*/}

                                  <input {...getInputProps()} />
                                  {
                                    isDragActive ?
                                      <div className="dropFileTitle">Drop the files here ...</div> :
                                      (
                                        <div style={{textAlign: "center"}}>
                                            <div className="datamapperDropFileTitle">Drag and drop CSV files here, or click to select CSV files</div>
                                            <div className="datamapperDropFileTitle" style={{fontSize: "18px"}}>Supported types: CSV of 500MB or less</div>
                                        </div>
                                      )

                                  }
                            </div>
                        )
                        :
                        (
                            <div className="datamapperDropFileRectangle_Max" style={{margin: "30px 15px 0px 15px"}}>
                                <div style={{textAlign: "center"}}>
                                    <div className="datamapperDropFileTitle_Max">A maximum of 3 CSV files are allowed in a standard mosaic map</div>
                                    <div className="datamapperDropFileTitle_Max" style={{fontSize: "18px"}}>Contact Support For Questions About Enterprise Mosaic Maps (coming soon)</div>
                                </div>
                            </div>
                        )
                    }

                    <div className="datamapperMainDisplay">
                        <div id="mainFileParent">

                        </div>
                    </div>
                    <div style={{color: "red", fontSize: "11px", marginBottom: "5px", marginLeft: "15px"}}>
                        NOTE: The above map only displays the raw map counts without the removal of duplicate or invalid numbers, or the removal of those who have opted out.  The cleaning process happens aftetr the APPLY button is pressed
                    </div>
{/*                    <Button variant="outlined" startIcon={<DeleteOutlinedIcon fontSize="large" />}
                        style={{height: "60px", width: "220px", margin: "0px 0px 0px 15px", background: "white"}}
                        size="medium" onClick={(e) => {clear(e)}}>Clear Mapping</Button>*/}
                </div>
                <div>
                    <Modal
                        open={modalOpen}
                        onClose={handleModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <div className="modalDatamapperColumnMapping">
                            <DataMapperColumnMapping/>
                        </div>
                    </Modal>
                    <Modal
                        open={modalOpenRowFilter}
                        onClose={handleModalCloseRowFilter}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <div className="modalDatamapperRowFilter">
                            <DataMapperRowFilter/>
                        </div>
                    </Modal>
                    <Modal
                        open={modalOpenMapStats}
                        onClose={handleModalCloseMapStats}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <div className="datamapperInitialStatsModal">
                            <DataMapperStats/>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );

};

export default DataMapper2;
