import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { CampaignContext } from "./CampaignContext";
//import { CompanyContext } from "./CompanyContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

import { v4 as uuidv4 } from 'uuid';

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const AmplifyEventContext = createContext();

export const AmplifyEventContextProvider = ({ children }: any) => {

    const { jwt, dispatch } = useContext(AuthContext);
    const { campaignKey, adminBWApplicationID, campaignPricingInformation, defaultTimeZone } = useContext(CampaignContext);
    //const { companyKey, companyID, companyHistoryDaySpan } = useContext(CompanyContext);

    const [eventCoreChanged, setEventCoreChanged] = useState(null);
    const [eventApproversChanged, setEventApproversChanged] = useState(null);
    const [eventNBSamplesChanged, setEventNBSamplesChanged] = useState(null);
    const [eventPhoneInformationChanged, setEventPhoneInformationChanged] = useState(null);

    const [eventCoreInformation, setEventCoreInformation] = useState(null);
    const [eventPhoneInformation, setEventPhoneInformation] = useState(null);
    const [eventApprovalInformation, setEventApprovalInformation] = useState(null);
    const [eventSampleInformation, setEventSampleInformation] = useState(null);
    const [eventArchiveInformation, setEventArchiveInformation] = useState(null);
    const [eventMessageBodyInformation, setEventMessageBodyInformation] = useState(null);
    const [eventMessagePricingInformation, setEventMessagePricingInformation] = useState(null);
    const [eventMessageAuditInformation, setEventMessageAuditInformation] = useState(null);
    const [eventMessageRevInformation, setEventMessageRevInformation] = useState(null);

    const [eventUXSampleDataInformation, setEventUXSampleDataInformation] = useState(newEventUXSampleDataInformation());

    //eslint-disable-next-line
    const [eventUXVotersetStats, setEventUXVotersetStats] = useState(newEventUXVotersetStats());
    const [refreshEventList, setRefreshEventList] = useState(null);

    const [previouslySavedDataMap, setPreviouslySavedDataMap] = useState(newPreviouslySavedDataMap());

    //eslint-disable-next-line
    const [clearDataMapperFiles, setClearDataMapperFiles] = useState('');
    const [clearMMSAttachments, setClearMMSAttachments] = useState(Date.now());
    //eslint-disable-next-line
    const [mosaicMap, setMosaicMap] = useState('');

    // MODAL MANAGEMENT ----------------------------------------------------------------------------------------------------------------------
    const [modalOpenArchive, setModalOpenArchive] = useState(false);
    const [modalOpenCommit, setModalOpenCommit] = useState(false);
    // MODAL MANAGEMENT ----------------------------------------------------------------------------------------------------------------------

    const [eventLoadingInProgress, setEventLoadingInProgress] = useState(false);
    const [eventLoadingInProgressMsg, setEventLoadingInProgressMsg] = useState('Preparing Campaign and Event Information... should only be a few moments');
    const [ableToAllocateTN, setAbleToAllocateTN] = useState(true);

    // INTERNAL ------------------------------------------------------------------------------------------------------------------------------
    const [updatedStamp, setUpdatedStamp] = useState(dayjs().unix());
    // INTERNAL ------------------------------------------------------------------------------------------------------------------------------

    //TOAST MESSAGING ------------------------------------------------------------------------------------------------------------------------
    const [toastMessage, setToastMessage] = useState('');
    //TOAST MESSAGING ------------------------------------------------------------------------------------------------------------------------

    const approveStates = new Set(["approve", "approving", "approved"])
    const commitStates = new Set(["commit", "committing", "committed"])

    useEffect(() => {
        if (!eventMessageBodyInformation) {
            return;
        }

        calcSetEventUnitPrice(
            eventMessageBodyInformation.sms_segments,
            eventMessageBodyInformation.media_get_url,
            eventMessageBodyInformation.media_type
        );
    }, [eventMessageBodyInformation])

    useEffect(() => {
        if (!campaignPricingInformation || !eventMessageBodyInformation) {
            return
        }

        calcSetEventUnitPrice(
            eventMessageBodyInformation.sms_segments,
            eventMessageBodyInformation.media_get_url,
            eventMessageBodyInformation.media_type
        );
    }, [campaignPricingInformation])


    // EDR - Define All Message Structures Here
    // This gives a consistently initialized structure
    // NOTE: statusId value is used by UI components to determine if they need to update their local values
    //       converted it from UUIDv4 to Date.now() which is ms since Epoch
    function newEventCoreInformation(uniqueID) {
        return {
            statusId: uniqueID,
            key: null,
            name: '',
            project_code: '',
            start_datetime: null,
            end_datetime: null,
            timezoneUniv: defaultTimeZone,//null,
            timezone: null,
            status: '',
            status_legacy: '',
            object_type: "event",
            voterset_key: null
        };
    }

    function newEventPhoneInformation(uniqueID) {
        return {
            statusId: uniqueID,
            admin_phone_number: null,
            customer_phone_number: null,
            area_code: null,
            tn_status: null
        };
    }

    function newEventApprovalInformation(uniqueID) {
        return {
            statusId: uniqueID,
            approval_numbers: null,
            approve_code: null,
            approve_status: null
        };
    }

    function newEventArchiveInformation(uniqueID) {
        return {
            statusId: uniqueID,
            archive_status: null
        };
    }

    function newEventMessagePricingInformation(uniqueID) {
        return {
            statusId: uniqueID,
            unitPrice: 0,
            message_cost: 0
        };
    }

    function newEventMessageBodyInformation(uniqueID) {
        return {
            statusId: uniqueID,
            message_template_key: "fake_key",
            content: '',
            smsCharacterCount: 0,
            smsReadyForTransmission: false,
            sms_segments: 0,
            mms_segments: 0,
            media_name: '',
            media_type: '',
            media_copy: null,
            media_copy_url: null,
            media_delete_url: null,
            media_get_url: null,
            media_put_url: null,
            CrystalSegment: 0
        };
    }

    function newEventSampleInformation(uniqueID) {
        return {
            statusId: uniqueID,
            sample_numbers: null
        };
    }

    function newEventMessageAuditInformation(uniqueID) {
        return {
            statusId: uniqueID,
            process_status: null,
            commit_status: null,
            complete_status: null,
            disconnect_status: null,
            review_status: null,
            status_tracker: null
        };
    }

    function newEventMessageRevInformation(uniqueID) {
        return {
            statusId: uniqueID,
            created_dt: null,
            updated_dt: null,
            created_user: null,
            updated_user: null,
            version: null
        };
    }

    function newEventUXVotersetStats(uniqueID) {
        return {
            statusId: uniqueID,
            voterset_key: null,
            memberCount: 0,
            cleanedCount: 0,
            removedCount: 0,
            cleaningDetails: 0,
            mosaicUniverseCount: 0,
            dataMapHeaderStats: []
        };
    }

    function newPreviouslySavedDataMap(uniqueID) {
        return {
            dataMapperInfo: null,
            updateId: uniqueID,
            mosaicUniverseCount: 0,
            voterset_key: '',
            statsFile: null,
            source: null
        };
    }

    function newEventUXSampleDataInformation(uniqueID) {
        return {
            UID: uniqueID,
            FirstName: "George",
            LastName: "Washington",
            URL: "https://www.foo.com",
            County: "Fairfax",
            sampleSMSBody: ""
        };
    }

    function clearAllExistingEventValues(){

        setEventLoadingInProgressMsg('Preparing Campaign and Event Information... should only be a few moments');
        setAbleToAllocateTN(true);

        setToastMessage('');

        setEventCoreInformation( newEventCoreInformation(null) );
        setEventPhoneInformation( newEventPhoneInformation() );
        setEventApprovalInformation( newEventApprovalInformation() );
        setEventSampleInformation( newEventSampleInformation() );
        setEventArchiveInformation( newEventArchiveInformation() );
        setEventMessageBodyInformation( newEventMessageBodyInformation() );
        setEventMessagePricingInformation( newEventMessagePricingInformation() );
        setEventMessageAuditInformation( newEventMessageAuditInformation() );
        setEventMessageRevInformation( newEventMessageRevInformation() );
        setEventUXSampleDataInformation( newEventUXSampleDataInformation() )
        setEventUXVotersetStats( newEventUXVotersetStats() );

        //console.log("AmplifyEventContext: clearAllExistingEventValues() calling setPreviouslySavedDataMap() with an empty structure")
        setPreviouslySavedDataMap( newPreviouslySavedDataMap() );
        setClearMMSAttachments( Date.now() );
    }

    // edr - new (to remove from getExistingEvent(null))
    // need to combine this with clearAllExistingEventValues()
    // they should perform same initialization with possibly only minor diffs
    async function initializeNewEvent(){
        //console.log("AmplifyEventContext: entered initializeNewEvent()")
        setAbleToAllocateTN(true);

        var _startDT = dayjs().add(2, 'hour');

        // console.log("THIS IS THE CAMPAIGN DEFAULT TIMEZONE => ", defaultTimeZone);

        var _localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if(defaultTimeZone.trim().length > 0){
            _localTZ = defaultTimeZone;
        }

        //DEM: NEED TO ATTACH THE DEFAULT CAMPAIGN TIME ZONE HERE
        var _tzTranslated = timezoneTranslation(_localTZ, _startDT)

        // console.log("TRANSLATED TIMEZONE INFORMATION => ", _tzTranslated);

        // ret vals from timezoneTranslation
        //     univTZ: univTZ,
        //     shortTZ: shortTZ,
        //     OffSet: _newOffset

        // var _localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // var _newTZInfo = timezoneTranslation(_localTZ, dayjs());


        var tempEventCoreInformation = newEventCoreInformation(null);
        tempEventCoreInformation['statusId'] = Date.now();
        tempEventCoreInformation['start_datetime'] = _startDT;
        tempEventCoreInformation['end_datetime'] = dayjs.utc().add(90, 'day');
        tempEventCoreInformation['timezoneUniv'] = _tzTranslated.univTZ;
        tempEventCoreInformation['timezone'] = _tzTranslated.shortTZ;

        setEventCoreInformation(tempEventCoreInformation);

        setEventPhoneInformation( newEventPhoneInformation() );
        setEventApprovalInformation( newEventApprovalInformation() );
        setEventArchiveInformation( newEventArchiveInformation() );
        setEventMessagePricingInformation( newEventMessagePricingInformation() );
        setEventMessageBodyInformation( newEventMessageBodyInformation() );
        setEventSampleInformation( newEventSampleInformation() );
        setEventMessageAuditInformation( newEventMessageAuditInformation() );
        setEventMessageRevInformation( newEventMessageRevInformation() );
        setEventUXVotersetStats( newEventUXVotersetStats() );

        //console.log("AmplifyEventContext: initializeNewEvent() calling setPreviouslySavedDataMap() with an empty structure")
        setPreviouslySavedDataMap( newPreviouslySavedDataMap() );
        setEventUXSampleDataInformation( newEventUXSampleDataInformation() );
    }

    // edr - this also gets called after an event was successfully patched from the SAVE EVENT button
    async function getExistingEvent(inboundEventKey){
        //console.log("AmplifyEventContext: entered getExistingEvent(inboundEventKey) ", inboundEventKey)
        if (!inboundEventKey) {
            //console.log("AmplifyEventContext: exiting getExistingEvent(inboundEventKey) because (!inboundEventKey) ")
            return;
        }
        setEventLoadingInProgress(true);

        setEventLoadingInProgressMsg('Preparing Campaign and Event Information... should only be a few moments');
        setAbleToAllocateTN(true);
        //**************************************************************************************//
        var _e;
        await axios.get(
            `${config.eventapi.url}/api/v1/events/${inboundEventKey}`,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            //console.log("AmplifyEventContext: getExistingEvent() events API response = ", response)
            _e = response.data;
        });

        //
        // Event record is read, now populate all the related structures
        //
        var _tzInfo;
        if (_e['timezone'] && _e['start_datetime']) {
            _tzInfo = timezoneTranslation(_e['timezone'], _e['start_datetime']);
        }
        setEventCoreInformation(
            {
                statusId: Date.now(),
                key: _e['key'],
                name: _e['name'],
                project_code: _e['project_code'],
                start_datetime: _e['start_datetime'],
                end_datetime: _e['end_datetime'],
                timezoneUniv: _tzInfo.univTZ,
                timezone: _tzInfo.shortTZ,
                status: _e['status'],
                status_legacy: _e['status_legacy'],
                voterset_key: _e['voterset_key']
            }
        );

        setEventPhoneInformation(
            {
                statusId: Date.now(),
                admin_phone_number: _e['admin_phone_number'],
                customer_phone_number: _e['customer_phone_number'],
                area_code: _e['area_code'],
                tn_status: _e['tn_status']
            }
        );

        setEventApprovalInformation(
            {
                statusId: Date.now(),
                approval_numbers: _e['approval_numbers'],
                approve_code: _e['approve_code'],
                approve_status: _e['approve_status']
            }
        );

        setEventSampleInformation(
            {
                statusId: Date.now(),
                sample_numbers: _e['sample_numbers']
            }
        );

        setEventMessagePricingInformation(
            {
                statusId: Date.now(),
                unitPrice: _e['message_cost'],
                message_cost: _e['message_cost']
            }
        );

        setEventArchiveInformation(
            {
                statusId: Date.now(),
                archive_status: _e['archive_status']
            }
        );

        setEventMessageAuditInformation(
            {
                statusId: Date.now(),
                process_status: _e['process_status'],
                commit_status: _e['commit_status'],
                complete_status: _e['complete_status'],
                disconnect_status: _e['disconnect_status'],
                review_status: _e['review_status'],
                status_tracker: _e['status_tracker']
            }
        );

        setEventMessageRevInformation(
            {
                statusId: Date.now(),
                created_dt: _e['created_dt'],
                updated_dt: _e['updated_dt'],
                created_user: _e['created_user'],
                updated_user: _e['updated_user'],
                version: _e['version']
            }
        );

        //
        // calc values & apply settings for the message details and costs
        //

        // call the function to set up the sample body without updating the sample record
        var _content = _e["content"].trim() ?? "";
        var _smsSampleBody;
        var _smsLength = 0;
        var _readyForTransmission = false;
        if (_content && _content != "") {
            _smsSampleBody = createSampleSMSBody(_content);
            _smsLength = _smsSampleBody.length;
            _readyForTransmission = determineSMSTransmissionReadyStatus(_content);
        }

        var _mmsContent = '';
        if (eventMessageBodyInformation) {
            _mmsContent = eventMessageBodyInformation.media_get_url;
        }

        var _segmentCount = 0;
        if ((_smsSampleBody && _smsSampleBody != '') ||
            (_mmsContent && _mmsContent != ''))
        {
            _segmentCount = determineSMSSegments(_smsSampleBody, _mmsContent);
        }

        var _crystalSegment = determineCrystalSegment(_e['media_type']);

        setEventMessageBodyInformation(
            {
                statusId: Date.now(),
                content: _content,
                smsCharacterCount: _smsLength,
                smsReadyForTransmission: _readyForTransmission,
                sms_segments: _segmentCount,
                mms_segments: _e['mms_segments'],
                media_name: _e['media_name'],
                media_type: _e['media_type'],
                media_copy: _e['media_copy'],
                media_copy_url: _e['media_copy_url'],
                media_delete_url: _e['media_delete_url'],
                media_get_url: _e['media_get_url'],
                media_put_url: _e['media_put_url'],
                CrystalSegment: _crystalSegment
            }
        );

        var _eventUXSampleDataInformation = newEventUXSampleDataInformation(Date.now());
        _eventUXSampleDataInformation['sampleSMSBody'] = _smsSampleBody;
        setEventUXSampleDataInformation(_eventUXSampleDataInformation);

        //
        // get the voterset details for this event
        //
        var _eventUXVotersetStats;
        if (_e['voterset_key']) {
            //console.log("AmplifyEventContext: getExistingEvent(inboundEventKey) calling getVoterset(_e['voterset_key']): ", _e['voterset_key']);
            _eventUXVotersetStats = await getVoterset(_e['voterset_key']);
            if (!_eventUXVotersetStats) {
                _eventUXVotersetStats = newEventUXVotersetStats();
            }
        }
        else {
            _eventUXVotersetStats = newEventUXVotersetStats();
            var _prevSavedDataMap = newPreviouslySavedDataMap();
            _prevSavedDataMap['updateId'] = Date.now();
            _prevSavedDataMap['source'] = "collecting existing event";
            //console.log("AmplifyEventContext: getExistingEvent(inboundEventKey) calling setPreviouslySavedDataMap(_prevSavedDataMap) ", _prevSavedDataMap);
            setPreviouslySavedDataMap(_prevSavedDataMap);
        }
        _eventUXVotersetStats['statusId'] = Date.now();
        setEventUXVotersetStats(_eventUXVotersetStats);

        //
        // finally done
        //
        setEventLoadingInProgress(false);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function updateExistingEvent_Archive(eventKey){
        if (!eventKey) {
            return;
        }
        //console.log("AmplifyEventContext: entered updateExistingEvent_Archive() (NEEDS TESTING)")
        //console.log("AmplifyEventContext: updateExistingEvent_Archive() archiving the event - eventKey = ", eventKey)

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${eventKey}`,
            {"key": eventKey, "status": "archive"},
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        // })
        // .then(function (response) {
        //     console.log("AmplifyEventContext: updateExistingEvent_Archive() patch response: ", response)
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function updateExistingEvent_Commit(eventKey){
        if (!eventKey) {
            return;
        }
        //console.log("AmplifyEventContext: entered updateExistingEvent_Commit() (NEEDS TESTING)")
        //console.log("AmplifyEventContext: updateExistingEvent_Commit() committing the event - eventKey = ", eventKey)

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${eventKey}`,
            {"key": eventKey, "status": "commit"},
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        // })
        // .then(function (response) {
        //     console.log("AmplifyEventContext: updateExistingEvent_Commit() patch response: ", response)
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    // EDR - come back and rewrite this later
    // I have no idea what source variable is or why its here
    // It seems source is only provided when called from updateCoreInformationVoterSet()
    async function getVoterset(voterset_key, source){
        //console.log("AmplifyEventContext: entered getVoterset() (NEEDS TESTING)")
        //console.log("AmplifyEventContext: getVoterset() voterset_key, source = ", voterset_key, source)

        var thisNewEventUXVotersetStats = newEventUXVotersetStats();

        var vs_get_response;
        await axios.get(
            `${config.voterapi.url}/api/v1/votersets/${voterset_key}`,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            vs_get_response = response;
        });
        //console.log("AmplifyEventContext: getVoterset() response: ", vs_get_response)

        if (vs_get_response.data) {
            // get the stats file from Mosaic storage
            var res_stats;
            await axios.get(
                vs_get_response.data.voter_map.metadata['csv_get_stats_url'],
                {headers: { "Content-Type": "application/json" }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                //console.log("AmplifyEventContext: getVoterset() get stats response: ", response)
                res_stats = response;
            });

            var _mosaicUniverseCount = 0;
            if (res_stats) {
                //if a mosaic universe count exists, collect and set it, otherwise use the cleaned count
                try{
                    _mosaicUniverseCount = res_stats.data.mosaic_count['total'];
                }
                catch(err){
                    // mosaic count does not exist for legacy records
                    _mosaicUniverseCount = res_stats.data.cleaned_count['total'];
                }

                thisNewEventUXVotersetStats['statusId'] = Date.now();
                thisNewEventUXVotersetStats['voterset_key'] = voterset_key;
                thisNewEventUXVotersetStats['memberCount'] = res_stats.data.incoming_count['total'];
                thisNewEventUXVotersetStats['cleanedCount'] = res_stats.data.cleaned_count['total'];
                thisNewEventUXVotersetStats['removedCount'] = res_stats.data.removed_count['total'];
                thisNewEventUXVotersetStats['cleaningDetails'] = res_stats.data.removed_details;
                thisNewEventUXVotersetStats['mosaicUniverseCount'] = _mosaicUniverseCount;
                thisNewEventUXVotersetStats['dataMapHeaderStats'] = [
                        { name: 'usable', y: res_stats.data.cleaned_count['total'] },
                        { name: 'removed', y: res_stats.data.removed_count['total'] }
                    ];

                //console.log("AmplifyEventContext: getVoterset(): finished getting stats from API, now calling setEventUXVotersetStats(thisNewEventUXVotersetStats) ", thisNewEventUXVotersetStats);
                setEventUXVotersetStats(thisNewEventUXVotersetStats);
            }

            // let tempVarForLog = {
            //         dataMapperInfo: vs_get_response.data,
            //         updateId: Date.now(),
            //         mosaicUniverseCount: _mosaicUniverseCount,
            //         voterset_key: voterset_key,
            //         statsFile: res_stats,
            //         source: source + ' hello'
            //     }
            // console.log("AmplifyEventContext: getVoterset(): after getting voterset, this is calling setPreviouslySavedDataMap(tempVarForLog) ", tempVarForLog)

            setPreviouslySavedDataMap(
                {
                    dataMapperInfo: vs_get_response.data,
                    updateId: Date.now(),
                    mosaicUniverseCount: _mosaicUniverseCount,
                    voterset_key: voterset_key,
                    statsFile: res_stats,
                    source: source + ' hello'
                }
            );

            setMosaicMap(vs_get_response.data.voter_map);
        }
        else{
            var _prevSavedDataMap = newPreviouslySavedDataMap();
            _prevSavedDataMap['updateId'] = Date.now();
            _prevSavedDataMap['source'] = source;
            //console.log("AmplifyEventContext: getVoterset(): after getting no voterset response, this is calling setPreviouslySavedDataMap(_prevSavedDataMap) ", _prevSavedDataMap)
            setPreviouslySavedDataMap(_prevSavedDataMap);
            setMosaicMap('');
        }

        // some old code, like updateCoreInformationVoterSet(), expects this structure to be returned
        // so it can be saved in the useState variable.
        // I dont know when the "return" value was removed and saved in here instead.
        // So for now, just return whatever we have for a structure and see if application operates properly
        //console.log("AmplifyEventContext: getVoterset(): returning thisNewEventUXVotersetStats because some old code expects it: ", thisNewEventUXVotersetStats)
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
        return thisNewEventUXVotersetStats;
    }

    function determineNewEventStatus(){
        var _status = eventCoreInformation.status;

        // edr - cumbersome and error-prone logic, this needs to be rewritten
        if (eventPhoneInformation &&
            eventPhoneInformation.admin_phone_number &&
            eventPhoneInformation.customer_phone_number)
        {
            _status = "processed";
        } else if(eventCoreInformation.name.trim().length > 0 &&
            eventCoreInformation.project_code.trim().length > 0 &&
            (eventMessageBodyInformation.content && eventMessageBodyInformation.content.trim().length > 0) &&
            (eventPhoneInformation && eventPhoneInformation.area_code && eventPhoneInformation.area_code.length > 0 && eventPhoneInformation.area_code != "999") &&
            (eventApprovalInformation && eventApprovalInformation.approval_numbers && eventApprovalInformation.approval_numbers.length > 0) &&
            determineSMSTransmissionReadyStatus(eventMessageBodyInformation.content.trim()) == true)
        {
            _status = "process";
        } else {
            _status = "edit";
        }

        return _status;
    }

    async function createNewAmplifyEvent(){
        //console.log("AmplifyEventContext: entered createNewAmplifyEvent() (NEEDS TESTING)")
        var _event_key = null;

        var jsonData = {
              "name": eventCoreInformation.name,//"Temp_Event_Name_" + uuidv4(),
              "timezone": defaultTimeZone, //"pacific",
              "start_datetime": dayjs.utc().add(4, 'hours').format('YYYY-MM-DDTHH:mm'),
              "end_datetime": dayjs.utc().add(2, 'days').format('YYYY-MM-DDTHH:mm'),
              "campaign_key": campaignKey.toString(),
              "message_template_key": "fake_key",
              "content": '',
              "media_name": null,
              "media_type": null,
              "area_code": "999",
              "project_code": eventCoreInformation.project_code
            }

        await axios.post(
            `${config.eventapi.url}/api/v1/events`,
            jsonData,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            _event_key = response.data.key;
        });

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
        return _event_key;
    }

    async function saveAmplifyEvent(){
        //console.log("AmplifyEventContext: entered saveAmplifyEvent() (NEEDS TESTING)")

        setEventLoadingInProgress(true);

        //console.log("AmplifyEventContext: saveAmplifyEvent() eventCoreInformation = ", eventCoreInformation)
        var _event_key = eventCoreInformation.key;
        if (!_event_key) {
            _event_key = await createNewAmplifyEvent();

            //for new events... move the assigned asset from the campaign to the event
            if (eventMessageBodyInformation.media_get_url && eventMessageBodyInformation.media_name) {
                try{
                    await copyFacetImageFromCampaignToEvent(
                        eventMessageBodyInformation.media_get_url,
                        _event_key,
                        eventMessageBodyInformation.media_name,
                        eventMessageBodyInformation.media_type,
                        false
                    );
                }
                catch(error){
                    //console.log("there was an error copying the asset from the campaign library to the event => ", error);
                }
            }
        }

        var _status = eventCoreInformation.status;

        // save to Mongo as long as we're not in any commit state
        if ( !commitStates.has(eventCoreInformation.status) )
        {
            _status = determineNewEventStatus();

            var _sms = eventMessageBodyInformation.content ? eventMessageBodyInformation.content : '';
            var _areaCode = eventPhoneInformation.area_code ? eventPhoneInformation.area_code : '999';
            var _approvalNumbers = eventApprovalInformation.approval_numbers ? eventApprovalInformation.approval_numbers : [];
            var _sampleNumbers = eventSampleInformation.sample_numbers ? eventSampleInformation.sample_numbers : [];

            var _jsonEventData = {
                "key": _event_key,
                "name": eventCoreInformation.name,
                "timezone": eventCoreInformation.timezone,
                "start_datetime": dayjs.utc(eventCoreInformation.start_datetime).format('YYYY-MM-DDTHH:mm'),
                "end_datetime": dayjs.utc(eventCoreInformation.end_datetime).format('YYYY-MM-DDTHH:mm'),
                "campaign_key": campaignKey.toString(),
                "message_template_key": "fake_key",
                "content": _sms,
                "media_name": eventMessageBodyInformation.media_name,
                "media_type": eventMessageBodyInformation.media_type,
                "area_code": _areaCode,
                "project_code": eventCoreInformation.project_code,
                "approval_numbers": _approvalNumbers,
                "sample_numbers": _sampleNumbers,
                "sms_segments": eventMessageBodyInformation.sms_segments,
                "mms_segments": eventMessageBodyInformation.mms_segments,
                "message_cost": eventMessagePricingInformation ? eventMessagePricingInformation.message_cost : null,
                "status": _status,
                "voterset_key": eventCoreInformation.voterset_key
            }
            //console.log("AmplifyEventContext: saveAmplifyEvent() _jsonEventData for patching: ", _jsonEventData);

            await axios.patch(
                `${config.eventapi.url}/api/v1/events/${_event_key}`,
                _jsonEventData,
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
            )
            .then(function (response) {
                // console.log("AmplifyEventContext: saveAmplifyEvent() successfully patched event: response ", response);
                //console.log("AmplifyEventContext: saveAmplifyEvent() checking (_status == process) ", _status);
                if (_status == "process") {
                    //console.log("AmplifyEventContext: saveAmplifyEvent() _status == process: calling checkOnNewlyAllocatedPhones(_event_key): ", _event_key);
                    checkOnNewlyAllocatedPhones(_event_key);
                }
                else{
                    //console.log("AmplifyEventContext: saveAmplifyEvent() _status != process: calling getExistingEvent(_event_key): ", _event_key);
                    getExistingEvent(_event_key);
                    setEventLoadingInProgress(false);
                }
            })
            .catch(function (error) {
                console.log("unable to patch existing Amplify message event: ", error);
            });
        }

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function checkOnNewlyAllocatedPhones(inboundEventKey){
        //console.log("AmplifyEventContext: entered checkOnNewlyAllocatedPhones() (NEEDS TESTING) inboundEventKey = ", inboundEventKey)
        setEventLoadingInProgress(true);
        setEventLoadingInProgressMsg("collecting and allocating transmission phones for your messaging event...");

        var _eventKey = inboundEventKey ?? eventCoreInformation.key;

        //var _status = 'processing';
        var i = 1;
        var _phonesAllocated = false;

        var _newUpdateStamp = updatedStamp;

        var t = setInterval(async function() {
            // the update has not been processed yet by the server, so keep checking
            if (_phonesAllocated == false && i <= 16 && _eventKey) {
                if (i == 6) {
                    setEventLoadingInProgressMsg("collecting phone numbers from this area code provider is taking some time. one second please...");
                } else if (i == 11) {
                    setEventLoadingInProgressMsg("this is taking longer than antcipated. please hang in there a few more seconds as we keep trying...");
                }

                await axios.get(
                    `${config.eventapi.url}/api/v1/events/${_eventKey}`,
                    {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
                )
                .then(function (response) {

                    // console.log("inbound response data => ", response);
                    // console.log("admin phone number => ", response.data['admin_phone_number']);
                    // console.log("customer phone number => ", response.data['customer_phone_number']);

                    if (response.data['admin_phone_number'] != null && response.data['customer_phone_number'] != null) {
                        // console.log("****************COMPLETED COLLECTING THE TNs FOR THIS EVENT*************");
                        _newUpdateStamp = dayjs().unix();

                        if (_newUpdateStamp != updatedStamp) {
                            _phonesAllocated = true;
                            setUpdatedStamp(_newUpdateStamp);
                        }
                    }
                    i++;
                })
                .catch(function (error) {
                    console.log("error collecting the updated tn information", error);
                });
            }
            else{
                setUpdatedStamp(_newUpdateStamp);
                clearInterval(t);

                if (!_phonesAllocated) {
                    setAbleToAllocateTN(false);
                    setEventLoadingInProgress(false);
                }
                else{
                    setAbleToAllocateTN(true);
                    if (_eventKey) {
                        getExistingEvent(_eventKey);
                    }
                }
                setEventLoadingInProgress(false);
            }

            i++;

        }, 2500);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    function determineSMSSegments(smsContent, mmsContent){
        //console.log("AmplifyEventContext: entered determineSMSSegments() (NEEDS TESTING) smsContent = ", smsContent)
        //console.log("AmplifyEventContext: entered determineSMSSegments() (NEEDS TESTING) mmsContent = ", mmsContent)
        //console.log("AmplifyEventContext: entered determineSMSSegments() (NEEDS TESTING) mmsIncludedCharacters = ", campaignPricingInformation.mmsIncludedCharacters)
        if (!smsContent) {
            return 0;
        }
        //console.log("AmplifyEventContext: entered determineSMSSegments() (NEEDS TESTING) smsContent LENGTH = ", smsContent.length)

        // edr - havent evaluated any of this logic
        //       I only implemented early return based on existing logic
        var _segmentCount = 0;
        if (mmsContent == null || mmsContent.length == 0) {
            _segmentCount = Math.ceil(smsContent.length/160);
        }
        else{
            if (campaignPricingInformation.mmsIncludedCharacters == null ||
                campaignPricingInformation.mmsIncludedCharacters == 0)
            {
                _segmentCount = Math.ceil(smsContent.length/160);
            }
            else{
                _segmentCount = Math.ceil(smsContent.length/campaignPricingInformation.mmsIncludedCharacters);
            }
        }

        return _segmentCount;
    }

    // edr - this is only called once and its in getExistingEvent(inboundEventKey)
    // probably get rid of this unless we can find a use elsewhere
    function determineCrystalSegment(mediaType){
        if (mediaType && mediaType == "video/mp4") {
            return 1;
        } else {
            return 0;
        }
    }

    function timezoneTranslation(inboundTZ, eventDateTime){
        var _newOffset = 0
        var univTZ = '';
        var shortTZ = '';

        if (inboundTZ.trim().length != 0 && inboundTZ != 'system') {

            if (inboundTZ.indexOf("/") > 0) {
                univTZ = inboundTZ;

                switch(inboundTZ){
                    case "America/Los_Angeles":
                        shortTZ = "pacific";
                        break;
                    case "America/Anchorage":
                        shortTZ = "alaska";
                        break;
                    case "Pacific/Honolulu":
                        shortTZ = "hawaii";
                        break;
                    case "America/Phoenix":
                        shortTZ = "arizona";
                        break;
                    case "America/Denver":
                        shortTZ = "mountain";
                        break;
                    case "America/Chicago":
                        shortTZ = "central";
                        break;
                    case "America/New_York":
                        shortTZ = "eastern";
                        break;
                }
            }
            else{
                shortTZ = inboundTZ;

                switch(inboundTZ){
                    case "pacific":
                        univTZ = "America/Los_Angeles";
                        break;
                    case "alaska":
                        univTZ = "America/Anchorage";
                        break;
                    case "hawaii":
                        univTZ = "Pacific/Honolulu";
                        break;
                    case "arizona":
                        univTZ = "America/Phoenix";
                        break;
                    case "mountain":
                        univTZ = "America/Denver";
                        break;
                    case "central":
                        univTZ = "America/Chicago";
                        break;
                    case "eastern":
                        univTZ = "America/New_York";
                        break;
                }
            }

            try{
                _newOffset = dayjs.utc(eventDateTime).tz(univTZ).format("Z");
            }
            catch(err){
                //console.log("error setting offset", err);
            }

        }

        return {
            univTZ: univTZ,
            shortTZ: shortTZ,
            OffSet: _newOffset
        };
    }

    function determineSMSTransmissionReadyStatus(content){
        if (!content || content == "") {
            return false;
        }
        //console.log("AmplifyEventContext: entered determineSMSTransmissionReadyStatus() (NEEDS TESTING) content = ", content)

        var _sms = content.trim();
        if (_sms.indexOf("[%OptOutMessage-Classic%]") >= 0 ||
            _sms.indexOf("[%Double Opt-In/Out%]") >= 0 ||
            _sms.indexOf("[%OptOutMessage-Stop=End%]") >= 0 ||
            _sms.indexOf("[%OptOutMessage-Stop2End%]") >= 0 ||
            _sms.indexOf("[%OptOutMessage-StopToEnd%]") >= 0 )
        {
            return true;
        }

        return false;
    }

    function updateCoreInformation(newEventName, newProjectCode){
        newEventName = newEventName ? newEventName : "";
        newProjectCode = newProjectCode ? newProjectCode : "";
        if (!newEventName.length && !newProjectCode.length) {
            // console.log("DEM UpdateCoreInformation - EXITING");
            return;
        }

        // console.log("DEM UpdateCoreInformation - CONTINUE", newEventName);

        // //console.log("AmplifyEventContext: entered updateCoreInformation() (NEEDS TESTING) newEventName, newProjectCode = ", newEventName, newProjectCode)
        var _core = eventCoreInformation; //?? newEventCoreInformation();
        _core['statusId'] = Date.now();

        if (newEventName.length && _core['name'] != newEventName) {
            _core['name'] = newEventName;
        }

        if (newProjectCode.length && _core['project_code'] != newProjectCode) {
            _core['project_code'] = newProjectCode;
        }

        //console.log("this is the new core =>", _core);

        // var _core = eventCoreInformation ?? newEventCoreInformation();
        // _core['name'] = newEventName;
        // _core['project_code'] = newProjectCode;

        setEventCoreInformation(_core);
        setEventCoreChanged(uuidv4());
    }

    async function updateCoreInformationVoterSet(voterset_key, votersetStateId){
        //console.log("AmplifyEventContext: entered updateCoreInformationVoterSet() (NEEDS TESTING) voterset_key, votersetStateId = ", voterset_key, votersetStateId)
        if (!votersetStateId) {
            return;
        }

        var _core = eventCoreInformation ?? newEventCoreInformation();
        _core['statusId'] = Date.now();
        _core['voterset_key'] = voterset_key;
        setEventCoreInformation(_core);

        if (!_core.key) {
            //console.log("AmplifyEventContext: updateCoreInformationVoterSet() exiting early because !_core.key    _core = ", _core)
            return;
        }

        var _jsonEventData = {
            "key": _core.key,
            "voterset_key": voterset_key
        }

        //console.log("AmplifyEventContext: updateCoreInformationVoterSet() API call to patch voterset value in the event record _jsonEventData", _jsonEventData)
        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${_core.key}`,
            _jsonEventData,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        });

        //NOTE: Voter set key is set to null when the event's map is cleared
        if (voterset_key) {
            // edr - need to fix this, getVoterset() is already updating setEventUXVotersetStats()
            //       the new code wasnt returning anything, so I modified it to
            //       return an eventUXVotersetStats structure to keep old code working
            var _eventUXVotersetStats = await getVoterset(voterset_key, "update core info");
            _eventUXVotersetStats['statusId'] = Date.now();
            setEventUXVotersetStats(_eventUXVotersetStats);
        }
        else{
            // clear any previous voterset stats
            setEventUXVotersetStats( newEventUXVotersetStats() );
        }

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    function updateCoreInformationScheduling(timeZone, scheduledTime){
        //console.log("AmplifyEventContext: entered updateCoreInformationScheduling() (NEEDS TESTING) timeZone, scheduledTime = ", timeZone, scheduledTime)
        var translatedTZInfo = timezoneTranslation(timeZone, scheduledTime);
        var _core = eventCoreInformation ?? newEventCoreInformation();

        _core['statusId'] = Date.now();
        _core['start_datetime'] = scheduledTime;
        _core['end_datetime'] = dayjs.utc(scheduledTime).add(4, "day");
        _core['timezoneUniv'] = translatedTZInfo.univTZ;
        _core['timezone'] = translatedTZInfo.shortTZ;

        setEventCoreInformation(_core);
        setEventCoreChanged(uuidv4());
    }

    function calcSetEventUnitPrice(smsSegmentCount, mmsURL, mmsMediaType) {
        // edr - dont return early here because this sets the initial pricing information to display

        // console.log("THIS IS THE CAMPAIGN PRICING INFORMATION =>", campaignPricingInformation);

        if(!campaignPricingInformation){
            return;
        }

        smsSegmentCount = determineSMSSegments(eventMessageBodyInformation.content, eventMessageBodyInformation.media_get_url);

        var _price = campaignPricingInformation.smsBasePrice;
        var _crystal = false;

        // console.log("THIS IS THE CURRENT MEDIA TYPE => ", mmsMediaType);
        // console.log("THIS IS THE SEGMENT COUNT => ", mmsMediaType);

        if (mmsURL && mmsURL.length != 0) {
            _price = campaignPricingInformation.mmsBasePrice;
            if (mmsMediaType && mmsMediaType === "video/mp4") {
                _crystal = true;
            }
        }

        // if (smsSegmentCount > 0) {
            if (smsSegmentCount > 1) {
                _price += ((smsSegmentCount - 1) * campaignPricingInformation.additionalSegmentPrice);
            }

            if (_crystal) {
                _price += campaignPricingInformation.crystalBasePrice;
            }
        // }

        setEventMessagePricingInformation({
            statusId: Date.now(),
            unitPrice: _price,
            message_cost: _price
        });
    }

    function updatePreferredAreaCode(newAreaCode){
        // console.log("AmplifyEventContext: entered updatePreferredAreaCode() (NEEDS TESTING) newAreaCode = ", newAreaCode)
        var _eventPhoneInfo = eventPhoneInformation ?? newEventPhoneInformation();
        _eventPhoneInfo['statusId'] = Date.now();
        _eventPhoneInfo['area_code'] = newAreaCode;
        setEventPhoneInformation(_eventPhoneInfo);
        // console.log("AmplifyEventContext: entered updatePreferredAreaCode() (NEEDS TESTING) new event phone information = ", _eventPhoneInfo)
        setEventPhoneInformationChanged(uuidv4);
    }

    async function updateEventApprovers(newEventApprovers){

        //update the internal array of approvers
        var _eventApprovalInfo = eventApprovalInformation ?? newEventApprovalInformation();
        _eventApprovalInfo['statusId'] = Date.now();
        _eventApprovalInfo['approval_numbers'] = newEventApprovers;
        setEventApprovalInformation(_eventApprovalInfo)

        //mark that the array was updated
        setEventApproversChanged(uuidv4());

        //exit if there is no key for the event... in other words if teh event has not been saved to the Momgo DB
        if (!eventCoreInformation.key) {
            return;
        }
        // console.log("AmplifyEventContext: entered updateEventApprovers() (NEEDS TESTING) newEventApprovers = ", newEventApprovers)

        //PATCH THE EVENT TO STORE THE APPROVERS
        var _m = {
            "key": eventCoreInformation.key,
            "approval_numbers": newEventApprovers
        }

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${eventCoreInformation.key}`,
            _m,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("there was an error updating the event approvers => ", error);
        // })
        // .then(function (response) {
        //     console.log("AmplifyEventContext: updateEventApprovers() successfully patched event with approval_numbers: response ", response)
        });

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function updateEventSamplerecipients(newSampleRecipients){
        var _eventSampleInfo = eventSampleInformation ?? newEventSampleInformation();
        _eventSampleInfo['statusId'] = Date.now();
        _eventSampleInfo['sample_numbers'] = newSampleRecipients;
        setEventSampleInformation(_eventSampleInfo);

        if (!eventCoreInformation.key) {
            return;
        }
        // console.log("AmplifyEventContext: entered updateEventSamplerecipients() (NEEDS TESTING) newSampleRecipients, newlyCreatedSampleRecipient = ", newSampleRecipients)

        //PATCH THE EVENT TO STORE THE SAMPLES
        var _m = {
            "key": eventCoreInformation.key,
            "sample_numbers": newSampleRecipients
        }

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${eventCoreInformation.key}`,
            _m,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("there was an error updating the event samples => ", error);
        // })
        // .then(function (response) {
        //     console.log("AmplifyEventContext: updateEventSamplerecipients() successfully patched event with approval_numbers: response ", response)
        });

        //TBD FOR A FUTURE SPRINT - DEM
        //THIS IS STUB CODE FOR WHEN WE ADD THE PAST RECIPIENTS TO THE CAMPAIGN OBJECT
        // if(newlyCreatedSampleRecipient != null){

        //     var _localRecipients = campaignSampleRecipients;
        //     var _found = false;

        //     for (let i = 0; i < _localRecipients.length; i++){
        //         if(_a[i].phone == newlyCreatedSampleRecipient.phone){
        //             _found = true;
        //             break;
        //         }
        //     }

        //     if(_found == false){
        //         _localRecipients.splice(_localRecipients.length, 0, newlyCreatedSampleRecipient);

        //         var header = { headers: { Authorization: "Bearer " + jwt, accept: "application/json" } };
        //         const res_patch = await axios.patch(
        //             `${config.eventapi.url}/api/v1/campaigns/${campaignKey}`,
        //             _m,
        //             header
        //         );

        //     }
        // }

        setEventNBSamplesChanged(uuidv4());

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function updateMMSAttachments(mmsPatchVals){
        //console.log("AmplifyEventContext: entered updateMMSAttachments() (NEEDS TESTING) mmsPatchVals = ", mmsPatchVals)

        // this kind of change invalidates any "approval" state,
        // reset the event status to "processed"
        if (approveStates.has(eventCoreInformation.status))
        {
            await axios.patch(
                `${config.eventapi.url}/api/v1/events/${eventCoreInformation.key}`,
                {"key": eventCoreInformation.key, "status": "processed"},
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
            )
            .catch(function (error) {
                console.log("there was an error removing the MMS attachment from the event => ", error);
            })
            .then(function (response) {
                // console.log("AmplifyEventContext: updateMMSAttachments() successfully reset event status to processed: response ", response)
                eventCoreInformation.status = "processed";
            });
        }

        var _eventMessageBodyInfo = eventMessageBodyInformation ?? newEventMessageBodyInformation();
        _eventMessageBodyInfo['statusId'] = Date.now();
        _eventMessageBodyInfo['mms_segments'] = 1;
        _eventMessageBodyInfo['media_name'] = mmsPatchVals.mmsCorrectedName;
        _eventMessageBodyInfo['media_type'] = mmsPatchVals.mmsType;
        _eventMessageBodyInfo['media_copy'] = null;
        _eventMessageBodyInfo['media_copy_url'] = null;
        _eventMessageBodyInfo['media_delete_url'] = mmsPatchVals.mmsDeleteURL;
        _eventMessageBodyInfo['media_get_url'] = mmsPatchVals.mmsGetURL;
        _eventMessageBodyInfo['media_put_url'] = mmsPatchVals.mmsPutURL;
        _eventMessageBodyInfo['CrystalSegment'] = (mmsPatchVals.mmsType == "video/mp4") ? 1 : 0;

        setEventMessageBodyInformation({..._eventMessageBodyInfo});

        //console.log("\n\nAmplifyEventContext: updateMMSAttachments(): calling calcSetEventUnitPrice()\n\n")
        calcSetEventUnitPrice(
            eventMessageBodyInformation.sms_segments,
            eventMessageBodyInformation.media_get_url,
            eventMessageBodyInformation.media_type
        );

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function removeMMSAttachments(){
        //console.log("AmplifyEventContext: entered removeMMSAttachments() (NEEDS TESTING)")

        var _eventMessageBodyInfo = eventMessageBodyInformation;
        if (_eventMessageBodyInfo) {
            _eventMessageBodyInfo['statusId'] = Date.now();
            _eventMessageBodyInfo['mms_segments'] = 0;
            _eventMessageBodyInfo['media_name'] = null;
            _eventMessageBodyInfo['media_type'] = null;
            _eventMessageBodyInfo['media_copy'] = null;
            _eventMessageBodyInfo['media_copy_url'] = null;
            _eventMessageBodyInfo['media_delete_url'] = null;
            _eventMessageBodyInfo['media_get_url'] = null;
            _eventMessageBodyInfo['media_put_url'] = null;
            _eventMessageBodyInfo['CrystalSegment'] = 0;
        } else {
            _eventMessageBodyInfo = newEventMessageBodyInformation();
        }
        setEventMessageBodyInformation({..._eventMessageBodyInfo});

        //console.log("\n\nAmplifyEventContext: removeMMSAttachments(): calling calcSetEventUnitPrice()\n\n")
        calcSetEventUnitPrice(
            eventMessagePricingInformation,
            eventMessageBodyInformation.sms_segments,
            eventMessageBodyInformation.media_get_url,
            eventMessageBodyInformation.media_type
        );

        if (!eventCoreInformation.key)
            return;

        // this kind of change invalidates any "approval" state,
        // reset the event status to "processed"
        if (approveStates.has(eventCoreInformation.status)) {
            await axios.patch(
                `${config.eventapi.url}/api/v1/events/${eventCoreInformation.key}`,
                {"key": eventCoreInformation.key, "status": "processed"},
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // console.log("AmplifyEventContext: removeMMSAttachments() successfully reset event status to processed: response ", response)
                eventCoreInformation.status = "processed";
            });
        }

        var _n = {
            "key": eventCoreInformation.key,
            "content": eventMessageBodyInformation.content,
            "sms_segments": eventMessageBodyInformation.sms_segments,
            "mms_segments": 0,
            "media_name": null,
            "media_type": null,
            "media_copy": null,
            "media_copy_url": null,
            "media_delete_url": null,
            "media_get_url": null,
            "media_put_url": null
        }

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${eventCoreInformation.key}`,
            _n,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        // })
        // .then(function (response) {
        //     console.log("AmplifyEventContext: removeMMSAttachments() successfully patched MMS attributes: response ", response)
        });

        // does this just reread everything from Mongo?
        getExistingEvent(eventCoreInformation.key);

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    function updateSMSBody(smsContent){
        // console.log("AmplifyEventContext: entered updateSMSBody() (NEEDS TESTING) smsContent = ", smsContent)

        // call the function to set up the sample body without updating the sample record
        var _smsSampleBody = createSampleSMSBody(smsContent);
        var _eventMessageBodyInfo = eventMessageBodyInformation ?? newEventMessageBodyInformation();
        _eventMessageBodyInfo['statusId'] = Date.now();
        _eventMessageBodyInfo['content'] = smsContent;
        _eventMessageBodyInfo['smsCharacterCount'] = _smsSampleBody.length;
        _eventMessageBodyInfo['smsReadyForTransmission'] = determineSMSTransmissionReadyStatus(smsContent);

        // EDR - This isnt triggering the updates in AmplifyEventStepsCalloutMenu
        // //console.log("\n")
        // //console.log("AmplifyEventContext: updateSMSBody(): EDR - _eventMessageBodyInfo['statusId']: ", _eventMessageBodyInfo['statusId'])
        // //console.log("AmplifyEventContext: updateSMSBody(): EDR - _eventMessageBodyInfo['content']: ", _eventMessageBodyInfo['content'])
        // //console.log("AmplifyEventContext: updateSMSBody(): EDR - _eventMessageBodyInfo['smsCharacterCount']: ", _eventMessageBodyInfo['smsCharacterCount'])
        // //console.log("\n\n")

        var _mmsContent = '';

        if (eventMessageBodyInformation) {
            _mmsContent = eventMessageBodyInformation.media_get_url;
        }

        // RICK - TRYING THIS TEMPORARILY
        _eventMessageBodyInfo['sms_segments'] = determineSMSSegments(_smsSampleBody, _mmsContent);
        setEventMessageBodyInformation({..._eventMessageBodyInfo});

        var _eventUXSampleDataInfo = eventUXSampleDataInformation ?? newEventUXSampleDataInformation();
        _eventUXSampleDataInfo['statusId'] = Date.now();
        _eventUXSampleDataInfo['sampleSMSBody'] = _smsSampleBody;
        setEventUXSampleDataInformation(_eventUXSampleDataInfo);

        // //console.log("AmplifyEventContext: updateSMSBody(): calling calcSetEventUnitPrice()")
        // calcSetEventUnitPrice(
        //     eventMessageBodyInformation.sms_segments,
        //     eventMessageBodyInformation.media_get_url,
        //     eventMessageBodyInformation.media_type
        // );
        // console.log("\n\nAmplifyEventContext: updateSMSBody(): calling calcSetEventUnitPrice() smsCharacterCount, sms_segments, media_get_url, media_type: ", _eventMessageBodyInfo.smsCharacterCount, _eventMessageBodyInfo.sms_segments, _eventMessageBodyInfo.media_get_url, _eventMessageBodyInfo.media_type)
        // console.log("\n\n")
        calcSetEventUnitPrice(
            _eventMessageBodyInfo.sms_segments,
            _eventMessageBodyInfo.media_get_url,
            _eventMessageBodyInfo.media_type,
        );

    }

    function updateSMSSampleRecord(dataRecord){
        if (!dataRecord || dataRecord.length == 0) {
            return;
        }

        //console.log("AmplifyEventContext: entered updateSMSSampleRecord() (NEEDS TESTING) dataRecord = ", dataRecord)
        var _fname = dataRecord[0]['first_name'];
        var _lname = dataRecord[0]['last_name'];
        var _county = eventUXSampleDataInformation.County;
        var _url = eventUXSampleDataInformation.URL;

        if (dataRecord[0]['data_fields'] != null) {
            _county = dataRecord[0]['data_fields']['County'];
            _url = dataRecord[0]['data_fields']['URL'];
        }

        //console.log("AmplifyEventContext: entered updateSMSSampleRecord() calling createSampleSMSBody(eventMessageBodyInformation.content, _fname, _lname, _county, _url): ", eventMessageBodyInformation, _fname, _lname, _county, _url)
        var content = eventMessageBodyInformation ? eventMessageBodyInformation.content : "(EDR - eventMessageBodyInformation is null)"
        //var _newSample = createSampleSMSBody(eventMessageBodyInformation.content, _fname, _lname, _county, _url);
        var _newSample = createSampleSMSBody(content, _fname, _lname, _county, _url);

        setEventUXSampleDataInformation(
        {
            UID: Date.now(),
            FirstName: _fname,
            LastName: _lname,
            URL: _url,
            County: _county,
            sampleSMSBody: _newSample
        });
    }

    // edr - It appears that other areas of code rely on this to initialize
    // the eventMessageBodyInformation useState var. We need to find and fix those places.
    function createSampleSMSBody(smsContent, inFName, inLName, inCounty, inURL){
        // console.log("AmplifyEventContext: entered createSampleSMSBody() (NEEDS REWRITING && TESTING) smsContent, inFName, inLName, inCounty, inURL = ", smsContent, inFName, inLName, inCounty, inURL)
        if (!smsContent || smsContent == "") {
            //console.log("AmplifyEventContext: createSampleSMSBody() exiting because smsContent is empty. Need to fix whatever called this!!!!")
            return;
        }

        var curEventMessageBodyInformation = eventMessageBodyInformation ?? newEventMessageBodyInformation();

        // edr - useState vars aren't always updated when we want
        // we need to require passing in smsContent here
        var localSMS = smsContent;
        if (!localSMS) {
            localSMS = curEventMessageBodyInformation.content;
        }

        // we can use the nullish coalescing operator to set defaults
        var _fname = inFName ?? eventUXSampleDataInformation.FirstName;
        var _lname = inLName ?? eventUXSampleDataInformation.LastName;
        var _county = inCounty ?? eventUXSampleDataInformation.County;
        var _url = inURL ?? eventUXSampleDataInformation.URL;

        if (localSMS.includes("[%OptOutMessage-Classic%]")) {
            localSMS = localSMS.replaceAll("[%OptOutMessage-Classic%]", "Reply STOP to Unsubscribe");
        }

        if (localSMS.includes("[%Double Opt-In/Out%]")) {
            localSMS = localSMS.replaceAll("[%Double Opt-In/Out%]", "YES=Enroll STOP=Stop Msgs");
        }

        if(localSMS.includes("[%OptOutMessage-Stop=End%]")) {
            localSMS = localSMS.replaceAll("[%OptOutMessage-Stop=End%]", "Stop=End");
        }

        if(localSMS.includes("[%OptOutMessage-Stop2End%]")) {
            localSMS = localSMS.replaceAll("[%OptOutMessage-Stop2End%]", "Stop2End");
        }

        if(localSMS.includes("[%OptOutMessage-StopToEnd%]")) {
            localSMS = localSMS.replaceAll("[%OptOutMessage-StopToEnd%]", "Reply STOP To End");
        }

        // MSG Info
        if(localSMS.includes("[%URL%]")) {
            localSMS = localSMS.replaceAll("[%URL%]", _url);
        }

        if(localSMS.includes("[%County%]")) {
            localSMS = localSMS.replaceAll("[%County%]", _county);
        }

        //Member Info
        if(localSMS.includes("[%MemberFirstName%]")) {
            localSMS = localSMS.replaceAll("[%MemberFirstName%]", _fname);
        }

        if(localSMS.includes("[%MemberLastName%]")) {
            localSMS = localSMS.replaceAll("[%MemberLastName%]", _lname);
        }

        if(localSMS.includes("[%MemberName%]")) {
            localSMS = localSMS.replaceAll("[%MemberName%]", _fname + ' ' + _lname);
        }

        curEventMessageBodyInformation['smsCharacterCount'] = localSMS.length;
        curEventMessageBodyInformation['statusId'] = Date.now();

        //console.log("AmplifyEventContext: createSampleSMSBody() curEventMessageBodyInformation = ", curEventMessageBodyInformation)
        setEventMessageBodyInformation({...curEventMessageBodyInformation});

        return localSMS.trim();
    }

    async function copyFacetImageFromCampaignToEvent(getURL, inEventKey, correctedName, mmsType, updateStatus){
        // console.log("AmplifyEventContext: entered copyFacetImageFromCampaignToEvent() (NEEDS TESTING) getURL, inEventKey, correctedName, mmsType, updateStatus = ", getURL, inEventKey, correctedName, mmsType, updateStatus)

        // this kind of change invalidates any "approval" state,
        // reset the event status to "processed"
        if (approveStates.has(eventCoreInformation.status)) {
            await axios.patch(
                `${config.eventapi.url}/api/v1/events/${inEventKey}`,
                {"key": inEventKey, "status": "processed"},
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // console.log("AmplifyEventContext: copyFacetImageFromCampaignToEvent() successfully reset event status to processed: response ", response)
                eventCoreInformation.status = "processed";
            });
        }

        var _targetPutUrl = null;
        var _targetGetUrl = null;

        //PATCH THE EVENT TO GET THE SIGNED URLS FOR THE EVENT
        var _mMMS = {
            "key": inEventKey,
            "media_name": correctedName,
            "media_type": mmsType
        }

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${inEventKey}`,
            _mMMS,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .then(function (response) {
            // console.log("AmplifyEventContext: copyFacetImageFromCampaignToEvent() successfully patched media_name and media_type: response ", response)
            _targetPutUrl = response.data.media_put_url;
            _targetGetUrl = response.data.media_get_url;
        })
        .catch(function (error) {
            console.log(error);
        });

        // something is wrong if no value at this point
        if (!getURL) {
            return;
        }

        var _mmsFile;

        //DOWNLOAD THE FILE FROM THE CAMPAIGN REPO
        await axios.get(
            getURL,
            {responseType: 'blob'}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            //console.log("AmplifyEventContext: copyFacetImageFromCampaignToEvent() successfully downloaded image data: response ", response)
            if (response.data) {
                _mmsFile = response.data;
            }
        });

        //NOW UPLOAD THE OPTIMIZED FILE TO THE EVENT URL
        if (_mmsFile) {
            var upload_success = false;

            await axios.put(
                _targetPutUrl,
                _mmsFile,
                {headers: { "Access-Control-Allow-Origin": "*", "Content-Type": mmsType }}
            )
            .then(function (response) {
                // console.log("AmplifyEventContext: copyFacetImageFromCampaignToEvent() successfully uploaded image data to the event: response ", response)
                upload_success = true;
            })
            .catch(function (error) {
                console.log("MMS Upload Error => ", error);
            });

            //FINALLY, BECAUSE OF THE CHANGE TO THE IMAGE, SET THE EVENT BACK TO "PROCESS" STATUS
            if (upload_success && (updateStatus == null || updateStatus == true)) {
                var _newStatus = determineNewEventStatus();
                var _mFinal = {
                    "key": inEventKey,
                    "status": _newStatus
                }

                await axios.patch(
                    `${config.eventapi.url}/api/v1/events/${inEventKey}`,
                    _mFinal,
                    {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
                )
                .catch(function (error) {
                    console.log(error);
                // })
                // .then(function (response) {
                //     console.log("AmplifyEventContext: copyFacetImageFromCampaignToEvent() successfully updated the event status: response ", response)
                });
            }
        }

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
        return _targetGetUrl;
    }

    async function postSampleMessage(messageBundle){
        //console.log("AmplifyEventContext: entered postSampleMessage() (NEEDS TESTING) messageBundle = ", messageBundle)
        if (!eventPhoneInformation || !messageBundle || !messageBundle.fromPhone) {
            return;
        }

        var fromPhone = messageBundle.fromPhone;
        var targetPhone = messageBundle.targetPhone ?? '';
        var mediaURL = messageBundle.mediaURL ?? '';
        var messageBody = messageBundle.messageBody + `\r\n\r\n` + "****PROOF****";
        var sampleId = messageBundle.sample_id ?? 0;
        var targetName = messageBundle.name ?? '';

        var _id = dayjs().unix();

        if (targetPhone.length == 10) {
            targetPhone = '+1' + targetPhone;
        }

        var _msg =
            {
                "name": uuidv4(),
                "event_id": _id.toString(),
                "bandwidth_campaign_id": "TBD",
                "application_id": adminBWApplicationID,
                "admin_phone_number": fromPhone,
                "admin_phone_number_area_code": eventPhoneInformation.area_code,
                "admin_phone_number_status": "COMPLETE",
                "tasks":
                    [{
                        "task_type": "ADMIN_MESSAGE",
                        "data":
                            {
                                "message":
                                    {
                                        "body": createSampleSMSBody(messageBody),
                                        "media_url": mediaURL,
                                        "tag": null
                                    },
                                "customer":
                                    {
                                        "phone": targetPhone,
                                        "data_message": null,
                                        "tag": null
                                    }
                            },
                        "status": 'pending',
                        "object_type": null,
                        "created_dt": null,
                        "created_user": null,
                        "updated_dt": null,
                        "updated_user": null
                    }]
            }

        await axios.put(
            `https://afevent.roosevelt.hyperscaleaf.com/api/v1/adminevents/${_id}`,
            //`${config.eventapi.url}/api/v1/adminevents/${_id}`,
            _msg,
            {headers: { Authorization: "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZmFwcHNlcnZpY2UiLCJyb2xlcyI6WyJzZWM6Z2xvYmFsYWRtaW4iXSwiZXhwIjoyMDMwMjA3Mjg4fQ.C-aubpVMsd-PRx01FpJwDc3pNKcNN5LPDsRhkVMcQAs' }}
        )
        .catch(function (error) {
            console.log("error posting sample message:", error);
        // })
        // .then(function (response) {
        //     console.log("successfully sent sample message: response ", response);
        });

        //PATCH THE MESSAGE EVENT WITH THE SENT STAMP
        var _now = dayjs();

        if (eventSampleInformation) {
            var _s = eventSampleInformation.sample_numbers;
            var _found = false;

            for (var i = 0; i < _s.length ; i++){
                if (_s[i].id == sampleId) {
                    _s[i].msg_sent = _now;
                    _s[i].status = 'SENT';
                    _found = true;
                    break;
                }
            }
        }

        var _new = {id: sampleId, name: targetName, phone: targetPhone, status: 'SENT', msg_sent: _now};

        if (!_found) {
            _s.splice(_s.length, 0, _new);
        }

        // edr - commented out because I don't think this is getting stored or used
        //eventSampleInformation.sample_numbers = _s;

        var _e = {
            "key" : eventCoreInformation.key,
            "sample_numbers": _s
        }

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${eventCoreInformation.key}`,
            _e,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        // })
        // .then(function (response) {
        //     console.log("successfully patched event with sample timestamps: response ", response);
        });

        setToastMessage(`sent event sample to ${messageBundle.name} at ${messageBundle.targetPhone}`);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function postApprovalStart(){
        //console.log("AmplifyEventContext: entered postApprovalStart() (NEEDS TESTING)")
        if (!eventCoreInformation.key) {
            return;
        }

        //PATCH THE EVENT TO STORE THE APPROVERS
        var _m = {
            "key": eventCoreInformation.key,
            "status": "approve"
        }

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${eventCoreInformation.key}`,
            _m,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("there was an error sending the approval messages to the event approvers => ", error);
        // })
        // .then(function (response) {
        //     console.log("successfully patched the event state to approve: response ", response);
        });

        setToastMessage(`initiated the approval process for ${eventCoreInformation.name} (Project Code: ${eventCoreInformation.project_code})`);
        getExistingEvent(eventCoreInformation.key);

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function postEventApproval(approver){
        //console.log("AmplifyEventContext: entered postEventApproval() (NEEDS TESTING) approver = ", approver)
        if (!eventCoreInformation.key) {
            return;
        }

        var _approvers = [];
        if (eventApprovalInformation) {
            for (let i=0; i < eventApprovalInformation.approval_numbers.length; i++) {
                var _approverId = eventApprovalInformation.approval_numbers[i].id;
                var _approverName = eventApprovalInformation.approval_numbers[i].name;
                var _approverPhone = eventApprovalInformation.approval_numbers[i].phone;
                var _approverStatus = eventApprovalInformation.approval_numbers[i].status;
                var _PENDING_dt = eventApprovalInformation.approval_numbers[i].PENDING_dt;

                if (_approverId == approver.id) {
                    _approverStatus = "APPROVED";
                    _PENDING_dt = dayjs().unix();
                }

                var _a = {PENDING_dt: _PENDING_dt, id: _approverId, name: _approverName, phone: _approverPhone, status: _approverStatus};
                _approvers.splice(_approvers.length,0,_a);
            }
        }

        //PATCH THE EVENT TO STORE THE APPROVERS
        var _m = {
            "key": eventCoreInformation.key,
            "status": "approved",
            "approval_numbers": _approvers
        }

        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${eventCoreInformation.key}`,
            _m,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("there was an error sending the approval messages to the event approvers => ", error);
        // })
        // .then(function (response) {
        //     console.log("successfully patched the event state to approved with approval_numbers: response ", response);
        });

        setToastMessage(`approved event ${eventCoreInformation.name} (Project Code: ${eventCoreInformation.project_code}) for release`);
        getExistingEvent(eventCoreInformation.key);

        setEventApproversChanged(uuidv4());

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    async function patchEventVoterSetClearMapping(){

        var _core = eventCoreInformation ?? newEventCoreInformation();

        if(!_core.key){
            return;
        }

        _core['statusId'] = Date.now();
        _core['voterset_key'] = null;
        _core['status'] = determineNewEventStatus();
        setEventCoreInformation(_core);

        if (!_core.key) {
            //console.log("AmplifyEventContext: updateCoreInformationVoterSet() exiting early because !_core.key    _core = ", _core)
            return;
        }

        var _jsonEventData = {
            "key": _core.key,
            "voterset_key": null,
            "status": _core.status
        }

        //console.log("AmplifyEventContext: updateCoreInformationVoterSet() API call to patch voterset value in the event record _jsonEventData", _jsonEventData)
        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${_core.key}`,
            _jsonEventData,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .then(function (response) {
            setEventCoreChanged(uuidv4());
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    return (
        <AmplifyEventContext.Provider value={{
            getExistingEvent:                                       getExistingEvent,
            initializeNewEvent:                                     initializeNewEvent,

            eventCoreChanged:                                       eventCoreChanged,
            eventApproversChanged:                                  eventApproversChanged,
            eventNBSamplesChanged:                                  eventNBSamplesChanged,
            eventPhoneInformationChanged:                           eventPhoneInformationChanged,

            eventCoreInformation:                                   eventCoreInformation,

            eventPhoneInformation:                                  eventPhoneInformation,
            eventApprovalInformation:                               eventApprovalInformation,
            eventSampleInformation:                                 eventSampleInformation,
            eventArchiveInformation:                                eventArchiveInformation,
            eventMessageBodyInformation:                            eventMessageBodyInformation,
            eventMessagePricingInformation:                         eventMessagePricingInformation,
            eventMessageAuditInformation:                           eventMessageAuditInformation,
            eventMessageRevInformation:                             eventMessageRevInformation,

            eventUXSampleDataInformation:                           eventUXSampleDataInformation,
            refreshEventList:                                       refreshEventList,
            setRefreshEventList:                                    setRefreshEventList,

            updateCoreInformation:                                  updateCoreInformation,
            updateCoreInformationVoterSet:                          updateCoreInformationVoterSet,
            updatePreferredAreaCode:                                updatePreferredAreaCode,
            updateEventApprovers:                                   updateEventApprovers,
            updateEventSamplerecipients:                            updateEventSamplerecipients,
            updateMMSAttachments:                                   updateMMSAttachments,
            updateSMSBody:                                          updateSMSBody,
            updateSMSSampleRecord:                                  updateSMSSampleRecord,
            updateCoreInformationScheduling:                        updateCoreInformationScheduling,
            updateExistingEvent_Archive:                            updateExistingEvent_Archive,
            updateExistingEvent_Commit:                             updateExistingEvent_Commit,

            copyFacetImageFromCampaignToEvent:                      copyFacetImageFromCampaignToEvent,

            removeMMSAttachments:                                   removeMMSAttachments,
            clearMMSAttachments:                                    clearMMSAttachments,

            calcSetEventUnitPrice:                                 calcSetEventUnitPrice,
            determineNewEventStatus:                                determineNewEventStatus,

            saveAmplifyEvent:                                       saveAmplifyEvent,

            clearDataMapperFiles:                                   clearDataMapperFiles,
            clearAllExistingEventValues:                            clearAllExistingEventValues,
            previouslySavedDataMap:                                 previouslySavedDataMap,

            modalOpenArchive:                                       modalOpenArchive,
            setModalOpenArchive:                                    setModalOpenArchive,
            modalOpenCommit:                                        modalOpenCommit,
            setModalOpenCommit:                                     setModalOpenCommit,

            postSampleMessage:                                      postSampleMessage,
            postApprovalStart:                                      postApprovalStart,
            postEventApproval:                                      postEventApproval,

            eventLoadingInProgress:                                 eventLoadingInProgress,
            eventLoadingInProgressMsg:                              eventLoadingInProgressMsg,

            ableToAllocateTN:                                       ableToAllocateTN,

            toastMessage:                                           toastMessage,

            // exporting so other components can initialize their local vars to proper structures
            newEventPhoneInformation:                               newEventPhoneInformation,
            newEventApprovalInformation:                            newEventApprovalInformation,
            newEventMessageBodyInformation:                         newEventMessageBodyInformation,
            newEventMessagePricingInformation:                      newEventMessagePricingInformation,

            patchEventVoterSetClearMapping:                         patchEventVoterSetClearMapping

        }}>
            {children}
        </AmplifyEventContext.Provider>
    );
}
