import { useContext } from "react";
import { UnifyContext } from "../../context/UnifyContext";

import TextField from '@mui/material/TextField';

const UnifyCampaignCreate_VolunteerPage = () => {

    const { volunteerParagraph,setVolunteerParagraph } = useContext(UnifyContext);

    return (
        <div style={{height: "500px", margin: "0px 30px 20px 30px"}}>

            <div className="unifyCallOut" style={{width: "90%", margin: "40px 60px 40px 0px"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>Success comes from a great group of volunteers.  Make sure you tell everyone why you are the one they should come out and support.  in other words... remind everyone again why you are THE ONE!</div>
            </div>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Ask for help... your volunteers are the heart of the win</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Volunteer Paragraph"
                        style={{width: "1200px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setVolunteerParagraph(e.target.value)}
                        value={volunteerParagraph}
                    />
                </div>
            </div>
            <div className="unifyCallOut" style={{width: "90%", margin: "40px 60px 40px 0px"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>And just like that, you are done with communications creation! CONGRATULATIONS! Now... let&lsquo;s set your target audience to get your message out and win!</div>
            </div>
        </div>

    );
};

export default UnifyCampaignCreate_VolunteerPage;
