const CancelActivity = () => {

    return (
        <div className="navigationCancel">
            <div className="flex">
                <div style={{margin: "20px 0px 0px 40px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="#ffffff"
                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                </div>
                <div className="grid" style={{margin: "28px 0px 0px 20px"}}>
                    <div className="navigationCancelTitle">Cancel</div>
                </div>
            </div>
        </div>
    );
};

export default CancelActivity;
