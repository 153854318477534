import { createContext, useState, useEffect, useRef } from "react";

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { v4 as uuidv4 } from 'uuid';

export const ApplicationContext = createContext();

export const ApplicationContextProvider = ({ children }: any) => {
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);

    const [appRenderType, setAppRenderType] = useState('');

    const [applicationSegmment, setApplicationSegment] = useState('');

    const [openLogoutImminent, setOpenLogoutImminent] = useState(false);
    const [logoutCountdownStarted, setLogoutCountdownStarted] = useState(false);

    const [closeLogoutModalAndReset, setCloseLogoutModalAndReset] = useState('');

    const [timeDisplay, setTimeDisplay] = useState('2:00');
    // const [remaining, setRemaining] = useState(120);
    const [tick, setTick] = useState('');

    let countdownInterval = useRef();

    //eslint-disable-next-line
    const [init, setInit] = useState(false);

    function reportWindowSize() {
        var sizes = {width: 0, height: 0, appRenderType: ""};

        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);

        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;
        sizes.appRenderType = "desktop";
        if(window.innerWidth >= 1600){
            setAppRenderType("desktop");
            sizes.appRenderType = "desktop";
        }
        else if(window.innerWidth < 1600 && window.innerWidth > 450){
            setAppRenderType("tablet");
            sizes.appRenderType = "tablet";
        }
        else{
            setAppRenderType("phone");
            sizes.appRenderType = "phone";
        }
        return sizes;
    }

    function updateApplicationSegment(segmentName){
        setApplicationSegment(segmentName);
    }

    useEffect(() => {
        if(openLogoutImminent == true){
            // setRemaining(120);
            sessionStorage.setItem('time remaining', 120);

            setInit(true);
            startTimer(true);
        }
    }, [openLogoutImminent])

    async function startTimer(){

        // console.log("log out imminent =>", openLogoutImminent);
        setLogoutCountdownStarted(true);

        sessionStorage.setItem('countdown process id', '');
        sessionStorage.setItem('current countdown process id', '');

        countdownInterval.current = setInterval(() => {

            if(sessionStorage.getItem('countdown process id') == sessionStorage.getItem('current countdown process id')){
                setTick(uuidv4());
            }
            else{
                clearInterval(countdownInterval.current);
                sessionStorage.setItem('countdown process id', '');
                sessionStorage.setItem('current countdown process id', '');
            }

        }, 1000);

    }

    useEffect(() => {
        sessionStorage.setItem('countdown process id', closeLogoutModalAndReset);
        sessionStorage.setItem('time remaining', 120);
        setTimeDisplay("2:00");
    }, [closeLogoutModalAndReset])

    useEffect(() => {

        var _remaining = sessionStorage.getItem('time remaining') - 1;

        // setRemaining(_remaining);
        sessionStorage.setItem('time remaining', _remaining);

        var _d = Math.floor(_remaining/60);
        var _displayMinutes = `0${_d}`;
        var _displaySeconds = 0;

        if(_d == 0){
            _displaySeconds = _remaining;
        }
        else{
            _displaySeconds = Math.floor((_remaining) % (_d * 60));
        }

        if(_displaySeconds < 10){
            _displaySeconds = `0${_displaySeconds}`;
        }

        setTimeDisplay(_displayMinutes + ":" + _displaySeconds);

    }, [tick])

    window.onresize = reportWindowSize;

return (
        <ApplicationContext.Provider value={{
            windowWidth:   						windowWidth,
            windowHeight:   					windowHeight,
            reportWindowSize:                   reportWindowSize,
            appRenderType:                      appRenderType,
            applicationSegmment:                applicationSegmment,
            updateApplicationSegment:           updateApplicationSegment,
            openLogoutImminent:                 openLogoutImminent,
            setOpenLogoutImminent:              setOpenLogoutImminent,
            logoutCountdownStarted:             logoutCountdownStarted,
            setLogoutCountdownStarted:          setLogoutCountdownStarted,
            timeDisplay:                        timeDisplay,
            closeLogoutModalAndReset:           closeLogoutModalAndReset,
            setCloseLogoutModalAndReset:        setCloseLogoutModalAndReset
        }}>
        {children}
        </ApplicationContext.Provider>
    );
}
