import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

const Event_TotSent = ({ TotSent }) => {
    const { isMobileDisplay } = useContext(AuthContext);
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <>
            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className={`stats ${
                                    isMobileDisplay
                                        ? "eventTileMobile rounded-none pl-5 pt-5 pb-5 pr-5 bg-white"
                                        : "dashboardHalfHeightTile rounded-none ml-5 pl-5 pt-5 pb-5 pr-5"
                                    }`}>


                <div style={{margin: "50px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"/>
                        <path d="M16 16l-4-4-4 4"/>
                    </svg>
                </div>
                <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                    <div className="dashboardTileTitle">Total Universe</div>
                    <div className="dashboardTileMainData">{TotSent}</div>
                    <div className="dashboardTileSubtext">Total messages sent for this event</div>
                </div>
            </div>
            :
            <div className={`dashboardHalfHeightTile_Phone flex`}>

                <div style={{margin: "17px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none"
                            stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"/>
                        <path d="M16 16l-4-4-4 4"/>
                    </svg>
                </div>
                <div className="grid" style={{margin: "10px 0px 0px 10px", height: "80px"}}>
                    <div className="dashboardTileTitle_Phone">Total Universe</div>
                    <div className="dashboardTileMainData_Phone">{TotSent}</div>
                    <div className="dashboardTileSubtext_Phone">Total messages sent for this event</div>
                </div>
            </div>

            }
        </>
    );
};

Event_TotSent.propTypes = {
    TotSent: PropTypes.number.isRequired
};

export default Event_TotSent;
