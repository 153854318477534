export default function WIP() {
    return (
        <div id="mainBody" style={{background: "#ffce00", height: "100vh", width: "100vw"}}>
            <img src={process.env.PUBLIC_URL + "/images/WIP.gif"}/>

            <div style={{position: "relative", marginTop: "50px", fontSize: "32px", fontWeight: "bold", color: "black", textAlign: "center"}}>
                The team is working hard to get the mobile version working as quickly as possible... but for now, please enjoy this application from your desktop.
            </div>

            <div className="signinFooter align-bottom">
                <img src={process.env.PUBLIC_URL + "/images/WIP.gif"}/>
            </div>
        </div>
    );
}
