const apiconfigs = {
    messageapi: {
        url: process.env.REACT_APP_MESSAGEAPI,
    },
    accountapi: {
        url: process.env.REACT_APP_ACCOUNTAPI,
    },
    voterapi: {
        url: process.env.REACT_APP_VOTERAPI,
    },
    eventapi: {
        url: process.env.REACT_APP_EVENTAPI,
    },
    reportapi: {
        url: process.env.REACT_APP_REPORTAPI,
    },
    bandwidthapi: {
        url: process.env.REACT_APP_AFBANDWIDTHAPI,
    },
    s3api: {
        url: process.env.REACT_APP_SIMPLESTORAGESERVICE,
    },
};

export const config = apiconfigs;
