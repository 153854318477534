import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { CampaignContext } from "./CampaignContext";
import { CompanyContext } from "./CompanyContext";
import { AmplifyEventContext } from "./AmplifyEventContext"

// API calls
import axios from "axios";
import { config } from "../constants/global.js";
import { v4 as uuidv4 } from 'uuid';

export const FacetContext = createContext();

export const FacetContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { campaignKey } = useContext(CampaignContext);
    const { companyKey } = useContext(CompanyContext);
    const { clearMMSAttachments } = useContext (AmplifyEventContext);

    const [mmsPatchVals, setMMSPatchVals] = useState('');
    const [facetProcessingImageUpload, setFacetProcessingImageUpload] = useState(null);

    const [facetMessage, setFacetMessage] = useState('');
    const [processingFacetImage, setProcessingFacetImage] = useState(false);

    function facetClearMMSFileInfo(){
        setMMSPatchVals({mms: '', mmsType: '', mmsFile: {}});
        setFacetProcessingImageUpload(null);
        setProcessingFacetImage(false);
    }

    //this gets fired off from the Amplify event context when there is a request to clear all data (navigate back to the main menu)
    useEffect(() => {
        facetClearMMSFileInfo();
    }, [clearMMSAttachments])

    useEffect(() => {
        try{
            if (mmsPatchVals.mms) {
                if (mmsPatchVals.mms.toString().length > 0 && mmsPatchVals.mmsType.toString().length > 0) {
                    setProcessingFacetImage(true);
                    patchForNewMMS(mmsPatchVals.mms.toString(), mmsPatchVals.mmsType.toString(), mmsPatchVals.mmsFile);
                }
            }
        }
        catch(error){
            console.log("error preparing to upload a new MMS attachement => ", error);
        }
    }, [mmsPatchVals])

    async function checkFacetFileProcessingComplete(getURL) {
        var _status = "IN PROGRESS";
        await axios.get(
            getURL,
            {headers: { "Content-Type": "" }},
        )
        .catch(function (error) {
            console.log("error trying to collect the file from the s3 repository -> ", error);
            _status = "error";
        })
        .then(function (response) {
            // console.log("FacetContext: checkFacetFileProcessingComplete() response will be the binary image")
            if (response && response.status == 200)
                _status = "COMPLETE";
        });
        return _status;
    }

    async function patchForNewMMS(mms, mmsType, mmsFileInbound){
        // console.log("FacetContext: entered patchForNewMMS(mms, mmsType, mmsFileInbound)  ", mms, mmsType, mmsFileInbound)
        var _uploadFileInfo;
        var _putURL;

        await axios.get(
            `${config.s3api.url}/api/v1/file?business_key=${companyKey}&campaign_key=${campaignKey}&file_name=${mms}`,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("there was a terminal error when trying to upload a new MMS attachment => ", error);
        })
        .then(function (response) {
            // console.log("FacetContext: patchForNewMMS() response ", response)
            _uploadFileInfo = response.data;
            _putURL = response.data.facets_aws_put_url;
        });

        if (_putURL) {
            await axios.put(
                _putURL,
                mmsFileInbound,
                {headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "" }}
            )
            .catch(function (error) {
                console.log("there was a terminal error when trying to upload a new MMS attachment => ", error);
            });

            //********************************* START CHECKING IF THE FILE WAS SUCCESSFULLY PROCESSED  ********************/
            setFacetProcessingImageUpload({id: uuidv4(), mms: mms, mmsType: mmsType, mmsFile: mmsFileInbound, s3FileInformation: _uploadFileInfo });
            //********************************* START CHECKING IF THE FILE WAS SUCCESSFULLY PROCESSED  ********************/
        }
        else{
            console.log("unable to collect the specified file storage information from the S3 repository");
        }
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    return (
        <FacetContext.Provider value={{

            setMMSPatchVals:                                            setMMSPatchVals,
            facetProcessingImageUpload:                                 facetProcessingImageUpload,
            setFacetProcessingImageUpload:                              setFacetProcessingImageUpload,
            checkFacetFileProcessingComplete:                           checkFacetFileProcessingComplete,

            facetMessage:                                               facetMessage,
            setFacetMessage:                                            setFacetMessage,
            processingFacetImage:                                       processingFacetImage,
            setProcessingFacetImage:                                    setProcessingFacetImage,
            facetClearMMSFileInfo:                                      facetClearMMSFileInfo

        }}>
            {children}
        </FacetContext.Provider>
    );
}
