import { useEffect, useContext, useState, useRef} from "react";
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import { CompanyContext } from "../../context/CompanyContext";
import { useNavigate } from "react-router-dom";

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import EventList_Home from "../../components/Home/EventList_Home";

import SessionTimeout from "../../components/session/SessionTimeout";
import Session_LogoutImminent from "../../components/session/Session_LogoutImminent";
import Modal from '@mui/material/Modal';

export default function Dashboard() {
    const { companyID } = useContext(CompanyContext);
    const { reportWindowSize, appRenderType, openLogoutImminent, setOpenLogoutImminent, setLogoutCountdownStarted, closeLogoutModalAndReset } = useContext(ApplicationContext);
    const navigate = useNavigate();
    const { jwt, dispatch, roles, first_name, last_name } = useContext(AuthContext);

    //eslint-disable-next-line
    const [displayWidth, setDisplayWidth] = useState(0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const [isAFCUser, setIsAFCUser] = useState(false);

    const [pacificTime, setPacificTime] = useState('');
    const [mountainTime, setMountainTime] = useState('');
    const [centralTime, setCentralTime] = useState('');
    const [easternTime, setEasternTime] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    var dayOfYear = require('dayjs/plugin/dayOfYear')
    dayjs.extend(dayOfYear)

    if (jwt === null) {
        window.location.href = "/login";
    }

    useEffect(() => {
        validateAdmin();
    }, [roles]);

    useEffect(() => {
        updateClocks();
        reportWindowSize();
        setDisplayWidth(windowSize.current[0]);
        validateAdmin();
    }, [])

    async function updateClocks(){

        //var t = setInterval(async function() {
        setInterval(async function() {

            var currTime = dayjs();

            setPacificTime(currTime.tz("America/Los_Angeles").format("dddd HH:mm:ss"));
            setMountainTime(currTime.tz("America/Denver").format("dddd HH:mm:ss"));
            setCentralTime(currTime.tz("America/Chicago").format("dddd HH:mm:ss"));
            setEasternTime(currTime.tz("America/New_York").format("dddd HH:mm:ss"));

            // console.log(_pacificTime);

        }, 1000);

    }

    function ResetPassword(){
        navigate("/passwordManagement");
    }

    const logoutClick = async (event) => {
        event.preventDefault();
        dispatch({ type: "LOGOUT" });
        // handleAccountClose();
    };

    function validateAdmin() {
        for (let i=0; i < roles.length; i++) {
            if (roles[i].startsWith("sec:afc") || roles[i].startsWith("sec:globaladmin")) {
                setIsAFCUser(true);
                return true;
            }
        }
        return false;
    }

    function solutionClicked(source, type){
        switch(type){
        case "Amplify":
            navigate("/amplifyDashboard");
            break;
        case "Unify":
            navigate("/unifyHome");
            break;
        }
    }

    useEffect(() => {
        if(openLogoutImminent == true){
            handleModalOpen();
        }
        else{
            handleModalClose();
        }
    }, [openLogoutImminent])

    useEffect(() => {
        // console.log("modalOpen => ", modalOpen);
        if(modalOpen == false){
            setLogoutCountdownStarted(false);
            setOpenLogoutImminent(false);
        }

    }, [modalOpen])

    useEffect(() => {
        handleModalClose();
    }, [closeLogoutModalAndReset])

    return (
        <div>
            <SessionTimeout/>
            {appRenderType == "desktop" ?
            <div className="homeBackground">
                <div className="mainPageDiv" style={{background: "transparent"}}>

                    <div style={{height: "700px", background: "white"}}>
                        <div className="flex">
                            {/*}<div style={{width: "600px"}}>&nbsp;</div>{*/}
                            <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/home_top_background.gif" style={{height: "700px", marginLeft: "397px"}}/>
                        </div>
                        <div style={{marginTop: "-700px"}}>
                            <div style={{height:"120px", background: "transparent"}}>&nbsp;</div>
                            <div name="DisplayWelcome" style={{marginLeft: "50px"}}>
                                <label style={{fontSize: "32px"}}>Welcome back, {first_name}!</label>
                                <div className="flex">
                                    <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/user.gif" style={{height: "75px"}}/>
                                    <div className="grid" style={{height: "60px", marginTop: "5px"}}>
                                        <div><label style={{fontSize: "24px", marginBottom: "0px", marginLeft: "20px", marginTop: "15px"}}>{first_name}&nbsp;{last_name}</label></div>
                                        <div className="flex">
                                            <div style={{fontSize: "14px", color: "red", marginTop: "0px", marginLeft: "23px", cursor: "pointer", textDecorationLine: "underline"}} onClick={logoutClick}>logout</div>
                                            <div style={{fontSize: "14px", color: "red", marginTop: "0px", marginLeft: "50px", cursor: "pointer", textDecorationLine: "underline"}} onClick={ResetPassword}>reset password</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div name="DisplayWelcome" style={{marginTop: "20px", marginLeft: "50px"}}>
                            <div className="grid">
                                <label style={{fontSize: "24px"}}>Your enrolled solutions</label>
                                <label style={{fontSize: "10px"}}>select your solution below to get started</label>
                            </div>
                            <div className="flex" style={{marginTop: "0px"}}>
                                <div className="homePageApplicationButton tooltip" onClick={e => solutionClicked(e, "Amplify")}>
                                    <img alt="Amplify Messaging Platform" className="homePageApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_inverse_thumbnail.png"/>
                                    <span className="tooltiptext">Amplify Messaging Platform</span>
                                </div>
                                {isAFCUser == true ?
                                <div className="homePageApplicationButton tooltip" onClick={e => solutionClicked(e, "Unify")}>
                                    <img className="homePageApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/Unify_main_logo_1.png"/>
                                    <span className="tooltiptext">Web Site and Landing Page Management</span>
                                </div>
                                :
                                <div/>
                                }
                            </div>
                            <div className="grid" style={{marginTop: "20px"}}>
                                <label style={{fontSize: "18px"}}>Coming soon. Contact Alliance Forge for more information</label>
                            </div>
                            <div className="flex" style={{marginTop: "20px"}}>
                                <div className="homePageApplicationButton tooltip">
                                    <img className="homePageComingSoonApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Crystal/AFC_Crystal.png"/>
                                    <span className="tooltiptext">Video and Image Studio</span>
                                </div>
                                <div className="homePageApplicationButton tooltip">
                                    <img className="homePageComingSoonApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Mosaic/Mosaic%20Logo%20_full_white%20Back.png"/>
                                    <span className="tooltiptext">World Class Data Cloud Data Management</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="homePageLatest" style={{background: "transparent"}}>
                        <div style={{height: "10px", width: "100%", background: "transparent"}}>&nbsp;</div>
                        <div className="flex">
                            <div className="homePageDoubleWidthTile grid" style={{overflowY: "hidden", marginLeft: "0px"}}>
                                <div style={{marginTop: "30px", marginLeft: "10px", fontWeight: "bold"}}><label>Upcoming or Most Recent Amplify Events</label></div>
                                <EventList_Home company_id={companyID || 0} style={{maxHeight: "300px"}}/>
                            </div>
                            <div className="homePageTile" style={{overflowY: "hidden"}}>
                                <div className="flex" style={{margin: "10px"}}>
                                    <img style={{height: "150px", width: "150px", borderRadius: "5%", overflow: "hidden"}}
                                        src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/smiling_outdoors.gif"/>

                                    <div style={{marginLeft: "10px", width: "150px", marginTop: "10px"}}>
                                        <div><label style={{fontSize: "18px", fontWeight: "bold"}}>Meet your people!</label></div>
                                        <div><label>Use Unify to create a stunning, full featured landing page for your next event</label></div>
                                    </div>
                                </div>
                                <div style={{width: "350px", height: "3px", background: "lightgray", marginLeft: "10px" }}></div>
                                <div className="flex" style={{margin: "10px"}}>
                                    <img style={{height: "150px", width: "150px", borderRadius: "5%", overflow: "hidden"}}
                                        src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/smiling_with_the_cell.gif"/>

                                    <div style={{marginLeft: "10px", width: "200px", marginTop: "5px"}}>
                                        <div><label style={{fontSize: "18px", fontWeight: "bold"}}>Introducing, Facets</label></div>
                                        <div><label>Your images... sized, optimized, and prepared for text messaging, web sites, and social media... in the blink of an eye.</label></div>
                                    </div>
                                </div>

                            </div>
                            <div className="homePageTile" style={{overflowY: "hidden"}}>
                                <div className="homePacificTime">
                                    <div style={{marginTop: "10px", marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px"}}>Pacific Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold"}}>{pacificTime}</label></div>
                                </div>
                                <div className="homeMountainTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px"}}>Mountain Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold"}}>{mountainTime}</label></div>
                                </div>
                                <div className="homeCentralTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px"}}>Central Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold"}}>{centralTime}</label></div>
                                </div>
                                <div className="homeEasternTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px"}}>Eastern Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold"}}>{easternTime}</label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : appRenderType=="tablet" ?
            <div className="homeBackground_Tablet">
                <div className="mainPageDiv_Mobile" style={{height: "700px"}}>
                    <div style={{height: "700px", width: "790px", background: "white"}}>
                        <div className="flex">
                            {/*}<div style={{width: "600px"}}>&nbsp;</div>{*/}
                            <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/home_top_background.gif"
                                style={{height: "700px", width: "393px", marginLeft: "397px", objectFit: "cover", objectPosition: "50% 0"}}/>
                        </div>
                        <div style={{marginTop: "-700px"}}>
                            <div style={{height:"120px", background: "transparent"}}>&nbsp;</div>
                            <div name="DisplayWelcome" style={{marginLeft: "50px"}}>
                                <label style={{fontSize: "32px"}}>Welcome back, {first_name}!</label>
                                <div className="flex">
                                    <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/user.gif" style={{height: "75px"}}/>
                                    <div className="grid" style={{height: "60px", marginTop: "5px"}}>
                                        <div><label style={{fontSize: "24px", marginBottom: "0px", marginLeft: "20px", marginTop: "15px"}}>{first_name}&nbsp;{last_name}</label></div>
                                        <div className="flex">
                                            <div style={{fontSize: "14px", color: "red", marginTop: "0px", marginLeft: "23px", cursor: "pointer", textDecorationLine: "underline"}} onClick={logoutClick}>logout</div>
                                            <div style={{fontSize: "14px", color: "red", marginTop: "0px", marginLeft: "50px", cursor: "pointer", textDecorationLine: "underline"}} onClick={ResetPassword}>reset password</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div name="DisplayWelcome" style={{marginTop: "20px", marginLeft: "50px"}}>
                            <div className="grid">
                                <label style={{fontSize: "24px"}}>Your enrolled solutions</label>
                                <label style={{fontSize: "10px"}}>select your solution below to get started</label>
                            </div>
                            <div className="flex" style={{marginTop: "0px"}}>
                                <div className="homePageApplicationButton tooltip" onClick={e => solutionClicked(e, "Amplify")}>
                                    <img alt="Amplify Messaging Platform" className="homePageApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_inverse_thumbnail.png"/>
                                    <span className="tooltiptext">Amplify Messaging Platform</span>
                                </div>
                                {isAFCUser == true ?
                                <div className="homePageApplicationButton tooltip" onClick={e => solutionClicked(e, "Unify")}>
                                    <img className="homePageApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/Unify_main_logo_1.png"/>
                                    <span className="tooltiptext">Web Site and Landing Page Management</span>
                                </div>
                                :
                                <div/>
                                }
                            </div>
                            <div className="grid" style={{marginTop: "20px"}}>
                                <label style={{fontSize: "18px", width: "350px"}}>Coming soon. Contact Alliance Forge for more information</label>
                            </div>
                            <div className="flex" style={{marginTop: "20px"}}>
                                <div className="homePageApplicationButton tooltip">
                                    <img className="homePageComingSoonApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Crystal/AFC_Crystal.png"/>
                                    <span className="tooltiptext">Video and Image Studio</span>
                                </div>
                                <div className="homePageApplicationButton tooltip">
                                    <img className="homePageComingSoonApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Mosaic/Mosaic%20Logo%20_full_white%20Back.png"/>
                                    <span className="tooltiptext">World Class Data Cloud Data Management</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="homePageLatest" style={{background: "transparent"}}>
                        <div style={{height: "10px", width: "100%", background: "transparent"}}>&nbsp;</div>
                        <div className="flex">
                            <div className="homePageDoubleWidthTile grid" style={{overflowY: "hidden", marginLeft: "0px"}}>
                                <div style={{marginTop: "30px", marginLeft: "10px", fontWeight: "bold"}}><label>Upcoming or Most Recent Amplify Events</label></div>
                                <EventList_Home company_id={companyID || 0} style={{maxHeight: "300px"}}/>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="homePageTile" style={{overflowY: "hidden"}}>
                                <div className="flex" style={{margin: "10px"}}>
                                    <img style={{height: "150px", width: "150px", borderRadius: "5%", overflow: "hidden"}}
                                        src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/smiling_outdoors.gif"/>

                                    <div style={{marginLeft: "10px", width: "150px", marginTop: "10px"}}>
                                        <div><label style={{fontSize: "18px", fontWeight: "bold"}}>Meet your people!</label></div>
                                        <div><label>Use Unify to create a stunning, full featured landing page for your next event</label></div>
                                    </div>
                                </div>
                                <div style={{width: "350px", height: "3px", background: "lightgray", marginLeft: "10px" }}></div>
                                <div className="flex" style={{margin: "10px"}}>
                                    <img style={{height: "150px", width: "150px", borderRadius: "5%", overflow: "hidden"}}
                                        src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/smiling_with_the_cell.gif"/>

                                    <div style={{marginLeft: "10px", width: "200px", marginTop: "5px"}}>
                                        <div><label style={{fontSize: "18px", fontWeight: "bold"}}>Introducing, Facets</label></div>
                                        <div><label>Your images... sized, optimized, and prepared for text messaging, web sites, and social media... in the blink of an eye.</label></div>
                                    </div>
                                </div>

                            </div>
                            <div className="homePageTile" style={{overflowY: "hidden"}}>
                                <div className="homePacificTime">
                                    <div style={{marginTop: "10px", marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px"}}>Pacific Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold"}}>{pacificTime}</label></div>
                                </div>
                                <div className="homeMountainTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px"}}>Mountain Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold"}}>{mountainTime}</label></div>
                                </div>
                                <div className="homeCentralTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px"}}>Central Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold"}}>{centralTime}</label></div>
                                </div>
                                <div className="homeEasternTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px"}}>Eastern Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold"}}>{easternTime}</label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div>
                <div className="homeBackground_Phone">
                    <div style={{height: "700px", background: "white"}}>
                        <div className="flex">
                            {/*}<div style={{width: "600px"}}>&nbsp;</div>{*/}
                            <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/home_top_background.gif" style={{height: "700px", marginLeft: "160px", opacity: "20%"}}/>
                        </div>
                        <div style={{marginTop: "-700px"}}>
                            <div style={{height:"120px", background: "transparent"}}>&nbsp;</div>
                            <div name="DisplayWelcome" style={{marginLeft: "20px"}}>
                                <label style={{fontSize: "32px", color: "black"}}>Welcome back, {first_name}!</label>
                                <div className="flex">
                                    <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/user.gif" style={{height: "75px"}}/>
                                    <div className="grid" style={{height: "60px", marginTop: "5px"}}>
                                        <div><label style={{fontSize: "24px", marginBottom: "0px", marginLeft: "20px", marginTop: "15px", color: "black"}}>{first_name}&nbsp;{last_name}</label></div>
                                        <div className="flex">
                                            <div style={{fontSize: "14px", color: "red", marginTop: "0px", marginLeft: "23px", cursor: "pointer", textDecorationLine: "underline"}} onClick={logoutClick}>logout</div>
                                            <div style={{fontSize: "14px", color: "red", marginTop: "0px", marginLeft: "50px", cursor: "pointer", textDecorationLine: "underline"}} onClick={ResetPassword}>reset password</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div name="DisplayWelcome" style={{marginTop: "20px", marginLeft: "20px"}}>
                            <div className="grid">
                                <label style={{fontSize: "24px", color: "black"}}>Your enrolled solutions</label>
                                <label style={{fontSize: "10px", color: "black"}}>select your solution below to get started</label>
                            </div>
                            <div className="flex" style={{marginTop: "0px"}}>
                                <div className="homePageApplicationButton tooltip" onClick={e => solutionClicked(e, "Amplify")}>
                                    <img alt="Amplify Messaging Platform" className="homePageApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_inverse_thumbnail.png"/>
                                    <span className="tooltiptext">Amplify Messaging Platform</span>
                                </div>
                                {isAFCUser == true ?
                                <div className="homePageApplicationButton tooltip" onClick={e => solutionClicked(e, "Unify")}>
                                    <img className="homePageApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/Unify_main_logo_1.png"/>
                                    <span className="tooltiptext">Web Site and Landing Page Management</span>
                                </div>
                                :
                                <div/>
                                }
                            </div>
                            <div className="grid" style={{marginTop: "20px"}}>
                                <label style={{fontSize: "18px", width: "350px", color: "black"}}>Coming soon. Contact Alliance Forge for more information</label>
                            </div>
                            <div className="flex" style={{marginTop: "20px"}}>
                                <div className="homePageApplicationButton tooltip">
                                    <img className="homePageComingSoonApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Crystal/AFC_Crystal.png"/>
                                    <span className="tooltiptext">Video and Image Studio</span>
                                </div>
                                <div className="homePageApplicationButton tooltip">
                                    <img className="homePageComingSoonApplicationIcon" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Mosaic/Mosaic%20Logo%20_full_white%20Back.png"/>
                                    <span className="tooltiptext">World Class Data Cloud Data Management</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="homePageLatest" style={{background: "transparent", marginTop: "0px"}}>
                        <div className="flex">
                            <div className="homePageFullWidthTile_Phone grid" style={{overflowY: "hidden", marginLeft: "0px"}}>
                                <div style={{marginTop: "10px", marginLeft: "10px", fontWeight: "bold"}}><label>Upcoming or Most Recent Amplify Events</label></div>
                                <EventList_Home company_id={companyID || 0} style={{maxHeight: "300px"}}/>
                            </div>
                        </div>
                        <div>
                            <div className="homePageFullWidthTile_Phone" style={{overflowY: "hidden"}}>
                                <div className="flex" style={{margin: "10px"}}>
                                    <img style={{height: "150px", width: "150px", borderRadius: "5%", overflow: "hidden"}}
                                        src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/smiling_outdoors.gif"/>

                                    <div style={{marginLeft: "10px", width: "150px", marginTop: "10px"}}>
                                        <div><label style={{fontSize: "18px", fontWeight: "bold", color: "black"}}>Meet your people!</label></div>
                                        <div><label style={{color: "black"}}>Use Unify to create a stunning, full featured landing page for your next event</label></div>
                                    </div>
                                </div>
                                <div style={{width: "350px", height: "3px", background: "lightgray", marginLeft: "10px" }}></div>
                                <div className="flex" style={{margin: "10px"}}>
                                    <img style={{height: "150px", width: "150px", borderRadius: "5%", overflow: "hidden"}}
                                        src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/home/smiling_with_the_cell.gif"/>

                                    <div style={{marginLeft: "10px", width: "200px", marginTop: "5px"}}>
                                        <div><label style={{fontSize: "18px", fontWeight: "bold", color: "black"}}>Introducing, Facets</label></div>
                                        <div><label style={{color: "black"}}>Your images... sized, optimized, and prepared for text messaging, web sites, and social media... in the blink of an eye.</label></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className="homePageFullWidthTile_Phone" style={{overflowY: "hidden"}}>
                                <div className="homePacificTime">
                                    <div style={{marginTop: "10px", marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px", color: "black"}}>Pacific Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold", color: "black"}}>{pacificTime}</label></div>
                                </div>
                                <div className="homeMountainTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px", color: "black"}}>Mountain Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold", color: "black"}}>{mountainTime}</label></div>
                                </div>
                                <div className="homeCentralTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px", color: "black"}}>Central Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold", color: "black"}}>{centralTime}</label></div>
                                </div>
                                <div className="homeEasternTime">
                                    <div style={{marginLeft: "10px", marginBottom: "0px"}}><label style={{fontSize: "12px", color: "black"}}>Eastern Time</label></div>
                                    <div style={{marginTop: "-5px", marginLeft: "10px"}}><label style={{fontSize: "30px", fontWeight: "bold", color: "black"}}>{easternTime}</label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <div className="LogoffImminent_modalWindow">
                        <Session_LogoutImminent />
                    </div>
                </Modal>
            </div>
        </div>
    );
}
