import Login from "./pages/login/Login";
// import Topbar from "./components/topbar/Topbar";
import Dashboard from "./pages/dashboard/Dashboard";
import CampaignDashboard from "./pages/campaign/campaignDashboard";
import CampaignCreate from "./pages/campaign/campaignCreate"
import EventReport from "./pages/event/EventReport";
import EventCreate from "./pages/event/EventCreate"
// import { EventReportContextProvider } from "./context/EventReportContext";
import WIP from "./pages/WIP/WIP";
import UnifyCampaignCreate from "./pages/unify/UnifyCampaignCreate";
import Unify_Home from "./pages/unify/Unify_Home";
import Unify_TemplateSelection from "./pages/unify/Unify_TemplateSelection";
import Password_Management from "./pages/profile/passwordManagement";
import Password_Reset from "./pages/profile/passwordReset";
import HomePage from "./pages/home/HomePage";
import EventEditDesktopOnly from "./pages/WIP/EventEditDesktopOnly";

import { React, useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import { config } from "./constants/global.js";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {SnackbarProvider} from 'notistack';

function App() {
    //const { enqueueSnackbar } = useSnackbar();
    const { jwt, dispatch, refresh } = useContext(AuthContext);

    const reload = async () => {
        await axios.get(
            `${config.accountapi.url}/api/v1/auth/refresh`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
        });
    }

    if (refresh) {
        reload();
    }

    return (
        <Router>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="appBackground">
                    <Routes>
                        <Route path="/login" element={<Login fullscreen={true} />} />
                        <Route path="/amplifyDashboard" element={<Dashboard />} />
                        <Route path="/campaigndashboard" element={<CampaignDashboard />} />
                        <Route path="/campaigncreate" element={<CampaignCreate />} />
                        <Route path="/eventreport" element={<EventReport />} />
                        <Route path="/eventcreate" element={<EventCreate />} />
                        <Route path="/wip" element={<WIP/>} />
                        <Route path="/unifyCampaignCreate" element={<UnifyCampaignCreate />} />
                        <Route path="/unifyHome" element={<Unify_Home />} />
                        <Route path="/unifyTemplateSelection" element={<Unify_TemplateSelection/>} />
                        <Route path="/passwordManagement" element={<Password_Management />} />
                        <Route path="/passwordReset" element={<Password_Reset />} />
                        <Route path="/" element={ <HomePage /> } />
                        <Route path="/EventEditDesktopOnly" element={<EventEditDesktopOnly />} />
                    </Routes>
                </div>
            </LocalizationProvider>
        </Router>
    );
}

export default function IntegrationNotistack() {
    return(
        <SnackbarProvider maxSnack={5}>
            <App />
        </SnackbarProvider>
    );
}
