import { useEffect, useState, useContext, React } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { EventReportContext } from "../../context/EventReportContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";
import generatePDF, {Margin} from 'react-to-pdf';

import EventReporting_SuccessDonutChart from "../../components/events/EventReporting_SuccessDonutChart";
import EventReporting_SuccessBarChart from "../../components/events/EventReporting_SuccessBarChart";

import Modal from "react-modal";
Modal.setAppElement("#root");

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

const EventMembers = ({ event_id }) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { companyID, eventDetails, eventStats } = useContext(EventReportContext);
    const { logoURL } = useContext(CompanyContext);
    const { appRenderType } = useContext(ApplicationContext);

    const [isOpen, setIsOpen] = useState(false);
    const [startedOverall, setStartedOverall] = useState(false);
    const [eventID, setEventID] = useState();

    // state machine
    const [csvFileState, setCSVFileState] = useState("none");
    const [csvStatusString, setCSVStatusString] = useState("empty");
    const [nextState, setNextState] = useState(0);

    const [rawDataRows, setRawDataRows] = useState("none");
    const [memberList, setMemberList] = useState("none");
    const [memberCount, setMemberCount] = useState(0);

    const [downloadedAlready, setDownloadedAlready] = useState(false);
    const [startDownloading, setStartDownloading] = useState(false);
    const [calledDownload, setCalledDownload] = useState(false);

    const [startProcessing, setStartProcessing] = useState(false);
    const [calledProcess, setCalledProcess] = useState(false);

    const [startFetching, setStartFetching] = useState(false);
    const [calledAPI, setCalledAPI] = useState(false);

    // reset all state vars from any previous runs
    const startNewDownload = () => {
        setEventID(event_id);
        setRawDataRows("none");
        setMemberList("none");
        setMemberCount(0);

        setDownloadedAlready(false);
        setStartDownloading(false);
        setCalledDownload(false);

        setStartProcessing(false);
        setCalledProcess(false);

        setStartFetching(false);
        setCalledAPI(false);

        // set state vars to start it
        setIsOpen(true);
        setStartedOverall(true);
        setCSVFileState("start");
        setCSVStatusString("start");
        setNextState(nextState + 1);
    };

    useEffect(() => {
        if (isOpen && !startedOverall && csvFileState == "none") {
            setCSVFileState("start");
            setStartedOverall(true);
            setDownloadedAlready(false);
            setCalledDownload(false);
            setStartProcessing(false);
            setCalledProcess(false);
            setStartFetching(false);
            setCalledAPI(false);
        }
    }, [isOpen]);

    // state machine
    useEffect(() => {
        if (eventID == event_id) {
            if (startedOverall) {
                if (csvFileState == "start") {
                    setCSVStatusString("Starting");
                    setStartFetching(true);
                    setCSVFileState("fetching");
                } else if (csvFileState == "fetching") {
                    // determine if fetching is complete AND needs processing
                    if (rawDataRows != "none") {
                        // fetch completed
                        if (memberList == "none") {
                            setCSVStatusString("Processing");
                            setStartProcessing(true);
                            setCSVFileState("processing");
                        }
                    }
                } else if (csvFileState == "processing") {
                    if (memberList != "none") {
                        // completed processing
                        setCSVStatusString("Downloading");
                        setDownloadedAlready(false);
                        setStartDownloading(true);
                        setCSVFileState("downloading");
                    }
                } else if (csvFileState == "downloading") {
                    if (downloadedAlready == "true") {
                        setCSVFileState("completed");
                    }
                }
            }
        }
    }, [csvFileState, nextState]);

    // download data file
    useEffect(() => {
        if (eventID == event_id) {
            if (startDownloading && !calledDownload) {
                setStartDownloading(false);
                setCalledDownload(true);
                downloadCSV();
                setIsOpen(false);
            }
        }
    }, [startDownloading]);

    // process data file to CSV format
    useEffect(() => {
        if (eventID == event_id) {
            if (startProcessing && !calledProcess) {
                setStartProcessing(false);
                setCalledProcess(true);
                processingEventMemberData(event_id);
            }
        }
    }, [startProcessing]);

    // fetch file via API
    useEffect(() => {
        if (eventID == event_id) {
            if (startFetching && !calledAPI) {
                setStartFetching(false);
                setCalledAPI(true);
                getEventMembers(companyID, event_id);
            }
        }
    }, [startFetching]);


    // generate CSV structure
    const processingEventMemberData = () => {
        // create a new list of data desired from API call and store it for app to use
        const csvHeader = [
            { label: 'FName', key: 'FName' },
            { label: 'LName', key: 'LName' },
            { label: 'MobilePhone', key: 'MobilePhone' },
            { label: 'Party', key: 'Party' },
            { label: 'StateAbbr', key: 'StateAbbr' },
            { label: 'StateVoterID', key: 'StateVoterID' },
            { label: 'Date Sent', key: 'date_sent' },
            { label: 'Error Description', key: 'errorDescription' },
            { label: 'Error Details', key: 'error_details' },
            { label: 'Status', key: 'status' },
        ];

        // data rows are in res.data.values
        setMemberList(arrayToCSV(csvHeader, rawDataRows));
        setNextState(nextState + 1);
    };

    // Get event data from API
    const getEventMembers = async (company_id, event_id) => {
        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectnewrawlistafterposting?correlation_id=${event_id}&company_id=${company_id}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
            setCSVFileState("error");
        })
        .then(function (response) {
            setMemberCount(response.data.values.length);
            setRawDataRows(response.data.values);
        });

        setNextState(nextState + 1);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    // sends file to browser
    const downloadCSV = () => {
        if (downloadedAlready == false) {
            setDownloadedAlready(true);
            const blob = new Blob([memberList], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = eventDetails.campaign+"-"+eventDetails.name+"-"+eventDetails.project_code+"-"+event_id+"-"+"members.csv";
            link.click();
            setCSVFileState("downloaded");
        } else {
            console.log("already downloaded");
        }
        setNextState(nextState + 1);
    };

    // Function to convert the JSON(Array of objects) to CSV.
    const arrayToCSV = (headers, data) => {
        const csvRows = [];

        // getting headers.
        const headerValues = headers.map(header => header.label);
        csvRows.push(headerValues.join(','));

        // Push into array as comma separated values.
        // Getting rows.
        for (const row of data) {
            const rowValues = headers.map(header => {
            const escaped = ('' + row[header.key]).replace(/"/g, '\\"'); // To replace the unwanted quotes.
            return `"${escaped}"` // To escape the comma in a address like string.
            });

            csvRows.push(rowValues.join(',')); // Push into array as comma separated values.
        }

        return csvRows.join('\n'); // To enter the next rows in the new line '\n'
        // EDR - unsure why this line is here
        //setNextState(nextState + 1);
    };

    function requestedClose() {
        setIsOpen(false);
    }

    //EXEC REPORT GENERATION
    const getTargetElement = () => document.getElementById('dashReport');

    const options = {
       // default is `save`
       method: 'save',
       // default is Resolution.MEDIUM = 3, which should be enough, higher values
       // increases the image quality but also the size of the PDF, so be careful
       // using values higher than 10 when having multiple pages generated, it
       // might cause the page to crash or hang.
       resolution: 1,
       page: {
          // margin is in MM, default is Margin.NONE = 0
          margin: Margin.SMALL,
          // default is 'A4'
          format: 'letter',
          // default is 'portrait'
          orientation: 'landscape',
       },
       canvas: {
          // default is 'image/jpeg' for better size performance
          mimeType: 'image/jpeg',
          qualityRatio: 1
       },
       // Customize any value passed to the jsPDF instance and html2canvas
       // function. You probably will not need this and things can break,
       // so use with caution.
        overrides: {
       //    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
           pdf: {
                compress: true,
           },
        },
        output: {
           options: {
                filename: eventDetails.name + '.pdf',
           },
        }
       //    // see https://html2canvas.hertzen.com/configuration for more options
       //    canvas: {
       //       useCORS: true
       //    }
       // },
    };

    return (
        <div className={`flex
                            ${appRenderType == "phone" ?
                                  "dashboardTileReport_Phone bg-white rounded-none ml-5 pl-5 pt-5 pb-5 pr-5"
                                : "dashboardHalfHeightTile rounded-none ml-5 pl-5 pt-5 pb-5 pr-5"
                            }`} style={{overflow: "hidden"}}>

            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="flex">
                <div style={{margin: "50px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#74dbff" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8M12 19.8V12M16 17l-4 4-4-4"/>
                    </svg>
                </div>
                <div className="grid" style={{margin: "00px 0px 0px 35px", height: "80px"}}>
                    <div className="dashboardTileTitle">Download Event Reports</div>
                    <div>
                        <button className="btn btn_accent" style={{margin: "10px 0px 5px 0px", width: "240px", fontSize: "12px"}} onClick={() => generatePDF(getTargetElement, options)}>Build Executive Summary PDF</button>
                    </div>
                    <div>
                        <button className="btn btn_accent" style={{margin: "5px 0px 10px 0px", width: "240px", fontSize: "12px"}} id="csvbutton" onClick={startNewDownload}>Build Members Detail CSV</button>
                        <Modal
                            isOpen={isOpen}
                            onRequestClose={requestedClose}
                            contentLabel="My Dialog"
                            className="modal download-modal">
                            <div>My Modal Dialog</div>
                            <div>event_id: {event_id}</div>
                            <div>status: {csvStatusString}</div>
                            <div>row count: {memberCount}</div>
                        </Modal>
                    </div>
                    {/*<div className="dashboardTileSubtext">Collect all members in this event</div>*/}
                </div>
            </div>
            :
            <div className="flex">
                <div style={{margin: "50px 0px 0px -5px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#74dbff" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8M12 19.8V12M16 17l-4 4-4-4"/>
                    </svg>
                </div>
                <div className="grid" style={{margin: "00px 0px 0px 35px", height: "80px"}}>
                    <div className="dashboardTileTitle">Download Event Reports</div>
                    <div>
                        <button className="btn btn_accent" style={{margin: "10px 0px 5px 0px", width: "240px", fontSize: "12px"}} onClick={() => generatePDF(getTargetElement, options)}>Build Executive Summary PDF</button>
                    </div>
                    <div>
                        <button className="btn btn_accent" style={{margin: "5px 0px 10px 0px", width: "240px", fontSize: "12px"}} id="csvbutton" onClick={startNewDownload}>Build Members Detail CSV</button>
                        <Modal
                            isOpen={isOpen}
                            onRequestClose={requestedClose}
                            contentLabel="My Dialog"
                            className="modal download-modal">
                            <div>My Modal Dialog</div>
                            <div>event_id: {event_id}</div>
                            <div>status: {csvStatusString}</div>
                            <div>row count: {memberCount}</div>
                        </Modal>
                    </div>
                    {/*<div className="dashboardTileSubtext">Collect all members in this event</div>*/}
                </div>
            </div>
            }

            <div id="dashReport" style={{height: "9380px", marginLeft: "100px"}}>

                <div className="reportPage" id="repPage1">
                    <img src={process.env.PUBLIC_URL + "/images/report_page-1.1.gif"}/>

                    <div style={{ position: "relative", marginTop: "-1500px", marginLeft: "50px"}}>
                        <img className='object-cover' src={logoURL} style={{width: "400px", height: "400px", border: "5px solid #555"}}/>
                    </div>

                    <div className="accountHeaderCompanyName grid" style={{ position: "relative", marginTop: "250px", marginLeft: "50px"}}>
                        <div className="reportTitlePageTitle">event completion report</div>
                        <div className="reportTitlePageDate" style={{marginTop: "20px"}}>{eventDetails.dtz_string}</div>
                        <div className="reportTitlePageSubTitle" style={{marginTop: "20px"}}>{eventDetails.name}</div>
                    </div>

                    <div style={{position: "relative", marginTop: "320px", marginLeft: "40px"}}>
                        <label style={{color: "white", fontSize: "14px", marginLeft: "10px"}}>powered by</label>
                        <img style={{width: "200px", marginTop: "10px"}} src={process.env.PUBLIC_URL + "/images/Amplify_transaprent.png"}/>
                    </div>


                </div>

                <div className="reportPage" id="repPage2">
                    {/*}<img src={"https://media2.giphy.com/media/3orieS4jfHJaKwkeli/giphy.gif"}/>{*/}
                    <div>
                        <img src={process.env.PUBLIC_URL + "/images/lifecycle.gif"}/>
                    </div>

                    <div className="flex" style={{ position: "relative", marginTop: "-100px", marginLeft: "00px", zIndex: "10"}}>

                        <div >
                            <img src={logoURL} style={{width: "100px", height: "100px", border: "5px solid #555"}}/>
                        </div>

                        <div style={{ position: "relative", marginLeft: "550px", marginTop: "30px"}}>
                            <label style={{fontSize: "14px", color: "black"}}>- CONFIDENTIAL - DO NOT REPLICATE OR DISTRIBUTE WITHOUT WRITTEN PERMISSION -</label>
                        </div>

                        <div style={{ position: "relative", marginLeft: "770px", marginTop: "30px"}}>
                            <label style={{fontSize: "14px", color: "black"}}>- page 1 -</label>
                        </div>
                    </div>


                </div>

                <div className="reportPage" id="repPagemsgOverview" style={{marginTop: "50px"}}>

                    <img src={process.env.PUBLIC_URL + "/images/MsgStats.gif"}/>

                    <div className="reportTitlePageTitle" style={{marginTop: "-1525px", marginLeft: "100px", color: "black"}}>message overview</div>

                    <div className="reportMsgOverview">
                        <div style={{ width: "1250px"}}>
                            <table >
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="reportEventResultsHeaderTitle">Event Name:</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4">
                                            <div className="reportEventResultsHeaderEventTitle">{eventDetails.name}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{marginTop: "20px"}}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="reportEventResultsHeaderTitle">Campaign Name:</div>
                                        </td>
                                        <td>
                                            <div className="reportEventResultsHeaderTitle">Event Status:</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="reportEventResultsHeaderBody" style={{width: "395px"}}>{eventDetails.campaign}</div>
                                        </td>
                                        <td>
                                            <div className="reportEventResultsHeaderBody">{eventDetails.status}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="reportEventResultsHeaderTitle">Event ID:</div>
                                        </td>
                                        <td>
                                            <div className="reportEventResultsHeaderTitle">Event Date/Time:</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="reportEventResultsHeaderBody">{eventDetails.id}</div>
                                        </td>

                                        <td>
                                            <div className="reportEventResultsHeaderBody">{eventDetails.dtz_string}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div>
                            <div className="flex" style={{marginTop: "20px"}}>
                                <div style={{width: "300px", maxHeight: "500px", textOverflow: "ellipsis"}}>
                                    <div className="reportEventResultsHeaderTitle" style={{fontWeight: "bold"}}>RAW SMS:</div>
                                    <label className="reportEventResults_SMSBody">{eventDetails.msg_body}</label>
                                </div>
                                <div style={{width: "300px", marginLeft: "100px", maxHeight: "500px", textOverflow: "ellipsis"}}>
                                    <div className="reportEventResultsHeaderTitle" style={{fontWeight: "bold"}}>FORMATTED SMS:</div>
                                    <div className="reportEventResults_SMSBody">{eventDetails.msg_body_formatted}</div>
                                </div>
                            </div>
                            <div style={{marginTop: "30px"}}>
                                <div className="reportEventResultsHeaderTitle">SMS Body Length</div>
                                <div className="reportEventResultsHeaderBody" style={{fontSize: "24px"}}>{eventDetails.MsgBody_Length}</div>
                            </div>
                            <div>
                                <div className="reportEventResultsHeaderTitle">SMS Segment Count</div>
                                <div className="reportEventResultsHeaderBody" style={{fontSize: "24px"}}>{eventDetails.MsgBody_SegmentCount}</div>
                            </div>
                        </div>

                    </div>

                    <div className="flex" style={{ position: "relative", marginTop: "100px", marginLeft: "00px", zIndex: "10"}}>

                        <div >
                            <img src={logoURL} style={{width: "100px", height: "100px", border: "5px solid #555"}}/>
                        </div>

                        <div style={{ position: "relative", marginLeft: "550px", marginTop: "30px"}}>
                            <label style={{fontSize: "14px", color: "black"}}>- CONFIDENTIAL - DO NOT REPLICATE OR DISTRIBUTE WITHOUT WRITTEN PERMISSION -</label>
                        </div>

                        <div style={{ position: "relative", marginLeft: "770px", marginTop: "30px"}}>
                            <label style={{fontSize: "14px", color: "white"}}>- page 2 -</label>
                        </div>
                    </div>


                </div>

                <div className="reportPage" id="repPage3">
                    <img src={process.env.PUBLIC_URL + "/images/report_page-2.gif"}/>

                    <div style={{ position: "relative", marginTop: "-1440px", marginLeft: "1120px"}}>
                        <label className="reportData">{eventStats.msg_sent_count.toLocaleString()}</label>
                    </div>

                    <div style={{ position: "relative", marginTop: "100px", marginLeft: "1720px"}}>
                        <label className="reportData" style={{color: "white", fontSize: "72px"}}>{eventStats.msg_perc_successful_count.toFixed(2)}% </label>
                    </div>

                    <div style={{ position: "relative", marginTop: "-60px", marginLeft: "1120px"}}>
                        <label className="reportData" style={{color: "white"}}>{eventStats.msg_success_count.toLocaleString()}</label>
                    </div>

                    <div style={{ position: "relative", marginTop: "100px", marginLeft: "1720px"}}>
                        <label className="reportData" style={{color: "black", fontSize: "72px"}}>{eventStats.msg_perc_opt_out_count.toFixed(2)}% </label>
                    </div>

                    <div style={{ position: "relative", marginTop: "-60px", marginLeft: "1120px"}}>
                        <label className="reportData">{eventStats.msg_opt_out_count.toLocaleString()}</label>
                    </div>

                    <div style={{ position: "relative", marginTop: "120px", marginLeft: "1120px"}}>
                        <label className="reportData" style={{color: "white"}}>{eventStats.msg_member_cleaned_count.toLocaleString()}</label>
                    </div>

                    <div className="flex" style={{ position: "relative", marginTop: "130px", marginLeft: "00px", zIndex: "10"}}>

                        <div >
                            <img src={logoURL} style={{width: "100px", height: "100px", border: "5px solid #555"}}/>
                        </div>

                        <div style={{ position: "relative", marginLeft: "550px", marginTop: "30px"}}>
                            <label style={{fontSize: "14px", color: "black"}}>- CONFIDENTIAL - DO NOT REPLICATE OR DISTRIBUTE WITHOUT WRITTEN PERMISSION -</label>
                        </div>

                        <div style={{ position: "relative", marginLeft: "770px", marginTop: "30px"}}>
                            <label style={{fontSize: "14px", color: "black"}}>- page 3 -</label>
                        </div>
                    </div>

                </div>

                <div className="reportPage" style={{position: "relative", marginTop: "20px"}} id="repPage4">

                    <img src={process.env.PUBLIC_URL + "/images/report_page-3.gif"}/>

                    <div className="reportTitlePageTitle" style={{marginTop: "-1600px", color: "black"}}>{eventDetails.name}</div>

                    <div className="flex" style={{position: "relative", marginTop: "255px"}}>
                        <div style={{ position: "relative", marginTop: "00px", marginLeft: "30px"}}>
                            <EventReporting_SuccessDonutChart/>
                        </div>

                        <div style={{ position: "relative", marginTop: "00px", marginLeft: "30px"}}>
                            <EventReporting_SuccessBarChart />
                        </div>
                    </div>

                    <div className="flex" style={{ position: "relative", marginTop: "330px", marginLeft: "00px", zIndex: "10"}}>

                        <div >
                            <img src={logoURL} style={{width: "100px", height: "100px", border: "5px solid #555"}}/>
                        </div>

                        <div style={{ position: "relative", marginLeft: "550px", marginTop: "30px"}}>
                            <label style={{fontSize: "14px", color: "black"}}>- CONFIDENTIAL - DO NOT REPLICATE OR DISTRIBUTE WITHOUT WRITTEN PERMISSION -</label>
                        </div>

                        <div style={{ position: "relative", marginLeft: "770px", marginTop: "30px"}}>
                            <label style={{fontSize: "14px", color: "black"}}>- page 4 -</label>
                        </div>
                    </div>


                </div>

                <div className="reportPage" id="repPageThankYou">

                    <img src={process.env.PUBLIC_URL + "/images/ThankYou.gif"}/>


                    <div style={{ position: "relative", marginTop: "-1500px", marginLeft: "1700px"}}>
                        <img className='object-cover' src={logoURL} style={{width: "250px", height: "250px", border: "5px solid #555"}} />
                    </div>

                    <div style={{position: "relative", marginTop: "900px", marginLeft: "1785px"}}>
                        <label style={{color: "white", fontSize: "14px", marginLeft: "10px"}}>powered by</label>
                        <img style={{width: "200px", marginTop: "10px"}} src={process.env.PUBLIC_URL + "/images/Amplify_transaprent.png"}/>
                    </div>
                </div>
{/*
                <div className="reportPage" id="repPageThankYou">

                    <img src={"https://af-cust-logos.us-southeast-1.linodeobjects.com/Arena.gif"}/>
                    <img className='object-cover' src={logoURL} style={{width: "250px", height: "250px", border: "5px solid #555"}}/>

                    Hello World

                </div>*/}

            </div>



        </div>
    )
};

EventMembers.propTypes = {
    event_id: PropTypes.string.isRequired
};

export default EventMembers;
