import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { CompanyContext } from "./CompanyContext";

// Highcharts
import Highcharts from 'highcharts';
//import HighchartsReact from 'highcharts-react-official';

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { v4 as uuidv4 } from 'uuid';

export const EventReportContext = createContext();

export const EventReportContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { companyID, companyName } = useContext(CompanyContext);

    const [isLoaded, setIsLoaded] = useState(false);
    const [eventID, setEventID] = useState(0);

    const [groupData, setGroupData] = useState(0);
    const [detailData, setDetailData] = useState(0);
    const [apiValues, setAPIValues] = useState({
        eventName: '',
        msgSentCount: -1,
        msgErrCount: 0,
        msgSuccessCount: 0,
        msgOptOutCount: 0
    });

    const [loading, setLoading] = useState(true);
    const [eventStatus, setEventStatus] = useState('');

    const [eventDetails, setEventDetails] = useState(
    {
        id: 0,
        name: '',
        campaign: '',
        project_code: '',
        msg_body: '',
        msg_body_formatted: '',
        msg_mms: '',
        tz_name: '',
        utc_start_time: '00:00:00',
        utc_adjustment: 0,
        dtz_string: '',
        MsgBody_Length: 0,
        MsgBody_SegmentCount: 0,
        status: ''
    });

    const [eventStats, setEventStats] = useState(
    {
        msg_sent_count: 0,
        msg_err_count: 0,
        msg_opt_out_count: 0,
        msg_perc_opt_out_count: 0,
        msg_success_count: 0,
        msg_perc_successful_count: 0,
        msg_member_cleaned_count: 0
    });

    const [displayReport, setDisplayReport] = useState(true);
    const [displayReportId, setDisplayReportId] = useState('');

    useEffect(() => {
        if (isLoaded == false && eventID > 0) {
            getStats(eventDetails.id);
        }
    });

    useEffect(() => {
        //initialize the base objects
        setEventDetails({
            id: 0,
            name: '',
            campaign: '',
            project_code: '',
            msg_body: '',
            msg_body_formatted: '',
            msg_mms: '',
            tz_name: '',
            utc_start_time: '00:00:00',
            utc_adjustment: 0,
            dtz_string: '',
            MsgBody_Length: 0,
            MsgBody_SegmentCount: 0,
            status: '',
        });

        setEventStats({
            msg_sent_count: 0,
            msg_err_count: 0,
            msg_opt_out_count: 0,
            msg_perc_opt_out_count: 0,
            msg_success_count: 0,
            msg_perc_successful_count: 0,
            msg_member_cleaned_count: 0
        })

        setAPIValues({
            eventName: '',
            msgSentCount: -1,
            msgErrCount: 0,
            msgSuccessCount: 0,
            msgOptOutCount: 0
        })

        setLoading(true);
        setDisplayReport(true);

        setGroupData([]);
        setDetailData([]);

        checkDisplayReportStatus(eventID, companyID);
    }, [eventID]);

    useEffect(() => {
        if (!displayReport) {
            return;
        }

        getStats(eventID);
    }, [displayReportId])

    async function checkDisplayReportStatus(eventID, companyID){
        if (!eventID || companyID <= 0) {
            return;
        }

        var tempAPIValues;
        await axios.get(
            `${config.reportapi.url}/api/v1/event/eventmessagecarrierstatusheader?correlation_id=${eventID}&company_id=${companyID}`,
            { headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            if (!response || !response.data) {
                return;
            }

            tempAPIValues = response.data.values;

            let thisEventDetails = { utc_start_time: tempAPIValues[0].UTCStartTime };
            var _tStart = dayjs.utc(thisEventDetails.utc_start_time.replace(" ", "T"));
            var _tCurrent = dayjs.utc();
            var _tDiff = _tCurrent.diff(_tStart, 'hour');

            if(_tDiff <= 2){
                setDisplayReport(false);
                setLoading(false);
            }
            else{
                setDisplayReport(true);
            }

            //sets an id for the data collect process - triggers the use effect
            setDisplayReportId( uuidv4() );
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    // Set the proper date-timezone-string
    function createDTZString(utc_start_time, tz_name) {
        // UTC will only init with a list of date/time components
        // so, split date time string into components for UTC
        const tempDTArray = utc_start_time.split(' ');
        const tempDateArray = tempDTArray[0].split('-');
        const tempTimeArray = tempDTArray[1].split(':');

        // create date object with proper UTC time
        const dts = new Date(
            Date.UTC(tempDateArray[0], tempDateArray[1]-1, tempDateArray[2],
                tempTimeArray[0], tempTimeArray[1], tempTimeArray[2])
        );

        // determine the timezone we need
        const tzPrefix = tz_name.split(' ', 1);
        let tzString = "";
        if (tzPrefix[0] == "Eastern") {
            tzString = "America/New_York";
        } else if (tzPrefix[0] == "Central") {
            tzString = "America/Chicago";
        } else if (tzPrefix[0] == "Mountain") {
            tzString = "America/Denver";
        } else if (tzPrefix[0] == "Pacific") {
            tzString = "America/Los_Angeles";
        }
        // get date time string for proper timezone (still missing timezone string though)
        const properTimeString = dts.toLocaleString("en-US", { timeZone: tzString });

        // determine Standard or Daylight time
        const isDST = new Date().getTimezoneOffset() < 0;
        const correctTZName = tzPrefix + (isDST ? " Standard Time" : " Daylight Time");

        // add Day of Week, just for grins
        const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const dow = dts.getDay();

        // finally have our string!
        const completeDTZString = days[dow] + " " + properTimeString + " " + correctTZName;

        return completeDTZString;
    }

    // Get stats data from API
    const getStats = async (eventID) => {
        if (eventID > 0 && companyID > 0) {
            setLoading(true);

            var tempAPIValues;
            await axios.get(
                `${config.reportapi.url}/api/v1/event/eventmessagecarrierstatusheader?correlation_id=${eventID}&company_id=${companyID}`,
                {headers: { Authorization: "Bearer " + jwt }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // console.log("EventReportContext: getStats(): response ", response)
                tempAPIValues = response.data.values;
            });

            var donutAPIValues;
            await axios.get(
                `${config.reportapi.url}/api/v1/event/collectAmplifyResultsDialLine?correlation_id=${eventID}&company_id=${companyID}`,
                {headers: { Authorization: "Bearer " + jwt }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                donutAPIValues = response.data.values;
            });

            setIsLoaded(true);

            let thisEventDetails = {
                id: tempAPIValues[0].CorrelationID,
                name: tempAPIValues[0].EventName.replace(/-\d{3}$/,""),
                campaign: tempAPIValues[0].CampaignName,
                project_code: tempAPIValues[0].MsgProjectCode,
                msg_body: tempAPIValues[0].MsgBody,
                msg_body_formatted: tempAPIValues[0].msgBodyFormatted,
                msg_mms: tempAPIValues[0].MsgMMS,
                tz_name: tempAPIValues[0].TimeZoneName,
                utc_start_time: tempAPIValues[0].UTCStartTime,
                utc_adjustment: parseFloat(tempAPIValues[0].UTCAdjustment),
                dtz_string:'',
                status:'',
                MsgBody_Length: tempAPIValues[0].MsgBody_Length,
                MsgBody_SegmentCount: tempAPIValues[0].MsgBody_SegmentCount,
            };

            thisEventDetails.dtz_string = createDTZString(thisEventDetails.utc_start_time, thisEventDetails.tz_name);

            if(thisEventDetails.dtz_string != '')
            {
                thisEventDetails.status = "COMPLETE";
            }
            else
            {
                thisEventDetails.status = "IN PROCESS";
            }

            setEventDetails(thisEventDetails);

            let thisEventStats = {
                msg_sent_count: parseFloat(tempAPIValues[0].MemberCount),
                msg_err_count: parseFloat(tempAPIValues[0].Error),
                msg_opt_out_count: parseFloat(tempAPIValues[0].OptOutCount),
                msg_perc_opt_out_count: parseFloat(tempAPIValues[0].PercOptOut),
                msg_success_count: parseFloat(tempAPIValues[0].Successful),
                msg_perc_successful_count: parseFloat(tempAPIValues[0].PercSuccessful),
                msg_member_cleaned_count: parseFloat(tempAPIValues[0].membersCleaned),
            };


            //  Re-organize the data received
            // --------------------------------------------------------------------
            let eventValues = {
                eventName: '',
                msgSentCount: 0,
                msgErrCount: 0,
                msgSuccessCount: 0,
                msgOptOutCount: 0
            };

            var dataGroups = {};

            var drillDataLen = donutAPIValues.length;
            for (let j = 0; j < drillDataLen; j += 1) {

                var groupName = donutAPIValues[j].msgResult;

                let detail = {
                        name: donutAPIValues[j].msgError,
                        y: parseFloat(donutAPIValues[j].totalCount),
                        color: 0
                    };

                if (!(groupName in dataGroups)) {
                    dataGroups[groupName] = {details: [], total: 0};
                }

                dataGroups[groupName].details.push(detail);
                dataGroups[groupName].total += detail.y;
            }

            // Create structs for pie charts
            var groupData = [];
            var detailData = [];
            var colors = Highcharts.getOptions().colors;

            let groupList = Object.keys(dataGroups);
            let groupListLen = groupList.length;
            for (let g = 0; g < groupListLen; g += 1) {
                let groupName = groupList[g];
                let groupDict = {name: groupName, y: dataGroups[groupName].total, color: colors[g]};
                groupData.push(groupDict);

                let dataGroupDetails = dataGroups[groupName].details;
                let detailLen = dataGroupDetails.length;
                for (let d = 0; d < detailLen; d += 1) {
                    dataGroupDetails[d].color = colors[g];
                    detailData.push(dataGroupDetails[d]);
                }

                // set bar chart values
                if (groupName == "removed") {
                    eventValues.msgErrCount += dataGroups[groupName].total;
                }
            }

            setEventStats(thisEventStats);
            setGroupData(groupData);
            setDetailData(detailData);
            setAPIValues(eventValues);

            setLoading(false);
        }
    }


    return (
        <EventReportContext.Provider value={{companyID:                         companyID,
                                             companyName:                       companyName,
                                             eventID:                           eventID,
                                             setEventID:                        setEventID,
                                             eventDetails:                      eventDetails,
                                             setEventDetails:                   setEventDetails,
                                             eventStats:                        eventStats,
                                             setEventStats:                     setEventStats,
                                             loading:                           loading,
                                             groupData:                         groupData,
                                             detailData:                        detailData,
                                             apiValues:                         apiValues,
                                             setEventStatus:                    setEventStatus,
                                             eventStatus:                       eventStatus,
                                             displayReport:                     displayReport
                                            }}>
            {children}
        </EventReportContext.Provider>
    );
}
