import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import { CampaignContext } from "../../context/CampaignContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";

// Datatables
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import JSZip from 'jszip';
window.JSZip = JSZip;
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone)

//NOTIFICATIONS OF COMPLETION
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

$.Datatable = require("datatables.net");
$.DataTable = require('datatables.net-select');
$.DataTable = require('datatables.net-buttons');
$.DataTable = require('datatables.net-buttons-dt');
$.DataTable = require('datatables.net-buttons/js/buttons.colVis');
$.DataTable = require('datatables.net-buttons/js/buttons.html5');
$.DataTable = require('datatables.net-buttons/js/buttons.print');
$.DataTable = require('pdfmake');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

$.Datatable = require("datatables.net");

const CampaignEventList = () => {
    const { isMobileDisplay } = useContext(AuthContext);
    const { windowWidth } = useContext(ApplicationContext);
    const { campaignEvents, setCampaignID, campaignID, companyID,
            loadingCampaignEvents, loadingAllBusinessEvents,
            legacyAmplify } = useContext(CampaignContext);
    const { getExistingEvent } = useContext(AmplifyEventContext);

    const navigate = useNavigate();
    const tableRef = useRef();
    const [isMounted, setIsMounted] = useState(false);

    const [openErrorSnackAlert, setOpenErrorSnackAlert] = useState(false);
    const [errorSnackAlertMessage, setErrorSnackAlertMessage] = useState('');

    //eslint-disable-next-line
    var loadingEvent = false;

    useEffect(() => {
        if (isMounted || campaignEvents.length <= 0)
            return

        //var domVals = 'tip';
        var domVals = 'ti';
        if (campaignEvents.length > 15) {
            domVals = 'ltip';
        }

        setIsMounted(true);
        if (isMobileDisplay) {
            $(tableRef.current).DataTable({
                data: [],
                dom: 'ltip',
                columns: [
                    { defaultContent: '<i>(undefined)</i>', width: '325px', title: 'Project', visible: false },
                    { defaultContent: '<i>(undefined)</i>', width: '375px', title: 'Event Name' },
                    { defaultContent: '<i>(undefined)</i>', width: '200px', className: 'dt-body-right', title: 'Count'},
                    { defaultContent: '<i>(undefined)</i>', width: '200px', className: 'dt-body-right', title: 'Start Date'},
                    { defaultContent: '<i>(undefined)</i>', width: '200px', className: 'dt-body-right', title: 'Start', visible: false },
                    // { width: '50px', className: 'dt-body-right', title: '' },
                    { width: '150px', className: 'dt-body-right', title: 'Status' },
                ],
                order: [[3, 'desc'],[4, 'desc'],[5, 'desc']],
            });
        } else {
            // https://datatables.net/reference/option/dom
            $(tableRef.current).DataTable({
                data: [],
                lengthMenu: [10, 15, 20, 25],
                dom: domVals,
                pageLength:15,
                autoWidth: true,
                columns: [
                    { defaultContent: '<i>(undefined)</i>', width: '200px', title: 'Project' },
                    { defaultContent: '<i>(undefined)</i>', width: '425px', title: 'Event Name' },
                    { defaultContent: '<i>(undefined)</i>', width: '100px', className: 'dt-body-right', title: 'Count'},
                    { defaultContent: '<i>(undefined)</i>', width: '100px', className: 'dt-body-right', title: 'Start Date'},
                    { defaultContent: '<i>(undefined)</i>', width: '100px', className: 'dt-body-right', title: 'Start' },
                    { defaultContent: '<i>(undefined)</i>', width: '50px', className: 'dt-body-right', title: '' },
                    { defaultContent: '<i>(undefined)</i>', width: '150px', className: 'dt-body-right', title: 'Status' },
                    { defaultContent: '<i>(undefined)</i>', width: '150px', className: 'dt-body-right', title: 'CorrelationID', visible: false },
                    { defaultContent: '<i>(undefined)</i>', width: '150px', className: 'dt-body-right', title: 'Mongo Key', visible: false },
                    { defaultContent: '<i>(undefined)</i>', width: '150px', className: 'dt-body-right', title: 'UTC Timestamp', visible: false },
                ],
                order: [[9, 'desc'], [3, 'desc'],[4, 'desc'],[5, 'desc']],
            });
        }

        // push data rows into Datatable
        $(tableRef.current).dataTable().fnAddData(campaignEvents);

        // define 'onClick' event handler for each row
        $(tableRef.current).dataTable().off().on('click', 'tr', function(){
            // get the row data
            var rowdata = $(tableRef.current).dataTable().api().row(this).data();
            if (typeof rowdata !== 'undefined') {
                var _status = rowdata[6];
                var _eventID = rowdata[7];
                var _key = rowdata[8];
                var _compId = companyID;
                var _campId = campaignID;
                setExistingEventAndLoad(_status, _eventID, _compId, _campId, _key);
            }
        })

    }, [campaignEvents]);

    async function setExistingEventAndLoad(status, eventID, companyID, campaign_id, eventKey){
        //this will only happen with Mongo based events
        if (status.toLowerCase() == "complete" ||
            status.toLowerCase() == "committed" ||
            status.toLowerCase() == "committing" ||
            status.toLowerCase() == "commit" ||
            windowWidth < 1600)
        {
                navigate("/eventreport?event_key="+eventID+"&event_status="+status.toUpperCase()+"&company_id="+companyID);
        } else {
            if (legacyAmplify == false) {
                loadingEvent = true;
                setCampaignID(campaign_id);

                await getExistingEvent(eventKey);

                navigate("/eventcreate");
                loadingEvent = false;
            } else {
                setErrorSnackAlertMessage("unable to open legacy events in a state other than 'complete'");
                setOpenErrorSnackAlert(true);
            }
        }
    }

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpenErrorSnackAlert(false);
    };

    return (

        <div className="grid">
            <div className="eventList">
                <div className="eventListTable">
                    <table ref={tableRef} className="compact hover stripe dataTable_wrapper" style={{cursor: "pointer", padding: "20px 10px 0px 10px"}}></table>
                </div>
            </div>
            {(loadingCampaignEvents == true) || (loadingAllBusinessEvents == true) ?
                (
                    <div className="componentLoading" style={{margin: "0px 0px 0px 0px"}}>
                        <div className="loading loading-bars loading-lg" style={{ marginTop: "150px", marginLeft: "335px",
                            width: "350px", height: "350px"}} />
                        {/*<label>hello World</label>*/}
                    </div>
                )
                :
                (
                    <div></div>
                )
            }

            <Snackbar open={openErrorSnackAlert} autoHideDuration={6000} onClose={handleSnackBarClose}>
                <Alert
                      onClose={handleSnackBarClose}
                      severity="error"
                      variant="filled"
                      sx={{ width: '100%' }}
                    >
                  {errorSnackAlertMessage}
                </Alert>
            </Snackbar>

        </div>

    );
};

export default CampaignEventList;
