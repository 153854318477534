import { useContext } from "react";
import { EventReportContext } from "../../context/EventReportContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);

const EventReporting_SuccessBarChart = () => {
    const { eventDetails, eventStats } = useContext(EventReportContext);

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    const dataChartBar = [
        { label: 'Universe Size', count: eventStats.msg_sent_count, color: 'Blue' },
        { label: 'Successful', count: eventStats.msg_success_count, color: 'Green' },
        { label: 'Error', count: eventStats.msg_err_count, color: 'Orange' },
        { label: 'Opt Out', count: eventStats.msg_opt_out_count, color: 'Red' }
    ];

    return (
            <div style={{width: "1360px", height: "710px"}}>

                <HighchartsReact containerProps={{style: {height : "100%", width : "100%"}}}
                    highcharts={Highcharts} options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'column'
                        },
                        title: {
                            text: eventDetails.name
                        },
                        colors: ["#346793", "#4081ba", "#639aca", "#88b3d8", "#aecbe5"],
                        xAxis: {
                            categories: dataChartBar.map(d => d.label)
                        },
                        yAxis: {
                            title: {
                                text: 'Messages'
                            }
                        },
                        series: [
                            {
                                data: dataChartBar.map(d => d.count),
                                dataLabels: {
                                    enabled: true,
                                }
                            }
                        ],
                        legend: {
                            enabled: false
                        }
                    }
                }/>

        </div>
    );
};

export default EventReporting_SuccessBarChart;
