import { useContext } from "react";
import { EventReportContext } from "../../context/EventReportContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);

const EventReporting_SuccessDonutChart = () => {
    const { groupData } = useContext(EventReportContext);

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        },
        colors: ["#346793", "#4081ba", "#639aca", "#88b3d8", "#aecbe5"],
    });

    return (
        <div style={{width: "600px", height: "710px"}}>

            <HighchartsReact containerProps={{style: {height : "100%", width : "100%"}}}
                highcharts={Highcharts} options={
                {
                    chart: {
                        renderTo: 'container',
                        type: 'pie'
                    },
                    title: {
                        text: 'Success Rate'
                    },
                    yAxis: {
                        title: {
                            text: 'Total percent messages'
                        }
                    },
                    plotOptions: {
                        pie: {
                            shadow: false,
                            startAngle: 0,
                            //colors,
                        }
                    },
                    series: [
                        {
                             name: 'Messages',
                             size: '90%',
                             innerSize: '60%',
                             data: groupData,
                             dataLabels: {
                                 enabled: true,
                                 format: '{point.name}: {point.percentage:.1f}%',
                                 distance: 25
                             }
                        },
                    ]
                }
            }/>
        </div>
    );
};

export default EventReporting_SuccessDonutChart;
