import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { CampaignContext } from "../../context/CampaignContext"
import { ApplicationContext } from "../../context/ApplicationContext";
import { FacetContext } from "../../context/FacetContext";
import { CrystalContext } from "../../context/CrystalContext"
import { AmplifyEventContext } from "../../context/AmplifyEventContext";

import Topbar from "../../components/topbar/Topbar";

import Hierarchy_navigation from "../../components/navigation/Hierarchy_navigation";
import CreateNewCampaign from "../../components/navigation/CreateNewCampaign";
import CreateNewEvent from "../../components/navigation/CreateNewEvent";

import CampaignEventList from "../../components/campaigns/campaignEventList";
import CampaignProjectCodeMsgCounts from "../../components/campaigns/CampaignProjectCodeMsgCounts";
import CampaignResponseTypeMsgCounts from "../../components/campaigns/CampaignResponseTypeMsgCounts";
import Campaign_TotalMessagesSent from "../../components/campaigns/Campaign_TotalMessagesSent";
import Campaign_PerSuccessful from "../../components/campaigns/Campaign_PerSuccessful";
import Campaign_TotalTargetsCleaned from "../../components/campaigns/Campaign_TotalTargetsCleaned";
import Campaign_DaysInDisplay from "../../components/campaigns/Campaign_DaysInDisplay";
import Campaign_UserPermissions from "../../components/campaigns/Campaign_UserPermissions";
import Campaign_DigitalAssetManagement from "../../components/campaigns/Campaign_DigitalAssetManagement";
import Campaign_DataUniverseManagement from "../../components/campaigns/Campaign_DataUniverseManagement";

import CircularProgressRing from "@mui/material/CircularProgress";

//FOR TIMEOUT/LOGOFF
import SessionTimeout from "../../components/session/SessionTimeout";
import Session_LogoutImminent from "../../components/session/Session_LogoutImminent";
import Modal from '@mui/material/Modal';

export default function campaignDashboard() {
    const { jwt, roles } = useContext(AuthContext);
    const { windowWidth, openLogoutImminent, setOpenLogoutImminent, setLogoutCountdownStarted, closeLogoutModalAndReset } = useContext(ApplicationContext);
    const { companyName, logoURL, loading} = useContext(CompanyContext);
    const { campaignName, legacyAmplify } = useContext(CampaignContext);
    const { crystalClearMMSFileInfo } = useContext(CrystalContext);
    const { facetClearMMSFileInfo } = useContext(FacetContext);
    const { initializeNewEvent } = useContext(AmplifyEventContext);

    const [disableCampaignCreate, setDisableCampaignCreate] = useState("hidden");
    const [disableEventCreate, setDisableEventCreate] = useState("hidden");

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    if (jwt === null) {
        window.location.href = "/login";
    }

    const navigate = useNavigate();

    const createNewEventClick = async() => {

        //added 04/24/2024
        facetClearMMSFileInfo();
        crystalClearMMSFileInfo();

        initializeNewEvent();

        navigate("/eventcreate");
    }


    useEffect(() => {
        document.title = "Alliance Forge | Campaign Dashboard";

        if(roles.indexOf("sec:globaladmin") > -1 || roles.indexOf("sec:companyadmin") > -1 ){
            setDisableCampaignCreate("visible");
            setDisableEventCreate("visible");
        }
        else{
            setDisableCampaignCreate("hidden");
            setDisableEventCreate("hidden");
        }

    }, [jwt]);

    useEffect(() => {
        if(openLogoutImminent == true){
            handleModalOpen();
        }
        else{
            handleModalClose();
        }
    }, [openLogoutImminent])

    useEffect(() => {
        // console.log("modalOpen => ", modalOpen);
        if(modalOpen == false){
            setLogoutCountdownStarted(false);
            setOpenLogoutImminent(false);
        }

    }, [modalOpen])

    useEffect(() => {
        handleModalClose();
    }, [closeLogoutModalAndReset])

    return (
        <div>
            <SessionTimeout/>
            {windowWidth >= 1600 ?
                <div className="mainPageDiv">
                    <div className="accountHeader">

                    </div>
                    <div className="accountHeaderImage" style={{ position: "relative", zIndex: 10 }}>
                        <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "140px", height: "140px", border: "5px solid #555"}} src={(logoURL)}/>
                    </div>
                    <div className="accountHeaderCompanyName">
                        <div className="accountHeaderCompanyNameTitle">{"//"}{(companyName != null) ? (companyName.toLowerCase() + "/" + (campaignName.toLowerCase())) : ''}</div>
                    </div>

                    <Topbar displayAdmin={false} />

                    <div className="flex">
                        <div className="hierarchyNavigationTileCreateCampaign" style={{visibility: disableCampaignCreate}}>
                            <CreateNewCampaign />
                        </div>
                        <div className="hierarchyNavigationTileDouble">
                            <Hierarchy_navigation status={"campaign"} />
                        </div>
                        {
                            legacyAmplify == false ?
                            (
                                <div className="hierarchyNavigationTileCreateEvent" onClick={() => createNewEventClick()} style={{visibility: disableEventCreate}}>
                                    <CreateNewEvent/>
                                </div>
                            )
                            :
                            (
                                <div className="hierarchyNavigationTileCreateEvent" style={{visibility: "collapse"}}>
                                    <CreateNewEvent/>
                                </div>
                            )
                        }

                    </div>

                    <div className="grid" style={{margin: "0px 0px 0px 0px"}}>
                        <div className="flex">
                            <div className="grid">
                                <div className="dashboardTile">
                                    <CampaignProjectCodeMsgCounts/>
                                </div>
                                <div className="dashboardTile">
                                    <CampaignResponseTypeMsgCounts/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="dashboardHalfHeightTile">
                                    <Campaign_TotalMessagesSent/>
                                </div>
                                <div className="dashboardHalfHeightTile">
                                    <Campaign_PerSuccessful/>
                                </div>
                                <div className="dashboardHalfHeightTile">
                                    <Campaign_TotalTargetsCleaned />
                                </div>
                                <div className="dashboardHalfHeightTile">
                                    <Campaign_DaysInDisplay days={30 || 0} />
                                </div>
                            </div>
                            <div>
                                <div className="dashboardDoubleWidthDoubleHeightTile">
                                    <CampaignEventList/>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="dashboardDoubleHeightTile">
                                <Campaign_UserPermissions/>
                            </div>
                            <div className="dashboardDoubleHeightTile">
                                <Campaign_DigitalAssetManagement/>
                            </div>
                            <div className="dashboardDoubleWidthDoubleHeightTile">
                                <Campaign_DataUniverseManagement/>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <div className="mainPageDiv_Mobile">
                    <div className="accountHeader_Mobile">

                    </div>
                    <div className="accountHeaderImage_Mobile" style={{ position: "relative", zIndex: 10 }}>
                        <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "100px", height: "100px", border: "5px solid #555"}} src={(logoURL)}/>
                    </div>
                    <div className="accountHeaderCompanyName_Mobile">
                        <div className="accountHeaderCompanyNameTitle_Mobile">{"../" + (campaignName.toLowerCase())}</div>
                    </div>

                    <Topbar displayAdmin={true}/>

                    <div className="grid" style={{margin: "0px 0px 0px 0px"}}>
                        <div className="dashboardDoubleWidthDoubleHeightTile">
                            <CampaignEventList/>
                        </div>

                        <div className="flex">
                            <div className="grid">
                                <div className="dashboardTile">
                                    <CampaignProjectCodeMsgCounts/>
                                </div>
                                <div className="dashboardTile">
                                    <CampaignResponseTypeMsgCounts/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="dashboardHalfHeightTile">
                                    <Campaign_TotalMessagesSent/>
                                </div>
                                <div className="dashboardHalfHeightTile">
                                    <Campaign_PerSuccessful/>
                                </div>
                                <div className="dashboardHalfHeightTile">
                                    <Campaign_TotalTargetsCleaned />
                                </div>
                                <div className="dashboardHalfHeightTile">
                                    <Campaign_DaysInDisplay days={30 || 0} />
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className="flex">
                            <div className="dashboardDoubleHeightTile">
                                <Campaign_UserPermissions/>
                            </div>
                            <div className="dashboardDoubleHeightTile">
                                <Campaign_DigitalAssetManagement/>
                            </div>
                        </div>
                        <div className="dashboardDoubleWidthDoubleHeightTile">
                            <Campaign_DataUniverseManagement/>
                        </div>
                    </div>
                </div>
            }
                {loading ?
                    (
                    <div>
                        <CircularProgressRing
                            className="loadingEventReport
                            inset-2/4 absolute"
                            color="inherit"
                            size={700}
                        />
                    </div>
                    ) : (<></>) }

            <div>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <div className="LogoffImminent_modalWindow">
                        <Session_LogoutImminent />
                    </div>
                </Modal>
            </div>
        </div>


    );
}
