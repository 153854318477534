import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { AdminContextProvider } from "./context/AdminContext";
import { ApplicationContextProvider } from "./context/ApplicationContext";
import { ProfileManagementContextProvider } from "./context/ProfileManagementContext";
import { CompanyContextProvider } from "./context/CompanyContext";
import { CampaignContextProvider } from "./context/CampaignContext";
import { EventReportContextProvider } from "./context/EventReportContext";
import { AmplifyEventContextProvider} from "./context/AmplifyEventContext";
import { AmplifyAreaCodeContextProvider } from "./context/AmplifyAreaCodeContext";
import { UnifyContextProvider } from "./context/UnifyContext";
import { DataMapperContextProvider } from "./context/DataMapperContext";
import { FacetContextProvider } from "./context/FacetContext";
import { CrystalContextProvider } from "./context/CrystalContext";
import "./index.css";
import "./mobile_index.css";
import "./phone_index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <AuthContextProvider>
        <AdminContextProvider>
            <ApplicationContextProvider>
                <ProfileManagementContextProvider>
                    <CompanyContextProvider>
                        <CampaignContextProvider>
                            <AmplifyEventContextProvider>
                                <AmplifyAreaCodeContextProvider>
                                    <EventReportContextProvider>
                                        <UnifyContextProvider>
                                            <DataMapperContextProvider>
                                                <CrystalContextProvider>
                                                    <FacetContextProvider>
                                                        <App />
                                                    </FacetContextProvider>
                                                </CrystalContextProvider>
                                            </DataMapperContextProvider>
                                        </UnifyContextProvider>
                                    </EventReportContextProvider>
                                </AmplifyAreaCodeContextProvider>
                            </AmplifyEventContextProvider>
                        </CampaignContextProvider>
                    </CompanyContextProvider>
                </ProfileManagementContextProvider>
            </ApplicationContextProvider>
        </AdminContextProvider>
    </AuthContextProvider>
    // </React.StrictMode>
);
