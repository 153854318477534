import { useEffect, useContext, useState, useRef} from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { CampaignContext } from "../../context/CampaignContext";
import { ApplicationContext } from "../../context/ApplicationContext";

import Topbar from "../../components/topbar/Topbar";

import Hierarchy_navigation from "../../components/navigation/Hierarchy_navigation";

//event management
import UnifyNewCampaignCreate from "../../components/unify/unifyNewCampaignCreate";

export default function UnifyCampaignCreate() {

    const { jwt } = useContext(AuthContext);
    const { companyName } = useContext(CompanyContext);
    const { campaignName } = useContext(CampaignContext);
    const { reportWindowSize, updateApplicationSegment } = useContext(ApplicationContext);

    //eslint-disable-next-line
    const [displayWidth, setDisplayWidth] = useState(0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          // Custom logic to handle the refresh
          // Display a confirmation message or perform necessary actions
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        updateApplicationSegment("Unify");

        reportWindowSize();
        setDisplayWidth(windowSize.current[0]);
    }, [])

    if (jwt === null) {
        window.location.href = "/login";
    }

    useEffect(() => {
        document.title = "Alliance Forge | Unify Campaign";
    }, [jwt]);

    return (

        <div className="mainPageDiv">

            <div className="accountHeader">

            </div>
            <div className="accountHeaderImage" style={{ position: "relative", zIndex: 10 }}>
                <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "100px", height: "100px", border: "5px solid #555"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/skunkWorks.jpg"/>
            </div>
            <div className="accountHeaderCompanyName">
                <div className="accountHeaderCompanyNameTitle_Mobile">{"//"}{(companyName != null) ? (companyName.toLowerCase()) : ''}{"/"}{(companyName != null) ? (campaignName.toLowerCase()) : ''}</div>
            </div>

            <Topbar displayAdmin={false} />

            <div className="flex">
                <div className="hierarchyNavigationTileFull">
                    <Hierarchy_navigation status={"campaign"} objectWidth={"full"}/>
                </div>
            </div>

            <div className="mainBody">
                <UnifyNewCampaignCreate/>
            </div>

        </div>

        );

}
