import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CampaignContext } from "../../context/CampaignContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";
import { DataMapperContext } from "../../context/DataMapperContext";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

//DATA MAPPER
import DataMapper2 from "../datamapper/DataMapper2";
import EventMessage from "../TextEditor/EventMessage";
import AmplifyAreaCodeSelector from "./AmplifyAreaCodeSelector";
import AmplifyEventScheduler from "./AmplifyEventScheduler";
import AmplifyEventApprovers from "./AmplifyEventApprovers";
import AmplifyEventStepsCalloutMenu from "./AmplifyEventStepsCalloutMenu";
import AmplifyEventMainMenu from "./AmplifyEventMainMenu";
import AmplifyEventNBSamples from "./AmplifyEventNBSamples";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';

import Modal from '@mui/material/Modal';
import PodcastsIcon from '@mui/icons-material/Podcasts';

//NOTIFICATIONS OF COMPLETION
import { useSnackbar } from 'notistack';

const AmplifyEventManagement = () => {
    const { mosaicUniverseSize } = useContext(DataMapperContext);

    const steps = ['Name your message event', 'Select a universe map', 'Set schedule', 'Build Your Message', 'Determine the Area Code', 'Approvers', 'Samples'];

    const { updateCoreInformation,
            eventCoreInformation,
            modalOpenArchive,
            setModalOpenArchive,
            updateExistingEvent_Archive,
            setModalOpenCommit,
            modalOpenCommit,
            updateExistingEvent_Commit,
            eventLoadingInProgress,
            eventLoadingInProgressMsg,
            clearAllExistingEventValues,
            ableToAllocateTN,
            toastMessage,
            eventCoreChanged
        } = useContext(AmplifyEventContext);

    const { adminBWApplicationID, custBWApplicationID, setEventDataDirty } = useContext(CampaignContext);

    const navigate = useNavigate();

    //eslint-disable-next-line
    const [timingClickMessage, setTimingClickMessage] = useState('Click me to show scheduling details');

    var sideMarginTop = "0px";

    const [eventInfoStatusId, setEventInfoStatusId] = useState(null);

    const [eventKey, setEventKey] = useState(null);
    const [eventName, setEventName] = useState('');
    const [project_code, setProject_Code] = useState('');

    //eslint-disable-next-line
    const [start_datetime, setStart_DateTime] = useState(null);

    //eslint-disable-next-line
    const [end_datetime, setEnd_DateTime] = useState(null);

    //eslint-disable-next-line
    const [timezone, setTimezone] = useState('');
    const [status, setStatus] = useState('');

    //eslint-disable-next-line
    const [status_legacy, setStatus_Legacy] = useState('');

    //eslint-disable-next-line
    const [voterset_key, setVoterSet_Key] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (toastMessage && toastMessage.length > 0) {
            enqueueSnackbar(toastMessage, {
                variant: "success",
                autoHideDuration: 5000
            });
        }
    }, [toastMessage])

    useEffect(() => {
        //console.log("AmplifyEventManagement: useEffect()[eventCoreInformation] setting local vars to eventCoreInformation changes ", eventCoreInformation)
        if (!eventCoreInformation) {
            return;
        }

        if (eventCoreInformation.statusId != eventInfoStatusId) {
            setEventInfoStatusId(eventCoreInformation.statusId);

            if (eventName != eventCoreInformation.name) {
                setEventName(eventCoreInformation.name);
            }

            if (project_code != eventCoreInformation.project_code) {
                setProject_Code(eventCoreInformation.project_code);
            }

            setEventKey(eventCoreInformation.key);
            setStart_DateTime(eventCoreInformation.start_datetime);
            setEnd_DateTime(eventCoreInformation.end_datetime);
            setTimezone(eventCoreInformation.timezone);
            setStatus(eventCoreInformation.status);
            setStatus_Legacy(eventCoreInformation.status_legacy);
            setVoterSet_Key(eventCoreInformation.voterset_key);
        }
    }, [eventCoreInformation, eventCoreChanged])

    useEffect(() => {
        // allow these to be empty strings in case the user is trying to change the values
        updateCoreInformation(eventName, project_code);
    }, [eventName, project_code])

    useEffect(() => {
        manageEventNameChange(eventName);
    }, [eventName])

    useEffect(() => {
        manageProjectCodeChange(project_code);
    }, [project_code])

    async function commitEvent(){
        if (!eventCoreInformation || !eventCoreInformation.key) {
            return;
        }

        if(eventCoreInformation.start_datetime < dayjs()){
            modalCannotCommitPastDateEventsOpen(true);
        }
        else{
             //console.log("AmplifyEventManagement: commitEvent() calling updateExistingEvent_Commit(eventCoreInformation.key) ", eventCoreInformation)
            setEventDataDirty(true);
            handleModalClose_Commit();
            updateExistingEvent_Commit(eventCoreInformation.key);
            navigate("/amplifyDashboard");
        }

    }

    async function commitArchiveEvent(){
        if (!eventCoreInformation || !eventCoreInformation.key) {
            return;
        }

        //console.log("AmplifyEventManagement: commitArchiveEvent() calling updateExistingEvent_Archive(eventCoreInformation.key) ", eventCoreInformation)
        setEventDataDirty(true);
        handleModalClose_Archive();
        updateExistingEvent_Archive(eventCoreInformation.key);
        navigate("/amplifyDashboard");
    }

    //Non-linear stepper

    const [activeStep, setActiveStep] = useState(0);

    //eslint-disable-next-line
    const [completed, setCompleted] = useState({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        document.getElementById('scrollMain').scrollLeft = (newActiveStep * 1100);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        document.getElementById('scrollMain').scrollLeft = ((activeStep - 1) * 1100);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
        document.getElementById('scrollMain').scrollLeft = (step * 1100);
    };

    //MODAL MANAGMENT

    const [modalErrorDesc, setModalErrorDesc] = useState('');

    //eslint-disable-next-line
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSystemMessageOpen, setModalSystemMessageOpen] = useState(false);
    const [modalCannotCommitPastDateEventsOpen, setModalCannotCommitPastDateEventsOpen] = useState(false);

    const [modalOpenCommitVerified, setModalOpenCommitVerified] = useState(false);

    const handleModalSystemMessageClose = () => {
        setModalSystemMessageOpen(false);
        setModalErrorDesc('');
    };
    const handleModalClose_Commit = () => setModalOpenCommit(false);
    const handleModalCannotCommitPastDateEventsClose = () => setModalCannotCommitPastDateEventsOpen(false);

    const handleModalClose_Archive = () => setModalOpenArchive(false);

    useEffect(() => {

        // console.log("this is the modal open commit status => ", modalOpenCommit);

        if(modalOpenCommit == false){
            return;
        }

        // console.log("this is the start date/time => ", eventCoreInformation.start_datetime);

        if(dayjs.utc(eventCoreInformation.start_datetime) < dayjs()){
            // console.log("DO NOT ALLOW COMMIT");
            setModalCannotCommitPastDateEventsOpen(true);
            setModalOpenCommit(false);
        }
        else{
            // console.log("ALLOW COMMIT");
            setModalOpenCommitVerified(true);
            setModalOpenCommit(false);
        }

    }, [modalOpenCommit])

    function handleCommitEventsCancel(){
        setModalOpenCommit(false);
        setModalOpenCommitVerified(false);
    }

    function handleCloseTNAllocationError(){
        try{
            clearAllExistingEventValues();
            navigate("/amplifyDashboard");
        }
        catch(error){
            console.log("error exiting the event => ", error);
        }
    }

    function manageEventNameChange(eventName){
        var newEventName = eventName; //.trim();

        //NOTE: remove unwanted characters
        //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
        //eslint-disable-next-line
        newEventName = newEventName.replace(/[^a-z0-9\-\_.\ ]/gi, '');

        setEventName(newEventName);
    }

    function manageProjectCodeChange(projectCode){
        var newProjectCode = projectCode; //.trim();

        //NOTE: remove unwanted characters
        //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
        //eslint-disable-next-line
        newProjectCode = newProjectCode.replace(/[^a-z0-9\-\_.\ ]/gi, '');

        setProject_Code(newProjectCode);
    }

    return (
        <div>
            { eventLoadingInProgress == true ?
            <div style={{width: "1600px", height: "1200px", background: "white"}}>
                <div style={{marginTop: "200px", width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "center", verticalAlign: "middle", alignItems: "center", width: "1600px", background: "white", lineHeight: "50px"}}>
                        <label>{eventLoadingInProgressMsg}</label>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "1600px"}}>
                        <div className="loading loading-bars loading-sm" style={{ marginTop: "20px",
                                    width: "600px", height: "800px"}} />
                    </div>
                </div>
            </div>
            : ableToAllocateTN == false ? //&& (eventPhoneInformation.admin_phone_number == null || eventPhoneInformation.customer_phone_number == null) ?
            <div style={{width: "1600px", height: "1200px", background: "white"}}>
                <div style={{display: "flex", justifyContent: "center", verticalAlign: "middle", alignItems: "center", width: "1600px", background: "white", lineHeight: "50px", marginTop: "200px"}}>
                    <label>The necessary phones are in the process of being allocated. Please check back on this event again in a few minutes.</label>
                </div>
                <div style={{marginTop: "50px", display: "flex", justifyContent: "center", alignItems: "center", width: "1600px"}}>
                    <Button variant="outlined"
                        style={{height: "60px", width: "220px", margin: "0px 0px 0px 0px", background: "white", color: "black"}}
                        onClick={() => handleCloseTNAllocationError()} size="large">OK</Button>
                </div>
            </div>
            :
            <div>
                {/* TOP LEVEL MENU}{*/}
                <div>
                    <AmplifyEventMainMenu/>
                </div>

                <div style={{margin: "20px 0px 20px 30px"}}>
                    {/*}<form onSubmit={handleSubmit}>{*/}
                        <div className="flex">

                            <div className="eventCreateDetailForm">
                                {/*}<br/><h6>Create Your Next Messaging Event</h6><br/>{*/}

                                <Box sx={{ width: "1100px" }}>
                                    <div style={{marginBottom: "20px", marginTop: "20px", marginRight: "30px"}}>
                                    <Stepper nonLinear activeStep={activeStep}>
                                        {steps.map((label, index) => (
                                          <Step key={label} completed={completed[index]}>
                                            <StepButton color="inherit" onClick={handleStep(index)}>
                                              {label}
                                            </StepButton>
                                          </Step>
                                        ))}
                                    </Stepper>
                                    </div>

                                    <div className="flex" style={{marginBottom: "20px", marginRight: "30px"}}>
                                        <button className="btn btn-outline bg-blue-200"
                                            disabled={activeStep === 0}
                                            onClick={handleBack} style={{marginLeft: "10px", borderRadius: "50px", height: "50px", width: "50px"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"
                                                stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" style={{marginLeft: "-7px", marginTop: "11px"}}><path d="M15 18l-6-6 6-6"/>
                                            </svg>
                                        </button>
                                        <Box style={{width: "1210px"}} />
                                        <button className="btn btn-outline bg-blue-200" onClick={handleNext} style={{marginLeft: "10px", borderRadius: "50px", height: "50px", width: "50px"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"
                                                stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round" style={{marginLeft: "-3px", marginTop: "11px"}}><path d="M9 18l6-6-6-6"/>
                                            </svg>
                                        </button>
                                    </div>

                                </Box>

                                <div id="scrollMain" className="flex" style={{ width: "1100px", overflowX:"hidden", overflowY:"hidden"}}>

                                    <div className="eventCreateHeaderInformation" id="card1" style={{minWidth: "1080px", height: "1140px"}}>
                                        <div className="eventCreateTiming">

                                            <div className="collapse-title flex">
                                                <div className="stepEllipse mt-3 -ml-5">
                                                    <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px 0px"}}>1.</label>
                                                </div>
                                                <div className="collapse-title text-xl font-bold">MESSAGE EVENT CORE INFORMATION</div>
                                            </div>

                                        </div>


                                        <div className="flex">
                                            <div className="grid" style={{marginRight: "20px"}}>
                                                <div className="flex">
                                                    <label className="userEditTitle" htmlFor="eventName">Event Name:</label>
                                                    <label className="userEditTitle" htmlFor="tags" style={{color: "red", fontSize: "24px", marginLeft: "2px", marginTop: "-10px", marginBottom: "-10px"}}>*</label>
                                                </div>
                                                <input className="input input-bordered input-md" type="text" id="eventName" name="eventName" value={eventName}
                                                    onChange={e => setEventName(e.target.value)}
                                                    placeholder={'Event Name'} style={{width: "500px", background: "white", color: "black"}}/>
                                            </div>
                                            <div className="grid">
                                                <div className="flex">
                                                    <label className="userEditTitle" htmlFor="projectCode">Project Code:</label>
                                                    <label className="userEditTitle" htmlFor="tags" style={{color: "red", fontSize: "24px", marginLeft: "2px", marginTop: "-10px", marginBottom: "-10px"}}>*</label>
                                                </div>
                                                <input className="input input-bordered input-md w-[500px]" type="text" id="projectCode" name="projectCode" value={project_code}
                                                    onChange={e => setProject_Code(e.target.value)}
                                                    placeholder={'Project Code'} style={{background: "white", color: "black"}}/>
                                            </div>

                                        </div>

                                        <br/>
                                        <hr/>

                                        <div className="grid eventCreateFunctionTile" style={{background: "darkorange", marginTop: "20px", width: "500px", height: "160px"}}>
                                            <label className="eventCreateFunctionTileTitle">Event Status</label>
                                            <label className="eventCreateFunctionTileCount" style={{fontSize: "72px"}}>{status.length != 0 ? status : "new"}</label>
                                        </div>

                                        <div className="flex">
                                            <div className="grid eventCreateFunctionTile" style={{background: "dodgerblue", marginTop: "20px", width: "500px", height: "160px"}}>
                                                <label className="eventCreateFunctionTileTitle">Mosaic Universe Size</label>
                                                <label className="eventCreateFunctionTileCount" style={{fontSize: "72px"}}>{mosaicUniverseSize}</label>
                                            </div>
                                        </div>

                                        <div className="grid" style={{position: "absolute", bottom: "40px", left: "50px", fontSize: "12px", height: "50px"}}>
                                            <div>Admin App ID:</div>
                                            <div>{adminBWApplicationID}</div>
                                        </div>

                                        <div className="grid" style={{position: "absolute", bottom: "40px", left: "400px", fontSize: "12px", height: "50px"}}>
                                            <div>Customer App ID:</div>
                                            <div>{custBWApplicationID}</div>
                                        </div>

                                        <div className="grid" style={{position: "absolute", bottom: "40px", left: "800px", fontSize: "12px", height: "50px"}}>
                                            <div>Event Key:</div>
                                            <div>{eventKey}</div>
                                        </div>
                                    </div>

                                    <div className="eventCreateTiming" id="card2" style={{minWidth: "1080px", height: "1140px"}}>
                                        <div className="border border-black" style={{borderRadius: "20px"}}>
                                            <div className="collapse-title flex">
                                                <div className="stepEllipse mt-3">
                                                    <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px 0px"}}>2.</label>
                                                </div>
                                                <div className="collapse-title text-xl font-bold">UNIVERSE MAPPING</div>
                                            </div>
                                            <div >
                                                <DataMapper2 />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="eventCreateTiming" id="card3" style={{minWidth: "1080px", height: "1140px"}}>
                                        <div className="border border-black" style={{height: "1140px", borderRadius: "20px"}}>
                                            <div className="collapse-title flex">
                                                <div className="stepEllipse mt-3">
                                                    <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px 0px"}}>3.</label>
                                                </div>
                                                <div className="collapse-title text-xl font-bold">SCHEDULING - {timingClickMessage}</div>
                                            </div>

                                            <AmplifyEventScheduler />

                                        </div>
                                    </div>

                                    <div className="eventCreateTiming" id="card4" style={{minWidth: "1080px", height: "1140px"}}>
                                        <div className="border border-black" style={{ height: "1140px", borderRadius: "20px"}}>
                                            <div className="collapse-title flex">
                                                <div className="stepEllipse mt-3">
                                                    <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px 0px"}}>4.</label>
                                                </div>
                                                <div className="collapse-title text-xl font-bold">MESSAGE</div>
                                            </div>
                                            <EventMessage />
                                        </div>
                                    </div>

                                    <div className="eventCreateTiming" id="card5" style={{minWidth: "1080px", height: "1140px"}}>
                                        <div className="border border-black" style={{height: "1140px", borderRadius: "20px"}}>
                                            <div className="collapse-title flex">
                                                <div className="stepEllipse mt-3">
                                                    <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px 0px"}}>5.</label>
                                                </div>
                                                <div className="collapse-title text-xl font-bold">AREA CODE SELECTION</div>
                                            </div>

                                            <div style={{margin: "20px 0px 20px 20px"}}>
                                                <AmplifyAreaCodeSelector/>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="eventCreateTiming" id="card6" style={{minWidth: "1080px", height: "1140px"}}>
                                        <div className="border border-black" style={{height: "1140px", borderRadius: "20px"}}>
                                            <div className="collapse-title flex">
                                                <div className="stepEllipse mt-3">
                                                    <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px 0px"}}>6.</label>
                                                </div>
                                                <div className="collapse-title text-xl font-bold">APPROVERS</div>
                                            </div>

                                            <AmplifyEventApprovers />

                                        </div>
                                    </div>

                                    <div className="eventCreateTiming" id="card7" style={{minWidth: "1080px", height: "1140px"}}>
                                        <div className="border border-black" style={{height: "1140px", borderRadius: "20px"}}>

                                            <div className="collapse-title flex">
                                                <div className="stepEllipse mt-3">
                                                    <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px -7px"}}>7.</label>
                                                </div>
                                                <div className="collapse-title text-xl font-bold">SAMPLES</div>
                                            </div>

                                            <AmplifyEventNBSamples />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex" style={{margin: sideMarginTop + " 0px 0px 0px"}}>

                                {/*}CALLOUT SIDE MENU HERE {*/}
                                <AmplifyEventStepsCalloutMenu />

                            </div>
                        </div>

                    {/*}</form>{*/}
                </div>

                <div>
                  <Modal
                    open={modalOpenCommitVerified}
                    onClose={handleModalClose_Commit}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >

                    <div className="modalCommitAck" style={{height: "700px", width: "400px", padding: "10px 15px 10px 15px"}}>

                        <div style={{marginTop: "10px", textAlign: "center", fontSize: "24px", fontWeight: "bold"}}>ACKNOWLEDGEMENT</div>
                        <div style={{textAlign: "center", fontSize: "28px", fontWeight: "bold"}}>RELEASE FOR PROCESSING</div>
                        <br/>
                        <hr/>
                        <br/>
                        <div style={{fontSize: "16px", fontWeight: "bold", marginTop: "3px"}}>By selecting the button below marked COMMIT, I acknowledge that I have:</div>

                        <div style={{fontSize: "16px", fontWeight: "normal", marginTop: "10px"}}>1. Reviewed the content in the message event for accurancy and completeness</div>
                        <div style={{fontSize: "16px", fontWeight: "normal", marginTop: "10px"}}>2. Reviewed the scheduling of this event is correct</div>
                        <div style={{fontSize: "16px", fontWeight: "normal", marginTop: "10px"}}>3. I have applied any necessary attributions as required by regulatory and compliance authorities</div>
                        <br/>
                        <hr/>
                        <br/>
                        <div style={{fontSize: "16px", fontWeight: "bold", marginTop: "3px"}}>Once I COMMIT this message event, I acknowledge that the event is
                            released for processing and distribution, AS IS, at the specified time, with the specified area code, and the allocated phone number</div>


                        <Box sx={{ height: "90px" }}></Box>
                        <div className="flex" style={{marginLeft: "23px"}}>
                            <div>
                                <Button variant="outlined" startIcon={<PodcastsIcon fontSize="large" />}
                                    style={{height: "60px", width: "150px", margin: "0px 0px 0px 0px", background: "white", color: "black"}}
                                    size="large" onClick={() => commitEvent()}>COMMIT</Button>
                            </div>
                            <div>
                                <Button variant="outlined"
                                    style={{height: "60px", width: "150px", margin: "0px 0px 0px 20px", background: "red", color: "white"}}
                                    onClick={() => handleCommitEventsCancel()} size="large">Cancel</Button>
                            </div>
                        </div>


                    </div>
                  </Modal>
                  <Modal
                    open={modalCannotCommitPastDateEventsOpen}
                    onClose={handleModalCannotCommitPastDateEventsClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">

                    <div className="modalCannotCommitAck" style={{height: "600px", width: "400px", padding: "10px 20px 10px 20px"}}>

                        <br/>
                        <div style={{marginTop: "10px", textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "white"}}>NOT ABLE TO COMMIT</div>
                        <div style={{textAlign: "center", fontSize: "28px", fontWeight: "bold", color: "white"}}>FOR PROCESSING</div>
                        <br/>
                        <hr/>
                        <br/>
                        <div style={{fontSize: "24px", fontWeight: "bold", marginTop: "3px", color: "white"}}>This event is scheduled in the past and cannot be committed for processing.</div>
                        <br/>
                        <div style={{fontSize: "24px", fontWeight: "bold", marginTop: "3px", color: "white"}}>Please update the requested start time and try to release again.</div>


                        <Box sx={{ height: "40px" }}></Box>
                        <div>
                            <Button variant="outlined"
                                style={{height: "60px", width: "220px", margin: "0px 0px 0px 70px", background: "white", color: "black"}}
                                onClick={() => handleModalCannotCommitPastDateEventsClose()} size="large">OK</Button>
                        </div>

                    </div>
                  </Modal>
                  <Modal
                    open={modalSystemMessageOpen}
                    onClose={handleModalSystemMessageClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">

                    <div className="modalSystemMessageCommitAck">

                        <div style={{fontWeight: "bold", fontSize: "36px", maxwidth: "1500px", minWidth: "700px", marginTop: "50px", whiteSpace: "pre"}}>{modalErrorDesc}</div>


                        <Box sx={{ height: "90px" }}></Box>
                        <div>
                            <Button variant="outlined"
                                style={{height: "60px", width: "220px", margin: "0px 0px 0px 0px", background: "white", color: "black"}}
                                onClick={() => handleModalSystemMessageClose()} size="large">OK</Button>
                        </div>

                    </div>
                  </Modal>
                  <Modal
                    open={modalOpenArchive}
                    onClose={handleModalClose_Archive}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <div className="modalCommitAck_Archive" style={{height: "500px", width: "400px", padding: "10px 15px 10px 15px"}}>

                        <div style={{marginTop: "10px", textAlign: "center", fontSize: "28px", fontWeight: "bold"}}>ARCHIVE EVENT</div>
                        <br/>
                        <hr/>
                        <br/>
                        <div style={{fontSize: "20px", fontWeight: "bold", marginTop: "3px"}}>Selecting to archive an event will remove access to all event and result tracking information permanently.</div>

                        <div style={{fontSize: "24px", fontWeight: "normal", marginTop: "10px"}}>Do you wish to continue?</div>
                        <br/>
                        <hr/>
                        <br/>

                        <Box sx={{ height: "90px" }}></Box>
                        <div style={{marginLeft: "78px"}}>
                            <Button variant="outlined" startIcon={<DeleteForeverIcon fontSize="large" />}
                                style={{height: "60px", width: "220px", margin: "0px 0px 0px 0px", background: "white", color: "black"}}
                                size="large" onClick={() => commitArchiveEvent()}>ARCHIVE</Button>
                        </div>

                    </div>
                  </Modal>
                </div>
            </div>
            }
        </div>
    );

};

export default AmplifyEventManagement;
