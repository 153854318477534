import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { CampaignContext } from "../../context/CampaignContext";
import { useNavigate } from "react-router-dom";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

const NewCampaign = () => {
    const { jwt, dispatch, first_name, last_name } = useContext(AuthContext);
    //const { companyID, companyKey, getCompanyCampaigns, setCampaignDataDirty, checkCompanyContextRefresh } = useContext(CompanyContext);
    const { companyID, companyKey, setCampaignDataDirty, checkCompanyContextRefresh } = useContext(CompanyContext);

    const { setCampaignID, setCampaignEvents, setCampaignName, campaignName, setResponsesStart,
            //getEvents, resetCampaignVals, campaignSaving, checkCampaignForAppIDs,
            resetCampaignVals, campaignSaving, checkCampaignForAppIDs,
            newCampaignNavData, setNewCampaignNavData, campaignSavingComplete } = useContext(CampaignContext);

    const [stateID, setStateID] = useState(0);
    const [stateAbbreviation, setStateAbbreviation] = useState('');
    const [raceTypeID, setRaceTypeID] = useState(0);
    const [preferredAreaCode, setPreferredAreaCode] = useState('');
    const [importOptoutId, setImportOptoutId] = useState(-1);

    const [states, setStates] = useState(0);
    const [raceTypes, setRaceTypes] = useState(0);

    const [timezone, setTimezone] = useState(0);

    const navigate = useNavigate();

    const [currCampaigns, setCurrCampaigns] = useState([]);

    const [campaignNameError, setCampaignNameError] = useState('hidden');
    const [campaignNameOK, setCampaignNameOK] = useState('hidden');

    const [disableSubmitButton, setDisableSubmitButton] = useState(true);

    useEffect(() => {
        if (!states) {
            getStates();
        }

        if (!raceTypes) {
            getRaceTypes();
        }

        //get all current campaigns for this business
        getAllCampaigns();

        resetCampaignVals();
        setNewCampaignNavData(null);
        setDisableSubmitButton(true);
    }, []);

    const getStates = async () => {
        // console.log("NewCampaign: entered getStates() (NEEDS TESTING)");

        if (!jwt) {
            return;
        }

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectState`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            setStates(response.data.values);
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    const getAllCampaigns = async () => {
        // console.log("NewCampaign: entered getAllCampaigns() (NEEDS TESTING)");

        if (!jwt) {
            return;
        }

        await axios.get(
            `${config.accountapi.url}/api/v1/campaigns?limit=10000&business_key=${companyKey}&status=create&status=creating&status=active`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log("error collecting current campaigns", error);
        })
        .then(function (response) {
                var _c = response.data.values.sort((a,b) => a.name > b.name ? 1 : -1);
                setCurrCampaigns(_c);
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    // Get list of race types from API
    const getRaceTypes = async () => {
        // console.log("NewCampaign: entered getRaceTypes() (NEEDS TESTING)");
        if (!jwt) {
            return;
        }

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectRaceType`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            setRaceTypes(response.data.values);
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    // Get selected state details from API
    const getStateInfo = async (state_id) => {
        // console.log("NewCampaign: entered getStateInfo() (NEEDS TESTING)");
        if (!jwt || !state_id) {
            return;
        }

        var stateInfo = [];
        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectAllStateInformation?state_id=${state_id}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            stateInfo = response.data.values;

            let acList = [];
            stateInfo.forEach(object => {
                acList.push(object['AreaCode']);
            });

            var x = document.getElementById("areacodelist");
            x.innerHTML = `${stateInfo[0]['StateName']}: ${acList.toString()}`;

            setStateAbbreviation(stateInfo[0]['StateAbbr']);

            if (stateInfo[0]['TimeZoneName'].startsWith("Hawaiian")) {
                setTimezone('hawaii');
            } else if (stateInfo[0]['TimeZoneName'].startsWith("Alaska")) {
                setTimezone('alaska');
            } else if (stateInfo[0]['TimeZoneName'].startsWith("Pacific")) {
                setTimezone('pacific');
            } else if (stateInfo[0]['TimeZoneName'].startsWith("Mountain")) {
                setTimezone('mountain');
            } else if (stateInfo[0]['TimeZoneName'].startsWith("Arizona")) {
                setTimezone('arizona');
            } else if (stateInfo[0]['TimeZoneName'].startsWith("Central")) {
                setTimezone('central');
            } else if (stateInfo[0]['TimeZoneName'].startsWith("Eastern")) {
                setTimezone('eastern');
            }
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    // Post new campaign data to API
    const postNewCampaign = async (campaign_fields) => {
        // console.log("NewCampaign: entered postNewCampaign() (NEEDS TESTING)");
        if (!jwt || !campaign_fields) {
            return;
        }

        let jsonData = campaign_fields['sql'];
        var new_campaign_id_legacy = 0;
        var first_start_date = '';

        // post to sql
        await axios.post(
            `${config.reportapi.url}/api/v1/event/createCampaign?company_id=${campaign_fields['sql']['company_id']}`,
            jsonData,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            new_campaign_id_legacy = response.data.values[0].the_ouput;
            first_start_date = response.data['FirstStartDate'];
        });

        // post to mongo
        var _campaignKey = null;
        campaign_fields['mongo']['campaign_id_legacy'] = new_campaign_id_legacy.toString();
        jsonData = campaign_fields['mongo'];
        await axios.post(
            `${config.accountapi.url}/api/v1/campaigns`,
            jsonData,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            //var _campaignUpdated = response.data['updated_dt'];
            _campaignKey = response.data['key'];

            //move to the conclusion of the save event process
            var _newCampaignInfo =
                {
                    type: 'campaign',
                    newCampaignID_legacy: new_campaign_id_legacy,
                    campaignName: campaignName,
                    FirstStartDate: first_start_date
                };
            setNewCampaignNavData(_newCampaignInfo);
            setCampaignDataDirty(true);
            checkCompanyContextRefresh()
        });
        await checkCampaignForAppIDs(_campaignKey);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    useEffect(() => {
        if (campaignSavingComplete == true && newCampaignNavData != null) {
            campaignSelected(newCampaignNavData.type, newCampaignNavData.newCampaignID_legacy, newCampaignNavData.campaignName, newCampaignNavData.FirstStartDate);
        }
    }, [campaignSavingComplete])

    const campaignSelected = async(eventType, CampaignID, CampaignName, EventsStart) => {
        setCampaignEvents([]);
        setResponsesStart(EventsStart);
        setCampaignName(CampaignName);
        setCampaignID(CampaignID);

        // edr - need to mark Campaigns and Events lists as dirty

        //getEvents(companyID, CampaignID);

        //refresh the campaigns
        //getCompanyCampaigns();

        navigate("/campaignDashboard");
    };

    const onStateChange = (event) => {
        setStateID(parseFloat(event.target.value));
        for (let i=0; i<states.length; i++) {
            if (event.target.value == states[i]['StateID']) {
                setPreferredAreaCode(states[i]['PreferredAreaCode']);
                getStateInfo(states[i]['StateID']);
                break;
            }
        }
    }

    const onRaceTypeChange = (event) => {
        setRaceTypeID(parseFloat(event.target.value));
    }

    const onImportOptOutIdChange = (event) => {
        setImportOptoutId(parseFloat(event.target.value));
    }

    function handleSubmit(e) {
        try{
            e.preventDefault();

            let sql_campaign_fields = {}

            sql_campaign_fields['company_id'] = companyID;
            sql_campaign_fields['owner'] = first_name + " " + last_name;

            if (campaignName != '') {
                sql_campaign_fields['campaign_name'] = campaignName;
            }
            if (stateID != '') {
                sql_campaign_fields['state_id'] = stateID;
            }
            if (raceTypeID != '') {
                sql_campaign_fields['racetype_id'] = raceTypeID;
            }
            if (preferredAreaCode != '') {
                sql_campaign_fields['preferred_area_code'] = preferredAreaCode;
            }

            sql_campaign_fields['import_optout_id'] = importOptoutId;

            // need new sql id for account_id_legacy field
            let mongo_campaign_fields = {}
            mongo_campaign_fields['business_key'] = companyKey;
            mongo_campaign_fields['name'] = campaignName;
            mongo_campaign_fields['campaign_id_legacy'] = '';
            mongo_campaign_fields['sms_base_price_contract'] = 0.0;
            mongo_campaign_fields['mms_base_price_contract'] = 0.0;
            mongo_campaign_fields['mms_included_characters'] = 0;
            mongo_campaign_fields['afc_connect_base_price_contract'] = 0.0;
            mongo_campaign_fields['afc_crystal_pricing'] = 0.0;
            mongo_campaign_fields['additional_segment_price'] = 0.0;
            mongo_campaign_fields['bandwidth_campaign_id'] = '';
            mongo_campaign_fields['approval_numbers'] = [];
            mongo_campaign_fields['state'] = stateAbbreviation;  // state appreviation
            mongo_campaign_fields['timezone'] = timezone;

            if (Object.keys(sql_campaign_fields).length > 0) {
                let campaign_fields = {
                    'sql' : sql_campaign_fields,
                    'mongo' : mongo_campaign_fields
                }
                postNewCampaign(campaign_fields);
            }
        }
        catch(err){
            console.log("there was an error posting the new campaign", err);
        }
    }

    useEffect(() => {
        if (campaignName === '' || stateID === 0 || raceTypeID === 0 || campaignNameError === 'visible') {
            setDisableSubmitButton(true);
        }
        else{
            setDisableSubmitButton(false);
        }
    }, [campaignName, stateID, raceTypeID, campaignNameError])

    async function checkCampaignName(proposedCampaignName){
        // console.log("NewCampaign: entered checkCampaignName() (NEEDS REWRITING && TESTING)");

        //adjust campaign name to remove special characters

        //eslint-disable-next-line
        if(campaignName.trim().length > 0 && campaignName.search(/[^\ ()0-9A-Za-z-]/g)){
            //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
            //eslint-disable-next-line
            proposedCampaignName = campaignName.replace(/[^\ ()0-9A-Za-z-]/g, '-');
            setCampaignName(proposedCampaignName);
        }

        if(proposedCampaignName.length == 0){
            setCampaignNameError('hidden');
            setCampaignNameOK('hidden');
        }
        else{
            var _found = currCampaigns.find(c => c['name'] == proposedCampaignName);

            if(_found == null){
                setCampaignNameError('hidden');
                setCampaignNameOK('visible');
            }
            else{
                setCampaignNameError('visible');
                setCampaignNameOK('hidden');
            }
        }
    }

    return (
        <div>
            <div className="flex" style={{margin: "00px 0px 0px 0px", width: "1600px"}}>
                <div className="bg-blue-50" style={{width: "800px"}}>

                    <div style={{margin: "40px 0px 0px 0px"}}>
                        <div className="flex" style={{margin: "0px 10px 0px 15vw"}}>
                            <div style={{width: "30px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#4a4a4a" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <div className="text-sm" style={{margin: "0px 10px 0px 10px"}}>
                                Manage common messages for either a customer or geographic region, or both into a single functional campaign.
                            </div>
                        </div>
                    </div>

                    <div style={{margin: "40px 0px 0px 0px"}}>
                        <div className="flex" style={{margin: "0px 10px 0px 15vw"}}>
                            <div style={{width: "30px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#4a4a4a" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <div className="text-sm" style={{margin: "0px 10px 0px 10px"}}>
                                Your Opt-Out responses are automatically managed and curiated from your lists. One more way we empower you to focus on the message... not the minutia.
                            </div>
                        </div>
                    </div>

                    <div style={{margin: "40px 0px 0px 0px"}}>
                        <div className="flex" style={{margin: "0px 10px 0px 15vw"}}>
                            <div style={{width: "30px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#4a4a4a" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <div className="text-sm" style={{margin: "0px 10px 0px 10px"}}>
                                Organize your messages within a campaign to keep messaging and media assets together.
                            </div>
                        </div>
                    </div>

                    <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Header_Curves.png" />

                </div>

                <div className="bg-white" style={{padding: "0px 0px 0px 50px", width: "800px"}}>
                    <form onSubmit={handleSubmit}>
                        <div style={{margin: "30px 0px 60px 60px"}}>
                            <br/><h6>Create Your New Messaging Campaign</h6><br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="campaignName">Camapaign Name:</label>
                                <div className="flex">
                                    <input className="input input-bordered input-md w-full max-w-xs" type="text" id="campaignName"
                                        style={{background: "white", color: "black"}}
                                        name="campaignName" value={campaignName}
                                        onBlur={e => checkCampaignName(e.target.value)}
                                        onChange={e => setCampaignName(e.target.value)} placeholder={'Campaign Name'}/>
                                    <div style={{marginLeft: "10px", visibility: campaignNameOK}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                            strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                        </svg>
                                    </div>
                                </div>
                                <div style={{height: "3px"}}>
                                    <label style={{color: "red", fontSize: "14px", visibility: campaignNameError}}>This Campaign Name has already been used</label>
                                </div>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="state">State:</label>
                                <select id="state" className="select select-bordered w-full max-w-xs" onChange={(e) => { onStateChange(e); }}
                                    style={{background: "white", color: "black"}}>
                                    <option disabled selected>Campaign State</option>
                                    {states
                                        ? states.map((state) => {
                                            return <option key={state.StateID} value={state.StateID}>{state.StateName}</option>;
                                        })
                                        : null}
                                </select>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="racetype">Race Type:</label>
                                <select id="racetype" className="select select-bordered w-full max-w-xs" onChange={(e) => { onRaceTypeChange(e); }}
                                    style={{background: "white", color: "black"}}>
                                    <option disabled selected>Race Level | Race Type</option>
                                    {raceTypes
                                        ? raceTypes.map((raceType) => {
                                            return <option key={raceType.RaceTypeID} value={raceType.RaceTypeID}>{raceType.RaceTypeName}</option>;
                                        })
                                        : null}
                                </select>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="racetype">Import Opt-Outs from previous campaign: (OPTIONAL)</label>
                                <select id="racetype" className="select select-bordered w-full max-w-xs" onChange={(e) => { onImportOptOutIdChange(e); }}
                                    style={{background: "white", color: "black"}}>
                                    <option disabled selected>Prior Campaign</option>
                                    {raceTypes
                                        ? currCampaigns.map((campaigns) => {
                                            return <option key={campaigns.campaign_id_legacy} value={campaigns.campaign_id_legacy}>{campaigns.name}</option>;
                                        })
                                        : null}
                                </select>
                            </div>
                            <br/>

                            <div style={{visibility: "hidden", maxWidth: "300px"}}>
                                <div className="grid">
                                    <label className="userEditTitle" htmlFor="preferredAreaCode">Preferred Area Code:</label>
                                    <p id="areacodelist"></p>
                                    <input className="input input-bordered input-md w-full max-w-xs" type="text" id="preferredAreaCode" name="preferredAreaCode" value={preferredAreaCode} onChange={e => setPreferredAreaCode(e.target.value)} placeholder={'Preferred Area Code'}/>
                                </div>
                                <br/>
                            </div>

                            <button className="btn btn-outline bg-blue-200 mt-5 w-60" type="submit" disabled={disableSubmitButton}>Submit</button>
                        </div>
                    </form>
                </div>
                {/*<div className="bg-blue-50" style={{width: "330px"}} />*/}
            </div>
            {campaignSaving == true ?
                (
                    <div className="campaignSaving" style={{width: "1600px", marginTop: "-700px"}}>
                        <div className="loading loading-bars loading-lg" style={{ marginTop: "150px", marginLeft: "600px",
                            width: "350px", height: "350px"}} />
                        {/*<label>hello World</label>*/}
                    </div>
                )
                :
                (
                    <div></div>
                )
            }
        </div>

    );

};

export default NewCampaign;
