import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

const Event_TotError = ({ msgErrCount }) => {
    const { isMobileDisplay } = useContext(AuthContext);
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <>
            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className={`stats ${
                                    isMobileDisplay
                                        ? "eventTileMobile rounded-none pl-5 pt-5 pb-5 pr-5 bg-white"
                                        : "dashboardHalfHeightTile rounded-none ml-5 pl-5 pt-5 pb-5 pr-5"
                                    }`}>


                <div style={{margin: "50px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#ff0000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                </div>
                <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                    <div className="dashboardTileTitle">Total Message Errors</div>
                    <div className="dashboardTileMainData">{msgErrCount}</div>
                    <div className="dashboardTileSubtext">Total messages transmission fails</div>
                </div>
            </div>
            :
            <div className={`dashboardHalfHeightTile_Phone flex`}>
                <div style={{margin: "17px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#ff0000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                </div>
                <div className="grid" style={{margin: "10px 0px 0px 10px", height: "80px"}}>
                    <div className="dashboardTileTitle_Phone">Total Message Errors</div>
                    <div className="dashboardTileMainData_Phone">{msgErrCount}</div>
                    <div className="dashboardTileSubtext_Phone">Total messages transmission fails</div>
                </div>
            </div>
            }
        </>
    );
};

Event_TotError.propTypes = {
    msgErrCount: PropTypes.number.isRequired
};

export default Event_TotError;
