import { useEffect, useRef, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { EventReportContext } from "../../context/EventReportContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

// Datatables
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import JSZip from 'jszip';
window.JSZip = JSZip;
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone)

$.Datatable = require("datatables.net");
$.DataTable = require('datatables.net-select');
$.DataTable = require('datatables.net-buttons');
$.DataTable = require('datatables.net-buttons-dt');
$.DataTable = require('datatables.net-buttons/js/buttons.colVis');
$.DataTable = require('datatables.net-buttons/js/buttons.html5');
$.DataTable = require('datatables.net-buttons/js/buttons.print');
$.DataTable = require('pdfmake');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const EventResponses = ({ company_id, event_id }) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { eventDetails, loading } = useContext(EventReportContext);
    const { windowWidth, appRenderType } = useContext(ApplicationContext);
    const tableRef = useRef();
    const [responseList, setResponseList] = useState(0);
    const [datatableWidth, setDataTableWidth] = useState('500px');

    useEffect(() => {
        if (!responseList) {
            getEventResponses(company_id, event_id);
        }
    });

    useEffect(() => {
        if(appRenderType == "desktop"){
            setDataTableWidth('1570px');
        }
        else if(appRenderType == "tablet"){
            setDataTableWidth('790px');
        }
        else if(appRenderType == "phone"){
            setDataTableWidth("100vw");
        }

    }, [loading], [windowWidth], [appRenderType]);

    function initTable() {
        const filename = eventDetails.campaign+"-"+eventDetails.name+"-"+eventDetails.project_code+"-"+event_id+"-"+"responses";
        var table;
        if (appRenderType == "desktop") {
            table = $(tableRef.current).DataTable({
                data: [],
                dom: 'fBtlip',
                buttons: [
                    {extend:'csv', className: 'btn btn_accent mb-4 mr-2', filename: filename},
                    {extend:'excel', className: 'btn btn_accent mb-4 mr-2', filename: filename},
                    {extend:'pdf', className: 'btn btn_accent mb-4 mr-2', filename: filename},
                    {extend:'print', className: 'btn btn_accent mb-4 mr-2'},
                    {extend:'copy', className: 'btn btn_accent mb-4 mr-2'},
                    //{extend:'colvis', className: 'btn_low_dark'}
                ],
                columns: [
                    { title: 'InboundID' },
                    { title: 'FName' },
                    { title: 'LName' },
                    { title: 'MobilePhone' },
                    { title: 'Party' },
                    { title: 'VoterResponse' },
                    { title: 'Response Size' },
                    { title: 'Response DateTime'}
                ],
                order: [[6, 'desc']],
                options: {
                    processing: true,
                }
            });
        } else {
            //eslint-disable-next-line
            table = $(tableRef.current).DataTable({
                        data: [],
                        dom: 'ftlip',
                        columns: [
                            { title: 'FName' },
                            { title: 'VoterResponse'},
                            { title: 'Response Size', visible: false  },
                        ],
                        order: [[2, 'desc']],
                        options: {
                            processing: true,
                        }
                    });
        }
    }

    // Called any time responseList or eventDetails are modified
    useEffect(() => {
        if(responseList.length > 0)
        {
            if (eventDetails.id == event_id && responseList != 0) {
                if ( $.fn.dataTable.isDataTable( $(tableRef.current) )) {
                    $(tableRef.current).DataTable().destroy();
                }
                initTable();
                $(tableRef.current).dataTable().fnAddData(responseList);
            }
        }
    }, [responseList, eventDetails]);

    // Get event data from API
    const getEventResponses = async (company_id, event_id) => {
        if (!jwt || !company_id || company_id <= 0 || !event_id || event_id <= 0) {
            return;
        }

        // create a new list of data desired from API call and store it for app to use
        const newList = [];
        await axios.get(
            `${config.reportapi.url}/api/v1/event/eventmessagecarrierstatusresponses?correlation_id=${event_id}&company_id=${company_id}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            if (appRenderType == "desktop") {
                response.data.values.forEach(object => {
                    var _t = getEventStartDateTime_SQL(object['ResponseDateTime'], object['TimeZoneAbbrev']);
                    newList.push(
                        [
                            object['InboundID'],
                            object['FName'],
                            object['LName'],
                            object['MobilePhone'],
                            object['Party'],
                            object['VoterResponse'],
                            object['VoterResponse'].length,
                            _t.dispRequestedDate + ' ' + _t.dispRequestedTime + ' (' + object['TimeZoneAbbrev'] + ')'
                        ]
                    );
                });
            } else {
                response.data.values.forEach(object => {
                    newList.push(
                        [
                            object['FName'],
                            object['VoterResponse'],
                            object['VoterResponse'].length
                        ]
                    );
                });
            }
        });
        setResponseList(newList);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    function getEventStartDateTime_SQL(startUTCDateTime, timezone) {
        var _retVal = {utcDateTime:0, origTimeZone:'', timeZone:'', timeZoneOffset:0, timeZoneAbbrev:'', requestedDateTime:0, dispRequestedDate:'', dispRequestedTime:'', withinTimeWindow:true};

        try{
            var utcDateTime = dayjs.utc(startUTCDateTime);
            var _tz = '';
            var _offSet = 0;
            var _tzAbbrev = ''

            if(timezone == "Alaskan Standard Time" || timezone == "AKT" || timezone == "alaska"){
                _tz = "America/Anchorage";
                _tzAbbrev = 'AKT';
                _offSet = -9;
            }
            else if(timezone == "Hawaiian Standard Time" || timezone == "HIT" || timezone == "hawaii"){
                _tz = "Pacific/Honolulu";
                _tzAbbrev = 'HIT';
                _offSet = -10;
            }
            else if(timezone == "Pacific Standard Time" || timezone == "PT" || timezone == "pacific"){
                _tz = "America/Los_Angeles";
                _tzAbbrev = 'PT';
                _offSet = -8;
            }
            else if(timezone == "Arizona Standard Time" || timezone == "AZT" || timezone == "arizona"){
                _tz = "America/Phoenix";
                _tzAbbrev = 'AZT';
                _offSet = -8;
            }
            else if(timezone == "Mountain Standard Time" || timezone == "MT" || timezone == "mountain"){
                _tz = "America/Denver";
                _tzAbbrev = 'MT';
                _offSet = -7;
            }
            else if(timezone == "Central Standard Time" || timezone == "CT" || timezone == "central"){
                _tz = "America/Chicago";
                _tzAbbrev = 'CT';
                _offSet = -6;
            }
            else if(timezone == "Eastern Standard Time" || timezone == "ET" || timezone == "eastern"){
                _tz = "America/New_York";
                _tzAbbrev = 'ET';
                _offSet = -5;
            }
            else{
                _tz = "America/New_York";
                _tzAbbrev = 'ET';
                _offSet = -5;
            }

            var reqDateTime = utcDateTime.tz(_tz);
            var _shortReqStartDate = reqDateTime.format("MM/DD");
            var _shortreqStartTime = reqDateTime.format("HH:mm");

            _retVal.utcDateTime = utcDateTime;
            _retVal.origTimeZone = timezone;
            _retVal.timeZone = _tz;
            _retVal.timeZoneOffset = _offSet;
            _retVal.timeZoneAbbrev = _tzAbbrev;
            _retVal.requestedDateTime = reqDateTime;
            _retVal.dispRequestedDate = _shortReqStartDate;
            _retVal.dispRequestedTime = _shortreqStartTime;
        }catch(err){
            console.log("there was an error parsing the requested event date/time [2]", err);
        }
        return _retVal;
    }

    return(
        <>

        { appRenderType == "desktop" ? (
            <div className="dashboardFullWidthTripleHeightTile" style={{padding: "5px 5px 5px 5px"}}>
                <div className="eventRespTable" style={{background: "white", width: datatableWidth}}>
                    <table ref={tableRef} className="eventListComponentFull compact hover" style={{cursor: "pointer", padding: "20px 10px 0px 10px"}}></table>
                </div>
            </div>
            )
        : appRenderType == "tablet" ?(
            <div className="eventListMobile">
                <div className="eventRespTable_Mobile" style={{background: "white", width: datatableWidth}}>
                    <table ref={tableRef} className="eventListComponent stripe compact hover" style={{cursor: "pointer", padding: "20px 10px 0px 10px"}}></table>
                </div>
            </div>

        )
        : (
            <div className="eventList_Phone">
                <div className="eventRespTable_Phone" style={{background: "white", width: datatableWidth}}>
                    <table ref={tableRef} className="eventListComponent_Phone stripe compact hover" style={{cursor: "pointer", padding: "20px 10px 0px 10px"}}></table>
                </div>
            </div>

        )

        }
        </>

    )
};

EventResponses.propTypes = {
    company_id: PropTypes.string.isRequired,
    event_id: PropTypes.string.isRequired
};

export default EventResponses;
