import { React, useEffect, useState, useContext } from "react";
import { DataMapperContext } from "../../context/DataMapperContext";

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import TextField from '@mui/material/TextField';

const DataMapperRowFilter = () => {
    const { mapperFiles, selectedMin, selectedMax, selectedRandom,
        enableRange, enableRandom,
        closeRowFilter, saveRowFilterSettings} = useContext(DataMapperContext);

    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [random, setRandom] = useState(0);

    const [localEnableRange, setLocalEnableRange] = useState('true');
    const [localRandomSelection, setLocalRandomSelection] = useState("false");

    const [filterType, setFilterType] = useState("range");
    const [displayRangeControl, setDisplayRangeControl] = useState('visible');
    const [displayRandomControl, setDisplayRandomControl] = useState('collapse');
    const [possibleMax, setPossibleMax] = useState(0);

    useEffect(() => {
        setMin(selectedMin);
    }, [selectedMin])

    useEffect(() => {
        setMax(selectedMax);
        if(mapperFiles != null){
            var _possibleMax = 0;
            for (let i=0; i < mapperFiles.length; i++) {
                _possibleMax+=mapperFiles[i].qty;
            }
            setPossibleMax(_possibleMax);
        }
    }, [selectedMax])

    useEffect(() =>{
        setRandom(selectedRandom);
    }, [selectedRandom])


    useEffect(() => {
        setLocalEnableRange(enableRange);
        if(enableRange == true){
            setFilterType("range");
            setDisplayRandomControl("collapse");
            setDisplayRangeControl("visible");
        }
    }, [enableRange])

    useEffect(() => {
        setLocalRandomSelection(enableRandom);
        if(enableRandom == true){
            // console.log("test random");
            setFilterType("random");
            setDisplayRandomControl("visible");
            setDisplayRangeControl("collapse");
        }
    }, [enableRandom])

    function checkUpperRange(newVal){
        if(newVal > possibleMax){
            setMax(possibleMax)
        }
        else{
            setMax(newVal);
        }
    }

    function checkLowerRange(newVal){
        if(newVal.trim().length == 0){
            setMin(1);
        }
        if(newVal < 1){
            setMin(1);
        }
        else if(newVal >= max){
            setMin(max - 1);
        }
        else{
            setMin(newVal);
        }
    }

    function checkRandom(newVal){
        if(newVal == 0){
            setRandom(1);
        }
        else if(newVal > possibleMax){
            setRandom(possibleMax);
        }
        else{
            setRandom(newVal);
        }
    }

    function saveFilterSettings(){
        saveRowFilterSettings(min, max, random, localEnableRange, localRandomSelection);
    }

    function closeChildForm(){
        closeRowFilter();
    }

    function resetRange(){
        setMin(1);
        setMax(possibleMax);
    }

    function handleFilterTypeChange(source){
        var _type = source.target.value;

        setMin(1);
        setMax(possibleMax);

        setLocalEnableRange(false);
        setLocalRandomSelection(false);

        switch(_type){
        case "range":

            setRandom(0);
            setLocalEnableRange(true);

            setDisplayRangeControl("visible");
            setDisplayRandomControl("collapse");

            break;
        case "random":

            setRandom(possibleMax);
            setLocalRandomSelection(true);

            setDisplayRangeControl("collapse");
            setDisplayRandomControl("visible");

            break;
        }

        setFilterType(_type);
    }

    return (
        <div>
            <div className="datamapperFileRemove" style={{marginTop: "10px", marginLeft: "1050px"}} onClick={(e) => closeChildForm(e)}>X</div>
            <div className="collapse-title text-xl font-bold" style={{marginTop: "-40px", maxWidth: "1045px"}}>
                ROW FILTER DEFINITION
            </div>

            <hr style={{marginTop: "-10px"}}/>

            <div className="flex" style={{margin: "10px 0px 0px 20px", width: "100%"}}>
                <Button variant="outlined" startIcon={<SaveIcon fontSize="large" />}
                    style={{height: "60px", width: "220px", margin: "0px 0px 0px 0px", background: "white"}}
                    size="medium" onClick={() => saveFilterSettings()}>Save Filter</Button>


            </div>

            <div>
                <div style={{margin: "20px 0px 00px 22px"}}>

                    <div className="flex">

                        <div className="grid" style={{width: "400px"}}>
                            <div className="datamapperRowFilterInfoTitles">Number of Files in Map</div>
                            <div className="datamapperRowFilterInfoData">{mapperFiles.length}</div>
                            <div className="datamapperRowFilterInfoTitles">Total Maximum Rows From Files</div>
                            <div className="datamapperRowFilterInfoData">{possibleMax.toLocaleString()}</div>
                        </div>


                        <div className="grid eventCreateFunctionTile" style={{background: "cornflowerblue", width: "400px", height: "150px", marginLeft: "220px"}}>
                            <h5 style={{color: "white", fontWeight: "normal"}}>Mosaic Universe Range Size</h5>
                            <label style={{fontSize: "64px", fontWeight: "bold", color: "white", width: "380px", overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                                {
                                    filterType == "range" ?(
                                    max - min > 0 ?
                                        ((max - min) + 1).toLocaleString()
                                        :
                                        0
                                    )
                                    : filterType == "random" ? (
                                        Number(random).toLocaleString()
                                    )
                                    :
                                    (
                                        "N/A"
                                    )
                                }
                            </label>
                        </div>

                    </div>



                    <div style={{margin: "0px 0px 0px 0px", width: "565px"}}>
                        <br/><h6>Filter Down the universe size</h6><br/>
                    </div>

                    <hr/>

                    <RadioGroup value={filterType} onChange={handleFilterTypeChange}>
                        <FormControlLabel value="range" control={<Radio />} label="Universe Range"/>
                        <FormControlLabel value="random" control={<Radio />} label="Random selection"/>
                    </RadioGroup>

                    <div className="grid" style={{marginTop: "20px", visibility: displayRangeControl}}>

                        <div className="flex">

                            <div style={{marginTop: "35px", marginLeft: "0px"}}>
                                <h5>Mosaic Universe Range</h5>
                            </div>

                            <div style={{marginLeft: "20px", marginRight: "20px", marginTop: "50px"}}>
                                <hr style={{borderStyle: "dotted none none", borderColor: "lightgray", borderWidth: "3px", width: "334px"}} />
                            </div>

                            <div>
                                <TextField
                                    id="txtMin"
                                    type="number"
                                    label="First Record"
                                    onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                                    onChange={(e) => checkLowerRange(e.target.value)}
                                    value={min}
                                />
                            </div>

                            <div style={{marginLeft: "20px"}}>

                                <TextField
                                    id="txtMax"
                                    type="number"
                                    label="Last Record"
                                    onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                                    onChange={(e) => checkUpperRange(e.target.value)}
                                    value={max}
                                />
                            </div>

                        </div>

                        <div className="datamapperRowFilterResetRange" onClick={() => resetRange()}>reset range</div>

                    </div>

                    <div className="flex" style={{marginTop: "-87px", visibility: displayRandomControl}}>

                        <div style={{marginTop: "35px", marginLeft: "0px"}}>
                            <h5>Random Selection Subset</h5>
                        </div>

                        <div style={{marginLeft: "20px", marginRight: "20px", marginTop: "50px"}}>
                            <hr style={{borderStyle: "dotted none none", borderColor: "lightgray", borderWidth: "3px", width: "560px"}} />
                        </div>

                        <div>
                            <TextField
                                id="txtRandomQty"
                                type="number"
                                label="How Many Records?"
                                onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                                onChange={(e) => checkRandom(e.target.value)}
                                value={random}
                            />
                        </div>

                    </div>
                </div>

            </div>


        </div>
    );


};

export default DataMapperRowFilter;
