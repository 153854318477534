import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
// import { useAuth } from '../context';
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";

//import Session_LogoutImminent from "./Session_LogoutImminent";

import { v4 as uuidv4 } from 'uuid';

const SessionTimeout = () => {
  //eslint-disable-next-line
  const [events, setEvents] = useState(['click', 'load', 'scroll', 'init']);
  //eslint-disable-next-line
  const [second, setSecond] = useState(0);

  const [isOpen, setOpen] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { openLogoutImminent, setOpenLogoutImminent } = useContext(ApplicationContext);

  const [start, setStart] = useState('');

  //const [timeDisplay, setTimeDisplay] = useState('');

  useEffect(() => {
    if(start == false){
      setStart(uuidv4());
    }
  })

  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();

  // start inactive check
  let timeChecker = () => {

    startTimerInterval.current = setTimeout(() => {
        let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
        // console.log("startTimerInterval.current: storedTimeStamp = ", storedTimeStamp)
        warningInactive(storedTimeStamp);
    }, 60000);
  };

  // warning timer
  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 30;
      const popTime = 28;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const leftSecond = 120 - diff.seconds();

      // console.log("check");

      if (minPast === popTime) {
        // console.log("********************************** LOG OUT IMMINENT **********************************");
        setSecond(leftSecond);
        setOpen(true);
        setOpenLogoutImminent(true);
      }
      else if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        //console.log("*************************  LOG OUT! *****************************");
        setOpen(false);
        setOpenLogoutImminent(false);
        sessionStorage.removeItem('lastTimeStamp');
        //logout
        dispatch({ type: "LOGOUT" });

      }

    }, 1000);
  }

  useEffect(() => {

    if(openLogoutImminent == false){
      clearTimeout(startTimerInterval.current);
      clearInterval(warningInactiveInterval.current);

      setOpen(false);

      setStart(uuidv4());
    }
  }, [openLogoutImminent])


  // reset interval timer
  let resetTimer = useCallback(() => {

    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    timeStamp = moment().toISOString();
    sessionStorage.setItem('lastTimeStamp', timeStamp);

  }, [start]);

  useEffect(() => {
    // console.log("************** reset event hit here **********************");

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };

  }, [resetTimer, events]);

  if (!isOpen) {
    return null;
  }

  // change fragment to modal and handleclose func to close
  return (
    <div></div>
  );

};

export default SessionTimeout;
