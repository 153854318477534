/* eslint-disable react/prop-types */

/* eslint-disable */

import { React, useEffect, useState, useContext } from "react";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";
// import { CampaignContext } from "../../context/CampaignContext";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const AmplifyEventScheduler = () => {
    const { eventCoreInformation, updateCoreInformationScheduling, eventCoreChanged } = useContext(AmplifyEventContext);
    // const { defaultTimeZone } = useContext(CampaignContext);

    const [tzOffset, setTZOffset] = useState('');

    const [alaskaImage, setAlaskaImage] = useState('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Alaska.png');
    const [hawaiiImage, setHawaiiImage] = useState('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/hawaii.png');
    const [westCoastImage, setWestCoastImage] = useState('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/WestCoast.png');
    const [mountainImage, setMountainImage] = useState('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Mountain.png');
    const [arizonaImage, setArizonaImage] = useState('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Arizona.png');
    const [centralImage, setCentralImage] = useState('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Central.png');
    const [eastCoastImage, setEastCoastImage] = useState('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/EastCoast.png');

    const [alaskaSelected, setAlaskaSelected] = useState(false);
    const [hawaiiSelected, setHawaiiSelected] = useState(false);
    const [westCoastSelected, setWestCoastSelected] = useState(false);
    const [arizonaSelected, setArizonaSelected] = useState(false);
    const [mountainSelected, setMountainSelected] = useState(false);
    const [centralSelected, setCentralSelected] = useState(false);
    const [eastCoastSelected, setEastCoastSelected] = useState(false);

    const [eventCoreStatusId, setEventCoreStatusId] = useState(null);
    const [inboundStartTime, setInboundStartTime] = useState(null);

    //FOR THE UX ONLY
    const [eventDateTime, setEventDateTime] = useState();
    const [currentTimeZone, setCurrentTimeZone] = useState();

    // var thisTimezoneUniv = "";
    // var thisTimezoneValue = "";
    // var thisTimeString = "'select from the map'";

    // useEffect(() => {
    //     console.log("default time zone set => ", defaultTimeZone);
    //     setCurrentTimeZone(defaultTimeZone);
    // }, [])

    function updateLocalVars() {
        if (!eventCoreInformation) {
            return;
        }

        // console.log("*******************************************************************************************")
        // console.log("AmplifyEventScheduler: updateLocalVars()")
        // thisTimezoneUniv = eventCoreInformation.timezoneUniv;   // "America/Los_Angeles"
        // thisTimezoneValue = dayjs.utc(eventCoreInformation.start_datetime).tz(eventCoreInformation.timezoneUniv);
        // thisTimeString = eventCoreInformation.timezoneUniv != 'system' ? eventCoreInformation.timezoneUniv : 'select from the map';

        // console.log("THIS IS THE CURRENT CORE INFORMATION => ", dayjs());

        setCurrentTimeZone(eventCoreInformation.timezoneUniv);
        setInboundStartTime(dayjs.utc(eventCoreInformation.start_datetime).tz(eventCoreInformation.timezoneUniv));

        // console.log("AmplifyEventScheduler: updateLocalVars() thisTimezoneUniv, thisTimezoneValue, thisTimeString",
        //     thisTimezoneUniv, thisTimezoneValue, thisTimeString);
    }

    useEffect(() => {
        // console.log("AmplifyEventScheduler: useEffect()[eventCoreInformation] :", eventCoreInformation)
        if (eventCoreInformation?.start_datetime == null)
        {
            return;
        }

        // console.log("AmplifyEventScheduler: useEffect()[eventCoreInformation] :", eventCoreInformation)
        if (eventCoreStatusId != eventCoreInformation.statusId) {
            setEventCoreStatusId(eventCoreInformation.statusId);
            updateLocalVars();
        }

    }, [eventCoreInformation, eventCoreChanged])

    useEffect(() => {

        // console.log("THIS IS THE INBOUND START DATETIME => ", eventDateTime);

        if (!eventDateTime || !eventCoreInformation)
        {
            return;
        }

        // console.log("AmplifyEventScheduler: useEffect()[eventDateTime] :", eventDateTime)
        if(eventDateTime != eventCoreInformation.start_datetime && eventDateTime.length == null) {
            updateCoreInformationScheduling(eventCoreInformation.timezoneUniv, dayjs.utc(eventDateTime).tz(eventCoreInformation.timezoneUniv));
            updateLocalVars();
        }
    }, [eventDateTime])

    useEffect(() => {
        if (!eventCoreInformation ||
            !currentTimeZone ||
            currentTimeZone == '' ||
            currentTimeZone == 'system')
        {
            return;
        }

        // console.log("AmplifyEventScheduler: useEffect()[currentTimeZone] :", currentTimeZone)
        updateCoreInformationScheduling(currentTimeZone, eventCoreInformation.start_datetime);
        updateLocalVars();
    }, [currentTimeZone])

    function highlightZone(zone) {
        // console.log("AmplifyEventScheduler: highlightZone(zone) zone=", zone)

        switch (zone) {
            case "Alaska":
                setAlaskaImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Alaska_Highlight.png');
                break;
            case "Hawaii":
                setHawaiiImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Hawaii_Highlight.png');
                break;
            case "WestCoast":
                setWestCoastImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/westCoast_Highlight.png');
                break;
            case "Mountain":
                setMountainImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Mountain_Highlight.png');
                break;
            case "Arizona":
                setArizonaImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Arizona_Highlight.png');
                break;
            case "Central":
                setCentralImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Central_Highlight.png');
                break;
            case "EastCoast":
                setEastCoastImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/eastCoast_Highlight.png');
                break;
        }
    }

    function unHighlightZone(zone) {
        // console.log("AmplifyEventSchedular: unHighlightZone(zone) zone=", zone)
        switch (zone) {
            case "Alaska":
                if (alaskaSelected == false) {
                    setAlaskaImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Alaska.png');
                }
                break;
            case "Hawaii":
                if (hawaiiSelected == false) {
                    setHawaiiImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/hawaii.png');
                }
                break;
            case "WestCoast":
                if (westCoastSelected == false) {
                    setWestCoastImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/WestCoast.png');
                }
                break;
            case "Mountain":
                if (mountainSelected == false) {
                    setMountainImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Mountain.png');
                }
                break;
            case "Arizona":
                if (arizonaSelected == false) {
                    setArizonaImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Arizona.png');
                }
                break;
            case "Central":
                if (centralSelected == false) {
                    setCentralImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Central.png');
                }
                break;
            case "EastCoast":
                if (eastCoastSelected == false) {
                    setEastCoastImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/EastCoast.png');
                }
                break;
        }
    }
    //
    // // EDR - I need to fix this, I didn't mean for the timezone variables to always
    // // get re-evaluated and reset every time an element of eventCoreInformation gets changed
    // // it's not the biggest performance issue right now though
    useEffect(() => {
        // console.log("AmplifyEventScheduler: useEffect()[eventCoreInformation.timezoneUniv] :", eventCoreInformation.timezoneUniv)
        if (!eventCoreInformation)
        {
            return
        }

        // edr - trying to combine with the useEffect ()[eventCoreInformation] above
        if (eventCoreStatusId != eventCoreInformation.statusId) {
            setEventCoreStatusId(eventCoreInformation.statusId);
        }
        // edr - eof

        // console.log("eventDateTime value = ", eventDateTime);
        // console.log("currentTimezone value = ", currentTimezone);
        // console.log("dayjs.tz.guess = ", dayjs.tz.guess());
        // console.log("new event component loaded tz", currentTimezone);

        setAlaskaSelected(false);
        setHawaiiSelected(false);
        setWestCoastSelected(false);
        setArizonaSelected(false);
        setMountainSelected(false);
        setCentralSelected(false);
        setEastCoastSelected(false);

        setAlaskaImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Alaska.png');
        setHawaiiImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/hawaii.png');
        setWestCoastImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/WestCoast.png');
        setMountainImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Mountain.png');
        setArizonaImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Arizona.png');
        setCentralImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Central.png');
        setEastCoastImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/EastCoast.png');

        switch (eventCoreInformation.timezoneUniv) {
            case "America/Anchorage":
                setAlaskaImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Alaska_Highlight.png');
                setAlaskaSelected(true);
                setTZOffset("GMT-9");
                break;
            case "Pacific/Honolulu":
                setHawaiiImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Hawaii_Highlight.png');
                setHawaiiSelected(true);
                setTZOffset("GMT-10");
                break;
            case "America/Los_Angeles":
                setWestCoastImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/westCoast_Highlight.png');
                setWestCoastSelected(true);
                setTZOffset("GMT-8");
                break;
            case "America/Phoenix":
                setArizonaImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Arizona_Highlight.png');
                setArizonaSelected(true);
                setTZOffset("GMT-7/GMT-8");
                break;
            case "America/Denver":
                setMountainImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Mountain_Highlight.png');
                setMountainSelected(true);
                setTZOffset("GMT-7");
                break;
            case "America/Chicago":
                setCentralImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/Central_Highlight.png');
                setCentralSelected(true);
                setTZOffset("GMT-6");
                break;
            case "America/New_York":
                setEastCoastImage('https://afcuploadstorageprod.blob.core.windows.net/assets/1/maps/eastCoast_Highlight.png');
                setEastCoastSelected(true);
                setTZOffset("GMT-5");
                break;
        }

        // updateLocalVars();
        // setInboundStartTime(dayjs.utc(eventCoreInformation.start_datetime).tz(eventCoreInformation.timezoneUniv));

    // edr
    //}, [eventCoreInformation.timezoneUniv]);
    }, [eventCoreInformation, eventCoreChanged]);


/* eslint no-use-before-define: 2 */  // --> ON

    return(
    <div className="grid">
        <div className="grid" style={{margin: "10px 0px 20px 20px"}}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{margin: "0px 0px 20px 0px"}}>
                    <DateTimePicker
                                label="Event Date/Time"
                                // defaultValue={inboundStartTime}
                                value={inboundStartTime}
                                //inputFormat="hh:mm a"
                                timezone={ currentTimeZone }

                                // value={ thisTimezoneValue }

                                // value={dayjs(initEventDateTime)}
                                onChange={ setEventDateTime }
                                // onChange={e => updateEventDateTime(e)}
                                //views={["hours", "minutes"]}
                                clearable
                                disablePast
                                // minTime={dayjs().set('hour', 7)}
                                // maxTime={dayjs().set('hour', 20)}
                            />
                </div>
            </LocalizationProvider>

            <div style={{margin: "00px 0px 0px 0px"}}>
                <div className="timePickerTZDetailsTile">
                    <div>event time zone</div>
                    <div className="TZDetailsMainData">{ currentTimeZone }</div>
                    <div>{tzOffset}</div>
                </div>

            </div>

        </div>

        <div className="grid" style={{margin: "150px 0px 0px 50px"}}>

            <div className="flex" style={{margin: "-150px 0px 0px 0px"}}>
                <div>
                    <div style={{cursor: "pointer"}}>
                        <img src={alaskaImage} onClick={() => setCurrentTimeZone('America/Anchorage')} onMouseEnter={() => highlightZone('Alaska')}
                            onMouseLeave={() => unHighlightZone('Alaska')}/>
                    </div>

                    <div style={{margin: "200px 0px 0px 20px", cursor: "pointer"}}>
                        <img width={150} src={hawaiiImage} onClick={() => setCurrentTimeZone('Pacific/Honolulu')} onMouseEnter={() => highlightZone('Hawaii')}
                            onMouseLeave={() => unHighlightZone('Hawaii')}/>
                    </div>
                </div>
                <div className="flex" style={{margin: "230px 0px 0px -194px"}}>
                    <div style={{cursor: "pointer"}}>
                        <img src={westCoastImage} onClick={() => setCurrentTimeZone('America/Los_Angeles')} onMouseEnter={() => highlightZone('WestCoast')}
                            onMouseLeave={() => unHighlightZone('WestCoast')}/>
                    </div>
                    <div style={{margin:"248px 0px 0px -51px", cursor: "pointer", zIndex: "3"}}>
                        <img src={arizonaImage} onClick={() => setCurrentTimeZone('America/Phoenix')} onMouseEnter={() => highlightZone('Arizona')}
                            onMouseLeave={() => unHighlightZone('Arizona')}/>
                    </div>
                    <div style={{margin: "18px 0px 0px -101px", cursor: "pointer"}}>
                        <img src={mountainImage} onClick={() => setCurrentTimeZone('America/Denver')} onMouseEnter={() => highlightZone('Mountain')}
                            onMouseLeave={() => unHighlightZone('Mountain')}/>
                    </div>
                    <div style={{margin: "36px 0px 0px -82px", cursor: "pointer"}}>
                        <img src={centralImage} onClick={() => setCurrentTimeZone('America/Chicago')} onMouseEnter={() => highlightZone('Central')}
                            onMouseLeave={() => unHighlightZone('Central')}/>
                    </div>

                    <div style={{margin: "18px 0px 0px -138px", cursor: "pointer"}}>
                        <img src={eastCoastImage} onClick={() => setCurrentTimeZone('America/New_York')} onMouseEnter={() => highlightZone('EastCoast')}
                            onMouseLeave={() => unHighlightZone('EastCoast')}/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    );

};

export default AmplifyEventScheduler;
