import { React, useEffect, useState, useContext } from "react";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";
import { CampaignContext } from "../../context/CampaignContext";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import CommitIcon from '@mui/icons-material/Commit';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ApprovalIcon from '@mui/icons-material/Approval';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';

dayjs.extend(utc);
dayjs.extend(timezone);

const AmplifyEventMainMenu = () => {
    const { eventCoreInformation,
            eventPhoneInformation,
            eventApprovalInformation,
            eventSampleInformation,
            eventMessageBodyInformation,
            saveAmplifyEvent,
            setModalOpenArchive,
            setModalOpenCommit,
            postSampleMessage,
            postApprovalStart,
            getExistingEvent,
            eventCoreChanged
        } = useContext(AmplifyEventContext);

    const { setEventDataDirty } = useContext(CampaignContext);

    //material ui disabled button theme set
    const [commitButtonColor, setCommitButtonColor] = useState('');
    const [commitButtonBGColor, setCommitButtonBGColor] = useState('');

    const [archiveButtonColor, setArchiveButtonColor] = useState('');
    const [archiveButtonBGColor, setArchiveButtonBGColor] = useState('');

    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const [disableSendApprovalsButton, setDisableSendApprovalsButton] = useState(true);
    const [disableSendSamplesButton, setDisableSendSamplesButton] = useState(true);
    const [disableArchiveButton, setDisableArchiveButton] = useState(true);
    const [disableCommitButton, setDisableCommitButton] = useState(true);

    useEffect(() =>{
        if (!eventCoreInformation) {
            return;
        }

        switch(eventCoreInformation.status){
            case '':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(true);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'new':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(true);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'created':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(false);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(false);
                break;
            case 'process':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(true);
                setDisableCommitButton(true);
                setDisableArchiveButton(false);
                break;
            case 'processing':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(true);
                setDisableCommitButton(true);
                setDisableArchiveButton(false);
                break;
            case 'processed':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(false);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(false);
                break;
            case 'edit':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(true);
                setDisableCommitButton(true);
                setDisableArchiveButton(false);
                break;
            case 'editing':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(true);
                setDisableCommitButton(true);
                setDisableArchiveButton(false);
                break;
            case 'edited':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(false);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(false);
                break;
            case 'approve':
                setDisableSaveButton(true);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'approving':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(false);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(false);
                break;
            case 'approved':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(false);
                setDisableArchiveButton(false);
                break;
            case 'commit':
                setDisableSaveButton(true);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'committing':
                setDisableSaveButton(false);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'committed':
                setDisableSaveButton(true);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'reviewing':
                setDisableSaveButton(true);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'released':
                setDisableSaveButton(true);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'sending':
                setDisableSaveButton(true);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(false);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
            case 'completed':
                setDisableSaveButton(true);
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(true);
                setDisableCommitButton(true);
                setDisableArchiveButton(true);
                break;
        }

        if (!eventCoreInformation ||
            !eventCoreInformation.name ||
            !eventCoreInformation.name.length ||
            !eventCoreInformation.project_code.length)
        {
            setDisableSaveButton(true);
        }

        if (!eventApprovalInformation ||
            !eventApprovalInformation.approval_numbers ||
            !eventApprovalInformation.approval_numbers.length)
        {
            setDisableSendApprovalsButton(true);
        }

        if (!eventSampleInformation ||
            !eventSampleInformation.sample_numbers ||
            !eventSampleInformation.sample_numbers.length)
        {
            setDisableSendSamplesButton(true);
        }

        checkPhoneInformation();
    }, [eventCoreInformation, eventCoreChanged])

    useEffect(() => {
        checkPhoneInformation();
    }, [eventPhoneInformation])

    function checkPhoneInformation(){
        if (!eventPhoneInformation ||
            !eventPhoneInformation.admin_phone_number ||
            !eventPhoneInformation.customer_phone_number)
        {
            setDisableSendApprovalsButton(true);
            setDisableSendSamplesButton(true);
        } else {
            if (!eventPhoneInformation ||
                !eventPhoneInformation.admin_phone_number.length ||
                !eventPhoneInformation.customer_phone_number.length)
            {
                setDisableSendApprovalsButton(true);
                setDisableSendSamplesButton(true);
            }
            else{
                setDisableSendApprovalsButton(false);
                setDisableSendSamplesButton(false);
            }
        }
    }

    useEffect(() => {
        if (eventApprovalInformation &&
            eventApprovalInformation.approval_numbers &&
            eventApprovalInformation.approval_numbers.length > 0)
        {
            setDisableSendApprovalsButton(false);
        } else {
            setDisableSendApprovalsButton(true);
        }

        checkPhoneInformation();
    }, [eventApprovalInformation])

    useEffect(() => {
        if (eventSampleInformation &&
            eventSampleInformation.sample_numbers &&
            eventSampleInformation.sample_numbers.length > 0)
        {
            setDisableSendSamplesButton(false);
        } else {
            setDisableSendSamplesButton(true);
        }

        checkPhoneInformation();
    }, [eventSampleInformation])

    useEffect(() => {
        if (disableCommitButton) {
            setCommitButtonColor("lightgrey");
            setCommitButtonBGColor("white");
        }
        else{
            setCommitButtonColor("white");
            setCommitButtonBGColor("darkorange");
        }
    }, [disableCommitButton])

    useEffect(() => {
        if (disableArchiveButton) {
            setArchiveButtonColor("lightgray");
            setArchiveButtonBGColor("white");
        }
        else{
            setArchiveButtonColor("white");
            setArchiveButtonBGColor("red");
        }
    }, [disableArchiveButton])

    function saveEvent(){
        // console.log("\n\n")
        // console.log("AmplifyEventMainMenu: saveEvent() calling setEventDataDirty(true)")
        // console.log("\n\n")
        setEventDataDirty(true);
        saveAmplifyEvent();
    }

    function Approver_InitApprovalProcess(){
        // console.log("AmplifyEventMainMenu: Approver_InitApprovalProcess() calling postApprovalStart()")
        postApprovalStart();
    }

    async function Samples_SendAllSamples() {
        if (!eventSampleInformation || !eventPhoneInformation) {
            return;
        }

        // console.log("AmplifyEventMainMenu: entered Samples_SendAllSamples()")
        // for(let i=0; i < eventSampleInformation.sample_numbers.length; i++ ){
        //     var _msg = {
        //         targetPhone: eventSampleInformation.sample_numbers[i].phone,
        //         mediaURL: eventMessageBodyInformation.media_get_url,
        //         messageBody: eventMessageBodyInformation.content,
        //         metaDataMessage: '',
        //         fromPhone: eventPhoneInformation.admin_phone_number,
        //         sample_id: eventSampleInformation.sample_numbers[i].id,
        //         name: eventSampleInformation.sample_numbers[i].name
        //     };
        //
        //     await postSampleMessage(
        //         _msg
        //     )
        //     .catch(function (error) {
        //         console.log("there was an error send all the samples to the sample recipients => ", error);
        //     });
        // }

        for (let i=0; i < eventSampleInformation.sample_numbers.length; i++) {
            await postSampleMessage(
                {
                    targetPhone: eventSampleInformation.sample_numbers[i].phone,
                    mediaURL: eventMessageBodyInformation.media_get_url,
                    messageBody: eventMessageBodyInformation.content,
                    metaDataMessage: '',
                    fromPhone: eventPhoneInformation.admin_phone_number,
                    sample_id: eventSampleInformation.sample_numbers[i].id,
                    name: eventSampleInformation.sample_numbers[i].name
                }
            )
            .catch(function (error) {
                console.log("there was an error send all the samples to the sample recipients => ", error);
            });
        }
    }

    function handleModalOpen_Archive(){
        setModalOpenArchive(true);
    }

    function handleModalOpen_Commit(){
        setModalOpenCommit(true);
    }

    function refreshEvent(){
        // console.log("AmplifyEventMainMenu: refreshEvent() calling getExistingEvent(eventCoreInformation.key) ", eventCoreInformation.key)
        getExistingEvent(eventCoreInformation.key);
    }

    return(
        <div className="flex bg-neutral" style={{height: "75px", borderWidth: "1px", borderColor: "lightgray"}}>

            <div className="flex" style={{margin: "6px 0px 0px 10px", width: "220px"}}>
                <Button disabled={disableSaveButton} variant="outlined" startIcon={<SaveIcon fontSize="large" />}
                    style={{height: "60px", width: "200px", margin: "0px 0px 0px 0px", background: "white"}}
                    size="medium" onClick={() => saveEvent()}>Save Event</Button>
            </div>

            <Box sx={{ width: 40 }} />

            <Button disabled={disableSendApprovalsButton} variant="outlined" startIcon={<ApprovalIcon fontSize="large"/>}
                style={{height: "60px", width: "200px", margin: "6px 0px 0px 10px", background: "white"}} size="medium" onClick={() => Approver_InitApprovalProcess()}>Send Approvals</Button>

            <Button disabled={disableSendSamplesButton} variant="outlined" startIcon={<SendToMobileIcon fontSize="large"/>}
                style={{height: "60px", width: "200px", margin: "6px 0px 0px 10px", background: "white"}} size="medium" onClick={() => Samples_SendAllSamples()}>Send Samples</Button>

            <Box sx={{ width: 80 }} />

            <Button disabled={disableArchiveButton} variant="outlined" startIcon={<DeleteForeverIcon fontSize="large"/>}
                style={{height: "60px", width: "200px", margin: "6px 0px 0px 10px", background: archiveButtonBGColor, color: archiveButtonColor}} size="large" onClick={() => handleModalOpen_Archive()}>Archive Event</Button>

            <Button disabled={disableCommitButton} variant="outlined" startIcon={<CommitIcon fontSize="large"/>}
                style={{height: "60px", width: "300px", margin: "6px 0px 0px 10px", background: commitButtonBGColor, color: commitButtonColor}} size="large"
                    onClick={() => handleModalOpen_Commit()}>Commit For Processing</Button>

            <Box sx={{width: 100}}/>

            <Button variant="outlined" startIcon={<RefreshIcon fontSize="large" />}
                style={{height: "60px", width: "200px", margin: "6px 0px 0px 0px", background: "white"}}
                size="large" onClick={() => refreshEvent()}>Refresh</Button>
        </div>
    );
};

export default AmplifyEventMainMenu;
