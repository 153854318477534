import { useEffect, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import { EventReportContext } from "../../context/EventReportContext";
import { CompanyContext } from "../../context/CompanyContext";

// Custom components
import Topbar from "../../components/topbar/Topbar";

import Hierarchy_navigation from "../../components/navigation/Hierarchy_navigation";

import EventSuccessBarChart from "../../components/events/EventSuccessBarChart";
import EventSuccessDonutChart from "../../components/events/EventSuccessDonutChart";
import EventResponsesTimeline from "../../components/events/EventResponsesTimeline";
import EventResponses from "../../components/events/EventResponses";
import EventMembers from "../../components/events/EventMembers";

import EventResultsHeader from "../../components/events/EventResultsHeader";
import Event_TotOptOut from "../../components/events/Event_TotOptOut";
import Event_TotSent from "../../components/events/Event_TotSent";
import Event_Successful from "../../components/events/Event_Successful";
import Event_TotError from "../../components/events/Event_TotError";
import Event_TotCleaned from "../../components/events/Event_TotCleaned";

import EventResults_MsgPreview from "../../components/events/EventResults_MsgPreview";

//FOR TIMEOUT/LOGOFF
import SessionTimeout from "../../components/session/SessionTimeout";
import Session_LogoutImminent from "../../components/session/Session_LogoutImminent";
import Modal from '@mui/material/Modal';

export default function EventReport() {
    // Validate authentication
    const { jwt } = useContext(AuthContext);
    if (jwt === null) {
        window.location.href = "/login";
    }

    const [rotationFactorMsgDetails, setRotationFactorMsgDetails] = useState(0);
    const [clickMessageMsgDetails, setClickMessageMsgDetails] = useState('Click me to show the message details');

    const { setEventID, eventDetails, eventStats, loading, setEventStatus, displayReport } = useContext(EventReportContext);
    const { logoURL } = useContext(CompanyContext);
    const { appRenderType, openLogoutImminent, setOpenLogoutImminent, setLogoutCountdownStarted, closeLogoutModalAndReset } = useContext(ApplicationContext);

    const [searchParams] = useSearchParams();
    const event_key = searchParams.get('event_key');
    const company_id = searchParams.get('company_id');
    const event_status = searchParams.get('event_status');

    const [_eventName, setEventName] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    useEffect(() => {
        document.title = "Alliance Forge | Event Report";
        setEventID(event_key);
        setEventStatus(event_status);
    }, [jwt]);

    useEffect(() => {

        if(eventDetails.name.length > 0){
            setEventName(' (' + eventDetails.name + ')');
        }
        else {
            setEventName('');
        }

    }, [eventDetails])

    //collapsing menu icon rotation
    const styleMsgDetails = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorMsgDetails + "deg)"
    };

    function applyRotationMsgDetails(){
        if(rotationFactorMsgDetails == 0)
        {
            setRotationFactorMsgDetails(180);
            setClickMessageMsgDetails("Click me to hide the message details");
        }
        else
        {
            setRotationFactorMsgDetails(0);
            setClickMessageMsgDetails("Click me to show the message details");
        }
    }

    useEffect(() => {
        if(openLogoutImminent == true){
            handleModalOpen();
        }
        else{
            handleModalClose();
        }
    }, [openLogoutImminent])

    useEffect(() => {
        // console.log("modalOpen => ", modalOpen);
        if(modalOpen == false){
            setLogoutCountdownStarted(false);
            setOpenLogoutImminent(false);
        }

    }, [modalOpen])

    useEffect(() => {
        handleModalClose();
    }, [closeLogoutModalAndReset])

    return (
        <div >
            <SessionTimeout/>
            { appRenderType == "desktop" ?
            (
            <div className="mainPageDiv">
                    <div className="eventReportHeader"></div>

                    <div className="accountHeaderImage" style={{ position: "relative", zIndex: 10 }}>
                        <img className='mr-1 rounded-none border-solid border-2 object-cover' src={
                            `${ logoURL.length > 0 ?
                            (
                                logoURL
                            )
                            :
                            (
                                "https://afcuploadstorageprod.blob.core.windows.net/assets/1/def_account_logo.png"
                            )
                            }`} style={{width: "140px", height: "140px", border: "5px solid #555"}} />
                    </div>

                    <div className="accountHeaderCompanyName flex">
                        <div className="accountHeaderCompanyNameTitle">COMPLETION REPORT</div>
                        <div className="reportHeaderEvenNameTitle ml-5">{_eventName}</div>
                    </div>



                    <Topbar/>

                    <div className="flex">
                        <div className="hierarchyNavigationTileFull">
                            <Hierarchy_navigation status={"report"} objectWidth={"full"}/>
                        </div>

                    </div>

                    {
                        displayReport == false ?
                    (
                        <div style={{background: "white", width: "75%", height: "1200px", marginLeft: "275px", marginTop: "100px"}}>
                            <label style={{fontSize: "36px"}}>This event was just recently completed.  Generally, we see both responses and finalized statistics from the phone carriers after about 2 hours. The reports and statistics will be available 2 hours after transmission. Thank you!</label>
                            <img style={{marginLeft: "200px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify/Data%20Loading%20in%20Progress.png"/>
                        </div>
                    )
                    :
                    (
                    <div>
                        {loading ?

                            (
                                <div className="fullScreenLoading" style={{margin: "0px 0px 0px 0px"}}>
                                    <span className="loading loading-bars loading-lg" style={{marginTop: "200px", marginLeft: "540px", height: "500px", width: "500px"}} />
                                    {/*<label>hello World</label>*/}
                                </div>
                            )
                            :
                            (
                                <div className="grid">

                                    <div style={{margin: "10px 0px 0px 0px"}}>
                                        <EventResultsHeader CampaignName={eventDetails.campaign} EventID={eventDetails.id}
                                            EventStatus={eventDetails.status||''} EventName={eventDetails.name}
                                            EventDate={eventDetails.dtz_string}/>
                                    </div>



                                    <div className="eventReportCollapseDetails collapse mt-6">
                                        <input type="checkbox" onClick={applyRotationMsgDetails}/>
                                        <div className="collapse-title flex">
                                            <svg style={styleMsgDetails} xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24"
                                                fill="none" stroke={"#000000"} strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/>
                                            </svg>
                                            <div className="collapse-title ml-2 font-medium" style={{width: "500px"}}>{clickMessageMsgDetails}</div>
                                        </div>

                                        <div className="collapse-content">
                                            <div className="flex" style={{background: "white"}}>
                                                <EventResults_MsgPreview SMS={eventDetails.msg_body} MMS={eventDetails.msg_mms}
                                                    FormattedSMS={eventDetails.msg_body_formatted}/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="flex" style={{width: "2030px"}}>
                                        <div>
                                            <div className="flex">
                                                <Event_TotSent TotSent={eventStats.msg_sent_count || 0}/>
                                                <Event_Successful TotSuccess={eventStats.msg_success_count || 0}/>
                                                <Event_TotOptOut TotOptOut={eventStats.msg_opt_out_count || 0}/>
                                            </div>
                                            <div className="flex">
                                                <Event_TotError msgErrCount={eventStats.msg_err_count || 0}/>
                                                <Event_TotCleaned totalCleaned={ eventStats.msg_member_cleaned_count || 0 }/>
                                                <EventMembers event_id={event_key}/>
                                            </div>
                                        </div>
                                        <EventSuccessDonutChart />
                                    </div>

                                    <div>
                                        <div className="grid">

                                            <div className="flex" style={{margin: "0px 0px 0px 0px"}}>
                                                <EventSuccessBarChart />

                                                <EventResponsesTimeline company_id={company_id || 0} event_id={event_key} />
                                            </div>
                                            <div className="grid">
                                                <div className="flex">


                                                </div>
                                                <EventResponses company_id={company_id || 0} event_id={event_key}/>

                                                {/*}
                                                <div className="grid" style={{position: "absolute", bottom: "40px", left: "50px", fontSize: "12px", height: "50px"}}>
                                                    <div>Admin App ID:</div>
                                                    <div>{adminBWApplicationID}</div>
                                                </div>

                                                <div className="grid" style={{position: "absolute", bottom: "40px", left: "400px", fontSize: "12px", height: "50px"}}>
                                                    <div>Customer App ID:</div>
                                                    <div>{custBWApplicationID}</div>
                                                </div>

                                                <div className="grid" style={{position: "absolute", bottom: "40px", left: "800px", fontSize: "12px", height: "50px"}}>
                                                    <div>Event Key:</div>
                                                    <div>{event_key}</div>
                                                </div>
                                                {*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )

                        }
                    </div>
                    )
                    }
            </div>
            )
            : appRenderType == "tablet" ?
            (
            <div className="mainPageDiv_Mobile">
                    <div className="accountHeader_Mobile"></div>

                    <div className="accountHeaderImage_Mobile" style={{ position: "relative", zIndex: 10 }}>
                        <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "100px", height: "100px", border: "5px solid #555"}} src={(logoURL)}/>
                    </div>

                    <div className="accountHeaderCompanyName_Mobile flex">
                        <div className="accountHeaderCompanyNameTitle_Mobile">COMPLETION REPORT</div>
                    </div>

                    <Topbar displayAdmin={true}/>

                    <div className="hierarchyNavigationTileDouble">
                        <Hierarchy_navigation status={"report"} />
                    </div>

                    {
                        displayReport==false ?
                    (
                        <div style={{background: "white", width: "75%", height: "1200px", marginLeft: "100px", marginTop: "100px"}}>
                            <label style={{fontSize: "36px"}}>This event was just recently completed.  Generally, we see both responses and finalized statistics from the phone carriers after about 2 hours. The reports and statistics will be available 2 hours after transmission. Thank you!</label>
                            <img style={{marginLeft: "0px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify/Data%20Loading%20in%20Progress.png"/>
                        </div>
                    )
                    :
                    (
                    <div>
                        {loading?

                            (
                                <div className="fullScreenLoading_Mobile" style={{margin: "0px 0px 0px 0px"}}>
                                    <span className="loading loading-bars loading-lg" style={{marginTop: "200px", marginLeft: "185px", width: "400px"}} />
                                    {/*<label>hello World</label>*/}
                                </div>
                            )
                            :
                            (
                                <div className="grid">

                                    <div style={{margin: "10px 0px 0px 0px"}}>
                                        <EventResultsHeader CampaignName={eventDetails.campaign} EventID={eventDetails.id}
                                            EventStatus={eventDetails.status||''} EventName={eventDetails.name}
                                            EventDate={eventDetails.dtz_string}/>
                                    </div>


                                    <div className="eventReportCollapseDetails_Mobile collapse mt-6" style={{marginTop: "20px"}}>
                                        <input type="checkbox" onClick={applyRotationMsgDetails}/>
                                        <div className="collapse-title flex">
                                            <svg style={styleMsgDetails} xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24"
                                                fill="none" stroke={"#000000"} strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/>
                                            </svg>
                                            <div className="collapse-title ml-2 font-medium" style={{width: "500px"}}>{clickMessageMsgDetails}</div>
                                        </div>

                                        <div className="collapse-content">
                                            <div className="flex" style={{background: "white"}}>
                                                <EventResults_MsgPreview SMS={eventDetails.msg_body} MMS={eventDetails.msg_mms}
                                                    FormattedSMS={eventDetails.msg_body_formatted}/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="flex">
                                        <div>
                                            <div className="flex">
                                                <Event_TotSent TotSent={eventStats.msg_sent_count || 0}/>
                                                <Event_Successful TotSuccess={eventStats.msg_success_count || 0}/>
                                            </div>
                                            <div className="flex">
                                                <Event_TotOptOut TotOptOut={eventStats.msg_opt_out_count || 0}/>
                                                <Event_TotError msgErrCount={eventStats.msg_err_count || 0}/>
                                            </div>
                                            <div className="flex">
                                                <Event_TotCleaned totalCleaned={ eventStats.msg_member_cleaned_count || 0 }/>
                                                <EventMembers event_id={event_key}/>
                                            </div>
                                            <EventSuccessDonutChart/>
                                        </div>
                                    </div>

                                    <div className="grid">
                                        <EventSuccessBarChart />
                                        <EventResponsesTimeline company_id={company_id || 0} event_id={event_key}/>
                                        <EventResponses company_id={company_id || 0} event_id={event_key}/>
                                    </div>
                                </div>

                            )

                        }
                    </div>
                    )
                    }
            </div>
            )
            :
            (
            <div className="mainPageDiv_Phone">
                    <div className="accountHeader_Phone"></div>

                    <div className="accountHeaderImage_Phone" style={{ position: "relative", zIndex: 10 }}>
                        <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "100px", height: "100px", border: "5px solid #555"}} src={(logoURL)}/>
                    </div>

                    <div className="accountHeaderCompanyName_Phone flex">
                        <div className="accountHeaderCompanyNameTitleReport_Phone">COMPLETION REPORT</div>
                    </div>

                    <Topbar displayAdmin={true}/>

                    <div className="hierarchyNavigationTileDouble_Phone">
                        <Hierarchy_navigation status={"report"} />
                    </div>

                    {
                        displayReport == false ?
                    (
                        <div style={{background: "white", width: "75%", height: "1200px", marginLeft: "75px", marginTop: "100px"}}>
                            <label style={{fontSize: "24px"}}>This event was just recently completed.  Generally, we see both responses and finalized statistics from the phone carriers after about 2 hours. The reports and statistics will be available 2 hours after transmission. Thank you!</label>
                            <img style={{marginLeft: "0px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify/Data%20Loading%20in%20Progress.png"/>
                        </div>
                    )
                    :
                    (
                    <div>
                        {loading?

                            (
                                <div className="fullScreenLoading_Phone" style={{margin: "0px 0px 0px 0px"}}>
                                    <span className="loading loading-bars loading-lg" style={{marginTop: "130px", marginLeft: "120px", width: "200px"}} />
                                    {/*<label>hello World</label>*/}
                                </div>
                            )
                            :
                            (
                                <div className="grid">

                                    <div style={{margin: "10px 0px 0px 0px"}}>
                                        <EventResultsHeader CampaignName={eventDetails.campaign} EventID={eventDetails.id}
                                            EventStatus={eventDetails.status||''} EventName={eventDetails.name}
                                            EventDate={eventDetails.dtz_string}/>
                                    </div>


                                    <div className="eventReportCollapseDetails_Phone collapse mt-6" style={{marginTop: "20px"}}>
                                        <input type="checkbox" onClick={applyRotationMsgDetails}/>
                                        <div className="collapse-title flex">
                                            <svg style={styleMsgDetails} xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24"
                                                fill="none" stroke={"#000000"} strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/>
                                            </svg>
                                            <div className="collapse-title ml-2 font-medium" style={{width: "500px"}}>{clickMessageMsgDetails}</div>
                                        </div>

                                        <div className="collapse-content">
                                            <div className="flex" style={{background: "white"}}>
                                                <EventResults_MsgPreview SMS={eventDetails.msg_body} MMS={eventDetails.msg_mms}
                                                    FormattedSMS={eventDetails.msg_body_formatted}/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="grid" >
                                        <Event_TotSent TotSent={eventStats.msg_sent_count || 0}/>
                                        <Event_Successful TotSuccess={eventStats.msg_success_count || 0}/>
                                        <Event_TotOptOut TotOptOut={eventStats.msg_opt_out_count || 0}/>
                                        <Event_TotError msgErrCount={eventStats.msg_err_count || 0}/>
                                        <Event_TotCleaned totalCleaned={ eventStats.msg_member_cleaned_count || 0 }/>
                                        <EventMembers event_id={event_key}/>

                                        <EventSuccessDonutChart/>
                                        <EventSuccessBarChart />
                                        <EventResponsesTimeline company_id={company_id || 0} event_id={event_key} />
                                        <EventResponses company_id={company_id || 0} event_id={event_key}/>
                                    </div>


                                </div>

                            )

                        }
                    </div>
                    )
                    }
            </div>
            )
            }
            <div>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <div className="LogoffImminent_modalWindow">
                        <Session_LogoutImminent />
                    </div>
                </Modal>
            </div>
        </div>
    );
}
