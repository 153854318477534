import { useContext } from "react";
import { UnifyContext } from "../../context/UnifyContext";

import TextField from '@mui/material/TextField';

const UnifyCampaignCreate_DemoUniversePage = () => {

    const {
            sampleFirstName_1,setSampleFirstName_1,
            sampleLastName_1,setSampleLastName_1,
            sampleEmail_1,setSampleEmail_1,
            samplePhone_1,setSamplePhone_1,

            sampleFirstName_2,setSampleFirstName_2,
            sampleLastName_2,setSampleLastName_2,
            sampleEmail_2,setSampleEmail_2,
            samplePhone_2,setSamplePhone_2,

            sampleFirstName_3,setSampleFirstName_3,
            sampleLastName_3,setSampleLastName_3,
            sampleEmail_3,setSampleEmail_3,
            samplePhone_3,setSamplePhone_3,

            sampleFirstName_4,setSampleFirstName_4,
            sampleLastName_4,setSampleLastName_4,
            sampleEmail_4,setSampleEmail_4,
            samplePhone_4,setSamplePhone_4,

            sampleFirstName_5,setSampleFirstName_5,
            sampleLastName_5,setSampleLastName_5,
            sampleEmail_5,setSampleEmail_5,
            samplePhone_5,setSamplePhone_5,
        } = useContext(UnifyContext);

    return (

        <div style={{height: "500px", margin: "0px 30px 20px 30px", width: "1540px"}}>

            <div className="unifyCallOut" style={{width: "90%", margin: "40px 60px 40px 0px", background: "darkOrange"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>This is where the candidate would drag and drag their universe file and would select their universe. For now, we just have data entry for emails and phones...</div>
            </div>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>The samples to send... email and text</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 1 First Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleFirstName_1(e.target.value)}
                        value={sampleFirstName_1}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 1 Last Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleLastName_1(e.target.value)}
                        value={sampleLastName_1}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 1 Email Address"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleEmail_1(e.target.value)}
                        value={sampleEmail_1}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 1 Cell Phone"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSamplePhone_1(e.target.value)}
                        value={samplePhone_1}
                    />
                </div>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 2 First Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleFirstName_2(e.target.value)}
                        value={sampleFirstName_2}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 2 Last Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleLastName_2(e.target.value)}
                        value={sampleLastName_2}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 2 Email Address"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleEmail_2(e.target.value)}
                        value={sampleEmail_2}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 2 Cell Phone"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSamplePhone_2(e.target.value)}
                        value={samplePhone_2}
                    />
                </div>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 3 First Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleFirstName_3(e.target.value)}
                        value={sampleFirstName_3}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 3 Last Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleLastName_3(e.target.value)}
                        value={sampleLastName_3}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 3 Email Address"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleEmail_3(e.target.value)}
                        value={sampleEmail_3}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 3 Cell Phone"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSamplePhone_3(e.target.value)}
                        value={samplePhone_3}
                    />
                </div>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 4 First Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleFirstName_4(e.target.value)}
                        value={sampleFirstName_4}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 4 Last Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleLastName_4(e.target.value)}
                        value={sampleLastName_4}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 4 Email Address"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleEmail_4(e.target.value)}
                        value={sampleEmail_4}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 4 Cell Phone"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSamplePhone_4(e.target.value)}
                        value={samplePhone_4}
                    />
                </div>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 5 First Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleFirstName_5(e.target.value)}
                        value={sampleFirstName_5}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 5 Last Name"
                        style={{width: "200px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleLastName_5(e.target.value)}
                        value={sampleLastName_5}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 5 Email Address"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSampleEmail_5(e.target.value)}
                        value={sampleEmail_5}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Sample 5 Cell Phone"
                        style={{width: "380px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSamplePhone_5(e.target.value)}
                        value={samplePhone_5}
                    />
                </div>
            </div>

            <div className="unifyCallOut" style={{width: "90%", margin: "40px 60px 40px 0px"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>And just like that, you are done! CONGRATULATIONS! Press the &quot;GENERATE NOW&quot; button to get your micro-site, emails, and text messages generated. Time to go win this race!</div>
            </div>
        </div>

    );
};

export default UnifyCampaignCreate_DemoUniversePage;
