import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

export const AdminContext = createContext();

export const AdminContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);

    const [businesses, setBusinesses] = useState();

    useEffect(() => {
        if (!businesses) {
            getBusinesses();
        }
    },[]);

    const getBusinesses = async () => {
        if (!jwt) {
            console.log("AdminContext: getBusinesses() has no jwt and is leaving");
            return;
        }

        await axios.get(
            `${config.accountapi.url}/api/v1/businesses?start=0&limit=500&status=active`,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("There was an error collecting the business details -> ", error);
        })
        .then(function (response) {
            var business_list = [];
            response.data.values.forEach(object => {
                business_list.push({
                    "key": object['key'],
                    "account_id_legacy": object['account_id_legacy'],
                    "name": object['name'],
                    "logo_url": object['logo_url']
                });
            });
            business_list.sort((a,b) => (a.name > b.name) ? 1: -1);
            setBusinesses(business_list);
        });
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    function getBusiness(identifier) {
        if (!businesses || !identifier) {
            return;
        }

        var found;
        if (identifier.length > 3) {
            found = businesses.find((b) => b.key == identifier);
        } else {
            found = businesses.find((b) => b.account_id_legacy == identifier);
        }

        return found;
    }

    return (
        <AdminContext.Provider value={{
            businesses:                 businesses,
            getBusiness:                getBusiness
        }}>
            {children}
        </AdminContext.Provider>
    );
}
