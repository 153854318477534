import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import "datatables.net-dt/css/jquery.dataTables.min.css";
require ("highcharts/modules/exporting")(Highcharts);

const EventResponsesTimeline = ({ company_id, event_id }) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { appRenderType } = useContext(ApplicationContext);
    const [reportWidth, setReportWidth] = useState("800");

    //eslint-disable-next-line
    const [apiValues, setAPIValues] = useState({
        eventName: '',
        msgSentCount: -1,
        msgErrCount: 0,
        msgSuccessCount: 0,
        msgOptOutCount: 0
    });

    const [timelineData, setTimelineData] = useState(-1);

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    useEffect(() => {
        switch(appRenderType) {
            case "desktop":
                setReportWidth(790);
                break;
            case "tablet":
                setReportWidth(790);
                break;
            case "phone":
                setReportWidth(400);
                break;
        }

    }, [appRenderType])

    useEffect(() => {
        if (timelineData == -1) {
            getEventResponsesTimeline();
        }
    }, [event_id, company_id]);

    // Get event data from API
    const getEventResponsesTimeline = async () => {
        if (!jwt || !company_id || company_id <= 0 || !event_id || event_id <= 0)
            return;

        var tempAPIValues;
        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectAmplifyResponseTimeline?correlation_id=${event_id}&timeslice=15&company_id=${company_id}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            tempAPIValues = response.data.values;
        });

        setTimelineData(tempAPIValues);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    return (

        <div>
            { appRenderType == "desktop" ?
            (
            <div className="dashboardDoubleWidthTile" style={{padding: "5px 5px 5px 5px", width: "800px"}}>
                <HighchartsReact highcharts={Highcharts} options={
                    {
                        chart: {
                            height: 360,
                            width: 1005,
                            renderTo: 'container',
                            zoomType: 'x'
                        },
                        title: {
                            text: apiValues.eventName
                        },
                        subtitle: {
                            text: document.ontouchstart === undefined ?
                            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
                            align: 'left'
                        },
                        xAxis: {
                            type: 'int',
                            title: {
                                text: 'Minutes Since Message Sent'
                            }
                        },
                        yAxis: {
                            title: {
                                text: 'Responses'
                            }
                        },
                        legend: {
                            enabled: false
                        },

                        plotOptions: {
                            area: {
                                fillColor: {
                                    linearGradient: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 0,
                                        y2: 1
                                    },
                                    stops: [
                                        [0, "#293f78"],
                                        [1, "#aecbe5"]
                                        //[2, "#639aca"]
                                        //[1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                                    ]
                                },
                                marker: {
                                    radius: 2,
                                    lineColor: "#293f78",
                                    fillColor: "#293f78",
                                },
                                lineWidth: 1,

                                states: {
                                    hover: {
                                        lineWidth: 1
                                    }
                                },
                                threshold: null
                            }
                        },

                        series: [
                            {
                                type: 'area',
                                name: 'Response Quantity',
                                data: timelineData
                            }
                        ],
                    }
                }/>
            </div>
            )
            : appRenderType == "tablet" ?
            (
            <div className="dashboardDoubleWidthTile" style={{padding: "5px 5px 5px 5px", width: "790px"}}>
                <HighchartsReact highcharts={Highcharts} options={
                    {
                        chart: {
                            height: 360,
                            width: 1005,
                            renderTo: 'container',
                            zoomType: 'x'
                        },
                        title: {
                            text: apiValues.eventName
                        },
                        subtitle: {
                            text: document.ontouchstart === undefined ?
                            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
                            align: 'left'
                        },
                        xAxis: {
                            type: 'int',
                            title: {
                                text: 'Minutes Since Message Sent'
                            }
                        },
                        yAxis: {
                            title: {
                                text: 'Responses'
                            }
                        },
                        legend: {
                            enabled: false
                        },

                        plotOptions: {
                            area: {
                                fillColor: {
                                    linearGradient: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 0,
                                        y2: 1
                                    },
                                    stops: [
                                        [0, "#293f78"],
                                        [1, "#aecbe5"]
                                        //[2, "#639aca"]
                                        //[1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                                    ]
                                },
                                marker: {
                                    radius: 2,
                                    lineColor: "#293f78",
                                    fillColor: "#293f78",
                                },
                                lineWidth: 1,

                                states: {
                                    hover: {
                                        lineWidth: 1
                                    }
                                },
                                threshold: null
                            }
                        },

                        series: [
                            {
                                type: 'area',
                                name: 'Response Quantity',
                                data: timelineData
                            }
                        ],
                    }
                }/>
            </div>
            )
            :
            (
            <div className="dashboardDoubleWidthDoubleHeightTile_Phone" style={{padding: "5px 5px 5px 5px"}}>
                <HighchartsReact highcharts={Highcharts} options={
                    {
                        chart: {
                            height: 360,
                            width: reportWidth,
                            renderTo: 'container',
                            zoomType: 'x'
                        },
                        title: {
                            text: apiValues.eventName
                        },
                        subtitle: {
                            text: document.ontouchstart === undefined ?
                            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
                            align: 'left'
                        },
                        xAxis: {
                            type: 'int',
                            title: {
                                text: 'Minutes Since Message Sent'
                            }
                        },
                        yAxis: {
                            title: {
                                text: 'Responses'
                            }
                        },
                        legend: {
                            enabled: false
                        },

                        plotOptions: {
                            area: {
                                fillColor: {
                                    linearGradient: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 0,
                                        y2: 1
                                    },
                                    stops: [
                                        [0, "#293f78"],
                                        [1, "#aecbe5"]
                                        //[2, "#639aca"]
                                        //[1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                                    ]
                                },
                                marker: {
                                    radius: 2,
                                    lineColor: "#293f78",
                                    fillColor: "#293f78",
                                },
                                lineWidth: 1,

                                states: {
                                    hover: {
                                        lineWidth: 1
                                    }
                                },
                                threshold: null
                            }
                        },

                        series: [
                            {
                                type: 'area',
                                name: 'Response Quantity',
                                data: timelineData
                            }
                        ],
                    }
                }/>
            </div>
            )
            }
        </div>

    );
};

EventResponsesTimeline.propTypes = {
    company_id: PropTypes.string.isRequired,
    event_id: PropTypes.string.isRequired,
    setEventResults: PropTypes.func
};

export default EventResponsesTimeline;
