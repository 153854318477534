const Campaign_DigitalAssetManagement= () => {
    return (
        <>
            <div>
                <div className="flex" style={{margin: "10px 0px 0px 30px"}}>
                    <div style={{margin: "50px 0px 0px 20px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" fill="none"
                            stroke="#ffbb00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                            </path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line>
                        </svg>
                    </div>
                    <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                        <div className="dashboardTileTitle">Digital Asset Management</div>
                        <div className="dashboardTileMainData">COMING SOON</div>
                        <div className="dashboardTileSubtext">Single pane of glass for managing your Crystal Video Processing as well as all your campaign logos, images, and digital content</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Campaign_DigitalAssetManagement;
