import { useContext } from "react";
import { EventReportContext } from "../../context/EventReportContext";
import { ApplicationContext } from "../../context/ApplicationContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);

const EventSuccessDonutChart = () => {
    const { groupData, detailData } = useContext(EventReportContext);
    const { appRenderType } = useContext(ApplicationContext);

    const colors = Highcharts.getOptions().colors.map((c, i) =>
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        Highcharts.color(Highcharts.getOptions().colors[0])    //"#293f78")//
            .brighten((i - 3) / 7)
            .get()
    );

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    return (
        <div>
            {appRenderType == "desktop" ?
            (
            <div className="stats dashboardTileRightCol">

                <HighchartsReact  highcharts={Highcharts} options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'pie'
                        },
                        title: {
                            text: 'Success Rate'
                        },
                        yAxis: {
                            title: {
                                text: 'Total percent messages'
                            }
                        },
                        plotOptions: {
                            pie: {
                                shadow: false,
                                startAngle: 180,
                                colors,
                            }
                        },
                        series: [
                            {
                                name: 'Messages',
                                size: '60%',
                                data: groupData,
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.name}: {point.percentage:.1f}%',
                                    distance: 25
                                }
                            },
                            {
                                name: 'Details',
                                size: '80%',
                                innerSize: '60%',
                                data: detailData,
                                dataLabels: {
                                    distance: 50,
                                    enabled: true,
                                    format: '<b>{point.name}:</b> <span style="opacity: 0.5">{point.percentage:.1f}%</span>',
                                    filter: {
                                        property: 'y',
                                        operator: '>',
                                        value: 1
                                    },
                                    style: {
                                        fontWeight: 'normal'
                                    }

                                },
                                id: 'details',
                            },
                        ]
                    }
                }/>
            </div>
            )
            : appRenderType == "tablet" ?
            (
            <div className="stats dashboardDoubleWidthTile">

                <HighchartsReact  highcharts={Highcharts} options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'pie'
                        },
                        title: {
                            text: 'Success Rate'
                        },
                        yAxis: {
                            title: {
                                text: 'Total percent messages'
                            }
                        },
                        plotOptions: {
                            pie: {
                                shadow: false,
                                startAngle: 180,
                                colors,
                            }
                        },
                        series: [
                            {
                                name: 'Messages',
                                size: '60%',
                                data: groupData,
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.name}: {point.percentage:.1f}%',
                                    distance: 25
                                }
                            },
                            {
                                name: 'Details',
                                size: '80%',
                                innerSize: '60%',
                                data: detailData,
                                dataLabels: {
                                    distance: 50,
                                    enabled: true,
                                    format: '<b>{point.name}:</b> <span style="opacity: 0.5">{point.percentage:.1f}%</span>',
                                    filter: {
                                        property: 'y',
                                        operator: '>',
                                        value: 1
                                    },
                                    style: {
                                        fontWeight: 'normal'
                                    }

                                },
                                id: 'details',
                            },
                        ]
                    }
                }/>
            </div>
            )
            :
            (
            <div className="stats dashboardDoubleWidthTile_Phone">

                <HighchartsReact  highcharts={Highcharts} options={
                    {
                        chart: {
                            renderTo: 'container',
                            type: 'pie'
                        },
                        title: {
                            text: ''
                        },
                        yAxis: {
                            title: {
                                text: 'Total percent messages'
                            }
                        },
                        plotOptions: {
                            pie: {
                                shadow: false,
                                startAngle: 180,
                                colors,
                            }
                        },
                        series: [
                            {
                                name: 'Messages',
                                size: '60%',
                                data: groupData,
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.name}: {point.percentage:.1f}%',
                                    distance: 25
                                }
                            },
                            {
                                name: 'Details',
                                size: '80%',
                                innerSize: '60%',
                                data: detailData,
                                dataLabels: {
                                    distance: 50,
                                    enabled: true,
                                    format: '<b>{point.name}:</b> <span style="opacity: 0.5">{point.percentage:.1f}%</span>',
                                    filter: {
                                        property: 'y',
                                        operator: '>',
                                        value: 1
                                    },
                                    style: {
                                        fontWeight: 'normal'
                                    }

                                },
                                id: 'details',
                            },
                        ]
                    }
                }/>
            </div>
            )
            }
        </div>
    );
};

export default EventSuccessDonutChart;
