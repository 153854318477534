import { useEffect, useState, useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";
import { AmplifyAreaCodeContext } from "../../context/AmplifyAreaCodeContext";

import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

const AmplifyAreaCodeSelector = () => {
    const { areaCodeList } = useContext(CampaignContext);
    const { areaCodeTNCounts, getAreaCodeTNCounts, allStateAreaCodes, collectingAreaCodeQtys } = useContext(AmplifyAreaCodeContext);
    const { eventPhoneInformation, updatePreferredAreaCode } = useContext(AmplifyEventContext);

    const [localAreaCodeTNCounts, setLocalAreaCodeTNCounts] = useState([]);
    const [localAreaCodeTN_NoCounts, setLocalAreaCodeTN_NoCounts] = useState([]);

    const [localAllAreaCodes, setLocalAllAreaCodes] = useState([]);
    const [localStillCollectingAreaCodeCount, setLocalStillCollectingAreaCodeCount] = useState('');

    const [areaCodePref, setAreaCodePref] = useState('n/a');
    const [areaCodePref_qty, setAreaCodePref_qty] = useState('0');
    const [areaCodeTemp, setAreaCodeTemp] = useState('n/a');
    const [areaCodeTemp_qty, setAreaCodeTemp_qty] = useState('0');

    const [collectingAreaCodeTitle, setCollectingAreaCodeTitle] = useState('Still collecting phone qty information for area codes');
    const [ACLoadVisible, setACLoadVisible] = useState('hidden')

    // EDR - this var, eventPhoneInformation, and areaCodeList are both triggering
    // multiple API calls simultaneously to Bandwidth for area code counts
    // I don't think any changes to eventPhoneInformation fields warrants getting new values
    // from Bandwidth. Try commenting out this useEffect
    //
    //const [areaCodeStatusId, setAreaCodeStatusId] = useState(null);
    //
    // useEffect(() => {
    //     if(!eventPhoneInformation || eventPhoneInformation.statusId == areaCodeStatusId) {
    //         return;
    //     }
    //     console.log("AmplifyAreaCodeSelector: useEffect()[eventPhoneInformation] ", eventPhoneInformation)
    //
    //     setAreaCodeStatusId(eventPhoneInformation.statusId);
    //
    //     console.log("AmplifyAreaCodeSelector: useEffect()[eventPhoneInformation] calling getAreaCodeTNCounts()")
    //     getAreaCodeTNCounts();
    // }, [eventPhoneInformation])

    useEffect(() => {
        if (!areaCodeList || !areaCodeList.length) {
            return;
        }

        //console.log("AmplifyAreaCodeSelector: entered useEffect()[areaCodeList]: now calling getAreaCodeTNCounts(): areaCodeList = ", areaCodeList);
        getAreaCodeTNCounts();
    }, [areaCodeList])

    useEffect(() => {
        //console.log("AmplifyAreaCodeSelector: useEffect()[areaCodeTNCounts] ", areaCodeTNCounts)

        var _a = areaCodeTNCounts.filter(ac => ac.tn_count > 0);
        var _n = areaCodeTNCounts.filter(ac => ac.tn_count == 0);
        var _ip = localAllAreaCodes.length - areaCodeTNCounts.length;

        if(areaCodeTNCounts.length != 0){
            if(_ip <= 0){
                setCollectingAreaCodeTitle("All area code information collected");
                setLocalStillCollectingAreaCodeCount("COMPLETE");
                setACLoadVisible('hidden');
            }
            else{
                setCollectingAreaCodeTitle('Still collecting phone qty information for area codes');
                setLocalStillCollectingAreaCodeCount(_ip);
                setACLoadVisible('visible');
            }
        }
        else{
            setLocalStillCollectingAreaCodeCount('PENDING')
        }

        //console.log("AmplifyAreaCodeSelector: useEffect()[areaCodeTNCounts] calling setLocalAreaCodeTNCounts(_a) _a = ", _a);
        //console.log("AmplifyAreaCodeSelector: useEffect()[areaCodeTNCounts] calling setLocalAreaCodeTN_NoCounts(_n) _n = ", _n);
        setLocalAreaCodeTNCounts(_a);
        setLocalAreaCodeTN_NoCounts(_n);
    }, [areaCodeTNCounts])

    useEffect(() => {
        if (!allStateAreaCodes || !allStateAreaCodes.length) {
            return;
        }

        //console.log("AmplifyAreaCodeSelector: useEffect()[allStateAreaCodes] ", allStateAreaCodes)
        var l = allStateAreaCodes;
        setLocalAllAreaCodes(l);

        var _qty = l.length;
        setCollectingAreaCodeTitle('Still collecting phone qty information for area codes');
        setLocalStillCollectingAreaCodeCount(_qty);
    }, [allStateAreaCodes])

    function refreshAreaCodeQtys(){
        //console.log("AmplifyAreaCodeSelector: refreshAreaCodeQtys() calling getAreaCodeTNCounts()")
        getAreaCodeTNCounts();
    }

    function _areaCodeSelected(o){
        setAreaCodePref(o.area_code);
        setAreaCodePref_qty(o.tn_count);
        updatePreferredAreaCode(o.area_code);
    }

    function _areaCodeHoverStart(o){
        setAreaCodeTemp(o.area_code);
        setAreaCodeTemp_qty(o.tn_count);
    }

    function _areaCodeHoverEnd(){
        setAreaCodeTemp('n/a');
        setAreaCodeTemp_qty('0');

        if(areaCodePref != 'n/a'){
            setAreaCodeTemp(areaCodePref);
            setAreaCodeTemp_qty(areaCodePref_qty);
        }
    }

    function hasEventPhoneNumber() {
        if (!eventPhoneInformation)
        {
            return false;
        }

        // edr - original logic from the HTML below
        //if (eventPhoneInformation.customer_phone_number && (eventPhoneInformation.customer_phone_number.length || eventPhoneInformation.admin_phone_number.length))

        // edr - heres what I think is meant
        if ((eventPhoneInformation.customer_phone_number && eventPhoneInformation.customer_phone_number.length) ||
            (eventPhoneInformation.admin_phone_number && eventPhoneInformation.admin_phone_number.length))
        {
            return true;
        }

        return false;
    }

    return (
        <div>
            {collectingAreaCodeQtys == true ?
            (
                <div style={{background: "white", width: "1000px", height: "1000px"}}>
                    <div style={{width: "600x", margin: "200px 0px 0px 350px"}} className="loading loading-bars loading-lg"></div>
                </div>
            )
            : hasEventPhoneNumber() ?
            (
                <div>
                    <div className="areaCodeSelection" style={{margin: "65px 20px 0px 10px"}}>
                        <div style={{margin: "20px 20px 20px 20px"}}>
                            <div className="areaCodeSelection_title">Area Code Selected</div>
                            <div className="areaCodeSelection_data">{eventPhoneInformation.area_code}</div>
                        </div>
                    </div>
                </div>
            )
            :
            (
                <div className="flex">
                    <div style={{width: "400px"}}>
                        <div className="areaCodeSelectorBack">
                            <div style={{marginLeft: "10px", fontSize: "14px"}}>area code/phones available</div>
                            <div style={{height: "800px", overflowY: "scroll"}}>
                                {localAreaCodeTNCounts.map((tn_count) => (
                                    <div className="areaCodeBar flex" onClick={() => _areaCodeSelected(tn_count)}
                                        onPointerEnter={() => _areaCodeHoverStart(tn_count)} onPointerLeave={() => _areaCodeHoverEnd(tn_count)}
                                            style={{width: tn_count.control_width + "px"}} key={tn_count.area_code}>
                                        <div className="flex" style={{margin: "0px 0px 3px 10px"}}>
                                            <div className="areaCodeBar_AC">{tn_count.area_code}</div>
                                            <div className="areaCodeDivider">/</div>
                                            <div className="flex">
                                                <div className="areaCodeBar_Qty">{tn_count.tn_count_display}</div>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                                {localAreaCodeTN_NoCounts.map((tn_count) => (
                                    <div className="areaCodeBar_NoAvailability flex" style={{width: "100px"}} key={tn_count.area_code}>
                                        <div className="flex" style={{margin: "0px 0px 3px 10px"}}>
                                            <div className="areaCodeBar_AC_NoAvailability">{tn_count.area_code}</div>
                                            <div className="areaCodeDivider_NoAvailability">/</div>
                                            <div className="areaCodeBar_Qty_NoAvailability">{tn_count.tn_count_display}</div>
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button variant="outlined" startIcon={<RefreshIcon fontSize="large" />}
                        style={{height: "60px", width: "260px", margin: "0px 0px 0px 330px", background: "white"}}
                        size="medium" onClick={() => refreshAreaCodeQtys()}>Refresh Area Code Availability Qtys</Button>

                        <div className="grid">
                            <div className="areaCodeSelection" style={{margin: "65px 20px 0px 200px"}}>
                                <div style={{margin: "20px 20px 20px 20px"}}>
                                    <div className="areaCodeSelection_title">Area Code Selected</div>
                                    <div className="areaCodeSelection_data">{areaCodeTemp}</div>
                                    <div className="areaCodeSelection_sub">phone numbers available: {areaCodeTemp_qty}</div>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="areaCodeSelection" style={{margin: "30px 20px 0px 200px"}}>
                                <div className="flex">
                                    <div style={{margin: "20px 20px 20px 20px"}}>
                                        <div className="areaCodeSelection_title">{collectingAreaCodeTitle}</div>
                                        <div className="areaCodeSelection_data">{localStillCollectingAreaCodeCount}</div>
                                    </div>
                                    <div style={{margin: "39px 0px 0px 30px", visibility: ACLoadVisible}} >
                                        <span className="loading loading-bars loading-lg" style={{height: "100px", width: "100px"}}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        </div>
    );
};

export default AmplifyAreaCodeSelector;
