/* eslint-disable react/prop-types */

import { React, useEffect, useState, useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { v4 as uuidv4 } from 'uuid';

import ClearIcon from '@mui/icons-material/Clear';

dayjs.extend(utc);
dayjs.extend(timezone);

const AmplifyEventScheduler = () => {
    const { campaignApprovers,
            attachNewApproverToCampaign,
            removeApproverFromCampaign } = useContext(CampaignContext);

    const { eventCoreInformation,
            eventApprovalInformation,
            updateEventApprovers,
            eventPhoneInformation,
            postEventApproval, eventApproversChanged } = useContext(AmplifyEventContext);

    //APPROVERS
    type approver = {id: 0, name: '', phone: '', status: '', msg_sent: '', approval_recvd: ''};
    const [existingApprovers, setExistingApprovers] = useState([]);

    //eslint-disable-next-line
    const [existingApproversList, setExistingApproversList] = useState([]);
    const [approverListHeight, setApproverListHeight] = useState("43px");

    const [approverPromptVisibility, setApproverPromptVisibility] = useState(true)

    //eslint-disable-next-line
    const [approverCountFill, setApproverCountFill] = useState(false)

    //eslint-disable-next-line
    const [approverCountCheck, setApproverCountCheck] = useState("hidden");

    const [newApproverName, setNewApproverName] = useState('');
    const [newApproverPhone, setNewApproverPhone] = useState('');

    const [rotationFactorNewApprover, setRotationFactorNewApprover] = useState(0);

    const [newApproverClickMessage, setNewApproverClickMessage] = useState('Click me to create a New Approver');

    //eslint-disable-next-line
    const [approverClickMessage, setApproverClickMessage] = useState('Click me to show the selected approvers for your message');
    const newApproverStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorNewApprover + "deg)"
    };

    function handleDragOver(e){
        e.preventDefault();
    }

    function handleApproverOnDrag(e, lapprover){
        e.dataTransfer.setData("approver", lapprover);
    }

    useEffect(() => {
        if (!eventApprovalInformation) {
            return;
        }

        var _remainingHistoricalApprovers = [];
        if (existingApprovers) {
            for (let i=0; i < existingApprovers.length; i++) {
                var _existingApproverTN = existingApprovers[i]['phone'];
                var _found = eventApprovalInformation.approval_numbers.find((approver) => {
                    return approver.phone == _existingApproverTN;
                })

                if (!_found) {
                    _remainingHistoricalApprovers.push(existingApprovers[i]);
                }
            }
        }

        setExistingApprovers(_remainingHistoricalApprovers);
    }, [eventApprovalInformation]);

    // edr - testing for a change to a property on null objects will cause a fatal break in the web application
    // I only changed the trigger to avoid breaking but this needs better logic
    useEffect(() => {
        if (!eventApprovalInformation) {
            return;
        }

        //console.log("AmplifyEventApprovers: useEffect()[eventApprovalInformation.approval_numbers] (NEEDS FIXING)")
        if (eventApprovalInformation.approval_numbers && eventApprovalInformation.approval_numbers.length > 0) {
            setApproverPromptVisibility(false);
            setApproverListHeight((eventApprovalInformation.approval_numbers.length * 40) + "px");
        }
        else{
            setApproverPromptVisibility(true);
            setApproverListHeight("43px");
        }
    //}, [eventApprovalInformation.approval_numbers])
    }, [eventApprovalInformation, eventApproversChanged])

    async function handleApproverOnDrop(e){
        //console.log("AmplifyEventApprovers: handleApproverOnDrop(e) (NEEDS TESTING) e = ", e)
        var _id = e.dataTransfer.getData("approver");
        var _droppedApprover = null;
        var _existing = null;
        var _approverAlreadyExists = false;

        try{
            _droppedApprover = existingApprovers.find((approver) => {
                return approver.id == _id;
            })
        }
        catch(err){
            //console.log("error collecting dropped approver => ", err);
        }

        if (eventApprovalInformation && eventApprovalInformation.approval_numbers) {
            _existing = eventApprovalInformation.approval_numbers.find((approver) => {
                return approver.id == _id;
            })
        }

        try{
            var _found = eventApprovalInformation.approval_numbers.find((approver) => {
                    return approver.phone.slice(-10) == _droppedApprover.phone.slice(-10);
                })

            if (_found) {
                _approverAlreadyExists = true;
            }
        }
        catch(errTN){
            //console.log("unable to identify if the approver already existed");
        }

        if (!_existing && !_approverAlreadyExists)
        {
            var _lnewApprover:approver = existingApprovers.find((approver) => {
                return approver.id == _id;
            })

            _lnewApprover.status = 'PENDING'

            var _a = eventApprovalInformation.approval_numbers;
            var _b = [];
            _b.push(_lnewApprover);

            var newArray = null;

            if (_a) {
                newArray = [..._a, ..._b];
            }
            else{
                newArray = [..._b];
            }

            // console.log("AmplifyEventApprovers: handleApproverOnDrop(e) calling updateEventApprovers(newArray, _lnewApprover)")
            await updateEventApprovers(newArray, _lnewApprover);
        }
    }

    function Approver_ApproveEvent(approver){
        //console.log("AmplifyEventApprovers: Approver_ApproveEvent(approver) (NEEDS TESTING) calling postEventApproval(approver) ", approver)
        postEventApproval(approver);
    }

    function Approver_RemoveApproverClick(approver){
        if (!eventApprovalInformation) {
            return;
        }

        //console.log("AmplifyEventApprovers: Approver_RemoveApproverClick(approver) (NEEDS TESTING) approver: ", approver)
        var _id = approver.id;

        //CALL SQL STORED PROC HERE

        var _a = [...eventApprovalInformation.approval_numbers];
        var _i = 0;
        for (let i = 0; i < _a.length; i++){
            if(_a[i].id == _id)
            {
                _i = i;
                break;
            }
        }
        _a.splice(_i, 1);

        //console.log("AmplifyEventApprovers: Approver_RemoveApproverClick(approver) calling updateEventApprovers(_a) ", _a)
        updateEventApprovers(_a);
    }

    function createNewApprover(){
        //console.log("AmplifyEventApprovers: createNewApprover() (NEEDS TESTING)")
        try{
            var _a = [];
            var _e = [];

            if (eventApprovalInformation && eventApprovalInformation.approval_numbers) {
                _a = [...eventApprovalInformation.approval_numbers];
            }

            if (existingApprovers) {
                _e = [...existingApprovers];
            }

            // var _found = false;
            // for (let i = 0; i < _a.length; i++){
            //     if(_a[i].phone == newApproverPhone){
            //         _found = true;
            //         break;
            //     }
            // }
            var foundApprover = _a.find(object => object.phone == newApproverPhone);
            if (!foundApprover)
            {
                var _new =  {id: uuidv4(), name: newApproverName, phone: newApproverPhone, status: 'PENDING'};
                _a.splice(_a.length, 0, _new);
                _e.splice(_a.length, 0, _new);

                try{
                    attachNewApproverToCampaign(_new);
                }
                catch(error){
                    //console.log("there was an error attaching the approver to the campaign => ", error);
                }

                updateEventApprovers(_a);
                setExistingApproversList(_e);
            }
            else
            {
                //TODO Need Toast Alert Here
            }
        }
        catch(error){
            //console.log("there was an error creating a new approver => ", error);
        }
    }

    useEffect(() => {
        //console.log("AmplifyEventApprovers: useEffect()[campaignApprovers] (NEEDS TESTING) ", campaignApprovers)
        var _a = [];
        // var _h = campaignApprovers;
        // for (let i=0; i < _h.length; i++) {
        //     var a = {id: uuidv4(), //i,
        //             name: _h[i]["ApproverName"],
        //             phone: _h[i]["ApproverPhone"]};
        //     _a.push(a);
        // }

        campaignApprovers.forEach(object => {
            _a.push({
                id: uuidv4(),
                name: object["ApproverName"],
                phone: object["ApproverPhone"]
            });
        });

        //console.log("AmplifyEventApprovers: useEffect()[campaignApprovers] calling setExistingApprovers(_a) ", _a)
        setExistingApprovers(_a);
    }, [campaignApprovers]);

    function applyRotationNewApprover(){
        if(rotationFactorNewApprover == 0)
        {
            setRotationFactorNewApprover(180);
            setNewApproverClickMessage("Click me to hide the New Approver process");
        }
        else
        {
            setRotationFactorNewApprover(0);
            setNewApproverClickMessage("Click me to create a New Approver");
        }
    }

    function removePreviousApprover(source, approverId, approverName, approverPhone){
        if (!eventApprovalInformation) {
            return;
        }

        //console.log("AmplifyEventApprovers: removePreviousApprover(source, approverId, approverName, approverPhone) (NEEDS TESTING) ", source, approverId, approverName, approverPhone)
        //only allow to remove if the approver is not on the event

        if (approverPhone.length == 10) {
            approverPhone = "+1" + approverPhone;
        }

        // var _found = false;
        // for(let i=0; i < eventApprovalInformation.approval_numbers.length; i++){
        //     if(eventApprovalInformation.approval_numbers[i].name .trim()== approverName.trim() && eventApprovalInformation.approval_numbers[i].phone.trim() == approverPhone.trim()){
        //         _found = true;
        //         break;
        //     }
        // }

        var foundApprovalInfo = eventApprovalInformation.approval_numbers.find(
                object => (object.name.trim() == approverName.trim() &&
                            object.phone.trim() == approverPhone.trim())
            );

        if (!foundApprovalInfo) {
            removeApproverFromCampaign({
                    id: approverId,
                    ApproverName: approverName,
                    ApproverPhone: approverPhone
                });
        }
        else{
            //TOAST NOTIFICATION HERE
        }
    }

    return (
    <div className="flex" style={{marginLeft: "10px", marginBottom: "20px"}}>
        <div>
            <div className="eventEditApproverBounds grid">
                <label className="userEditTitle" htmlFor="sampleLastName">Previous Approvers:</label>
                <div className="grid">
                    {existingApprovers.map((approver) => (
                        <div className="eventEditApprovers"
                            key={approver.id}
                            onDragStart={(e) => handleApproverOnDrag( e, approver.id)}
                            draggable>
                            <div className="flex">
                                <div className="flex" style={{justifyContent: "center", textAlign: "center", width: "100%", marginLeft: "50px"}}>
                                    <div>
                                        {approver.name}
                                    </div>
                                    <div style={{fontSize: "12px", marginLeft: "10px", marginTop: "5px"}}>({approver.phone})</div>
                                </div>
                                <div style={{width: "50px", cursor: "pointer"}} onClick={(e) => removePreviousApprover(e, approver.id, approver.name, approver.phone)}>
                                    <ClearIcon />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <div className="eventCreateTiming" style={{margin: "10px 0px 0px 0px", padding: "0px 20px 0px 0px", width: "500px"}}>
                <div className="collapse border border-black group-open:rotate-180">
                    <input id="newApproverAccordion" type="checkbox" onClick={applyRotationNewApprover} />
                    <div className="collapse-title flex">
                        <div className="stepEllipse mt-3" style={{width: "50px"}} />
                        <label className="text-l font-bold" style={{margin: "18px 0px 0px -33px"}}>6.1.</label>
                        <div className="collapse-title text-xl font-bold">NEW APPROVER - {newApproverClickMessage}</div>
                        <svg
                            style={newApproverStyle}
                            id="test" xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                            strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>
                    </div>

                    <div className="collapse-content">

                        <div className= "grid">

                                <div className="grid">
                                    <label className="userEditTitle" htmlFor="newApproverName">Approver Name:</label>
                                    <input className="input input-bordered input-md" style={{width: "445px", margin: "0px 10px 0px 0px"}} type="text" id="newApproverName" name="newApproverName"
                                        value={newApproverName} onChange={e => setNewApproverName(e.target.value)} placeholder={'Approver Name'}/>
                                </div>

                                <div className="grid">
                                    <label className="userEditTitle" htmlFor="newApproverCellPhone">Cell Phone:</label>
                                    <input className="input input-bordered input-md" style={{width: "445px", margin: "0px 0px 0px 0px"}} type="text" id="newApproverCellPhone" name="newApproverCellPhone"
                                        value={newApproverPhone} onChange={e => setNewApproverPhone(e.target.value)} placeholder={'Approver Cell Phone Number'}/>
                                </div>

                        </div>

                        <button className="btn btn-outline bg-blue-200 mt-5 ml-[205px] w-60" onClick={() => createNewApprover()}>Create</button>


                    </div>
                </div>
            </div>
        </div>
        <div className="eventEditApproversTarget"
            onDrop={handleApproverOnDrop} onDragOver={handleDragOver} style={{marginLeft: "-10px"}}>
            <div className="grid" style={{height: "25px"}}>


            </div>

            {
            approverPromptVisibility == true ?
                (
                    <div className="appliedApproverTargetRectangle">
                        <div className="dropFileTitle mt-[80px]">drop approvers here</div>
                    </div>
                )
                :
                (
                    <div className="grid">
                        <div className="appliedApproverTargetRectangle" style={{height: "100px"}}>
                            <div className="dropFileTitle mt-[30px]">drop approvers here</div>
                        </div>
                        <div style={{marginTop: "20px"}}>
                            <div className="flex">
                                <div className="mt-[7px] ml-[120px]">Approver Name</div>
                                <div className="mt-[7px] ml-[38px]">Approver Phone</div>
                                <div className="mt-[7px] ml-[20px]">Status</div>

                            </div>

                            <hr/>
                        </div>
                    </div>

                )
            }

            <div className="grid" style={{height: approverListHeight, marginTop: "5px"}}>
                {   eventApprovalInformation && eventApprovalInformation.approval_numbers != null
                    ?
                    (eventApprovalInformation.approval_numbers.map((approver) => (
                    <div className="appliedApprover flex" key={approver.id} style={{height: "30px"}}>

                        { (eventCoreInformation.status.toLowerCase() == 'approving' || eventCoreInformation.status.toLowerCase() == 'approve')
                            && (eventPhoneInformation && eventPhoneInformation.customer_phone_number.length > 0 && eventPhoneInformation.admin_phone_number.length > 0) ?
                        (
                            <div className="flex" style={{width: "80px"}}>
                                <div className="approverButtons" style={{margin: "0px 0px 0px 10px"}} onClick={() => Approver_RemoveApproverClick(approver)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#ff0000"
                                        strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline>
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                </div>
                                {eventCoreInformation.status == "approve" || eventCoreInformation.status == "approving" ?
                                <div className="approverButtons" style={{margin: "0px 0px 0px 10px"}} onClick={() => Approver_ApproveEvent(approver)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#0056ff"
                                        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 11 12 14 22 4"></polyline>
                                        <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                                    </svg>
                                </div>
                                :
                                <div/>
                                }
                            </div>
                        )
                        :
                        (
                            <div style={{width: "80px", background: "black", color: "white", height:"33px"}}>
                                <div style={{marginLeft: "25px", marginTop: "1px"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none"
                                        stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="11"
                                        width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                </div>
                            </div>
                        )
                        }
                        <div className="mt-[4px] ml-[30px] appliedApproverName" style={{fontSize: "12px", fontWeight: "bold", marginLeft: "30px", width: "110px"}}>{approver.name}</div>
                        <div className="mt-[4px] ml-[10px] appliedApproverName" style={{width: "100px"}}>{approver.phone}</div>
                        <div className="mt-[4px] ml-[10px] appliedApproverName" style={{width: "70px"}}>{approver.status.toUpperCase()}</div>
                        <div className="appliedApproverName" style={{width: "100px"}}>
                        {
                            approver.msg_sent != ''
                            ? (dayjs(approver.msg_sent).format('MM/DD/YYYY HH:mm'))
                            : ('')
                        }
                        </div>
                    </div>
                    )))
                    :
                    <div/>
                }
            </div>
        </div>
    </div>
    );

};

export default AmplifyEventScheduler;
