import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { AdminContext } from "../../context/AdminContext";
import { CompanyContext } from "../../context/CompanyContext";
import { ApplicationContext } from "../../context/ApplicationContext";

export default function AFCAdminMenu() {
    const { jwt, roles } = useContext(AuthContext);
    const { businesses } = useContext(AdminContext);
    const { changeCompany } = useContext(CompanyContext);
    const { appRenderType } = useContext(ApplicationContext);

    const [isAFCUser, setIsAFCUser] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if (!isMounted && jwt && isAFCUser) {
            setIsMounted(true);
        }
    });

    useEffect(() => {
        validateAdmin()
    }, [roles]);

    function validateAdmin() {
        for (let i=0; i < roles.length; i++) {
            if (roles[i].startsWith("sec:afc") || roles[i].startsWith("sec:globaladmin")) {
                setIsAFCUser(true);
                return true;
            }
        }
        return false;
    }

    const onChange = (event) => {
        changeCompany(event.target.value);
    }

    return (
        <div>
        {appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="grid items-center">
                <label className="mr-2" htmlFor="customerSelect">Customer:</label>
                <select id="customerSelect" style={{ marginTop: "10px", background: "white", color: "black"}} onChange={(e) => { onChange(e); }}>
                    <option value="default">Select a Customer</option>
                    {businesses
                        ? businesses.map((business) => {
                            return <option key={business.key} value={business.key}>{business.name}</option>;
                        })
                        : null}
                </select>

                {/*}<a href="UnifyHome"><img style={{width: "30px", height: "40px", marginLeft: "100px", marginTop: "5px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/Unify_main_logo_1.png"/></a>{*/}
            </div>
        :
            <div className="grid items-center">
                <label className="mr-2" htmlFor="customerSelect">Admin Menu:</label>
                <select id="customerSelect_Phone" style={{ marginTop: "10px", background: "white", color: "black"}} onChange={(e) => { onChange(e); }}>
                    <option value="default">Select a Customer</option>
                    {businesses
                        ? businesses.map((business) => {
                            return <option key={business.key} value={business.key}>{business.name}</option>;
                        })
                        : null}
                </select>

                {/*}<a href="UnifyHome"><img style={{width: "30px", height: "40px", marginLeft: "0px", marginTop: "10px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/Unify_main_logo_1.png"/></a>{*/}
            </div>
        }
        </div>
    );
}
