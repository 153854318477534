import Topbar from "../../components/topbar/Topbar";

export default function EventEditDesktopOnly() {

    return (
        <div id="mainBody" style={{background: "white", height: "100vh", width: "100vw"}}>

            <div style={{marginLeft: "25vw", marginTop: "-50px"}}>
                <img src={"https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_inverse_thumbnail.png"} style={{width: "50vw", maxWidth: "500px"}} />
            </div>

            <div style={{marginTop: "-30px"}}>
                <Topbar className="topBarMenu" />
            </div>

            <div style={{position: "relative", marginTop: "30px", marginLeft: "20px", marginRight: "20px", fontSize: "28px", fontWeight: "bold", color: "black", textAlign: "center"}}>
                This functionality is currently only available in the Amplify desktop version. The team is working hard to get the mobile version working as quickly as possible...
                    but for now, please enjoy this application from your desktop.
            </div>

            <div style={{position: "relative", marginTop: "20px", marginLeft: "20px", marginRight: "20px", fontSize: "28px", fontWeight: "bold", color: "black", textAlign: "center"}}>
                If you are accessing Amplify from your desktop/laptop and you are still seeing this message, you may need to zoom out to access the desktop mode.
            </div>

            <div style={{marginLeft: "10vw", marginTop: "30px"}}>
                <img src={"https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify/Tablet-and-Mobile%202.gif"} style={{width: "80vw", maxWidth: "800px"}}/>
            </div>
        </div>
    );
}
