import { React, useEffect, useRef, useState, useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";
import { CompanyContext } from "../../context/CompanyContext";
import { DataMapperContext } from "../../context/DataMapperContext";

//eslint-disable-next-line
import { MosaicMapVoterSet, MosaicMapFileMap } from "../../afc/MosaicMapping";

import { usePapaParse } from 'react-papaparse';

import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';

import Box from '@mui/material/Box';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

// Datatables
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";

$.Datatable = require("datatables.net");
$.DataTable = require('datatables.net-select');
$.DataTable = require('datatables.net-buttons');
$.DataTable = require('datatables.net-buttons-dt');
$.DataTable = require('datatables.net-buttons/js/buttons.html5');

import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const DataMapperColumnMapping = () => {
    const { campaignName } = useContext(CampaignContext);
    const { companyName } = useContext(CompanyContext);
    const { getMapperFile, currentFileID, closeColumnMapper, addFileQty,
            saveDataFileMapping, loadDatamapperColumnMapping, datamapApplied } = useContext(DataMapperContext);

    // number of rows to read from CSV file for previewing data
    //eslint-disable-next-line
    const dataPreviewRows = 10;

    const tableRef = useRef();
    const [isMounted, setIsMounted] = useState(false);
    const { readString } = usePapaParse();

    const [uniqueID, setUniqueID] = useState('');
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [cell, setCell] = useState('');
    const [politicalParty, setPoliticalParty] = useState('');
    const [state, setState] = useState('');
    const [precinct, setPrecinct] = useState('');
    const [county, setCounty] = useState('');
    const [url, setURL] = useState('');

    const [fileColumns, setFileColumns] = useState([]);
    const [allCSVDataRows, setAllCSVDataRows] = useState([]);

    const [disableSaveButton, setDisableSaveButton] = useState('true');

    //************************************ Data Mapper Items *************************
    const [selectedDataFile, setSelectedDataFile] = useState();
    const [selectedDataFileName, setSelectedDataFileName] = useState();

    const [displayFileLoading, setDisplayFileLoading] = useState(true);

    var mosaicFCMessage = "Loading Your Data File";

    //eslint-disable-next-line
    const [displayData, setDisplayData] = useState('visible');
    const [presentationData, setPresentationData] = useState([]);

    const [memberCount, setMemberCount] = useState(0);

    //DATA TABLE SUPPORT  ***********************************************************************************
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - presentationData.length) : 0;

    const [dataFileLength, setDataFileLength] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        // console.log("*************** LOAD COLUMN MAPPING UPDATED ************************");

        setDisplayFileLoading(true);
    }, [loadDatamapperColumnMapping])

    // this is the table of sample data rows in the Column Mapping modal
    useEffect(() => {

        if (isMounted) {
            return;
        }

        if(datamapApplied == true){
            setDisableSaveButton(true);
        }
        else{
            setDisableSaveButton(false);
        }

        setIsMounted(true);
        $(tableRef.current).DataTable({
            data: [],
            columns: [
                { title: 'ID', width: '250px'},
                { title: 'First Name', width: '100px' },
                { title: 'Last Name', width: '100px' },
                { title: 'Cell Phone', width: '90px' },
                { title: 'Party', width: '30px' },
                { title: 'State', width: '30px' },
                { title: 'Precinct', width: '50px' },
                { title: 'County', width: '100px' },
                { title: 'URL', width: '300px' },
            ],
            order: [[0, 'Last Name', 'Cell Phone']],
            options: {
                processing: true,
                resizeColumns: true
            }
        });
    });

    useEffect(() => {
        if(cell.trim().length > 0 && memberCount > 0 && datamapApplied == false){
            setDisableSaveButton(false);
        }
        else{
            setDisableSaveButton(true);
        }
    }, [cell, memberCount])

    useEffect(() => {
        // console.log("DataMapperColumnMapping: entered useEffect()[loadDatamapperColumnMapping] ", loadDatamapperColumnMapping)
        // we need valid currentFileID AND loadDatamapperColumnMapping values
        if ((!currentFileID || !currentFileID.length) ||
            (!loadDatamapperColumnMapping || loadDatamapperColumnMapping == ""))
        {
            // console.log("DataMapperColumnMapping: exiting useEffect()[loadDatamapperColumnMapping] because currentFileID, loadDatamapperColumnMapping ", currentFileID, loadDatamapperColumnMapping)
            return;
        }

        // console.log("DataMapperColumnMapping: useEffect()[loadDatamapperColumnMapping] calling getMapperFile(currentFileID) ", currentFileID)
        var _f = getMapperFile(currentFileID);
        if (!_f || !_f.file) {
            return;
        }

        // console.log("DataMapperColumnMapping: useEffect()[loadDatamapperColumnMapping] calling applySelectedDataFile(_f.file, _f.fileDisplayName, fileId) ", _f.file, _f.fileDisplayName, fileId)
        var fileId = loadDatamapperColumnMapping.fileId;
        applySelectedDataFile(_f.file, _f.fileDisplayName, fileId, _f.qty);

    }, [loadDatamapperColumnMapping])

    // received file from drag & drop or opened from dialog box
    useEffect(() => {
        if ((typeof selectedDataFile === 'string' && !selectedDataFile.trim().length) ||
            (typeof selectedDataFile === 'object' && !selectedDataFile))
        {
            // console.log("DataMapperColumnMapping: useEffect()[selectedDataFile]: exiting early because selectedDataFile is empty")
            return;
        }
        // console.log("DataMapperColumnMapping: useEffect()[selectedDataFile] calling readCSVFile(selectedDataFile)")
        readCSVFile(selectedDataFile);

        // console.log("DataMapperColumnMapping: useEffect()[selectedDataFile] calling testNewAFCMapping()");
        // testNewAFCMapping();
    }, [selectedDataFile])

    useEffect(() =>{
        if (!currentFileID.length) {
            return;
        }
        //console.log("DataMapperColumnMapping: useEffect()[fileColumns] calling readExistingColumnMappings(): fileColumns, currentFileID", fileColumns, currentFileID)
        readExistingColumnMappings();
    }, [fileColumns])

    useEffect(() =>{
        if (!memberCount) {
            return;
        }
        // console.log("DataMapperColumnMapping: useEffect()[memberCount] calling setDisplayFileLoading()")
        setDisplayFileLoading(false);
    }, [memberCount])

    //eslint-disable-next-line
    // function testNewAFCMapping() {
    //     var myCSVFilename = "TeirabDelegateTextFile.csv";

    //     //eslint-disable-next-line
    //     // var myMosaicMappingFile = new MosaicMapFileMap(myCSVFilename);
    //     // myMosaicMappingFile.mapFields.mapFName.set("First_Name");
    //     // myMosaicMappingFile.mapFields.mapLName.set("Last_Name");
    //     // myMosaicMappingFile.mapFields.mapCellPhone.set("Cell");
    //     //
    //     // console.log("output for testNewAFCMapping:\n", myMosaicMappingFile.output());

    //     var myMosaicVoterSet = new MosaicMapVoterSet();
    //     myMosaicVoterSet.fileGroup.createNewFileMap(myCSVFilename);
    //     console.log("output for testNewAFCMapping:\n", myMosaicVoterSet.toString());
    // }


    // edr - this is new and should be moved to the DataMapperContext
    function newFieldMapping() {
        return {
            UID: null,
            FName: null,
            Lname: null,
            Cell_Phone: null,
            Party: null,
            State: null,
            Precinct: null,
            County: null,
            URL: null
        };
    }

    //eslint-disable-next-line
    function countFileLines(file, mapperFileID) {
        // console.log("\n\n\nDataMapperColumnMapping: entered countFileLines(file, mapperFileID) ", file, mapperFileID)
        var numLines = 0;
        var block_size = 10240;
        var offset = 0;
        var inputFile = new FileReader();
        inputFile.onload = function() {
            var view = new Uint8Array(inputFile.result);
            for (var i = 0; i < view.length; ++i) {
                //if (view[i] === 10 || view[i] === 13) {
                if (view[i] === 10) {
                    numLines++;
                }
            }
            offset += block_size;
            seek();
        };
        inputFile.onerror = function() {
            addFileQty(0, mapperFileID);
        };
        seek();

        function seek() {

            if (offset >= file.size) {
                // subtract 1 to account for the header line
                numLines--;
                var doneTime = Date.now().toString();
                // addFileQty(numLines, mapperFileID);
                setDataFileLength(numLines);
                setMemberCount(numLines);
                return;
            }
            var slice = file.slice(offset, offset + block_size);
            // console.log("**************************** DEM *****************************");
            // console.log("THIS IS THE FILE VARIABLE CONTENTS => ", typeof(file));
            // console.log("THIS IS THE SLICE => ", slice);

            //IF THE VARIABLE TYPE OF THE SLICE IS A BLOB, CONTINUE
            //OTHERWISE, THIS IS LOADING FROM A PREVIOUSLY SAVED MAP.
            //IT SHOULD NEVER GET TO THIS POINT, BUT THIS PREVENTS AN INCORRECTED DATA TYPE BEING PASSED
            if(typeof(slice) != "string"){
                // console.log("STARTING");
                inputFile.readAsArrayBuffer(slice);
            }
            else{
                //NEED TO DEDTERMINE WHAT TO DO HERE IF IT IS REACHED WHEN NOT A BLOB
                //RIGHT NOW, IT WILL RETURN A ZERO
                // console.log("*********************** DEM 2 ******************************");
                // console.log("END2 END2 END2 END2 END2");
                setDataFileLength(numLines);
                setMemberCount(numLines);
                return;
            }

        }
    }

    function readCSVFile(csvFile){
        if (!csvFile) {
            return;
        }

        // reads entire file contents via PapaParse
        //console.log("DataMapperColumnMapping - entered readCSVFile(csvFile): reading all the file contents now", csvFile)
        //var startTime = Date.now().toString();
        //console.log("\n\nRICK - readCSVFile(csvFile): start time ", startTime)
        readString(
            csvFile,
            {
                worker: true,
                header: true,
                preview: dataPreviewRows,
                complete: (results) => {
                    //var doneTime = Date.now().toString();
                    //console.log("\n\nRICK - readCSVFile(csvFile): done time ", doneTime)

                    var headers = [];
                    if (results.data[0]) {
                        headers = Object.keys(results.data[0]);
                    }
                    //console.log("DataMapperColumnMapping - readCSVFile(csvFile): calling setFileColumns(headers) ", headers)
                    setFileColumns(headers);

                    //console.log("DataMapperColumnMapping - readCSVFile(csvFile): calling setAllCSVDataRows(results.data)", results.data)
                    setAllCSVDataRows(results.data);

                    //console.log("DataMapperColumnMapping - readCSVFile(csvFile): calling setBaseSampleData(headers, results.data, results.errors, selectedDataFile['name'] ", headers, results.data, results.errors, csvFile['name'])
                    setBaseSampleData(headers, results.data, results.errors, csvFile['name']);

                    // setDisplayFileLoading(false);
                }
            }
        )
    }

    //eslint-disable-next-line
    function applySelectedDataFile(data, fileName, mapperFileId, mapperFileRowQty){
        // console.log("DataMapperColumnMapping: entered applySelectedDataFile(data, fileName, mapperFileId): ", data, fileName, mapperFileId)
        mosaicFCMessage = "Verifying Your Data File";
        var _process = false;
        if(typeof data == 'object'){
            if(data != null){
                _process = true;
            }
        }
        else if(typeof data == 'string'){
            if(data.trim().length > 0){
                _process = true;
            }
        }

        if(_process == true){
            //NOTE: remove unwanted characters
            //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
            //eslint-disable-next-line
            var _CorrectedName = fileName.replace(/[^a-z0-9-._\ ]/gi, '_');
            setSelectedDataFileName(_CorrectedName);
            setSelectedDataFile(data);

            // RICK TEST
            var startTime = Date.now().toString();
            if(mapperFileRowQty > 0){
                setDataFileLength(mapperFileRowQty);
                setMemberCount(mapperFileRowQty);
            }
            else{
                countFileLines(data, mapperFileId);
            }

        }
    }

    function clearColumnMappings(){
        setFName("");
        setLName("");
        setCell("");
        setState("");
        setPrecinct("");
        setCounty("");
        setPoliticalParty("");
        setURL("");
    }

    function readExistingColumnMappings() {
        if (!currentFileID.length) {
            return;
        }

        //console.log("DataMapperColumnMapping: readExistingColumnMappings() calling getMapperFile(currentFileID): fileColumns, currentFileID ", fileColumns, currentFileID)
        var mapperFile = getMapperFile(currentFileID);
        if (!mapperFile) {
            // console.log("DataMapperColumnMapping: readExistingColumnMappings() failed to find mapperFile for currentFileID ", currentFileID)
            return;
        }

        var _uniqueID;
        var _fname;
        var _lname;
        var _cell;
        var _party;
        var _state;
        var _precinct;
        var _county;
        var _url;

        // edr - it appears that this condition only happens for files that have already been mapped and the user is editing the mapping
        if (mapperFile.columnMappings) {
            //console.log("DataMapperColumnMapping: readExistingColumnMappings() evaluating mapperFile.columnMappings ", mapperFile)
            if (mapperFile.columnMappings.FName) {
                _fname = mapperFile.columnMappings.FName.field;
                //console.log("DataMapperColumnMapping: readExistingColumnMappings() setting FName = ", _fname)
                setFName(_fname);
            }

            if (mapperFile.columnMappings.LName) {
                _lname = mapperFile.columnMappings.LName.field;
                //console.log("DataMapperColumnMapping: readExistingColumnMappings() setting LName = ", _lname)
                setLName(_lname);
            }

            if (mapperFile.columnMappings.Cell_Phone) {
                _cell = mapperFile.columnMappings.Cell_Phone.field;
                //console.log("DataMapperColumnMapping: readExistingColumnMappings() setting Cell = ", _cell)
                setCell(_cell);
            }

            if (mapperFile.columnMappings.Party) {
                _party = mapperFile.columnMappings.Party.field;
                //console.log("DataMapperColumnMapping: readExistingColumnMappings() setting PoliticalParty = ", _party)
                setPoliticalParty(_party);
            }

            if (mapperFile.columnMappings.State) {
                _state = mapperFile.columnMappings.State.field;
                //console.log("DataMapperColumnMapping: readExistingColumnMappings() setting State = ", _state)
                setState(_state);
            }

            if (mapperFile.columnMappings.Precinct) {
                _precinct = mapperFile.columnMappings.Precinct.field;
                //console.log("DataMapperColumnMapping: readExistingColumnMappings() setting Precinct = ", _precinct)
                setPrecinct(_precinct);
            }

            if (mapperFile.columnMappings.County) {
                _county = mapperFile.columnMappings.County.field;
                //console.log("DataMapperColumnMapping: readExistingColumnMappings() setting County = ", _county)
                setCounty(_county);
            }

            if (mapperFile.columnMappings.URL) {
                _url = mapperFile.columnMappings.URL.field;
                //console.log("DataMapperColumnMapping: readExistingColumnMappings() setting URL = ", _url)
                setURL(_url);
            }
        }
        // else{
        //     if(fileColumns.length > 0){
        //         console.log("DataMapperColumnMapping: useEffect()[fileColumns] trying to guess what fields the columns headers map to")
        //         mosaicFCMessage = "Checking common column names for mapping";
        //
        //         const commonFName = ['fname', 'firstname', 'FirstName', 'f_name', 'First_Name', 'FName', 'name', 'Name', 'first name', 'first_name'];
        //         const commonLName = ['lname', 'lastname', 'LastName', 'l_name', 'Last_Name', 'LName', 'last name', 'last_name'];
        //         const commonState = ['state', 'State', 'myState', 'mail_sta'];
        //         const commonPrecinct = ['precinct', 'Precinct', 'myPrecinct', 'precinct_name', 'precinct name'];
        //         const commonCounty= ['county', 'County', 'myCounty', 'county name', 'county_name'];
        //         const commonParty= ['party', 'Party', 'Registered Party', 'Political_Party_Full', 'calculated_party', 'registered_party', 'Calculated_Party', 'Registered_Party'];
        //         const commonURL= ['url', 'URL'];
        //
        //         if(fileColumns.some((element) => commonFName.includes(element)) == true){
        //             var found = fileColumns.filter((value) => commonFName.includes(value));
        //             _fname = found[0];
        //             setFName(_fname);
        //         }
        //
        //         if(fileColumns.some((element) => commonLName.includes(element)) == true){
        //             var foundLName = fileColumns.filter((value) => commonLName.includes(value));
        //             _lname = foundLName[0];
        //             setLName(_lname);
        //         }
        //
        //         if(fileColumns.some((element) => commonState.includes(element)) == true){
        //             var foundState = fileColumns.filter((value) => commonState.includes(value));
        //             _state = foundState[0];
        //             setState(_state);
        //         }
        //
        //         if(fileColumns.some((element) => commonPrecinct.includes(element)) == true){
        //             var foundPrecinct = fileColumns.filter((value) => commonPrecinct.includes(value));
        //             _precinct = foundPrecinct[0];
        //             setPrecinct(_precinct);
        //         }
        //
        //         if(fileColumns.some((element) => commonCounty.includes(element)) == true){
        //             var foundCounty = fileColumns.filter((value) => commonCounty.includes(value));
        //             _county = foundCounty[0];
        //             setCounty(_county);
        //         }
        //
        //         if(fileColumns.some((element) => commonParty.includes(element)) == true){
        //             var foundParty = fileColumns.filter((value) => commonParty.includes(value));
        //             _party = foundParty[0];
        //             setPoliticalParty(_party);
        //         }
        //
        //         if(fileColumns.some((element) => commonURL.includes(element)) == true){
        //             var foundURL = fileColumns.filter((value) => commonURL.includes(value));
        //             _url = foundURL[0];
        //             setURL(_url);
        //         }
        //     }
        // }

        // only apply mapping if we have the required cell field and theres data to map to
        if (_cell && (allCSVDataRows && allCSVDataRows.length)) {
            //console.log("DataMapperColumnMapping: readExistingColumnMappings() calling applyUpdatedDataMap(_uniqueID, _fname, _lname, _cell, _party, _state, _precinct, _county, _url, allCSVDataRows): ", _uniqueID, _fname, _lname, _cell, _party, _state, _precinct, _county, _url, allCSVDataRows)
            applyUpdatedDataMap(_uniqueID, _fname, _lname, _cell, _party, _state, _precinct, _county, _url, allCSVDataRows);
        }

        // setDisplayFileLoading(false);

    }

    //eslint-disable-next-line
    function setBaseSampleData(headers, data, parseErrors, filename){
        // console.log("DataMapperColumnMapping: entered setBaseSampleData(headers, data, parseErrors, filename): ", headers, data, parseErrors, filename)
        const date = new Date();
        mosaicFCMessage = "Loading the sample data from your file";
        var iRemoved = 0;

        //clean the source data or any parsing errors
        for (let i=0; i < parseErrors.length; i++) {
            var iError = parseErrors[i]["row"];

            data.splice(iError - iRemoved, 1);
            //log that an element was removed so that it can be accounted for on subsequent deletes
            iRemoved++;
        }

        var _lname = 'LName';
        var _fname = 'FName';
        var _cell = 'tbd';
        var _party = 'N/A';

        var _state = 'NA'; //campaignStateAbbr;

        var _cName = '';
        var _campName = '';

        if(companyName.length > 3){
            _cName = companyName.substring(0,4);
        }
        else{
            _cName = companyName;
        }

        if(campaignName.length > 3){
            _campName = campaignName.substring(0,4);
        }
        else{
            _campName = campaignName;
        }

        var _precinct = _cName.trim() + "_" + _campName.trim() + "_" + (date.getMonth() + 1) + date.getDate() + "A";
        var _county = _cName.trim() + "_" + _campName.trim() + "_" + (date.getMonth() + 1) + date.getDate() + "_COUNTY";

        var _max = 0;

        _max = data.length;

        var _sample = [];

        for (let i=0; i < _max; i++) {
            var _id = uuidv4();

            _sample.push([
                    _id,
                    _fname,
                    _lname,
                    _cell,
                    _party,
                    _state,
                    _precinct,
                    _county,
                    ''
                ]);
        }

        // setDataFileLength(_sample.length);

        // RICK QTY FIX (the UI was showing the "preview count" instead of real row count)
        // edr - The UI relies on the fileMap.qty field to display the right row count
        // This is currently how it gets updated after the ColumnMapper reads the entire file
        // but we need the ColumnMapper to NOT read the whole file and the new
        // file row counter will need to do the updating.
        // if(_sample.length > 0){
        //     //addFileQty(_sample.length, currentFileID);
        //     addFileQty(666, currentFileID);
        // }

        setDisplayData(_sample);
        setPresentationData(_sample);

        $(tableRef.current).dataTable().fnClearTable();
        if(_sample.length > 0){
            $(tableRef.current).dataTable().fnAddData(_sample);
        }

        // edr - the useEffect()[memberCount] on this variable is what
        // turns off "3 bars for loading" display variable
        //setMemberCount(_sample.length);
    }

    function TablePaginationActions(props) {
        // console.log("DataMapperColumnMapping: entered TablePaginationActions(props): ")
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
      count: PropTypes.number.isRequired,
      onPageChange: PropTypes.func.isRequired,
      page: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
    };

    function handleChange(e){
        //console.log("\n\nDataMapperColumnMapping: entered handleChange(e): ", e)

        //console.log("\nDataMapperColumnMapping: handleChange(e): initial vals: uniqueID, fname, lname, cell, politicalParty, state, precinct, county, url", uniqueID, fname, lname, cell, politicalParty, state, precinct, county, url)
        var _uniqueID = uniqueID;
        var _fname = fname;
        var _lname = lname;
        var _cell = cell;
        var _party = politicalParty;
        var _state = state;
        var _precinct = precinct;
        var _county = county;
        var _url = url;

        switch(e.target.name){
            case 'slUID':
                 setUniqueID(e.target.value);
                 _uniqueID = e.target.value;
                 break;
            case 'slFName':
                setFName(e.target.value);
                _fname = e.target.value;
                break;
            case 'slLName':
                setLName(e.target.value);
                _lname = e.target.value;
                break;
            case 'slCell':
                setCell(e.target.value);
                _cell = e.target.value;
                break;
            case 'slParty':
                setPoliticalParty(e.target.value);
                _party = e.target.value;
                break;
            case 'slState':
                setState(e.target.value);
                _state = e.target.value;
                break;
            case 'slPrecinct':
                setPrecinct(e.target.value);
                _precinct = e.target.value;
                break;
            case 'slCounty':
                setCounty(e.target.value);
                _county = e.target.value;
                break;
            case 'slURL':
                setURL(e.target.value);
                _url = e.target.value;
                break;
        }

        // only apply mapping if we have the required cell field and theres data to map to
        if (_cell && (allCSVDataRows && allCSVDataRows.length)) {
            //console.log("\n\nDataMapperColumnMapping: handleChange(e) calling applyUpdatedDataMap(): e = ", e)
            //console.log("\n\n")
            applyUpdatedDataMap(_uniqueID, _fname, _lname, _cell, _party, _state, _precinct, _county, _url, allCSVDataRows);
        }
    }

    async function applyUpdatedDataMap(
            colUniqueID,
            colFName,
            colLName,
            colCellPhone,
            colParty,
            colState,
            colPrecinct,
            colCounty,
            colURL,
            data)
    {
        //console.log("\n\nEDR - DataMapperColumnMapping: entered applyUpdatedDataMap(colUniqueID, colFName, colLName, colCellPhone, colParty, colState, colPrecinct, colCounty, colURL, data)", colUniqueID, colFName, colLName, colCellPhone, colParty, colState, colPrecinct, colCounty, colURL, data)
        // only apply mapping if we have the required cell field and theres data to map to
        if (!colCellPhone && (!data || !data.length)) {
            //console.log("\n\nEDR - DataMapperColumnMapping: exiting applyUpdatedDataMap() because missing cell field or no data to map to")
            return;
        }
        //console.log("\n\n")
        //******************************************* TODO copied from sample data function above.  Need to come up with a single repo for this
        const date = new Date();

        //console.log("cleaned data -> ", data);
        //console.log("updated f name ->", colFName);

        var _id = '';
        var _lname = 'LName';
        var _fname = 'FName';
        var _party = 'N/A';
        var _phone = 'tbd';

        //console.log("file data =>", colUniqueID);
        // console.log("file data =>", colFName);

        var _state = 'NA'; //campaignStateAbbr;

        var _cName = '';
        var _campName = '';

        if(companyName.length > 3){
            _cName = companyName.substring(0,4);
        }
        else{
            _cName = companyName;
        }

        if(campaignName.length > 3){
            _campName = campaignName.substring(0,4);
        }
        else{
            _campName = campaignName;
        }

        var _precinct = _cName.trim() + "_" + _campName.trim() + "_" + (date.getMonth() + 1) + date.getDate() + "A";
        var _county = _cName.trim() + "_" + _campName.trim() + "_" + (date.getMonth() + 1) + date.getDate() + "_COUNTY";

        var _url = '';

        //***************************************************************************************************************************************

        //console.log("display data -> ", displayData);

        var _max = data.length;

        setDisplayData([]);

        var _d = [];

        // console.log("loading sample data -> ", data);

        if(data.length > 0){

            for (let i=0; i < _max; i++) {

                try{
                    //colUniqueID, colFName, colLName, colCellPhone, colParty, colState, colPrecinct, colCounty, colURL

                    //var _id = uuidv4();

                    //console.log("Applying data map", allCSVDataRows[i][colFName]);

                    if (colUniqueID && colUniqueID.length) {
                        _id = data[i][colUniqueID];
                    }
                    else{
                        _id = uuidv4();
                    }

                    if (colFName && colFName.length){
                        _fname = data[i][colFName];
                    }

                    if (colLName && colLName.length) {
                        _lname = data[i][colLName];
                    }

                    if (colCellPhone && colCellPhone.length) {
                        _phone = data[i][colCellPhone];
                    }

                    if (colParty && colParty.length) {
                        _party = data[i][colParty];
                    }

                    if (colState && colState.length) {
                        _state = data[i][colState];
                    }

                    if (colPrecinct && colPrecinct.length) {
                        _precinct = data[i][colPrecinct];
                    }

                    if (colCounty && colCounty.length) {
                        _county = data[i][colCounty];
                    }

                    if (colURL && colURL.length) {
                        _url = data[i][colURL];
                    }

                    _d.push([
                            _id,
                            _fname,
                            _lname,
                            _phone,
                            _party,
                            _state,
                            _precinct,
                            _county,
                            _url
                        ]);

                }catch(err){
                    console.log("error parsing the requested record [i]:", err);
                }

            }

        }


        //_d = _d.sort((a,b) => b[3] - a[3])

        setDisplayData(_d);
        setPresentationData(_d);

        $(tableRef.current).dataTable().fnClearTable();
        if(_d.length > 0){
            $(tableRef.current).dataTable().fnAddData(_d);

        }

        // setMemberCount(_d.length);

    }

    async function saveMapping(){
        //console.log("DataMapperColumnMapping: entered saveMapping()")
        var thisFieldMapping = newFieldMapping();

        if (uniqueID.length > 0) {
            thisFieldMapping.UID = {"field": uniqueID}
        }

        if (fname.length > 0) {
            thisFieldMapping.FName = {"field": fname}
        }

        if (lname.length > 0) {
            thisFieldMapping.LName = {"field": lname}
        }

        if (cell.length > 0) {
            thisFieldMapping.Cell_Phone = {"field": cell}
        }

        if (politicalParty.length > 0) {
            thisFieldMapping.Party = {"field": politicalParty}
        }

        if (state.length > 0) {
            thisFieldMapping.State = {"field": state}
        }

        if (precinct.length > 0) {
            thisFieldMapping.Precinct = {"field": precinct}
        }

        if (county.length > 0) {
            thisFieldMapping.County = {"field": county}
        }

        if (url.length > 0) {
            thisFieldMapping.URL = {"field": url}
        }
        //console.log("DataMapperColumnMapping: saveMapping(): thisFieldMapping = ", thisFieldMapping);

        // clearColumnMappings();

        // tell DataMapperContext to save column mapping
        addFileQty(dataFileLength, currentFileID);
        saveDataFileMapping(thisFieldMapping);

    }

    function closeChildForm(){
        clearColumnMappings();
        closeColumnMapper(false);
    }

    return (
        <div style={{background: "white", margin: "10px"}}>

            { displayFileLoading === true ?
            <div>
                <div className="dataMapperLoading" style={{margin: "0px 0px 0px 0px", visibility: "visible", height: "100%"}} id="loading">
                    <div style={{marginTop: "50px", display: "flex", justifyContent: "center", verticalAlign: "middle", alignItems: "center", width: "133%", background: "white", lineHeight: "50px"}}>
                        <label>{mosaicFCMessage}</label>
                    </div>
                    <span className="loading loading-bars loading-lg" style={{marginTop: "140px", marginLeft: "320px", width: "420px"}} />
                    {/*<label>hello World</label>*/}
                </div>
            </div>
            :
            <div>
                <div className="datamapperFileRemove" style={{marginTop: "-5px", marginLeft: "1050px"}} onClick={(e) => closeChildForm(e)}>X</div>
                <div className="collapse-title text-xl font-bold" style={{marginTop: "-35px", maxWidth: "1045px"}}>
                    {selectedDataFileName.trim().substring(0, selectedDataFileName.trim().length - 4).toUpperCase()} - DATA MAPPING DEFINITION
                </div>

                <hr style={{marginTop: "-10px"}}/>

                <div className="flex" style={{margin: "10px 0px 0px 3px", width: "100%"}}>
                    <Button disabled={disableSaveButton} variant="outlined" startIcon={<SaveIcon fontSize="large" />}
                        style={{height: "60px", width: "220px", margin: "0px 0px 0px 0px", background: "white"}}
                        size="medium" onClick={() => saveMapping()}>Save Mapping</Button>

                    <div className="modalDatamapperColumnMappingQty">
                        <label >{dataFileLength}</label> {/*}sourceDataFileLength != ""? sourceDataFileLength.toLocaleString() : 0{*/}
                    </div>

                </div>

                <Tabs style={{marginTop: "10px"}}>
                    <TabList>
                        <Tab>COLUMN MAPPING</Tab>
                        <Tab>SAMPLE DATA</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="grid" style={{margin: "-20px 0px 0px 0px"}}>

                            <div style={{margin: "20px 0px 00px 22px"}}>
                                <div style={{margin: "0px 0px 0px 0px", width: "565px"}}>
                                    <br/><h6>Map your messaging universe data</h6><br/>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="bg-white" style={{padding: "00px 0px 20px 50px", width: "1060px"}}>

                                    <div className="grid" style={{margin: "0px 0px 0px -30px", display: "none"}}>
                                        <label className="userEditTitle" htmlFor="uniqueIdentifier'">Unique ID:</label>
                                        <div className="flex">
                                            <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slUID" multiple={false} onChange={handleChange} value={uniqueID} disabled>
                                                <option value=''>(select column for Unique ID)</option>
                                              {fileColumns.map((column) => (
                                                  <option key={column}>{column}</option>
                                              ))}

                                            </select>
                                            <div style={{margin: "10px 0px 0px 0px"}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                    strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                </svg>
                                            </div>
                                            <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="uniqueidentifer" value={uniqueID} name="uniqueidentifer" placeholder={'Auto Unique Identifier'} readOnly={true}/>
                                        </div>
                                    </div>

                                    <div className="flex" style={{margin: "0px 0px 0px 0px"}}>
                                        <div className="grid" style={{margin: "10px 0px 0px -30px"}}>
                                            <label className="userEditTitle" htmlFor="firstName">First Name:</label>
                                            <div className="flex">
                                                <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slFName" multiple={false} onChange={handleChange} value={fname}>
                                                    <option value=''>(select column for First Name)</option>
                                                  {fileColumns.map((column) => (
                                                        <option key={column}>{column}</option>
                                                  ))}
                                                </select>
                                                <div style={{margin: "10px 0px 0px 0px"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                        strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                    </svg>
                                                </div>
                                                <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="firstname" value={fname} name="firstname" placeholder={'Auto First Name'} readOnly={true}/>
                                            </div>
                                        </div>
                                        {/*<br/>*/}

                                        {/*}<div className="grid" style={{margin: "-15px 0px 0px 30px"}}>{*/}
                                        <div className="grid" style={{margin: "10px 0px 0px 30px"}}>
                                            <label className="userEditTitle" htmlFor="lastName">Last Name:</label>
                                            <div className="flex">
                                                <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slLName" multiple={false} onChange={handleChange} value={lname}>
                                                    <option value=''>(select column for Last Name)</option>
                                                  {fileColumns.map((column) => (
                                                      <option key={column}>{column}</option>
                                                  ))}
                                                </select>
                                                <div style={{margin: "10px 0px 0px 0px"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                        strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                    </svg>
                                                </div>
                                                <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="lastname" value={lname} name="lastname" placeholder={'Auto Last Name'} readOnly={true}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid" style={{margin: "10px 0px 0px -30px"}}>
                                        <div className="flex">
                                            <label className="userEditTitle" htmlFor="cellPhone">Cell Phone Number:</label>
                                            <label className="userEditTitle" htmlFor="tags" style={{color: "red", fontSize: "24px", marginLeft: "2px", marginTop: "-10px", marginBottom: "-10px"}}>*</label>
                                        </div>
                                        <div className="flex">
                                            <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slCell" multiple={false} onChange={handleChange} value={cell}>
                                                <option value=''>(select column for Cell Phone Number)</option>
                                              {fileColumns.map((column) => (
                                                  <option key={column}>{column}</option>
                                              ))}
                                            </select>
                                            <div style={{margin: "10px 0px 0px 0px"}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                    strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                </svg>
                                            </div>
                                            <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="cellphone" value={cell} name="cellphone" placeholder={'Cell Phone Number'} readOnly={true}/>
                                        </div>
                                    </div>


                                    <div className="flex" style={{margin: "0px 0px 0px 0px"}}>
                                        <div className="grid" style={{margin: "10px 0px 0px -30px"}}>
                                            <label className="userEditTitle" htmlFor="state">State:</label>
                                            <div className="flex">
                                                <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slState" multiple={false} onChange={handleChange} value={state}>
                                                    <option value=''>(select column for State)</option>
                                                  {fileColumns.map((column) => (
                                                      <option key={column}>{column}</option>
                                                  ))}
                                                </select>
                                                <div style={{margin: "10px 0px 0px 0px"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                        strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                    </svg>
                                                </div>
                                                <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="state" value={state} name="state" placeholder={'Auto State'} readOnly={true}/>
                                            </div>
                                        </div>

                                        <div className="grid" style={{margin: "10px 0px 0px 30px"}}>
                                            <label className="userEditTitle" htmlFor="precinct">Precinct:</label>
                                            <div className="flex">
                                                <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slPrecinct" multiple={false} onChange={handleChange} value={precinct}>
                                                    <option value=''>(select column for Precinct)</option>
                                                  {fileColumns.map((column) => (
                                                      <option key={column}>{column}</option>
                                                  ))}
                                                </select>
                                                <div style={{margin: "10px 0px 0px 0px"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                        strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                    </svg>
                                                </div>
                                                <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="precinct" value={precinct} name="precinct" placeholder={'Auto Precinct'} readOnly={true}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex" style={{margin: "0px 0px 0px 0px"}}>
                                        <div className="grid" style={{margin: "10px 0px 0px -30px"}}>
                                            <label className="userEditTitle" htmlFor="county">County:</label>
                                            <div className="flex">
                                                <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slCounty" multiple={false} onChange={handleChange} value={county}>
                                                    <option value=''>(select column for County)</option>
                                                  {fileColumns.map((column) => (
                                                      <option key={column}>{column}</option>
                                                  ))}
                                                </select>
                                                <div style={{margin: "10px 0px 0px 0px"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                        strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                    </svg>
                                                </div>
                                                <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="county" value={county} name="county" placeholder={'Auto County'} readOnly={true}/>
                                            </div>
                                        </div>

                                        <div className="grid" style={{margin: "10px 0px 0px 30px"}}>
                                            <label className="userEditTitle" htmlFor="politicalParty">Political Party:</label>
                                            <div className="flex">
                                                <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slParty" multiple={false} onChange={handleChange} value={politicalParty}>
                                                    <option value=''>(select column for Political Party)</option>
                                                  {fileColumns.map((column) => (
                                                      <option key={column}>{column}</option>
                                                  ))}
                                                </select>
                                                <div style={{margin: "10px 0px 0px 0px"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                        strokeWidth="2" strokeLinecap="round"
                                                        strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                    </svg>
                                                </div>
                                                <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="politicalparty" value={politicalParty} name="politicalparty" placeholder={'Auto Political Party'} readOnly={true}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid" style={{margin: "10px 0px 0px -30px"}}>
                                        <label className="userEditTitle" htmlFor="url">URL:</label>
                                        <div className="flex">
                                            <select className="select select-bordered w-[220px]" style={{fontSize: "12px"}} name="slURL" multiple={false} onChange={handleChange} value={url}>
                                                <option value=''>(select column for URL)</option>
                                              {fileColumns.map((column) => (
                                                  <option key={column}>{column}</option>
                                              ))}
                                            </select>
                                            <div style={{margin: "10px 0px 0px 0px"}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                                    strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                                </svg>
                                            </div>
                                            <input className="input input-bordered input-md w-[220px]" style={{fontSize: "12px"}} type="text" id="url" value={url} name="url" placeholder={'Auto URL'} readOnly={true}/>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div>

                            <div style={{margin: "0px 0px 0px 10px", width: "600px"}}>
                                <h6>Sample data ({memberCount > 0 ? memberCount : 0} target recipient(s)</h6>
                            </div>

                            <div className="bg-blue-50" style={{width: "1057px", height: "630px"}}>
                                <div className="DataOverflowScroll" style={{margin: "20px 10px 0px 10px", height: "98%"}}>
                                    {/*hello world*/}


                                    <TableContainer component={Paper} style={{marginTop: "20px"}}>
                                      <Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="sticky table">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>ID</TableCell>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>First Name</TableCell>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>Last Name</TableCell>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>Cell Phone</TableCell>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>Party</TableCell>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>State</TableCell>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>Precinct</TableCell>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>County</TableCell>
                                            <TableCell align="left" sx={{fontSize: ".75rem"}}>URL</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {(rowsPerPage > 0
                                                ? presentationData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : presentationData
                                              ).map((row) =>  (
                                            <TableRow
                                              key={row[0]}
                                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                              <TableCell component="th" scope="row" sx={{fontSize: ".75rem"}}>
                                                {row[0]}
                                              </TableCell>
                                              <TableCell align="left" sx={{fontSize: ".75rem"}}>{row[1]}</TableCell>
                                              <TableCell align="left" sx={{fontSize: ".75rem"}}>{row[2]}</TableCell>
                                              <TableCell align="left" sx={{fontSize: ".75rem"}}>{row[3]}</TableCell>
                                              <TableCell align="left" sx={{fontSize: ".75rem"}}>{row[4]}</TableCell>
                                              <TableCell align="left" sx={{fontSize: ".75rem"}}>{row[5]}</TableCell>
                                              <TableCell align="left" sx={{fontSize: ".75rem"}}>{row[6]}</TableCell>
                                              <TableCell align="left" sx={{fontSize: ".75rem"}}>{row[7]}</TableCell>
                                              <TableCell align="left" sx={{fontSize: ".75rem"}}>{row[8]}</TableCell>
                                            </TableRow>
                                          ))}
                                          {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                              <TableCell colSpan={6} />
                                            </TableRow>
                                          )}
                                        </TableBody>
                                        <TableFooter>
                                          <TableRow>
                                            <TablePagination
                                              rowsPerPageOptions={[5, 10, 20, 50, { label: 'All', value: -1 }]}
                                              colSpan={3}
                                              count={presentationData.length}
                                              rowsPerPage={rowsPerPage}
                                              page={page}
                                              SelectProps={{
                                                inputProps: {
                                                  'aria-label': 'rows per page',
                                                },
                                                native: true,
                                              }}
                                              onPageChange={handleChangePage}
                                              onRowsPerPageChange={handleChangeRowsPerPage}
                                              ActionsComponent={TablePaginationActions}
                                            />
                                          </TableRow>
                                        </TableFooter>
                                      </Table>
                                    </TableContainer>


                                </div>
                            </div>
                        </div>
                    </TabPanel>

                </Tabs>
            </div>
            }
        </div>
    );
};

export default DataMapperColumnMapping;
