import { useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";

const Campaign_PerSuccessful = () => {
    const { campaignStats } = useContext(CampaignContext);

    return (
        <>
            <div>
                <div className="flex" style={{margin: "10px 0px 0px 30px"}}>
                    <div style={{margin: "50px 0px 0px 20px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none"
                            stroke="#0b1e91" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                        </svg>
                    </div>
                    <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                        <div className="dashboardTileTitle">Percentage Successful</div>
                        <div className="dashboardTileMainData">{campaignStats.perSuccessfulFormatted}</div>
                        <div className="dashboardTileSubtext">Percentage of successfully delivery</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Campaign_PerSuccessful;
