import { useContext } from "react";
import {CampaignContext } from "../../context/CampaignContext"
import { useNavigate } from "react-router-dom";

const CreateNewCampaign = () => {
    const { setNewCampaignNavData } = useContext(CampaignContext)
    const navigate = useNavigate();
    const createCampaign = async() => {
        setNewCampaignNavData(null);
        navigate("/CampaignCreate");
    };

    return (
        <div className="navigationCreateCampaign" onClick={() => createCampaign()}>
            <div className="flex">
                <div style={{margin: "30px 0px 0px 40px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42"
                        viewBox="0 0 24 24" fill="none" stroke="#17326f" strokeWidth="2" strokeLinecap="round"
                        strokeLinejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                </div>
                <div className="grid" style={{margin: "32px 0px 0px 20px"}}>
                    <div className="createItemTitle">Create New Campaign</div>
                </div>
            </div>
        </div>
    );
};

export default CreateNewCampaign;
