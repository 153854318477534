import { useEffect, useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTreeChart from "highcharts/modules/treemap";
import HighchartsData from "highcharts/modules/data";

HighchartsData(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsTreeChart(Highcharts);
HighchartsData(Highcharts);

const CampaignProjectCodeMsgCounts = () => {
    const { loading, campaignProjectCodeMsgCounts} = useContext(CampaignContext);

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    useEffect(() =>{
        Highcharts.getOptions().colors.push("#293f78")
    })

    return (
        <div className={`stats dashboardTile`}>
            <div className="grid">
                {loading == true ?
                    (
                        <div className="componentTwoHighLoading" style={{margin: "0px 0px 0px 0px"}}>
                            <div className="loading loading-bars loading-lg" style={{ marginTop: "100px", marginLeft: "175px",
                                width: "150px", height: "150px"}} />
                            {/*<label>hello World</label>*/}
                        </div>

                    )
                    :
                    (
                        <HighchartsReact  highcharts={Highcharts} options={
                            {
                                colorAxis: {
                                    minColor: '#FFFFFF',
                                    maxColor: Highcharts.getOptions().colors[Highcharts.getOptions().colors.length - 1]
                                },
                                chart: {
                                    renderTo: 'container',
                                    type: 'treemap'
                                },
                                title: {
                                    text: 'Message Counts By Project Code'
                                },
                                series: [
                                    {
                                        layoutAlgorithm: 'squarified',
                                        name: 'Message Counts By Project Code',
                                        size: '90%',
                                        data: campaignProjectCodeMsgCounts

                                    }
                                ]
                            }
                        }/>
                    )

                }
            </div>
        </div>
    );
};

export default CampaignProjectCodeMsgCounts;
