import { useEffect, useState, useContext, useCallback } from "react";
import { FacetContext } from "../../context/FacetContext";
import { CrystalContext } from "../../context/CrystalContext";
import { AmplifyEventContext } from "../../context/AmplifyEventContext";
import { DataMapperContext } from "../../context/DataMapperContext";

import { browserName } from "react-device-detect";
import ReactPlayer from 'react-player';

//MMS drop location
import { useDropzone } from 'react-dropzone';
import TextInput from "../../components/TextEditor/TextInput";

import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import { red } from '@mui/material/colors';

import { v4 as uuidv4 } from 'uuid';

//NOTIFICATIONS ---------------------------------------------------
import { useSnackbar } from 'notistack';
//NOTIFICATIONS ---------------------------------------------------

const EventMessage = () => {
    const { eventCoreInformation,
            eventMessageBodyInformation,
            updateMMSAttachments,
            calcSetEventUnitPrice,
            removeMMSAttachments,
            updateSMSSampleRecord,
            eventUXSampleDataInformation,
            copyFacetImageFromCampaignToEvent
        } = useContext(AmplifyEventContext);

    const { setMMSPatchVals,
            facetProcessingImageUpload,
            setFacetProcessingImageUpload,
            checkFacetFileProcessingComplete,
            setFacetMessage,
            processingFacetImage,
            setProcessingFacetImage
        } = useContext(FacetContext);

    const { setMMSCrystalPatchVals, crystalProcessingImageUpload } = useContext(CrystalContext);
    const { dataMapperFirstRecord } = useContext(DataMapperContext);

    //MMS VALS INFORMATION
    //eslint-disable-next-line
    const [mmsEventPatchVals, setMMSEventPatchVals] = useState({updateid: '', mmsGetURL: '', mmsPutURL: '', mmsDeleteURL: ''});

    //eslint-disable-next-line
    const [mmsGetURL, setMMSGetURL] = useState('');

    //eslint-disable-next-line
    const [mmsFileName, setMMSFileName] = useState('');

    //eslint-disable-next-line
    const [mmsFileType, setMMSFileType] = useState('');

    //eslint-disable-next-line
    const [openErrorSnackAlert, setOpenErrorSnackAlert] = useState(false);

    //eslint-disable-next-line
    const [errorSnackAlertMessage, setErrorSnackAlertMessage] = useState('');

    const [rotationFactorSample, setRotationFactorSample] = useState(0);
    const [sampleClickMessage, setSampleClickMessage] =useState('Click me to show message sample data');

    //eslint-disable-next-line
    const [estimatedProcessingTime, setEstimatedProcessingTime] = useState(0);

    const [eventMessageEditVisibility, setEventMessageEditVisibility] = useState("visible");
    const [dAndDErrorMessage, setDAndDErrorMessage] = useState("");
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const [ userMessage, setUserMessage ] = useState('');
    const [ userError, setUserError] = useState('');

    const sampleDataStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorSample + "deg)"
    };

    useEffect(() => {
        setUserMessage("");
    }, [eventCoreInformation?.key])

    useEffect(() => {
        if(userMessage != null && userMessage.length > 0){
            enqueueSnackbar(userMessage, {
                variant: "success",
                autoHideDuration: 5000
            });
        }
    }, [userMessage])

    useEffect(() => {
        if(userError != null && userError.length > 0){
            enqueueSnackbar(userError, {
                variant: "error",
                autoHideDuration: 5000
            });
        }
    }, [userError])

    useEffect(() => {
        setUserError(dAndDErrorMessage);
    }, [dAndDErrorMessage])

    useEffect(() => {
        if(processingFacetImage == true){
            setEventMessageEditVisibility("hidden");
        }
        else{
            setEventMessageEditVisibility("visible");
        }
    }, [processingFacetImage])

    useEffect(() => {
        if (!crystalProcessingImageUpload) {
            return;
        }

        // console.log("EventMessage: useEffect()[crystalProcessingImageUpload] entered ", crystalProcessingImageUpload)
        // console.log("START THE LISTENING AT THE EVENT MESSENGER FOR CRYSTAL PROCESSING");
        var _type = crystalProcessingImageUpload.mmsType;
        var _patchVals = {updateid: uuidv4(), mmsType: _type, mmsFile: crystalProcessingImageUpload.mmsFile, mmsCorrectedName: crystalProcessingImageUpload.mms}
        setMMSEventPatchVals(_patchVals);
    }, [crystalProcessingImageUpload])

    useEffect(() => {
        if (!facetProcessingImageUpload) {
            return;
        }

        var _size = facetProcessingImageUpload.mmsFile.size;
        var _type = facetProcessingImageUpload.mmsType;

        var _estimatedProcessingTime = Math.ceil(_size/2750);
        setEstimatedProcessingTime(_estimatedProcessingTime);
        setFacetMessage(``);

        var _status = 'processing';
        var t = setInterval(async function() {
            if(_status != 'COMPLETE'){
                var _r = await checkFacetFileProcessingComplete(facetProcessingImageUpload.s3FileInformation.get_url);
                _status = _r;
                if(_status == 'COMPLETE'){
                    setMMSGetURL(facetProcessingImageUpload.s3FileInformation.get_url);

                    var _patchVals = {updateid: uuidv4(), mmsType: _type, mmsGetURL: facetProcessingImageUpload.s3FileInformation.get_url,
                        mmsPutURL: facetProcessingImageUpload.s3FileInformation.put_url,
                        mmsDeleteURL: facetProcessingImageUpload.s3FileInformation.delete_url,
                        mmsFile: facetProcessingImageUpload.mmsFile, mmsCorrectedName: facetProcessingImageUpload.mms}

                    if(eventCoreInformation?.key != null){
                        copyFacetImageFromCampaignToEvent(facetProcessingImageUpload.s3FileInformation.get_url, eventCoreInformation?.key,
                            facetProcessingImageUpload.mms, facetProcessingImageUpload.mmsType);
                    }

                    updateMMSAttachments(_patchVals);
                    setMMSEventPatchVals(_patchVals);
                    _status = 'COMPLETE';
                }
            }
            else{
                clearInterval(t);
                t = null;
                setProcessingFacetImage(false);
            }
        }, 500);
        setFacetProcessingImageUpload(null);
    }, [facetProcessingImageUpload]);

    // THIS LIKELY WILL NEED TO BE REINSTATED, BUT NEED TO TEST IT FIRST TO CHECK
    // useEffect(() => {
    //     //console.log("new sms", sms);
    //     // createSampleSMSBody(eventMessageBodyInformation.content);
    // }, [eventMessageBodyInformation, sampleFirstName, sampleLastName, sampleCounty, sampleURL] )

    useEffect(() => {
        if(dataMapperFirstRecord != null){
            updateSMSSampleRecord(dataMapperFirstRecord);
        }
    }, [dataMapperFirstRecord])

    const onDrop = useCallback((acceptedFiles:FileList) => {
        var i = 10;
        var t = setInterval(async function() {
            if(i < 5){
                i++;
                // console.log("drop counter", i);
            }
            else{
                clearInterval(t);

                if(acceptedFiles.length > 0){
                    var _iCounter = 0;
                    if(_iCounter > 0){
                        acceptedFiles.splice(0,1); //remove the accepted file due to size... purely clean up
                    }
                    else{
                        if(acceptedFiles[0].size < 5000000 && acceptedFiles[0].size > 0){
                            //NOTE: remove unwanted characters
                            //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
                            //eslint-disable-next-line
                            var _mmsCorrectedName = acceptedFiles[0].name.replace(/[^a-z0-9_.\\]/gi, '');
                            var _mmsVals = { mms: _mmsCorrectedName, mmsType: acceptedFiles[0].type, mmsFile: acceptedFiles[0]};
                            if(acceptedFiles[0].type == "video/mp4"){
                                setMMSCrystalPatchVals(_mmsVals);
                            }
                            else{
                                setMMSPatchVals(_mmsVals);
                            }
                            acceptedFiles.splice(0,1); //remove the accepted file due to size... purely clean up
                        }
                        else{
                            //ALERT MODAL
                            // var _s = acceptedFiles[0].size/1000;

                            // setModalErrorDesc("Media files attached to a message must be no larger than 600KB in size.\r\nThe file you attempted to attach was " + _s + " KB in size.\r\n\r\nPlease try a smaller file or contact us for support." );

                            acceptedFiles.splice(0,1); //remove the accepted file due to size... purely clean up
                        }
                    }
                }

            }
        }, 500);
    }, []);

    function dropFileValidator(file){
        if(file.name != undefined){
            var _iCounter = 0;
            var _s = 0;

            if(_iCounter > 0){
                setDAndDErrorMessage("Cannot accept a media file with a any special characters in the name (e.g. &, %, #).\r\n\r\nPlease rename the file or contact us for support.");
                return{
                    code: "invalid file name",
                    message: "Cannot accept a media file with a any special characters in the name (e.g. &, %, #).\r\n\r\nPlease rename the file or contact us for support."
                };

            }

            if(file.size > 5000000){
                _s = file.size/1000000;

                setDAndDErrorMessage("File size cannot exceed 5 MB in size. The file you attempted to attach was " + _s + " MB in size.\r\n\r\nPlease try a smaller file or contact us for support.");

                return{
                    code: "file too large",
                    message: "File size cannot exceed 5 MB in size. The file you attempted to attach was " + _s + " MB in size.\r\n\r\nPlease try a smaller file or contact us for support."
                };
            }
        }
        return null;
    }

    //eslint-disable-next-line
    const [mmsDropFile, setMMSDropFile] = useState('');
    //eslint-disable-next-line
    const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({onDrop, accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': [], 'video/mp4': []}, validator: dropFileValidator})
    //const mmsFileTypes = ["jpg", "png", "gif"];

    //eslint-disable-next-line
    function removeFile(e){
        removeMMSAttachments();
        setMMSDropFile('');
        setMMSFileName('');
        setMMSGetURL('');

        var _newSegCount = 0;
        try{
            _newSegCount = Math.ceil(eventMessageBodyInformation.content.length/160,0);
        }catch(parseError){
            console.log("Segment Count parse Error", parseError);
        }

        //determinEventUnitPrice(_newSegCount, null, null);
        calcSetEventUnitPrice(_newSegCount, null, null);
    }

    useEffect(() => {
        if(dAndDErrorMessage.trim() != ""){
            displayDandDErrorMessage(dAndDErrorMessage);
            setDisplayErrorMessage(true);
            setEventMessageEditVisibility("hidden");
        }
    }, [dAndDErrorMessage])

    function closeErrorMessage(){
        setDisplayErrorMessage(false);
        setEventMessageEditVisibility("visible");
        setDAndDErrorMessage("");
    }

    function displayDandDErrorMessage(inboundError){
        // console.log("at the snack alert");
        setOpenErrorSnackAlert(true);
        setErrorSnackAlertMessage(inboundError);
    }

    function applyRotationSampleData(){
        if(rotationFactorSample == 0)
        {
            setRotationFactorSample(180);
            setSampleClickMessage("Click me to hide message sample data");
        }
        else
        {
            setRotationFactorSample(0);
            setSampleClickMessage("Click me to show message sample data");
        }
    }

    function handleSampleDataChange(e, source){
        var _origSampleData = eventUXSampleDataInformation;
        var _newSample = {
            first_name: _origSampleData.first_name,
            last_name: _origSampleData.last_name,
            data_fields: {
                County: _origSampleData.county,
                URL: _origSampleData.URL
            }
        }

        switch(source){
        case "FirstName":
            _newSample.first_name = e;
            break;
        case "LastName":
            _newSample.last_name = e;
            break;
        case "URL":
            _newSample.data_fields.URL = e;
            break;
        case "Couhty":
            _newSample.data_fields.County = e;
            break;
        }

        updateSMSSampleRecord(_newSample);
    }

    function hasValidMessageContent() {
        if (eventMessageBodyInformation?.content &&
            (
                eventMessageBodyInformation.content.includes("[%OptOutMessage-Classic%]") ||
                eventMessageBodyInformation.content.includes("[%OptOutMessage-Stop2End%]") ||
                eventMessageBodyInformation.content.includes("[%OptOutMessage-StopToEnd%]") ||
                eventMessageBodyInformation.content.includes("[%OptOutMessage-Stop=End%]") ||
                eventMessageBodyInformation.content.includes("[%Double Opt-In/Out%]")
            ))
        {
            return true;
        }

        return false;
    }

return (
    <div>
        {displayErrorMessage == true ?
        <div>
            <div className="grid" onClick={(e) => closeErrorMessage(e)} style={{background: "white", height: "100%", width: "100%"}}>
                <div className="EventMessage_DAndDErrorMessage">
                    <div style={{marginTop: "300px"}}>
                        {dAndDErrorMessage}
                    </div>
                    <div style={{fontSize: "24px", marginTop: "30px", color: "red", cursor: "pointer"}}>
                        click here to return to the message creation form
                    </div>
                </div>
            </div>
        </div>
        :
        <div/>
        }
        { processingFacetImage == true ?
        <div>
            <div className="dataMapperLoading" style={{margin: "0px 0px 0px 0px", visibility: "visible", height: "100%"}} id="loading">
                {/*}
                <div style={{marginTop: "50px", display: "flex", justifyContent: "center", verticalAlign: "middle", alignItems: "center", width: "133%", background: "white", lineHeight: "50px"}}>
                    <label>{mosaicFCMessage}</label>
                </div>
                {*/}
                <span className="loading loading-bars loading-lg" style={{marginTop: "140px", marginLeft: "320px", width: "420px"}} />
            </div>
        </div>
        :
        <div/>
        }
        <div className="flex" style={{visibility: eventMessageEditVisibility}}>

            <div style={{margin: "0px 0px 20px 20px"}}>
                <div className="grid" style={{width: "680px"}}>
                    {
                        eventMessageBodyInformation?.media_name == null || eventMessageBodyInformation?.media_name.length == 0 ?
                        (
                            <div className="dropFileRectangle" {...getRootProps()}>

                                  <input {...getInputProps()} />
                                  {
                                    isDragActive ?
                                        <div className="dropFileTitle">Drop the files here ...</div> :
                                        (
                                        <div style={{textAlign: "center"}}>
                                            <div className="dropFileTitle">Drag and drop media files here, or click to select your file</div>
                                            <div className="dropFileTitle" style={{fontSize: "18px"}}>Supported types: jpeg, gif, png of 5MBs or less</div>
                                            <div className="dropFileTitle" style={{fontSize: "18px"}}>or mp4 of 600KBs or less</div>
                                        </div>
                                        )
                                  }
                            </div>
                        )
                        :
                        (
                            <div/>
                        )

                    }


                    {
                        eventMessageBodyInformation?.media_name != null && eventMessageBodyInformation?.media_name.length > 0 ?
                        (
                            <div style={{height: "150px"}}>
                                <div className="dataMapperFile" style={{padding: "10px 10px 10px 10px"}}>
                                    <div className="flex mt-[10px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="#000000"
                                            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/>
                                            <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/>
                                        </svg>
                                        <label className="datamapperFileName ml-[5px] mt-[15px]">{eventMessageBodyInformation.media_name}</label>
                                    </div>
                                    <div className="dataMapperFileClose" onClick={removeFile} style={{margin: "-75px 0px 0px 295px"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"
                                            stroke="#ff0000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M3 3h18v18H3zM15 9l-6 6m0-6l6 6"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div/>
                        )
                    }


                </div>
                <br/>

                <div className="grid">

                    <TextInput/>

                </div>

                <br/>

                {
                    hasValidMessageContent() ?
                        (
                            <div style={{marginTop: "30px", marginLeft: "50px", height: "123px"}} />
                        )
                    :
                        (
                            <div style={{marginTop: "10px", marginLeft: "0px", width: "680px", borderRadius: "20px", border: "10px solid red", padding: "10px"}}>
                                <div className="flex" >
                                    <PhonelinkEraseIcon sx={{ color: red[500], fontSize: 96}}/>
                                    <div style={{fontSize: "36px", fontWeight: "bold", color: "red", marginLeft: "10px", marginTop: "-3px"}}>OPT-OUT WILDCARD REQUIRED IN MESSAGE BODY</div>
                                </div>
                            </div>
                        )
                }

                <div className="eventCreateTiming" style={{margin: "20px 0px 0px 0px", width: "680px"}}>
                    <div className="collapse border border-black group-open:rotate-180">
                        <input id="messageDetailsAccordion" type="checkbox" onClick={applyRotationSampleData} />
                        <div className="collapse-title flex">
                            <div className="stepEllipse mt-3"  style={{width: "45px"}}  />
                            <label className="text-l font-bold" style={{margin: "18px 0px 0px -33px"}}>4.1.</label>
                            <div className="collapse-title text-xl font-bold">MESSAGE SAMPLE DATA - {sampleClickMessage}</div>
                            <svg
                                style={sampleDataStyle}
                                id="test" xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>
                        </div>

                        <div className="collapse-content" style={{background: "white", marginTop: "-20px"}}>

                            <div className="flex">
                                <div className="grid">
                                    <label className="userEditTitle" htmlFor="sampleFirstName">First Name:</label>
                                    <input className="input input-bordered input-md" style={{width: "208px", margin: "0px 10px 0px 0px"}} type="text" id="sampleFirstName" name="sampleFirstName"
                                        value={eventUXSampleDataInformation.FirstName} onChange={e => handleSampleDataChange(e.target.value, "FirstName")} placeholder={'Sample First Name'}/>
                                </div>

                                <div className="grid">
                                    <label className="userEditTitle" htmlFor="sampleLastName">Last Name:</label>
                                    <input className="input input-bordered input-md" style={{width: "208px", margin: "0px 10px 0px 0px"}} type="text" id="sampleLastName" name="sampleLastName"
                                        value={eventUXSampleDataInformation.LastName} onChange={e => handleSampleDataChange(e.target.value, "LastName")} placeholder={'Sample Last Name'}/>
                                </div>

                                <div className="grid">
                                    <label className="userEditTitle" htmlFor="sampleCounty">County:</label>
                                    <input className="input input-bordered input-md" style={{width: "210px", margin: "0px 0px 0px 0px"}} type="text" id="sampleCounty" name="sampleCounty"
                                        value={eventUXSampleDataInformation.County} onChange={e => handleSampleDataChange(e.target.value, "County")} placeholder={'Sample County'}/>
                                </div>
                            </div>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="sampleURL">URL:</label>
                                <input className="input input-bordered input-md" type="text" id="sampleURL" name="sampleURL"
                                    value={eventUXSampleDataInformation.URL} onChange={e => handleSampleDataChange(e.target.value, "URL")} placeholder={'Sample URL'}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="grid" style={{height: "950px", marginLeft: "-45px"}}>
                <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[700px] w-[350px] shadow-xl ml-16 mt-10 mb-5">
                    <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                    <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                    <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                    <div className="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                    <div className="rounded-[2rem] overflow-hidden w-[322px] h-[672px] bg-white">
                        <div className="w-[322px] h-[672px] pr-2 pt-2 pb-2 pr-2 bg-white eventResult_PhoneArtBoard">
                            {
                            eventMessageBodyInformation?.media_name != null && eventMessageBodyInformation?.media_name.length > 0 ?
                            <div className="chat chat-start" style={{margin: "30px 0px 0px 0px",
                                            width: "300px", maxWidth: "300px"}}>
                                <div className="chat-bubble eventEdit_MMSBody">
                                {
                                    eventMessageBodyInformation?.media_type != null && eventMessageBodyInformation?.media_type == "video/mp4"
                                        ? browserName != "Firefox"
                                                ?
                                                (
                                                    <ReactPlayer controls width="250px" height="200px" src={eventMessageBodyInformation.media_get_url + "#t=.01"} type="video/mp4"/>
                                                )
                                                //? (<Video width="250px"><source src={mms} type="video/mp4" /></Video> )
                                                :
                                                (
                                                    // <Video autoPlay loop muted controls
                                                    //     width="100%"
                                                    //     height="100%"
                                                    //     onCanPlayThrough={() => {
                                                    //         // Do stuff
                                                    //     }} style={{display: "block"}}>
                                                    //     <source src={eventMessageBodyInformation.media_get_url} />
                                                    // </Video>
                                                    <div style={{color: "black", fontSize: "24px", fontWeight: "bold"}}>UNABLE TO DISPLAY VIDEO SAMPLE IN FIREFOX</div>
                                                )
                                        :
                                        (
                                            <div>
                                                <img src={eventMessageBodyInformation?.media_get_url} />
                                            </div>
                                        )
                                }
                                </div>
                            </div>
                            :
                            <div/>
                            }
                            {
                                eventMessageBodyInformation?.media_name == null || eventMessageBodyInformation?.media_name.length == 0
                                    ?
                                    (
                                        <div className="chat chat-start" style={{margin: "30px 0px 0px 0px",
                                            width: "300px", maxWidth: "300px"}}>
                                            <div className="chat-bubble eventEdit_SMSBody">{eventUXSampleDataInformation.sampleSMSBody}</div>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="chat chat-start" style={{margin: "10px 0px 0px 0px",
                                            width: "300px", maxWidth: "300px"}}>
                                            <div className="chat-bubble eventEdit_SMSBody">{eventUXSampleDataInformation.sampleSMSBody}</div>
                                        </div>
                                    )
                            }

                        </div>
                    </div>
                </div>
                <label style={{position: "relative", marginTop: "-95px", marginLeft: "85px", fontSize: "12px"}}>* Sample only. May appear differently on an actual SMS/MMS enabled device.</label>
            </div>
        </div>
    </div>


    );

};

export default EventMessage;
