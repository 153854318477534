import { useContext } from "react";
import { EventReportContext } from "../../context/EventReportContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

import { browserName } from "react-device-detect";

import ReactPlayer from 'react-player';

//eslint-disable-next-line
const EventResults_MsgPreview = ({ SMS, MMS, FormattedSMS }) => {
    const { eventDetails } = useContext(EventReportContext);
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <>
            {appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="flex">
                <div>
                    <div className="rounded-none pl-4 pt-4 pb-4 pr-4 max-w-md card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                        <div className="eventResultsHeaderTitle">RAW SMS:</div>
                        <div className="eventResults_SMSBody">{SMS}</div>
                    </div>
                    <div className="rounded-none pl-4 pt-4 pb-4 pr-4 max-w-md card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                        <div className="eventResultsHeaderTitle">FORMATTED SMS:</div>
                        <div className="eventResults_SMSBody">{FormattedSMS}</div>
                    </div>
                    <div className="rounded-none pl-4 pt-4 pb-4 pr-4 max-w-md card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                        <div className="stat-title">SMS Body Length</div>
                        <div className="stat-value text-primary text-blue-300">{eventDetails.MsgBody_Length}</div>
                    </div>
                    <div className="rounded-none pl-4 pt-4 pb-4 pr-4 max-w-md card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                        <div className="stat-title">SMS Segment Count</div>
                        <div className="stat-value text-primary text-orange-500">{eventDetails.MsgBody_SegmentCount}</div>
                    </div>
                </div>

                {
                eventDetails.msg_mms.length > 0 && browserName != "Firefox" ?
                <div className="card rounded-none pl-4 pt-4 pb-4 pr-4 max-w-md card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                    <div className="eventResultsHeaderTitle">MMS:</div>

                    <div className="eventResults_MMSBody">
                        {
                            eventDetails.msg_mms.endsWith("mp4")
                             ? (<ReactPlayer controls width="400px" height="300px" url={eventDetails.msg_mms + "#t=.01"} type="video/mp4"/>)
                             : (<img style={{width: "400px"}} src={eventDetails.msg_mms}/>)
                        }
                    </div>
                </div>
                :
                <div />
                }

                <div className="ml-16 mt-5 mb-5" >
                    <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[700px] w-[350px] shadow-xl">
                        <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                        <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                        <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                        <div className="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                        <div className="rounded-[2rem] overflow-hidden w-[322px] h-[672px] bg-white">
                            <div className="w-[322px] h-[672px] pr-2 pt-2 pb-2 pr-2 bg-white eventResult_PhoneArtBoard">
                                {
                                eventDetails.msg_mms.length > 0 ?
                                <div className="eventResults_PhoneMMSBody">
                                    {
                                        eventDetails.msg_mms.endsWith("mp4")
                                            ? browserName != "Firefox"
                                                    ? (<ReactPlayer controls width="250px" height="200px" url={eventDetails.msg_mms + "#t=.01"} type="video/mp4"/>)
                                                    : (<div>UNABLE TO DISPLAY VIDEO SAMPLE IN FIREFOX</div>)
                                            : (<img style={{width: "250px"}} src={eventDetails.msg_mms}/>)
                                    }
                                </div>
                                :
                                <div/>
                                }
                                <div className="chat chat-start" style={{margin: "0px 0px 0px 0px", width: "300px"}}>
                                    <div className="chat-bubble eventResults_SMSBody">{FormattedSMS}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[350px] mt-5 text-sm">the image above is an approximation and could differ based on device type, verision, and operating system.</div>
                </div>

            </div>
            :
            <div style={{width: "100vw"}}>
                <div>
                    <div className="rounded-none pl-4 pt-4 pb-4 pr-4 max-w-sm card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                        <div className="eventResultsHeaderTitle">RAW SMS:</div>
                        <div className="eventResults_SMSBody">{SMS}</div>
                    </div>
                    <div className="rounded-none pl-4 pt-4 pb-4 pr-4 max-w-sm card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                        <div className="eventResultsHeaderTitle">FORMATTED SMS:</div>
                        <div className="eventResults_SMSBody">{FormattedSMS}</div>
                    </div>
                    <div className="rounded-none pl-4 pt-4 pb-4 pr-4 max-w-sm card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                        <div className="stat-title">SMS Body Length</div>
                        <div className="stat-value text-primary text-blue-300">{eventDetails.MsgBody_Length}</div>
                    </div>
                    <div className="rounded-none pl-4 pt-4 pb-4 pr-4 max-w-sm card shadow-md rounded-lg ml-3 mt-3 mr-3 mb-3">
                        <div className="stat-title">SMS Segment Count</div>
                        <div className="stat-value text-primary text-orange-500">{eventDetails.MsgBody_SegmentCount}</div>
                    </div>
                </div>

                {
                eventDetails.msg_mms.length > 0 ?
                <div className="card rounded-none pl-4 pt-4 pb-4 pr-4 max-w-md card shadow-md rounded-lg mt-3 mr-3 mb-3">
                    <div className="eventResultsHeaderTitle">MMS:</div>
                        <div className="eventResults_MMSBody">
                            {
                                eventDetails.msg_mms.endsWith("mp4")
                                 ? (<ReactPlayer controls width="380px" height="290px" url={eventDetails.msg_mms + "#t=.01"} type="video/mp4"/>)
                                 : (<img style={{width: "380px"}} src={eventDetails.msg_mms}/>)
                            }
                        </div>
                </div>
                :
                <div />
                }

                <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[700px] w-[350px] shadow-xl mt-5 mb-5 ml-9">
                    <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                    <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                    <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                    <div className="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                    <div className="rounded-[2rem] overflow-hidden w-[322px] h-[672px] bg-white">
                        <div className="w-[322px] h-[672px] pr-2 pt-2 pb-2 pr-2 bg-white eventResult_PhoneArtBoard">
                            {
                            eventDetails.msg_mms.length > 0 ?
                            <div className="eventResults_PhoneMMSBody">
                                {
                                    eventDetails.msg_mms.endsWith("mp4")
                                     ? (<ReactPlayer controls width="250px" height="200px" url={eventDetails.msg_mms + "#t=.01"} type="video/mp4"/>)
                                     : (<img style={{width: "250px"}} src={eventDetails.msg_mms}/>)
                                }
                            </div>
                            :
                            <div/>
                            }
                            <div className="chat chat-start" style={{margin: "0px 0px 0px 0px", width: "300px"}}>
                                <div className="chat-bubble eventResults_SMSBody">{FormattedSMS}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

EventResults_MsgPreview.propTypes = {
    SMS: PropTypes.string.isRequired,
    MMS: PropTypes.string.isRequired,
    FormattedSMS: PropTypes.string.isRequired,
};

export default EventResults_MsgPreview;
