import { createContext, useContext, useState } from "react";
import { AuthContext } from "./AuthContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

import { v4 as uuidv4 } from 'uuid';

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const ProfileManagementContext = createContext();

export const ProfileManagementContextProvider = ({ children }: any) => {

    const { jwt, dispatch } = useContext(AuthContext);

    const [userPWDValidationCode, setPWDValidationCode] = useState('');

    async function generatePWDresetCode(userInfo) {
        var _resp = {userName: '', EMail: '', Status: '', CellPhone: ''};
        var _userName = userInfo.userName;
        var _userEmail = userInfo.email;

        if (_userEmail == '')
            _userEmail = "na";

        if (_userName == '')
            _userName = "na";

        try {
            const res = await axios.get(
                `${config.reportapi.url}/api/v1/event/pwdresetcodecreate?userAccount=${_userName}&emailAddress=${_userEmail}`,
                {headers: { "Content-Type": "application/json" }}
            );

            if(res != undefined){
                if(res.data.values.length > 0){
                    if(res.data.values[0]["CellPhone"] != "+17755551212"){
                        setPWDValidationCode(res.data.values[0]["resetCode"]);

                        _resp.userName = res.data.values[0]["userAccount"];
                        _resp.EMail = res.data.values[0]["email_address"];
                        _resp.CellPhone = res.data.values[0]["CellPhone"];

                        var messageBody = `Your Amplify verification code is: ${res.data.values[0]["resetCode"]}`

                        var messageBundle = {targetPhone: res.data.values[0]["CellPhone"], messageBody: messageBody};

                        await sendVerificationCode(messageBundle);
                    }
                    else{
                        throw new Error("Invalid Phone Number");
                    }
                }
                else{
                    throw new Error("No Account Found");
                }
            }
            _resp.Status = "OK";
        }
        catch(error){
            console.log("**** ERROR generating reset code *****", error);
            _resp.Status =  "**** ERROR generating reset code *****", error;
        }

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
        return _resp;
    }

    async function sendVerificationCode(messageBundle) {

        var _resp = "";

        try{
            var targetPhone = '';
            var mediaURL = '';
            var messageBody = '';
            var fromPhone = '';

            if (messageBundle.targetPhone.length > 0) {
                targetPhone = messageBundle.targetPhone;
                messageBody = messageBundle.messageBody;
                // metaDataMessage = messageBundle.metaDataMessage;
                fromPhone = '+17754641213';
                // sampleId = sampleId;
                //targetName = '';

                var _id = dayjs().unix();

                if (targetPhone.length == 10)
                    targetPhone = '+1' + targetPhone;

                var _msg = {
                      "name": uuidv4(),
                      "event_id": _id.toString(),
                      "bandwidth_campaign_id": "TBD",
                      "application_id": '0914ecb6-a746-474f-9630-ba7d7558b1aa',      ///NOTE: This is app: app AFC TESTING-Test New Responses... location name: loc AFC TESTING-Test New Responses
                      "admin_phone_number": fromPhone,
                      "admin_phone_number_area_code": '775',
                      "admin_phone_number_status": "COMPLETE",
                      "tasks": [
                        {
                          "task_type": "ADMIN_MESSAGE",
                          "data": {
                            "message": {
                                "body": messageBody,
                                "media_url": mediaURL,
                                "tag": null
                            },
                            "customer": {
                                "phone": targetPhone,
                                "data_message": null,
                                "tag": null
                            }
                          },
                          "status": 'pending',
                          "object_type": null,
                          "created_dt": null,
                          "created_user": null,
                          "updated_dt": null,
                          "updated_user": null
                        }
                      ]
                    }

                const headers = { headers: { Authorization: "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZmFwcHNlcnZpY2UiLCJyb2xlcyI6WyJzZWM6Z2xvYmFsYWRtaW4iXSwiZXhwIjoyMDMwMjA3Mjg4fQ.C-aubpVMsd-PRx01FpJwDc3pNKcNN5LPDsRhkVMcQAs' } };
                await axios.put(
                    `https://afevent.roosevelt.hyperscaleaf.com/api/v1/adminevents/${_id}`,
                    //`${config.eventapi.url}/api/v1/adminevents/${_id}`,
                    _msg,
                    headers
                );
            }
            else{
                throw new Error("No Phone Number Supplied");
            }
            _resp = "OK";
        }
        catch(err){
            console.log("**** ERROR sending verification code *****", err);
            _resp = "**** ERROR sending verification code *****", err;
        }

        return _resp;
    }

    async function sendResetEmail(userName, email){
        var _userName = null;
        var _email = null;

        if(userName.length > 0){
            _userName = userName;
            _email = null;
        }
        else if(email.length > 0){
            _userName = null;
            _email = email;
        }

        var _msg = {
            "user_name": _userName,
            "email": _email
        }

        await axios.patch(
            `${config.accountapi.url}/api/v1/token/generate`,
            _msg,
            {headers: { "Content-Type": "application/json" }}
        )
        .catch(function (error) {
            console.log("**** ERROR sending reset email *****", error);
        });
    }

    async function resetPassword(passwordResetBundle) {
        var _msg = {
            "user_token": passwordResetBundle.user_token,
            "password": passwordResetBundle.password,
            "password_verification": passwordResetBundle.password
        }

        await axios.patch(
            `${config.accountapi.url}/api/v1/token/password/${passwordResetBundle.user_token}`,
            _msg,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        });
    }

return (
        <ProfileManagementContext.Provider value={{
            generatePWDresetCode:                               generatePWDresetCode,
            userPWDValidationCode:                              userPWDValidationCode,
            sendResetEmail:                                     sendResetEmail,
            resetPassword:                                      resetPassword
        }}>
        {children}
        </ProfileManagementContext.Provider>
    );
}
