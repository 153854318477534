import { useContext, useCallback } from "react";
import { UnifyContext } from "../../context/UnifyContext";

import TextField from '@mui/material/TextField';

//MMS drop location
import { useDropzone } from 'react-dropzone';

const UnifyCampaignCreate_HomePage = () => {

    const { unifyKey, uploadImage,

            heroTitle,setHeroTitle,
            heroParagraph,setHeroParagraph,
            heroBanner_GetURL,

            issuesTitle,setIssuesTitle,
            issuesIntroduction,setIssuesIntroduction,

            donateNowTitle,setDonateNowTitle,
            donationImage_GetURL,

            donateNowTitle_Home2,setDonateNowTitle_Home2,

            spreadTheWordTitle,setSpreadTheWordTitle,
            spreadTheWordDesc,setSpreadTheWordDesc,
            spreadTheWord1_GetURL,
            spreadTheWord2_GetURL,
            spreadTheWord3_GetURL,

        } = useContext(UnifyContext);

    const onDrop = useCallback((acceptedFiles:FileList, dropType, unifyCampaignKey) => {
        //mmsFileTypes

        if(acceptedFiles.length > 0){
            //NOTE: remove unwanted characters
            //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
            //eslint-disable-next-line
            var _imageCorrectedName = acceptedFiles[0].name.replace(/[^a-z0-9_.\ ]/gi, '');

            var imagePackage = {name: '', image: '', type: '', target: '', unifyCampaignKey: ''};
            imagePackage.name = _imageCorrectedName;
            imagePackage.image = acceptedFiles[0];
            imagePackage.type = acceptedFiles[0].type;
            imagePackage.target = dropType;
            imagePackage.unifyCampaignKey = unifyCampaignKey;

            // console.log("package to save", imagePackage);

            uploadImage(imagePackage);
        }
    }, []);

    const {getRootProps: getRootProps_Hero, getInputProps: getInputProps_Hero, isDragActive: isDragActive_Hero} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "hero", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Donate, getInputProps: getInputProps_Donate, isDragActive: isDragActive_Donate} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "donate", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Spread1, getInputProps: getInputProps_Spread1, isDragActive: isDragActive_Spread1} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "spread1", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Spread2, getInputProps: getInputProps_Spread2, isDragActive: isDragActive_Spread2} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "spread2", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Spread3, getInputProps: getInputProps_Spread3, isDragActive: isDragActive_Spread3} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "spread3", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    return (

        <div className="unifyComponentPage" style={{margin: "0px 30px 20px 30px"}}>

            {/*HERO SECTION*/}

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>The splash content... your hero section</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div className="flex">
                <div className="grid">
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Hero Title"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setHeroTitle(e.target.value)}
                        value={heroTitle}
                    />
                </div>
            </div>
            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Hero Paragraph Overlay"
                        style={{width: "620px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setHeroParagraph(e.target.value)}
                        value={heroParagraph}
                    />
                </div>

                <div style={{marginTop: "0px", marginLeft: "20px"}}>
                    { heroBanner_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "620px", height: "310px"}}>
                        <img src={heroBanner_GetURL}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Hero()} style={{width: "620px", height: "310px"}}>
                          <input {...getInputProps_Hero()} />
                          {
                            isDragActive_Hero ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your hero banner image here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>


            </div>

            {/*ISSUES SECTION*/}

            <div className="flex" style={{marginTop: "40px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>What you are passionate about</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div className="flex">
                <div className="grid">
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Issues Title"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssuesTitle(e.target.value)}
                        value={issuesTitle}
                    />
                </div>
            </div>
            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Issues Introduction Paragraph"
                        style={{width: "620px"}}
                        multiline
                        rows={12}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setIssuesIntroduction(e.target.value)}
                        value={issuesIntroduction}
                    />
                </div>

            </div>

            <div className="unifyCallOut" style={{width: "90%", margin: "40px 60px 40px 0px"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>We will automatially fill in the home page issues based on the issues you call out in the upcoming issues section... no need to do it twice!</div>
            </div>

            {/*DONATE NOW SECTION*/}

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Let&lsquo;s get some money moving</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div className="flex">
                <div className="grid">
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Donate Now Title"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setDonateNowTitle(e.target.value)}
                        value={donateNowTitle}
                    />
                </div>
            </div>
            <div className="flex" style={{marginTop: "20px"}}>
                <div style={{marginTop: "0px", marginLeft: "0px"}}>
                    { donationImage_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "620px", height: "310px"}}>
                        <img src={donationImage_GetURL}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Donate()} style={{width: "620px", height: "310px"}}>
                          <input {...getInputProps_Donate()} />
                          {
                            isDragActive_Donate ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your donation image here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>


            </div>

            {/*SPREAD THE WORD*/}

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Add three images that show people excited about your campaign</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex">
                <div className="grid">
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Spread The Word Title"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSpreadTheWordTitle(e.target.value)}
                        value={spreadTheWordTitle}
                    />
                </div>
            </div>
            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Spread The Word Description"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSpreadTheWordDesc(e.target.value)}
                        value={spreadTheWordDesc}
                    />
                </div>
            </div>
            <div className="flex">
                <div className="flex" style={{marginTop: "20px"}}>
                    { spreadTheWord1_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "300px", height: "300px"}}>
                        <img src={spreadTheWord1_GetURL}/>
                    </div>
                    :
                    <div style={{marginTop: "0px", marginLeft: "0px"}}>
                        <div className="dropFileRectangle_unify" {...getRootProps_Spread1()} style={{width: "300px", height: "300px"}}>
                              <input {...getInputProps_Spread1()} />
                              {
                                isDragActive_Spread1 ?
                                    <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                    (
                                    <div style={{textAlign: "center"}}>
                                        <div className="dropFileTitle_unify" style={{fontSize: "24px", marginLeft: "20px", marginRight: "20px"}}>Drag and drop your first friendly face image here, or click to select your file</div>
                                    </div>
                                    )
                              }
                        </div>

                    </div>
                    }
                </div>
                <div className="flex" style={{marginTop: "20px", marginLeft: "20px"}}>
                    { spreadTheWord2_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "300px", height: "300px"}}>
                        <img src={spreadTheWord2_GetURL}/>
                    </div>
                    :
                    <div style={{marginTop: "0px", marginLeft: "0px"}}>
                        <div className="dropFileRectangle_unify" {...getRootProps_Spread2()} style={{width: "300px", height: "300px"}}>
                              <input {...getInputProps_Spread2()} />
                              {
                                isDragActive_Spread2 ?
                                    <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                    (
                                    <div style={{textAlign: "center"}}>
                                        <div className="dropFileTitle_unify" style={{fontSize: "24px", marginLeft: "20px", marginRight: "20px"}}>Drag and drop your second friendly face image here, or click to select your file</div>
                                    </div>
                                    )
                              }
                        </div>

                    </div>
                    }
                </div>
                <div className="flex" style={{marginTop: "20px", marginLeft: "20px"}}>
                    { spreadTheWord3_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "300px", height: "300px"}}>
                        <img src={spreadTheWord3_GetURL}/>
                    </div>
                    :
                    <div style={{marginTop: "0px", marginLeft: "0px"}}>
                        <div className="dropFileRectangle_unify" {...getRootProps_Spread3()} style={{width: "300px", height: "300px"}}>
                              <input {...getInputProps_Spread3()} />
                              {
                                isDragActive_Spread3 ?
                                    <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                    (
                                    <div style={{textAlign: "center"}}>
                                        <div className="dropFileTitle_unify" style={{fontSize: "24px", marginLeft: "20px", marginRight: "20px"}}>Drag and drop your third friendly face image here, or click to select your file</div>
                                    </div>
                                    )
                              }
                        </div>
                    </div>
                    }
                </div>

            </div>

            {/*DONATE NOW SECTION 2*/}

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>And because you can never ask enough...</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div className="flex">
                <div className="grid">
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Second Donate Now Title"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setDonateNowTitle_Home2(e.target.value)}
                        value={donateNowTitle_Home2}
                    />
                </div>
            </div>

            <div className="unifyCallOut" style={{width: "90%", margin: "40px 60px 40px 0px"}}>
                <div style={{margin: "20px 20px 20px 20px", fontSize: "32px", fontWeight: "bold"}}>CONGRATULATIONS!  You have laid out the entire home page! Now, let&lsquo;s get people to know you by completing the &quot;About&quot; page!</div>
            </div>


        </div>

    );
};

export default UnifyCampaignCreate_HomePage;
