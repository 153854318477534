import { useEffect, useState, useContext } from "react";
import { UnifyContext } from "../../context/UnifyContext";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import Button from '@mui/material/Button';
import BackupIcon from '@mui/icons-material/Backup';
import RefreshIcon from '@mui/icons-material/Refresh';
import PublishIcon from '@mui/icons-material/Publish';

//Pages
import UnifyCampaignCreate_HomePage from "./unifyCampaignCreate_HomePage";
import UnifyCampaignCreate_AboutPage from "./unifyCampaignCreate_AboutPage";
import UnifyCampaignCreate_IssuesPage from "./unifyCampaignCreate_IssuesPage";
import UnifyCampaignCreate_EventsPage from "./unifyCampaignCreate_EventsPage";
import UnifyCampaignCreate_VolunteerPage from "./unifyCampaignCreate_VolunteerPage";
import UnifyCampaignCreate_GeneralInfo from "./unifyCampaignCreate_GeneralInfo";
import UnifyCampaignCreate_DemoUniversePage from "./unifyCampaignCreate_DemoUniversePage";


const unifyNewCampaignCreate = () => {

    const { createNewId,
            processingUnifyEventCreate,setProcessingUnifyEventCreate,
            generateConfigJson, smallLogo, largeLogo,
            populateSampleData,
            collectStatusOfUnifyEventCreate,
            sendTextMessageSamples,
          } = useContext(UnifyContext);

    //Non-linear stepper
    const steps = ['General Information', 'Home', 'About', 'Issues', 'Events', 'Volunteer', 'Universe Data'];

    const [activeStep, setActiveStep] = useState(0);

    //eslint-disable-next-line
    const [completed, setCompleted] = useState({});

    const [displayProgress, setDisplayProgress] = useState('collapse');
    const [displayBuilder, setDisplayBuilder] = useState('visible');

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        document.getElementById('scrollMain').scrollLeft = (newActiveStep * 1560);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        document.getElementById('scrollMain').scrollLeft = ((activeStep - 1) * 1560);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
        document.getElementById('scrollMain').scrollLeft = (step * 1560);
    };

    function createNewUnifyCampaignKey(){
        createNewId();
    }

    useEffect(() => {
        createNewId();
    }, []);

    useEffect(() => {

        if(processingUnifyEventCreate == true){

            var _status = 'processing';
            var i = 1;

            var t = setInterval(async function() {

                //FOR TESTING ONLY - DEM
                if(i == 5){
                    _status = "complete";
                }

                if(_status != 'complete'){
                    var _r = await collectStatusOfUnifyEventCreate();
                    _status = _r;
                }
                else{
                    clearInterval(t);
                    setProcessingUnifyEventCreate(false);
                    sendTextMessageSamples();
                }

                i++;

            }, 2500);
        }
    }, [processingUnifyEventCreate]);

    useEffect(() => {
        if(processingUnifyEventCreate == true){
            setDisplayProgress('visible');
            setDisplayBuilder('collapse');
        }
        else{
            setDisplayProgress('collapse');
            setDisplayBuilder('visible');
        }
    }, [processingUnifyEventCreate])

    async function saveEvent(){
        // console.log("small logo", smallLogo);
        // console.log("large logo", largeLogo);

        await generateConfigJson();
    }

    return (

        <div style={{height: "800px"}}>
            <div className="flex bg-neutral" style={{height: "75px", borderWidth: "1px", borderColor: "lightgray"}}>

                {/*<Box sx={{ p: 56 }} />*/}

                <div className="flex" style={{margin: "6px 0px 0px 25px", width: "220px"}}>
                    <Button
                        //disabled={disableSaveButton}
                        variant="outlined" startIcon={<PublishIcon fontSize="large" />}
                        style={{height: "60px", width: "220px", margin: "0px 0px 0px 0px", background: "darkOrange", color: "white"}}
                        size="large"
                        onClick={() => saveEvent()}
                    >GENERATE NOW</Button>
                </div>

                <Box sx={{ p: 22.5 }} />

                <Box sx={{width: 520}}/>
                <Button
                    // disabled={disableSendSamplesButton}
                    variant="outlined" startIcon={<BackupIcon fontSize="large"/>}
                    style={{height: "60px", width: "220px", margin: "6px 50px 0px 10px", background: "white"}} size="large"
                    onClick={() => populateSampleData()}
                    >Pre-Populate Sample Data</Button>

                <Button variant="outlined" startIcon={<RefreshIcon fontSize="large" />}
                    style={{height: "60px", width: "220px", margin: "6px 20px 0px 0px", background: "white"}}
                    size="large"
                    onClick={() => createNewUnifyCampaignKey()}
                    >Refresh</Button>



            </div>

            <div style={{margin: "20px 30px 20px 30px"}}>

                <Box sx={{ width: "100%" }}>
                    <div style={{marginBottom: "20px", marginTop: "20px", marginRight: "30px"}}>
                    <Stepper nonLinear activeStep={activeStep} alternativeLabel >
                        {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                    </Stepper>
                    </div>
                    <div className="flex" style={{marginBottom: "20px", marginRight: "30px", marginLeft: "5px"}}>
                        <button className="btn btn-outline bg-blue-200"
                            disabled={activeStep === 0}
                            onClick={handleBack} style={{marginLeft: "10px", borderRadius: "50px", height: "50px", width: "50px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"
                                stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" style={{marginLeft: "-7px", marginTop: "11px"}}><path d="M15 18l-6-6 6-6"/>
                            </svg>
                        </button>
                        <Box style={{width: "1560px"}} />
                        <button className="btn btn-outline bg-blue-200" onClick={handleNext} style={{marginLeft: "10px", borderRadius: "50px", height: "50px", width: "50px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"
                                stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" style={{marginLeft: "-3px", marginTop: "11px"}}><path d="M9 18l6-6-6-6"/>
                            </svg>
                        </button>
                    </div>
                </Box>

                <div>
                    <div className="dataMapperLoading" style={{margin: "0px 0px 0px 0px", visibility: displayProgress, height: "1200px", width: "1540px"}}>
                        <span className="loading loading-bars loading-lg" style={{marginTop: "200px", marginLeft: "420px"}} />
                    </div>

                    <div id="scrollMain" className="flex" style={{ marginTop: "-1200px", width: "1540px", height: "3050px", visibility: displayBuilder, overflowX:"hidden", overflowY:"hidden", background: "white"}}>

                        <div className="eventCreateHeaderInformation" id="card0" style={{minWidth: "1540px", minHeight: "3050px"}}>
                            <div >
                                <div className="collapse-title flex">
                                    <div className="collapse-title text-xl font-bold">CAMPAIGN INFORMATION</div>
                                </div>
                                <UnifyCampaignCreate_GeneralInfo />
                            </div>
                        </div>
                        <div className="eventCreateHeaderInformation" id="card1" style={{minWidth: "1540px", height: "3050px"}}>
                            <div >
                                <div className="collapse-title flex">
                                    <div className="collapse-title text-xl font-bold">HOME PAGE</div>
                                </div>
                                <UnifyCampaignCreate_HomePage />
                            </div>
                        </div>

                        <div className="eventCreateHeaderInformation" id="card2" style={{minWidth: "1540px", height: "3050px", background: "white"}}>
                            <div >
                                <div className="collapse-title flex">
                                    <div className="collapse-title text-xl font-bold">ABOUT PAGE</div>
                                </div>
                                <UnifyCampaignCreate_AboutPage />
                            </div>

                        </div>

                        <div className="eventCreateHeaderInformation" id="card3" style={{minWidth: "1540px", height: "3100px", background: "white"}}>
                            <div >
                                <div className="collapse-title flex">
                                    <div className="collapse-title text-xl font-bold">ISSUES PAGE</div>
                                </div>
                                <UnifyCampaignCreate_IssuesPage style={{marginBottom: "10px"}}/>
                            </div>

                        </div>

                        <div className="eventCreateHeaderInformation" id="card4" style={{minWidth: "1540px", height: "3050px", background: "white"}}>
                            <div >
                                <div className="collapse-title flex">
                                    <div className="collapse-title text-xl font-bold">EVENTS PAGE</div>
                                </div>
                                <UnifyCampaignCreate_EventsPage />
                            </div>

                        </div>

                        <div className="eventCreateHeaderInformation" id="card5" style={{minWidth: "1540px", height: "3050px", background: "white"}}>
                            <div >
                                <div className="collapse-title flex">
                                    <div className="collapse-title text-xl font-bold">VOLUNTEER PAGE</div>
                                </div>
                                <UnifyCampaignCreate_VolunteerPage />
                            </div>

                        </div>

                        <div className="eventCreateHeaderInformation" id="card6" style={{minWidth: "1540px", height: "1140px", background: "white"}}>
                            <div >
                                <div className="collapse-title flex">
                                    <div className="collapse-title text-xl font-bold">UNIVERSE SELECTION</div>
                                </div>
                                <UnifyCampaignCreate_DemoUniversePage />
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    );

};

export default unifyNewCampaignCreate;
