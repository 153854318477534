import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

const Event_TotOptOut = ({ TotOptOut }) => {
    const { isMobileDisplay } = useContext(AuthContext);
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <>

            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className={`stats ${
                                    isMobileDisplay
                                        ? "eventTileMobile rounded-none pl-5 pt-5 pb-5 pr-5 bg-white"
                                        : "dashboardHalfHeightTile rounded-none ml-5 pl-5 pt-5 pb-5 pr-5"
                                    }`}>


                <div style={{margin: "50px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#9b9b9b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="12" y1="2" x2="12" y2="6"></line>
                        <line x1="12" y1="18" x2="12" y2="22"></line>
                        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                        <line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line>
                        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                    </svg>
                </div>
                <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                    <div className="dashboardTileTitle">Total Opt Out Responses</div>
                    <div className="dashboardTileMainData">{TotOptOut}</div>
                    <div className="dashboardTileSubtext">Total targets who opted out</div>
                </div>
            </div>
            :
            <div className={`dashboardHalfHeightTile_Phone flex`}>

                <div style={{margin: "17px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#9b9b9b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <line x1="12" y1="2" x2="12" y2="6"></line>
                        <line x1="12" y1="18" x2="12" y2="22"></line>
                        <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                        <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                        <line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line>
                        <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                    </svg>
                </div>
                <div className="grid" style={{margin: "10px 0px 0px 10px", height: "80px"}}>
                    <div className="dashboardTileTitle_Phone">Total Opt Out Responses</div>
                    <div className="dashboardTileMainData_Phone">{TotOptOut}</div>
                    <div className="dashboardTileSubtext_Phone">Total targets who opted out</div>
                </div>

            </div>
            }
        </>
    );
};

Event_TotOptOut.propTypes = {
    TotOptOut: PropTypes.number.isRequired
};

export default Event_TotOptOut;
