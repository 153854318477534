import { useEffect, useContext, useCallback } from "react";
import { UnifyContext } from "../../context/UnifyContext";

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

//MMS drop location
import { useDropzone } from 'react-dropzone';


const UnifyCampaignCreate_GeneralInfo = () => {

    const { unifyKey, uploadImage,
            fName,setFName,
            lName,setLName,
            fullName,setFullName,
            campaignName,setCampaignName,
            paidForBy,setPaidForBy,
            mailingAddress,setMailingAddress,
            city,setCity,
            state,setState,
            zip,setZip,
            phone,setPhone,

            donationLink, setDonationLink,

            fb,setFb,
            instagram,setInstagram,
            x,setX,
            snapChat,setSnapChat,
            linkedIn,setLinkedIn,
            tikTok,setTikTok,

            webSiteName,setWebSiteName,
            emailAddress,setEmailAddress,

            largeLogo,
            largeLogo_GetURL,
            smallLogo,
            smallLogo_GetURL,
            favIcon_GetURL
        } = useContext(UnifyContext);


    //image Drag and drop
    const onDrop = useCallback((acceptedFiles:FileList, dropType, unifyCampaignKey) => {
        //mmsFileTypes
        if(acceptedFiles.length > 0){
            //NOTE: remove unwanted characters
            //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
            //eslint-disable-next-line
            var _imageCorrectedName = acceptedFiles[0].name.replace(/[^a-z0-9_.\ ]/gi, '');

            var imagePackage = {name: '', image: '', type: '', target: '', unifyCampaignKey: ''};
            imagePackage.name = _imageCorrectedName;
            imagePackage.image = acceptedFiles[0];
            imagePackage.type = acceptedFiles[0].type;
            imagePackage.target = dropType;
            imagePackage.unifyCampaignKey = unifyCampaignKey;

            // console.log("package to save", imagePackage);

            uploadImage(imagePackage);
        }
    }, []);

    const {getRootProps: getRootProps_Large, getInputProps: getInputProps_Large, isDragActive: isDragActive_Large} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "large", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_Small, getInputProps: getInputProps_Small, isDragActive: isDragActive_Small} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "small", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    const {getRootProps: getRootProps_favIcon, getInputProps: getInputProps_favIcon, isDragActive: isDragActive_favIcon} = useDropzone(
            {onDrop: (acceptedFile) => { onDrop(acceptedFile, "favIcon", unifyKey) },
            accept: {'image/jpg': [], 'image/jpeg': [], 'image/png': [], 'image/gif': []}});

    useEffect(() =>{
        // console.log("large logo", largeLogo);
    }, [largeLogo])

    useEffect(() => {
        // console.log("small logo", smallLogo);
    }, [smallLogo])

    return (

        <div className="unifyComponentPage" style={{margin: "0px 30px 20px 30px"}}>

            <div className="flex" style={{marginTop: "30px", marginBottom: "10px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>First of all... the basics</div>
                <hr style={{width: "1000px"}}/>
            </div>
            <div className="flex">
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Candidate First Name"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setFName(e.target.value)}
                        value={fName}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Candidate Last Name"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setLName(e.target.value)}
                        value={lName}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Full Name</div>*/}
                    <TextField
                        type="text"
                        label="Candidate Full Name"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setFullName(e.target.value)}
                        value={fullName}
                    />
                </div>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Campaign Name"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setCampaignName(e.target.value)}
                        value={campaignName}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Paid For By Language"
                        style={{width: "620px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setPaidForBy(e.target.value)}
                        value={paidForBy}
                    />
                </div>


            </div>

            <div className="flex" style={{marginTop: "40px", marginBottom: "0px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>How to donate to your campaign</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid" style={{marginLeft: "0px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Donation Link"
                        style={{width: "1260px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setDonationLink(e.target.value)}
                        value={donationLink}
                    />
                </div>
            </div>

            <div className="flex" style={{marginTop: "40px", marginBottom: "0px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>How to reach you</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Mailing Street Address"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setMailingAddress(e.target.value)}
                        value={mailingAddress}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Mailing City"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="State"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Zip Code"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setZip(e.target.value)}
                        value={zip}
                    />
                </div>

            </div>
            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid" style={{marginLeft: "0px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Phone Number"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                    />
                </div>
            </div>

            <div className="flex" style={{marginTop: "40px", marginBottom: "0px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Your Social Media game</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Facebook Address"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setFb(e.target.value)}
                        value={fb}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="Instagram"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setInstagram(e.target.value)}
                        value={instagram}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="X (formerly Twitter)"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setX(e.target.value)}
                        value={x}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="SnapChat"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setSnapChat(e.target.value)}
                        value={snapChat}
                    />
                </div>

            </div>
            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid" style={{marginLeft: "0px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="TikTok"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setTikTok(e.target.value)}
                        value={tikTok}
                    />
                </div>
                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="LinkedIn"
                        style={{width: "300px"}}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setLinkedIn(e.target.value)}
                        value={linkedIn}
                    />
                </div>
            </div>

            <div className="flex" style={{marginTop: "40px", marginBottom: "0px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Let&lsquo;s build you a presence</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex" style={{marginTop: "20px"}}>
                <div className="grid">
                    {/*<div>Candidate First Name</div>*/}
                    <TextField
                        type="text"
                        label="Preferred Web Site Name"
                        style={{width: "620px"}}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                            .us or .com
                            </InputAdornment>
                        }}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setWebSiteName(e.target.value)}
                        value={webSiteName}
                    />
                </div>

                <div className="grid" style={{marginLeft: "20px"}}>
                    {/*<div>Candidate Last Name</div>*/}
                    <TextField
                        type="text"
                        label="email address"
                        style={{width: "620px"}}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                            {
                                webSiteName.trim().length != 0 ?
                                "@" + webSiteName.trim()
                                :
                                "@websitename.com"
                            }

                            </InputAdornment>
                        }}
                        //onKeyPress={(e) => {if(!/[0-9]/.test(e.key)){event.preventDefault();} }}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        value={emailAddress}
                    />
                </div>


            </div>

            <div className="flex" style={{marginTop: "40px", marginBottom: "0px"}}>
                <hr style={{width: "50px"}}/>
                <div style={{marginTop: "-13px", marginLeft:"5px", marginRight: "5px"}}>Your Logos</div>
                <hr style={{width: "1000px"}}/>
            </div>

            <div className="flex">
                <div style={{marginTop: "30px", width: "450px", height: "300px"}}>
                    { largeLogo_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "450px", height: "300px"}}>
                        <img src={largeLogo_GetURL}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Large()} style={{width: "450px", height: "300px"}}>
                          <input {...getInputProps_Large()} />
                          {
                            isDragActive_Large ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your large campaign logo here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <div style={{marginTop: "30px", marginLeft: "20px", width: "450px", height: "300px"}}>
                    { smallLogo_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "450px", height: "300px"}}>
                        <img src={smallLogo_GetURL}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_Small()} style={{width: "450px", height: "300px"}}>
                          <input {...getInputProps_Small()} />
                          {
                            isDragActive_Small ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your small campaign logo here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                    }
                </div>

                <div style={{marginTop: "30px", marginLeft: "20px", width: "450px", height: "300px"}}>
                    { favIcon_GetURL.trim().length != 0 ?
                    <div className="dropFileRectangle_unify" style={{width: "450px", height: "300px"}}>
                        <img src={favIcon_GetURL}/>
                    </div>
                    :
                    <div className="dropFileRectangle_unify" {...getRootProps_favIcon()} style={{width: "450px", height: "300px"}}>
                          <input {...getInputProps_favIcon()} />
                          {
                            isDragActive_favIcon ?
                                <div className="dropFileTitle_unify">Drop the files here ...</div> :
                                (
                                <div style={{textAlign: "center"}}>
                                    <div className="dropFileTitle_unify" style={{marginLeft: "20px", marginRight: "20px"}}>Drag and drop your small browser icon here, or click to select your file</div>
                                </div>
                                )
                          }
                    </div>
                }
                </div>
            </div>

            <div className="flex" style={{marginTop: "40px"}}>
                <div className="flex" style={{marginLeft: "0px"}}>
                    <label>unify event key:</label>
                    <label style={{marginLeft: "20px"}}>{unifyKey}</label>
                </div>
            </div>

        </div>

    );
};

export default UnifyCampaignCreate_GeneralInfo;
