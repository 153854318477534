import { useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

const DaysInDisplay = ({ days }) => {
    const { appRenderType } = useContext(ApplicationContext);

    return (
        <div>
            { appRenderType == "desktop" || appRenderType == "tablet" ?
            <div className="dashboardHalfHeightTile flex">
                <div style={{margin: "50px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none"
                        stroke="#74dbff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg>
                </div>
                <div className="grid" style={{margin: "20px 0px 0px 20px", height: "80px"}}>
                    <div className="dashboardTileTitle">Days on Display</div>
                    <div className="dashboardTileMainData">{days}</div>
                    <div className="dashboardTileSubtext">The number of days of data on display</div>
                </div>

            </div>
            :
            <div className="dashboardHalfHeightTile_Phone flex">
                <div style={{margin: "17px 0px 0px 20px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none"
                        stroke="#74dbff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg>
                </div>
                <div className="grid" style={{margin: "10px 0px 0px 10px", height: "80px"}}>
                    <div className="dashboardTileTitle_Phone">Days on Display</div>
                    <div className="dashboardTileMainData_Phone">{days}</div>
                    <div className="dashboardTileSubtext_Phone">The number of days of data on display</div>
                </div>
            </div>
            }
        </div>
    );
};

DaysInDisplay.propTypes = {
    days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default DaysInDisplay;
