import { useEffect, useContext, useState, useRef} from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { CampaignContext } from "../../context/CampaignContext";
import { UnifyContext } from "../../context/UnifyContext";
import { ApplicationContext } from "../../context/ApplicationContext";

import Topbar from "../../components/topbar/Topbar";

import Hierarchy_navigation from "../../components/navigation/Hierarchy_navigation";

export default function Unify_TemplateSelection() {

    const { jwt } = useContext(AuthContext);
    const { companyName } = useContext(CompanyContext);
    const { campaignName } = useContext(CampaignContext);
    const { reportWindowSize, updateApplicationSegment } = useContext(ApplicationContext);
    const { createNewId } = useContext(UnifyContext);

    //eslint-disable-next-line
    const [displayWidth, setDisplayWidth] = useState(0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    useEffect(() => {

        updateApplicationSegment("Unify");

        reportWindowSize();
        setDisplayWidth(windowSize.current[0]);

        createNewId();
    }, []);

    if (jwt === null) {
        //return <Login fullscreen={true} />;
        window.location.href = "/login";
    }

    useEffect(() => {
        document.title = "Alliance Forge | Unify Home";
    //console.log("JWT = ", jwt);
    }, [jwt]);

    return (

        <div className="mainPageDiv">

            <div className="accountHeader">

            </div>
            <div className="accountHeaderImage" style={{ position: "relative", zIndex: 10 }}>
                <img className='mr-1 rounded-none border-solid border-2 object-cover' style={{width: "100px", height: "100px", border: "5px solid #555"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/skunkWorks.jpg"/>
            </div>
            <div className="accountHeaderCompanyName">
                <div className="accountHeaderCompanyNameTitle_Mobile">{"//"}{(companyName != null) ? (companyName.toLowerCase()) : ''}{"/"}{(companyName != null) ? (campaignName.toLowerCase()) : ''}</div>
            </div>

            <Topbar displayAdmin={false} />

            <div className="flex">

                <div className="hierarchyNavigationTileFull">
                    <Hierarchy_navigation status={"campaign"} objectWidth={"full"}/>
                </div>
            </div>

            <div className="mainBody">
                <div style={{height:" 1000px"}}>

                    <div className="flex" style={{width: "100%", marginTop: "30px"}}>
                        <div style={{margin: "0px 0px 0px 30px", width: "800px"}}>
                            <h4 style={{fontSize: "24px", fontWeight: "bold", marginTop: "5px"}}>SELECT YOUR UNIFY TEMPLATE</h4>
                        </div>

                        <div className="flex" style={{marginLeft: "500px"}}>
                            <div style={{fontSize: "18px", fontWeight: "bold", marginRight: "20px"}}>NEW</div>
                            <div style={{fontSize: "18px", fontWeight: "normal", marginRight: "20px"}}>POPULAR</div>
                            <div style={{fontSize: "18px", fontWeight: "normal", marginRight: "20px"}}>CAMPAIGNS</div>
                            <div style={{fontSize: "18px", fontWeight: "normal", marginRight: "20px"}}>ADVOCACY</div>
                            <div style={{fontSize: "18px", fontWeight: "normal", marginRight: "20px"}}>EVENTS</div>
                            <div style={{fontSize: "18px", fontWeight: "normal", marginRight: "20px"}}>PREMIUM</div>
                        </div>

                    </div>

                    <div style={{marginLeft: "30px", overflowy: "scroll", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "20px", gridAutoRows: "350px", width: "1550px"}}>
                        <a href="./unifyCampaignCreate"><img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/ChiefExecutive_Template.png"></img></a>
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/responsive_template_base.png"></img>
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/responsive_template_base.png"></img>
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/responsive_template_base.png"></img>
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/responsive_template_base.png"></img>
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/responsive_template_base.png"></img>

                    </div>


                </div>
            </div>
            <footer>
                <div className="signinFooter bg-neutral align-bottom" style={{height: "200px", background: "#102948"}}>
                    <div className="flex">
                        <div className="mt-8" >
                            <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                        </div>
                        <div className="signinFooterTagLineRight" >
                            <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/unify/unify_rwu_transparent.png"/>
                        </div>
                    </div>
                </div>
            </footer>

        </div>

        );

}
