import { useState, useEffect, useContext, forwardRef } from "react";
import PropTypes from 'prop-types';
import { AuthContext } from "../../context/AuthContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import { config } from "../../constants/global.js";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

//import ReactPlayer from 'react-player';
//import { DefaultPlayer as Video } from 'react-html5video';

// Notifications
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import dayjs from "dayjs";

//eslint-disable-next-line
const Login = ({ fullscreen=true }) => {

    const navigate = useNavigate();

    const { dispatch, setIsMobileDisplay } = useContext(AuthContext);
    const { windowWidth, reportWindowSize, appRenderType } = useContext(ApplicationContext);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const [currentSecond, setCurrentSecond] = useState(0);

    const [errorOpen, setErrorOpen] = useState(false);
    const vertical = 'bottom';
    const [errorMessage, setErrorMessage] = useState('');

    var topMargin = "200px 0"

    useEffect(() => {
        document.title = "Alliance Forge | Login";
        setIsMobileDisplay(isMobile);
        reportWindowSize();

        setCurrentSecond(dayjs().second() % 4);

    }, []);

    // Notification vars & functions (called a 'snackbar' or 'toast')
    const Alert = forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
        setErrorMessage('');
    };

    function forgotMyPassword(){
        navigate("/passwordManagement");
    }

    const loginClick = async (event) => {
        event.preventDefault();

        dispatch({ type: "LOGIN_START" });
        try {
            const credentials = {
                user_name: username,
                password: password,
            };
            const res = await axios.post(
                `${config.accountapi.url}/api/v1/auth/authenticate`,
                credentials
            );
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
            window.location.href = "/";
        } catch (err) {
            console.log(err);
            dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
            setErrorOpen(true);
            setErrorMessage(`Login Failed`);
        }
    };

    if(appRenderType == "phone")
    {
        topMargin = "30px 0";
    }

    return (
        <div className="ml-0 mr-0">

            <div className="bg-neutral" style={{height: "100px"}}>
                <div style={{height: "10px"}}>&nbsp;</div>
                <div style={{marginLeft: "10px"}}>
                    <img style={{width: "200px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_transaprent.png"/>
                </div>

            </div>


            {/*
            <div className="loginVideo bg-neutral" >
                <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/login.jpeg"></img>

                <Video autoPlay loop muted controls
                    width="100%"
                    height="100%"
                    onCanPlayThrough={() => {
                        // Do stuff
                    }} style={{display: "block"}}>
                    <source src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Lincoln_Steps_Full_2.mp4" type="video/mp4" />
                </Video>

            </div>
            */}

            {appRenderType == "desktop"  || appRenderType == "tablet" ?
            <div>
                <div className="loginImage bg-neutral" style={{overflow: "hidden"}}>
                    {
                        currentSecond == 0 ?
                    (
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/login_lincoln.gif" style={{overflow: "hidden", width: "100vw"}}></img>
                    )
                    :
                        currentSecond == 1 ?
                    (
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/login_wh.gif" style={{overflow: "hidden", width: "100vw"}}></img>
                    )
                    :
                        currentSecond == 2 ?
                    (
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/login_capitol.gif" style={{overflow: "hidden", width: "100vw"}}></img>
                    )
                    :
                    (
                        <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/login_jefferson.gif" style={{overflow: "hidden", width: "100vw"}}></img>
                    )
                    }
                </div>
                <div className="signinBody" style={{overflowX: "hidden"}}>
                    {/*<video style={{width: "3000vw", height: "1000vh"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/rushmoreSample_1.mp4" type="video/mp4" controls></video>*/}

                    <div className="stats w-96 rounded-none mb-5 pl-5 pt-5 pb-5 pr-5" style={{margin: topMargin, zIndex: "99"}}>

                        <div className="card-body">
                            <h2 className="login-title">Sign In</h2>

                            <div className="form-control w-full max-w-xs">
                              <label className="label">
                                <span className="label-text">Username</span>
                              </label>
                              <input type="text" placeholder="Username" className="input input-bordered w-full max-w-xs"
                                  autoCorrect="off"
                                  autoCapitalize="none"
                                  onChange={(e) => {
                                      setUsername(e.target.value);
                                  }}/>
                            </div>

                            <div className="form-control w-full max-w-xs">
                              <label className="label">
                                <span className="label-text">Password</span>
                              </label>
                              <input type="password" placeholder="Password" className="input input-bordered w-full max-w-xs"
                                  onChange={(e) => {
                                        setPassword(e.target.value);
                                    }} />
                            </div>

                            <div className="card-actions justify-center mt-10">
                              <button className="btn btn-neutral btn-wide" onClick={loginClick}>Sign In</button>
                            </div>


                            <div style={{marginTop: "30px"}}>
                              <label style={{color: "red", fontSize: "12px", cursor: "pointer", marginLeft: "170px"}} onClick={forgotMyPassword}>forgot my password</label>
                            </div>


                        </div>

                    </div>

                </div>
                <footer>
                    <div className="signinFooter bg-neutral align-bottom" style={{maxHeight: "200px", overflow: "hidden"}}>
                        <div className="flex">
                            <div className="mt-8" >
                                <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                            </div>
                            {windowWidth >= 650 ?
                                <div className="signinFooterTagLineRight" >
                                    <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/RUN%20WITH%20US%20-%20INVERSE.png"/>
                                </div>
                            :
                                <div/>
                            }
                        </div>
                    </div>
                </footer>
            </div>
            :
            <div>
                <div className="bg-neutral" style={{height: "73vh"}}/>
                <div className="signinBody_Phone" style={{overflowX: "hidden"}}>
                    {/*<video style={{width: "3000vw", height: "1000vh"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/rushmoreSample_1.mp4" type="video/mp4" controls></video>*/}

                    <div className="stats w-96 rounded-none mb-5 pl-5 pt-5 pb-5 pr-5" style={{margin: topMargin, zIndex: "99"}}>

                        <div className="card-body">
                            <h2 className="login-title">Sign In</h2>

                            <div className="form-control w-full max-w-xs">
                              <label className="label">
                                <span className="label-text">Username</span>
                              </label>
                              <input type="text" placeholder="Username" className="input input-bordered w-full max-w-xs"
                                  autoCorrect="off"
                                  autoCapitalize="none"
                                  onChange={(e) => {
                                      setUsername(e.target.value);
                                  }}/>
                            </div>

                            <div className="form-control w-full max-w-xs">
                              <label className="label">
                                <span className="label-text">Password</span>
                              </label>
                              <input type="password" placeholder="Password" className="input input-bordered w-full max-w-xs"
                                  onChange={(e) => {
                                        setPassword(e.target.value);
                                    }} />
                            </div>

                            <div className="card-actions justify-center mt-10">
                              <button className="btn btn-neutral btn-wide" onClick={loginClick}>Sign In</button>
                            </div>


                            <div style={{marginTop: "30px"}}>
                              <label style={{color: "red", fontSize: "12px", cursor: "pointer", marginLeft: "170px"}} onClick={forgotMyPassword}>forgot my password</label>
                            </div>


                        </div>

                    </div>

                </div>
                <footer>
                    <div className="signinFooterMobile flexwrap space-x-16 bg-neutral align-bottom">
                        <img width="200px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                        <img width="150px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/RUN%20WITH%20US%20-%20INVERSE.png"/>
                    </div>
                </footer>
            </div>
            }

            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical, horizontal: "center" }}>
              <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
              </Alert>
            </Snackbar>

        </div>
    );
}

Login.propTypes = {
   fullscreen: PropTypes.bool
}

export default Login;
