//import { createContext, useContext, useState, useEffect, useRef } from "react";
import { createContext, useContext, useState, useEffect } from "react";

import { AuthContext } from "./AuthContext";
import { CampaignContext } from "./CampaignContext";
import { AmplifyEventContext } from "./AmplifyEventContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

import { v4 as uuidv4 } from 'uuid';

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const DataMapperContext = createContext();

export const DataMapperContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { campaignKey, campaignID } = useContext(CampaignContext);
    const { clearDataMapperFiles, previouslySavedDataMap } = useContext(AmplifyEventContext);

    //*************** to determine load from event context ***********************
    var vsEC_UpdateId = '';
    //****************************************************************************
    // for use with a queue
    //const incomingFileList = useRef([]);

    const [mapperFiles, setMapperFiles] = useState([]);
    const [currentFileID, setCurrentFileID] = useState(null);

    const [newFileToAdd, setNewFileToAdd] = useState(null);

    // edr - this gets set with a UUIDv4 but I dont know what its used for
    const [closeColumnMapperControl, setCloseColumnMapperControl] = useState('');

    const [closeRowFilterControl, setCloseRowFilterControl] = useState('');
    const [closeDataMapperStats, setCloseDataMapperStats] = useState('');
    const [filesTotalQty, setFilesTotalQty] = useState(0);

    const [loadDatamapperColumnMapping, setLoadDatamapperColumnMapping] = useState('');

    //*********************************** Data Mapper Range *************************
    const [enableRange, setEnableRange] = useState(true);
    const [enableRandom, setEnableRandom] = useState(false);

    const [selectedMin, setSelectedMin] = useState(0);
    const [selectedMax, setSelectedMax] = useState(0);
    const [finalUnivSize, setFinalUnivSize] = useState(0);

    const [selectedRandom, setSelectedRandom] = useState(0);
    //************************* END Data mapper Row Filter Randomizer ***************

    //************************* mosaic map creation ****************************
    var _voterSetKey = '';

    //eslint-disable-next-line
    const [mosaicMap, setMosaicMap] = useState('');
    const [voterSetKey, setVoterSetKey] = useState('');

    const [mosaicUniverseSize, setMosaicUniverseSize] = useState(0);
    //*********************** END MOSAIC MAP CREATION ***************************

    //*************************** mosaic map cleanup ******************************
    const [dataMapperMosaicMessage, setDataMapperMosaicMessage] = useState('');
    const [dataMapperProcessingVoterSet, setDataMapperProcessingVoterSet] = useState(false);

    //eslint-disable-next-line
    const [dataMapperVoterSetStatus, setDataMapperVoterSetStatus] = useState('');

    //eslint-disable-next-line
    const [dataMapperUpdatedStamp, setDataMapperUpdatedStamp] = useState('');

    //************************* END mosaic map cleanup ****************************

    const [dataMapperVoterSetStatsURL, setDataMapperVoterSetStatsURL] = useState('');
    const [dataMapperFirstRecord, setDataMapperFirstRecord] = useState([]);

    const [dataMapperResults, setDataMapperResults] = useState(null);
    const [dataMapperLoadingPreviousMap, setDataMapperLoadingPreviousMap] = useState(false);

    /******************************* edit flags ***********************************/
    const [dataMapperMappingsEdited, setDataMapperMappingsEdited] = useState(false);
    const [dataMapperFilesAdded, setDataMapperFilesAdded] = useState(false);
    /***************************** end edit flags *********************************/

    /***************************** stats reporting ********************************/
    const [statsFile, setStatsFile] = useState(null);
    /***************************** stats reporting ********************************/

    /***************************** modal open setting *****************************/
    const [modalColumnMappingOpen, setModalColumnMappingOpen] = useState(null);
    var loadExistingMap = false;
    /******************************************************************************/

    const [datamapApplied, setDatamapApplied] = useState(false);

    useEffect(() => {
        var _a = []
        setMapperFiles([..._a]);
    }, [])

    // EDR - Define All Structures Here
    // This gives a consistently initialized structure
    function newFileInfo(uniqueID) {
        return {
            id: uniqueID,
            fileName: null,          // this our newly 'cleaned' filename
            fileDisplayName: null,
            fileSize: null,
            file: null,
            qty: null,
            saved: null,
            columnMappings: null,
            // this refers to the position in the mapperList
            // cant find any place where its being referenced but renaming it
            // from location to be mapperListPosition
            //location: null
            mapperListPosition: null,
            modalOpenId: null
        };
    }


    useEffect(() => {
        // console.log("DataMapperContext: entered useEffect()[voterSetKey]", voterSetKey)
        if (!voterSetKey) {
            return;
        }
        // console.log("DataMapperContext: useEffect()[voterSetKey] calling getDataMapperVoterSetFirstRecord(voterSetKey)", voterSetKey)
        getDataMapperVoterSetFirstRecord(voterSetKey);
    }, [voterSetKey])

    useEffect(() => {
        // console.log("DataMapperContext: useEffect()[selectedMin, selectedMax, selectedRandom] ", selectedMin, selectedMax, selectedRandom)
        if (!selectedMin || !selectedMax) {
            setFinalUnivSize(0);
        }
        else {
            if (enableRange) {
                setFinalUnivSize((selectedMax - selectedMin) + 1);
            }
            else if (enableRandom) {
                if (selectedRandom > finalUnivSize) {
                    setSelectedRandom(finalUnivSize);
                }
            }
        }
    }, [selectedMin, selectedMax, selectedRandom])


    // edr - newFileToAdd is deliberately being set to null by DataMapperContext::useEffect()[mapperFiles]
    // I wonder if that's how it differentiates between adding a new file versus loading one thats already been mapped?
    // Otherwise, it makes no sense to set this to null every time a new file is added in the UI
    useEffect(() => {
        // console.log("DataMapperContext: entered useEffect()[newFileToAdd] ", newFileToAdd)
        if (!newFileToAdd) {
            // console.log("DataMapperContext: exiting useEffect()[newFileToAdd] because  (!newFileToAdd) ")
            return;
        }
        // console.log("DataMapperContext: useEffect()[newFileToAdd] calling addToMapperFileList(newFileToAdd) ", newFileToAdd)
        addToMapperFileList(newFileToAdd);
    }, [newFileToAdd])

    useEffect(() => {
        // console.log("DataMapperContext: entered useEffect()[mapperFiles] (NEEDS TESTING)", mapperFiles);
        // console.log("*******************************************************************");
        // console.log("DataMapperContext: this is the current mapper file id:", currentFileID);

        if (!newFileToAdd || !currentFileID || !currentFileID.trim()) {
            return;
        }

        var _uuid = uuidv4();

        var _modalOpenId = null;
        var _indexId = mapperFiles.findIndex(object => object.id == currentFileID);

        // console.log("INDEX ID => ", _indexId);

        _modalOpenId = mapperFiles[_indexId]['modalOpenId'];

        // console.log("FOUND MODAL OPEN ID => ", _modalOpenId)

        // console.log("DataMapperContext: useEffect()[mapperFiles] calling setLoadDatamapperColumnMapping({id: _uuid, fileId: currentFileID})", _uuid, currentFileID);
        setLoadDatamapperColumnMapping({id: _uuid, fileId: currentFileID});
        setModalColumnMappingOpen(_modalOpenId);
        // console.log("DataMapperContext: useEffect()[mapperFiles] calling setNewFileToAdd(null)");
        setNewFileToAdd(null);
    }, [mapperFiles])

    useEffect(() => {
        // console.log("DataMapperContext: entered useEffect()[clearDataMapperFiles] (NEEDS TESTING)", clearDataMapperFiles);
        clearMapping();
    }, [clearDataMapperFiles])

    useEffect(() => {
        // console.log("DataMapperContext: entered useEffect()[dataMapperVoterSetStatsURL] (NEEDS TESTING)", dataMapperVoterSetStatsURL);
        if(dataMapperVoterSetStatsURL.trim().length > 0){
            // console.log("DataMapperContext: useEffect()[dataMapperVoterSetStatsURL] calling getDataMapperProcessingStats(dataMapperVoterSetStatsURL): ", dataMapperVoterSetStatsURL);
            getDataMapperProcessingStats(dataMapperVoterSetStatsURL);
        }
    }, [dataMapperVoterSetStatsURL])

    useEffect(() => {
        // console.log("DataMapperContext: useEffect()[previouslySavedDataMap] ", previouslySavedDataMap);
        clearMapping();
        if (!previouslySavedDataMap.dataMapperInfo ||
            previouslySavedDataMap.updatedId == vsEC_UpdateId)
        {
            return;
        }

        setVoterSetKey(previouslySavedDataMap.voterset_key);
        setDataMapperLoadingPreviousMap(true);
        setMosaicUniverseSize(previouslySavedDataMap.mosaicUniverseCount);
        vsEC_UpdateId = previouslySavedDataMap.updatedId;
        applyPreviouslySavedDataMap(previouslySavedDataMap);
    }, [previouslySavedDataMap])


    function addToMapperFileList(newFileInfo){
        // console.log("\n\n\n\nDataMapperContext: entered addToMapperFileList(newFileInfo) (NEEDS TESTING)", newFileInfo);

        var _modalOpenId = newFileInfo['modalOpenId'];

        if (!newFileInfo) {
            // console.log("DataMapperContext: exiting addToMapperFileList(newFileInfo) because (!newFileInfo)");
            // console.log("THIS IS WHERE ITS BROKEN\n\n\n\n")
            return;
        }
        //console.log("\n\n\n\n\nDataMapperContext: entered addToMapperFileList(newFileInfo) (NEEDS TESTING)", newFileInfo);

        if (!mapperFiles || !mapperFiles.length) {
            // console.log("DataMapperContext: addToMapperFileList(newFileInfo): evaluated (!mapperFiles || !mapperFiles.length) to be true")
            // console.log("DataMapperContext: addToMapperFileList(newFileInfo): existing mapperFiles list is empty, adding newFileInfo")
            newFileInfo.mapperListPosition = 0;

            //////CHECK HERE - DEM
            setMapperFiles([newFileInfo]);

            // edr - this tells patchMosaicMap2() that it needs to upload the CSV file to S3 bucket for processing
            // this fixed the file uploading problem
            setDataMapperFilesAdded(true);
            setDataMapperMappingsEdited(true);

            setModalColumnMappingOpen(_modalOpenId);

            // if(loadExistingMap){
            //     setModalColumnMappingOpen(null);            /// USE THIS TO TRIGGER THE OPENING OF THE COLUMN MAPPING MODAL
            // }
            // else{
            //     setModalColumnMappingOpen(uuidv4());
            // }

        } else {
            // console.log("DataMapperContext: addToMapperFileList(newFileInfo): evaluated (!mapperFiles || !mapperFiles.length) to be false")
            var existingMapperFile = mapperFiles.find(object => object.fileName == newFileInfo.fileName);

            // console.log("DataMapperContext: addToMapperFileList(newFileInfo): called mapperFiles.Find() and existingMapperFile = ", existingMapperFile)
            if (!existingMapperFile) {
                // console.log("DataMapperContext - addToMapperFileList(newFileInfo): did NOT find the mapperFile in mapperList: existingMapperFile = ", existingMapperFile);

                // var _files = mapperFiles;
                // var _lastNode = _files.length;
                // newFileInfo.mapperListPosition = _lastNode;
                // _files.splice(_lastNode, 0, newFileInfo);
                // setMapperFiles([..._files]);

                var _files = mapperFiles;
                newFileInfo.mapperListPosition = _files.length;
                _files.push(newFileInfo);
                setMapperFiles([..._files]);
                // console.log("DataMapperContext - addToMapperFileList(newFileInfo): appending newFileInfo to mapperList ", _files);

                // edr - this tells patchMosaicMap2() that it needs to upload the CSV file to S3 bucket for processing
                setDataMapperFilesAdded(true);
                setDataMapperMappingsEdited(true);

                setModalColumnMappingOpen(_modalOpenId);            /// USE THIS TO TRIGGER THE OPENING OF TEH COLUMN MAPPING MODAL

            } else {
                // console.log("DataMapperContext - addToMapperFileList(newFileInfo): newFileInfo is already in the mapperList, exiting");
                setModalColumnMappingOpen(null);            /// USE THIS TO PREVENT THE OPENING OF THE COLUMN MAPPING MODAL
            }
        }
    }

    function removeDataFile(mapperFileID){
        // console.log("DataMapperContext: entered removeDataFile(mapperFileID) (NEEDS TESTING) ", mapperFileID);
        // try{
        //     var _a = mapperFiles;
        //     var _iFound = -1;
        //
        //     for(let i = 0; i < _a.length; i++){
        //         if(_a[i].id == mapperFileID){
        //             _iFound = i;
        //             break;
        //         }
        //     }
        //
        //     if(_iFound != -1){
        //         _a.splice(_iFound, 1);
        //         console.log("DataMapperContext: removeDataFile(mapperFileID) calling setMapperFiles([..._a]) ", _a);
        //         setMapperFiles([..._a]);
        //         setDataMapperMappingsEdited(true);
        //     }
        //
        //     if(_a.length == 0){
        //         setEnableRange(true);
        //         setEnableRandom(false);
        //     }
        //
        //     setMosaicUniverseSize(0);
        // }
        // catch(err){
        //     console.log(`Unable to remove the given file from the mapper files array (${mapperFileID})`, err);
        // }

        var localMapperFiles = mapperFiles;
        var foundIndex = localMapperFiles.findIndex(object => object.id == mapperFileID);
        if (foundIndex) {
            // remove from the list
            // console.log("DataMapperContext: removeDataFile(mapperFileID) removing mapFile from mapperFiles: localMapperFiles[foundIndex] = ", localMapperFiles[foundIndex]);
            localMapperFiles.splice(foundIndex, 1);
            setMapperFiles([...localMapperFiles]);
            // console.log("DataMapperContext: removeDataFile(mapperFileID) removed mapFile from mapperFiles = ", localMapperFiles);
            setDataMapperMappingsEdited(true);
        } else {
            // console.log("DataMapperContext: removeDataFile(mapperFileID) NOT removing mapFile from mapperFiles = ", localMapperFiles);
        }

        if (!localMapperFiles.length) {
            // console.log("DataMapperContext: removeDataFile(mapperFileID) calling setEnableRange(true) and setEnableRandom(false)");
            setEnableRange(true);
            setEnableRandom(false);
        }

        // console.log("DataMapperContext: removeDataFile(mapperFileID) calling setMosaicUniverseSize(0)");
        setMosaicUniverseSize(0);
    }

    function getMapperFile(mapperFileID) {
        // console.log("DataMapperContext: entered getMapperFile(mapperFileID) ", mapperFileID);
        // console.log("DataMapperContext: current mapper files =>", mapperFiles);
        if (!mapperFileID || !mapperFiles) {
            // console.log("DataMapperContext: EMPTY RETURN");
            return;
        }

        var _mapperfile = mapperFiles.find(object => object.id === mapperFileID);
        // console.log("DataMapperContext: current mapper file =>", _mapperfile);

        return _mapperfile;
    }

    async function attachNewFile(fileInfo) {
        // console.log("DataMapperContext: entered attachNewFile(fileInfo) ", fileInfo);
        if (!fileInfo) {
            // console.log("DataMapperContext: exiting attachNewFile(fileInfo) because (!fileInfo) ", fileInfo);
            return;
        }
        // console.log("DataMapperContext: attachNewFile(fileInfo) calling setCurrentFileID(fileInfo.id) ", fileInfo.id);
        setCurrentFileID(fileInfo.id);

        // console.log("DataMapperContext: attachNewFile(fileInfo) calling setNewFileToAdd(fileInfo) ", fileInfo);
        setNewFileToAdd(fileInfo);


        // add fileInfo to the array of files (for use with a queue)
        // var localFileInfoList = incomingFileList.current;
        // localFileInfoList.push(fileInfo);
        // incomingFileList.current = localFileInfoList;

        // add the file id to the processing queue here
    }

    async function saveDataFileMapping(columnMap){
        // console.log("DataMapperContext: entered saveDataFileMapping(columnMap) (NEEDS REWRITING and TESTING)", columnMap);
        //collect the mapperFile from the collection
        // var m = null; //getMapperFile(currentFileID);
        // var _iFound = -1;
        // var _a = mapperFiles;
        //var _b = ([..._a]);

        // for(let i = 0; i < _a.length; i++){
        //     if(_a[i].id == currentFileID){
        //         _iFound = i;
        //         break;
        //     }
        // }
        //
        // if(_iFound != -1){
        //     m = _a[_iFound];
        // }
        //
        // if(m != null){
        //     m.columnMappings = columnMap;
        //     m.saved = true;
        //
        //     // _a.splice(_iFound, 1);
        //     _a.splice(_iFound, 1, m);
        //
        //     console.log("DataMapperContext: saveDataFileMapping(columnMap) calling setMapperFiles([..._a]) ", _a);
        //     setMapperFiles([..._a]);
        //
        //     closeColumnMapper(true);
        // }
        //
        // countFileRows(_a);
        // setDataMapperMappingsEdited(true);
        // setCurrentFileID('');
        // setMosaicUniverseSize(0);

        // get the fileInfo.id from top of the queue, and find that object in mapperFiles

        // console.log("DataMapperContext: saveDataFileMapping(columnMap): currentFileID = ", currentFileID);
        var mapperFileID = currentFileID;

        // console.log("DataMapperContext: saveDataFileMapping(columnMap): mapperFiles = ", mapperFiles);
        var localMapperFiles = mapperFiles;

        var foundIndex = localMapperFiles.findIndex(object => object.id == mapperFileID);
        //var foundIndex = localMapperFiles.findIndex(object => object.id == "rick");
        // console.log("DataMapperContext: saveDataFileMapping(columnMap): foundIndex = ", foundIndex);
        // console.log("DataMapperContext: saveDataFileMapping(columnMap): localMapperFiles[foundIndex] = ", localMapperFiles[foundIndex]);

        if (foundIndex == -1) {
            // console.log("DataMapperContext: saveDataFileMapping(columnMap) failed to find mapperFileID in mapperFiles: ", mapperFileID, localMapperFiles);
            return;
        }

        setCurrentFileID(mapperFileID);

        // edr - maybe should verify this is correct mapperFileID
        localMapperFiles[foundIndex].columnMappings = columnMap;
        localMapperFiles[foundIndex].saved = true;

        // the localMapperFiles[foundIndex].qty value is used by the UI to display number of rows

        // console.log("DataMapperContext: saveDataFileMapping(columnMap) updated column mappings for mapperFileID, calling setMapperFiles(localMapperFiles) ", mapperFileID, localMapperFiles);
        setMapperFiles([...localMapperFiles]);

        // console.log("DataMapperContext: saveDataFileMapping(columnMap) calling closeColumnMapper(true)");
        closeColumnMapper(true);  // send signal to close the column mapping component modal

        // console.log("DataMapperContext: saveDataFileMapping(columnMap) calling countFileRows(localMapperFiles)");
        countFileRows(localMapperFiles);

        // console.log("DataMapperContext: saveDataFileMapping(columnMap) calling setDataMapperMappingsEdited(true)");
        setDataMapperMappingsEdited(true);

        // console.log("DataMapperContext: saveDataFileMapping(columnMap) calling setCurrentFileID('')");
        setCurrentFileID('');

        // console.log("DataMapperContext: saveDataFileMapping(columnMap) calling setMosaicUniverseSize(0) (not sure why its hardcoded to 0)");
        setMosaicUniverseSize(0);
    }

    function countFileRows(inboundFiles){
        // console.log("DataMapperContext: entered countFileRows(inboundFiles) (NEEDS TESTING) ", inboundFiles);

        // var _qty = 0;
        // for(let i = 0; i < inboundFiles.length; i++){
        //     _qty += inboundFiles[i].qty;
        // }

        // faster sum iterating instead of looping
        var _qty = inboundFiles.reduce((sum, obj) => sum + obj.qty, 0);
        setSelectedMax(_qty);

        if(selectedRandom > _qty){
            setSelectedRandom(_qty);
        }

        if(selectedMin == 0){
            setSelectedMin(1);
        }

        return _qty;
    }

    // edr - I think that "saved == true" means that the mappings have already been
    //       updated in the mapperFile object in mapperFiles.
    //       I originally misunderstood how this worked and now it needs to be
    //       rewritten again, time permitting.
    function closeColumnMapper(saved){
        // console.log("DataMapperContext: entered closeColumnMapper(saved) ", saved);
        if (saved) {
            // console.log("DataMapperContext: enter & exit closeColumnMapper(saved) because (saved) ", saved);

            // close the DataMapperColumnMapping modal
            setCurrentFileID("");
            setCloseColumnMapperControl(uuidv4());
            return;
        }
        //console.log("DataMapperContext: entered closeColumnMapper(saved) (NEEDS TESTING) ", saved);
        // if (!saved) {
        //     //CHECK IF THE CURRENT FILE SHOULD BE SAVED OR NOT
        //     var m = null; //getMapperFile(currentFileID);
        //     var _iFound = -1;
        //     var _a = mapperFiles;
        //
        //     for(let i = 0; i < _a.length; i++){
        //         if(_a[i].id == currentFileID){
        //             _iFound = i;
        //             break;
        //         }
        //     }
        //
        //     if(_iFound != -1){
        //         m = mapperFiles[_iFound];
        //     }
        //
        //     if(m.saved == false){
        //         //remove from the mapperFile array
        //         _a.splice(_iFound, 1);
        //         console.log("DataMapperContext: closeColumnMapper(saved) calling setMapperFiles([..._a]) ", _a);
        //         setMapperFiles([..._a]);
        //     }
        // }
        //
        // //CLOSE
        // setCurrentFileID(null);
        // setCloseColumnMapperControl(uuidv4());

        var mapperFileID = currentFileID;
        var localMapperFiles = mapperFiles;
        var foundIndex = localMapperFiles.findIndex(object => object.id == mapperFileID);
        // console.log("DataMapperContext: closeColumnMapper(saved) foundIndex = ", foundIndex);
        // console.log("**** DEM DEM DEM DEM localMapperFiles[foundIndex] ****", localMapperFiles[foundIndex].qty);
        //if (foundIndex && !localMapperFiles[foundIndex].saved) {
        if (localMapperFiles[foundIndex].qty == 0) {
            // remove from the list
            // console.log("DataMapperContext: closeColumnMapper(saved) removing mapFile from mapperFiles: localMapperFiles[foundIndex] = ", localMapperFiles[foundIndex]);
            localMapperFiles.splice(foundIndex, 1);
            setMapperFiles([...localMapperFiles]);
            // console.log("DataMapperContext: closeColumnMapper(saved) removed mapFile from mapperFiles = ", localMapperFiles);
        } else {
            //console.log("DataMapperContext: closeColumnMapper(saved) NOT removing mapFile from mapperFiles = ", localMapperFiles);
        }

        //console.log("DataMapperContext: closeColumnMapper(saved) calling setCloseColumnMapperControl(uuidv4()) to close the mapping component modal window")
        setCloseColumnMapperControl(uuidv4());

        // close the DataMapperColumnMapping modal
        setCurrentFileID(null);
        setCloseColumnMapperControl(uuidv4());
        return;
    }

    function saveRowFilterSettings(min, max, random, inEnableRange, inEnableRandom){
        // console.log("DataMapperContext: entered saveRowFilterSettings(min, max, random, inEnableRange, inEnableRandom) ", min, max, random, inEnableRange, inEnableRandom)
        setSelectedMin(min);
        setSelectedMax(max);
        setSelectedRandom(random);

        setEnableRange(inEnableRange);
        setEnableRandom(inEnableRandom);

        if(inEnableRange == true){
            setFinalUnivSize((max - min) + 1);
        }
        else{
            setFinalUnivSize(random);
        }

        setDataMapperMappingsEdited(true);
        setMosaicUniverseSize(0);

        closeRowFilter();
    }

    function closeRowFilter(){
        // console.log("DataMapperContext: entered closeRowFilter() ")
        setCloseRowFilterControl(uuidv4());
    }

    function closeDataMapperInitialStats(){
        // console.log("DataMapperContext: entered closeDataMapperInitialStats() ")
        setCloseDataMapperStats(uuidv4());
    }

    function addFileQty(qty, mapperFileID){
        // console.log("DataMapperContext: entered addFileQty(qty, mapperFileID) (NEEDS TESTING) ", qty, mapperFileID);
    // function addFileQty(qty, fileId){
    //     console.log("DataMapperContext: entered addFileQty(qty, fileId) (NEEDS TESTING) ", qty, fileId);
    //     var m = null; //getMapperFile(currentFileID);
    //     var _iFound = -1;
    //     var _a = mapperFiles;
    //
    //     for(let i = 0; i < _a.length; i++){
    //         if(_a[i].id == fileId){
    //             _iFound = i;
    //             break;
    //         }
    //     }
    //
    //     if(_iFound != -1){
    //         _a[_iFound].qty = qty;
    //         console.log("DataMapperContext: addFileQty(qty, fileId) 1. calling setMapperFiles([..._a]) ", _a);
    //         setMapperFiles([..._a]);
    //     }
    //
    //     if(m != null){
    //         console.log("DataMapperContext: addFileQty(qty, fileId) 2. calling setMapperFiles([..._a]) ", _a);
    //         setMapperFiles([..._a]);
    //     }

        var localMapperFiles = mapperFiles;
        var foundIndex = localMapperFiles.findIndex(object => object.id == mapperFileID);
        // console.log("DataMapperContext: addFileQty(qty, fileId) foundIndex = ", foundIndex);
        if (foundIndex != -1) {
            localMapperFiles[foundIndex].qty = qty;
            setMapperFiles([...localMapperFiles]);
            // console.log("DataMapperContext: addFileQty(qty, fileId) updated mapFile.qty in mapperFiles = ", localMapperFiles);
        } else {
            //console.log("DataMapperContext: addFileQty(qty, fileId) NOT updating mapFile.qty in mapperFiles = ", localMapperFiles);
        }
    }

    function clearMapping(){
        // console.log("DataMapperContext: entered clearMapping() ");
        mapperFiles.length = 0;
        setSelectedMin(1);
        setSelectedMax(1);

        setVoterSetKey('');

        setMosaicUniverseSize(0);
        setEnableRange(true);
        setEnableRandom(false);

        setDataMapperMappingsEdited(false);
        setDataMapperFilesAdded(false);

        setStatsFile(null);
        setDatamapApplied(false);
    }

    async function generateVotersetKey(mapName, description){
        // console.log("DataMapperContext: entered generateVotersetKey(mapName, description) ", mapName, description);
        if (!campaignKey) {
            return;
        }

        if (voterSetKey && voterSetKey.trim().length) {
            //console.log("DataMapperContext: generateVotersetKey() error: must clear exisiting voterSetKey before generating a new one ", voterSetKey)
            return;
        }

        var jsonData =  {
          "name": mapName, //"Temp_Name_" + uuidv4(),
          "description": description, //"init",
          "campaign_key": campaignKey.toString(), //campaignID.toString(),
          "voter_map": {}
        }

        var newVoterSetKey;
        await axios.post(
            `${config.voterapi.url}/api/v1/votersets`,
            jsonData,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            // edr - sometimes we get here and response is undefined with no errors
            // need to find a reliable & graceful way to handle this everywhere
            if (response) {
                //console.log("DataMapperContext: generateVotersetKey(mapName, dataFileTags) API get (generate) voterset key response ", response);
                newVoterSetKey = response.data.key;
                setVoterSetKey(newVoterSetKey);
            } else {
                console.log("ERROR: DataMapperContext: generateVotersetKey() failed to get a response from API: jsonData = ", jsonData);
            }
        });

        //console.log("DataMapperContext: generateVotersetKey(mapName, dataFileTags) returning newVoterSetKey = ", newVoterSetKey);
        return newVoterSetKey;
    }

    function newMosaicMap() {
        // console.log("DataMapperContext: entered newMosaicMap() ");
        return {
            "mosaic": {
                "filetype": "universe map file",
                "version": "0.10"
            },
            "metadata": {
                "name": "New Map",
                "mosaic_filename": "New_MOSAIC.csv",
                "mosaic_file_url": null,
                "tags": null,
                "s3_key": "campaignkey/thiskey",
                "optout_s3_key": null,
                "total_rows": 0,
                "total_removed": 0,
                "total_optout": 0,
                "processing_time": 0
            },
            "files":{
            },
            "postprocess":{
            }
        };
    }

    async function patchMosaicMap2(mapName, dataFileTags){
        // console.log("\n\n\n\nRICK patchMosaicMap2\n\n\n\n")
        // console.log("DataMapperContext: entered patchMosaicMap2(mapName, dataFileTags) (NEEDS TESTING) ", mapName, dataFileTags);
        if (!mapName || !mapName.trim().length) {
            // console.log("DataMapperContext: exiting patchMosaicMap2(mapName, dataFileTags) because (!mapName || !mapName.trim().length) ", mapName);
            return;
        }

        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) LOG NOTICE #01");
        var _thisMap;
        if (!voterSetKey || !voterSetKey.trim().length) {
            // console.log("DataMapperContext: patchMosaicMap2() calling generateVotersetKey()");
            _voterSetKey = await generateVotersetKey(mapName, dataFileTags);
            // console.log("DataMapperContext: patchMosaicMap2() received _voterSetKey = ", _voterSetKey);
            _thisMap = newMosaicMap();

            if (campaignID) {
                _thisMap.metadata.optout_s3_key = `optout.${campaignID}.json`;
            }
        }
        else{
            // console.log("DataMapperContext: patchMosaicMap2() calling API voterset get, voterSetKey = ", voterSetKey);
            _voterSetKey = voterSetKey;
            await axios.get(
                `${config.voterapi.url}/api/v1/votersets/${_voterSetKey}`,
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
            )
            .catch(function (error) {
                console.log("error collecting the voterset map =>", error);
            })
            .then(function (response) {
                // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) API get voterset response ", response);
                _thisMap = response.data.voter_map;
                _thisMap.files = {};

                // edr - cant save to useState until we are done
                //setMosaicMap(_m);
            });
        }
        //END GET VOTERSET KEY ******************************************************************
        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) LOG NOTICE #02");

        _thisMap.metadata.mosaic_filename = mapName + "_MOSAIC.csv";
        _thisMap.metadata.name = mapName;
        _thisMap.metadata.tags = dataFileTags;

        // iterate over the files and generate their individual mapping section
        mapperFiles.forEach(object => {
            // console.log("DataMapperContext: patchMosaicMap2() mapping file: filename = ", object.fileName);
            _thisMap.files[object.fileName] = {
                "s3_root": "https://universe-file-test.us-lax-1.linodeobjects.com/",
                "bucket": "af-votersets",
                "csv_get_url": null,
                "csv_get_prepped_url": null,
                "csv_get_removed_url": null,
                "csv_put_url": null,
                "mapping": {
                    "UID": null,
                    "FName": object.columnMappings.FName ? {"field": object.columnMappings.FName.field} : null,
                    "LName": object.columnMappings.LName ? {"field": object.columnMappings.LName.field} : null,
                    "Cell_Phone": object.columnMappings.Cell_Phone ? {"field": object.columnMappings.Cell_Phone.field} : null,
                    "Party": object.columnMappings.Party ? {"field": object.columnMappings.Party.field} : null,
                    "State": object.columnMappings.State ? {"field": object.columnMappings.State.field} : null,
                    "Precinct": object.columnMappings.Precinct ? {"field": object.columnMappings.Precinct.field} : null,
                    "County": object.columnMappings.County ? {"field": object.columnMappings.County.field} : null,
                    "URL": object.columnMappings.URL ? {"field": object.columnMappings.URL.field} : null
                },
                "operations": {
                    "insert": {"field":"*", "operator":"=", "value":"*"}
                }
            };
        });
        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) LOG NOTICE #03");

        if (enableRange) {
            _thisMap.postprocess["range"] = {
                "start": Number(selectedMin - 1),           //note: range object requires the -1 for inclusivity
                "end": Number(selectedMax)
            };
        }

        if (enableRandom) {
            _thisMap.postprocess["random"] = Number(selectedRandom);
        }

        //setMosaicMap({..._thisMap});
        setMosaicMap(_thisMap);

        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) _voterSetKey = ", _voterSetKey);
        var _wrapper = {
          "key": _voterSetKey.toString(),
          "description": "",
          "voter_map": {},
          "status": "create" //_statusToPatch
        }
        _wrapper.voter_map = _thisMap;
        await axios.patch(
            `${config.voterapi.url}/api/v1/votersets/${_voterSetKey}`,
            _wrapper,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            // console.log("DataMapperContext: patchMosaicMap2() API patch voterset response: ", response);
        });
        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) LOG NOTICE #04");

        //ONLY UPLOAD THE FILES IF THERE WAS A CHANGE TO THE FILES IN MAPPERFILES
        if(dataMapperFilesAdded){
            //GET THE S3 BUCKET FILE INFO FOR EACH FILE
            // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) getting S3 bucket info for each file");
            var vs_map_files;
            await axios.get(
                `${config.voterapi.url}/api/v1/votersets/${_voterSetKey}?setput=true`,
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // console.log("DataMapperContext: patchMosaicMap2() API get voterset file URLs, response ", response)
                vs_map_files = response.data.voter_map.files;
            });

            setDataMapperMosaicMessage("uploading all your files to our platform for processing");

            //*************************************************************************************loop over each file
            // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) LOG NOTICE #05");

            // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) looping over each file to post them");
            for (var iPut = 0; iPut < mapperFiles.length; iPut++) {
                // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) working with this mapperFile = ", mapperFiles[iPut]);
                var _fileName = mapperFiles[iPut].fileName;

                // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) updating _thisMap, before = ", _thisMap);
                _thisMap.files[_fileName].csv_get_url = null;
                _thisMap.files[_fileName].csv_get_prepped_url = vs_map_files[_fileName].csv_get_prepped_url;
                _thisMap.files[_fileName].csv_get_removed_url = vs_map_files[_fileName].csv_get_removed_url;
                _thisMap.files[_fileName].csv_put_url = vs_map_files[_fileName].csv_put_url;
                // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) updating _thisMap, after = ", _thisMap);

                setDataMapperMosaicMessage(`uploading ${_fileName} for processing`);

                // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) uploading this file to this url: _fileName, vs_map_files[_fileName].csv_put_url = ", _fileName, vs_map_files[_fileName].csv_put_url);
                await axios.put(
                    vs_map_files[_fileName].csv_put_url,
                    mapperFiles[iPut].file,
                    {headers: { "Content-Type": "" }}
                )
                .catch(function (error) {
                    console.log(`error posting the file ${_fileName}`, error);
                // })
                // .then(function (response) {
                //     console.log("DataMapperContext: patchMosaicMap2() API put universe file, response ", response);
                });
            }

            //*************************************************************************************END loop over each file

            //PATCH THE VOTERSET AGAIN WITH THE URLS ATTACHED TO EACH FILE
            // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) patching voterset with updated URLs: _wrapper = ", _wrapper);
            await axios.patch(
                `${config.voterapi.url}/api/v1/votersets/${_voterSetKey}`,
                _wrapper,
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
            )
            .catch(function (error) {
                console.log(error);
            // })
            // .then(function (response) {
            //     console.log("DataMapperContext: patchMosaicMap2() API patch voterset file with URLs, response ", response);
            });
        }

        //***************************************************************************************************************
        //NOW, set the status of the voter set to "process" to kick off the file creation of the prepped and removed file
        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) LOG NOTICE #06");

        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) patching the voterset status now to kick off the Mosaic processing");
        await axios.patch(
            `${config.voterapi.url}/api/v1/votersets/${_voterSetKey}`,
                {
                    "key": _voterSetKey,
                    "status":"process"
                },
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        // })
        // .then(function (response) {
        //     console.log("DataMapperContext: patchMosaicMap2() API patch voterset file with status = process to begin Mosaic processing, response ", response);
        });

        //***************************************************************************************//
        //**************** START THE WATCH FOR PROCESSING OF THE VOTER SET **********************//
        //***************************************************************************************//
        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) setting setDataMapperProcessingVoterSet(true) to trigger the 'watching' process in browser");
        setDataMapperMosaicMessage("we are loading your new Mosaic file with your designed maps and specified filters");
        setDataMapperProcessingVoterSet(true);
        //***************************************************************************************//
        //**************** START THE WATCH FOR PROCESSING OF THE VOTER SET **********************//
        //***************************************************************************************//

        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) calling setMosaicMap(_thisMap): ", _thisMap);
        setMosaicMap(_thisMap);

        //***************************************************************************************************************

        // console.log("DataMapperContext: patchMosaicMap2(mapName, dataFileTags) turning off flags (setDataMapperMappingsEdited & setDataMapperFilesAdded) so application knows all the new mappings have been saved into the systems");

        setDataMapperMappingsEdited(false);
        setDataMapperFilesAdded(false);

        setDatamapApplied(true);

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
        // console.log("DataMapperContext: exiting patchMosaicMap2(mapName, dataFileTags)");
    }

    // EDR - FIX / CHECK this later to make sure it turns off the constant timer loop to check the status of Mosaic processing
    // EDR - this is only called once and its from
    //       DataMapper2::useEffect()[dataMapperProcessingVoterSet, dataMapperVoterSetStatus]
    async function collectDataMapperProcessedVoterSetFiles(){
        //console.log("DataMapperContext: entered collectDataMapperProcessedVoterSetFiles()");
        var _vsStatus = 'processing';

        await axios.get(
            `${config.voterapi.url}/api/v1/votersets/${voterSetKey}`,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("error collecting the processed map files", error);
            setDataMapperProcessingVoterSet(false);
        })
        .then(function (response) {
            // edr - sometimes we get here and response is undefined with no errors
            // need to find a reliable & graceful way to handle this everywhere
            if (response) {
                //console.log("DataMapperContext: collectDataMapperProcessedVoterSetFiles() response = ", response);
                //var _vs = response.data;
                _vsStatus = response.data['status'].toString();
                setDataMapperVoterSetStatus(_vsStatus);
                setDataMapperUpdatedStamp(response.data['updated_dt']);

                if(_vsStatus == 'active'){
                    setMosaicMap(response.data);
                    setDataMapperVoterSetStatsURL(response.data.voter_map.metadata.csv_get_stats_url);
                }
            } else {
                console.log("ERROR: DataMapperContext: collectDataMapperProcessedVoterSetFiles() failed to get a response from API: voterSetKey = ", voterSetKey);
            }
        });

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
        return _vsStatus;
    }

    async function getDataMapperProcessingStats(statsURL){
        // console.log("DataMapperContext: entered getDataMapperProcessingStats(statsURL) ", statsURL);
        var _resp = {
                        voterSetKey: '',
                        memberCount: 0,
                        cleanedCount: 0,
                        removedCount: 0,
                        mosaicUniverseCount: 0,
                        cleaningDetails: null
                    };

        await axios.get(
            statsURL,
            {headers: { "Content-Type": "application/json" }}
        )
        .catch(function (error) {
            console.log("error collecting the processing stats -> ", error);
        })
        .then(function (response) {
            _resp.memberCount = Number(response.data.incoming_count['total']);
            _resp.cleaned_count = Number(response.data.cleaned_count['total']);
            _resp.removedCount = Number(response.data.removed_count['total']);
            _resp.mosaicUniverseCount = Number(('total' in response.data.mosaic_count) ? response.data.mosaic_count['total'] : 0);
            _resp.cleaningDetails = response.data.removed_details;
            _resp.voterSetKey = voterSetKey;
        });

        setDataMapperResults(_resp);
        return _resp;
    }

    async function getDataMapperVoterSetFirstRecord(voterset_key){
        // console.log("DataMapperContext: getDataMapperVoterSetFirstRecord(voterset_key) ", voterset_key);
        //get the first voter record
        if (!voterset_key || voterset_key == '') {
            voterset_key = voterSetKey;
        }
        setVoterSetKey(voterset_key);

        await axios.get(
            `${config.voterapi.url}/api/v1/voters?voterset_key=${voterset_key}`,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("error collecting the processing stats -> ", error);
        })
        .then(function (response) {
            //console.log("DataMapperContext: getDataMapperVoterSetFirstRecord(voterset_key) API response: ", response)
            setDataMapperFirstRecord(response.data.values);
        });
    }

    async function applyPreviouslySavedDataMap(dataMap){
        // console.log("DataMapperContext: applyPreviouslySavedDataMap(dataMap) (NEEDS REWRITING and TESTING)", dataMap);

        loadExistingMap = true;

        var _keys = Object.keys(dataMap.dataMapperInfo.voter_map.files)
        var _statsFile = dataMap.statsFile;

        setStatsFile(_statsFile);

        //************************ load data files
        for (var i = 0; i < _keys.length; i++) {
            var _k = _keys[i];
            var _f = dataMap.dataMapperInfo.voter_map.files[_k];

            //COMMENTED OUT THE REMOVAL OF UNWANTED CHARACTERS FROM DATA MAPPED SAVED SINCE IT WOULD
            //ALREADY HAVE A VALID NAME IN THE CLOUD AND IT SHOULD NOT BE CHANGED IN THE APP WHEN ALREADY SAVED
            //NOTE: remove unwanted characters
            //eslint does not like the escape character in the regex, so I am disabling it for the subsequent line
            //eslint-disable-next-line
            var _CorrectedName = _k; //.replace(/[^a-z0-9-._\ ]/gi, '_');

            //get the file
            await axios.get(
                _f.csv_get_url,
                {headers: { accept: "application/json" }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                //console.log("DataMapperContext: applyPreviouslySavedDataMap() API get response ", response);
                var fileInfo = newFileInfo(uuidv4());
                fileInfo['fileName'] = _CorrectedName;
                fileInfo['fileDisplayName'] = _CorrectedName;
                fileInfo['fileSize'] = response.data.size;
                fileInfo['file'] = response.data;
                fileInfo['qty'] = (_k in _statsFile.data.incoming_count) ? _statsFile.data.incoming_count[_k] : 0;
                fileInfo['saved'] = true;
                fileInfo['columnMappings'] = _f.mapping;

                attachNewFile(fileInfo);
            });
        }

        //load post processor
        if ('postprocess' in dataMap.dataMapperInfo.voter_map) {
            var _postProcessor = dataMap.dataMapperInfo.voter_map.postprocess;
            var _ppKeys = Object.keys(_postProcessor);

            for (var iKeys = 0; iKeys < _ppKeys.length; iKeys++) {
                switch (_ppKeys[iKeys]) {
                    case "range":
                        setEnableRange(true);
                        setSelectedMin(Number(_postProcessor["range"].start) + 1);
                        setSelectedMax(Number(_postProcessor["range"].end));
                        break;
                    case "random":
                        break;
                }
            }
        }

        setMosaicUniverseSize(Number(dataMap.dataMapperInfo.voter_map.metadata.total_rows));
        setDataMapperLoadingPreviousMap(false);
        setDataMapperMappingsEdited(false);

        loadExistingMap = false;

        setDatamapApplied(true);
    }

    //async function collectAndUpdateMosaicUniverseCount(voterset_key){
    async function collectAndUpdateMosaicUniverseCount(){
        // console.log("DataMapperContext: entered collectAndUpdateMosaicUniverseCount() ");
        await axios.get(
            `${config.voterapi.url}/api/v1/votersets/${voterSetKey}`,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            //console.log("DataMapperContext: collectAndUpdateMosaicUniverseCount() API get response ", response);
            setMosaicUniverseSize(Number(response.data.voter_map.metadata.total_rows));
        });
    }

    return (
        <DataMapperContext.Provider value={{
            mapperFiles:                                        mapperFiles,
            setMapperFiles:                                     setMapperFiles,
            currentFileID:                                      currentFileID,
            setCurrentFileID:                                   setCurrentFileID,

            attachNewFile:                                      attachNewFile,
            getMapperFile:                                      getMapperFile,

            closeColumnMapper:                                  closeColumnMapper,
            closeColumnMapperControl:                           closeColumnMapperControl,

            loadDatamapperColumnMapping:                        loadDatamapperColumnMapping,

            filesTotalQty:                                      filesTotalQty,
            setFilesTotalQty:                                   setFilesTotalQty,

            addFileQty:                                         addFileQty,
            removeDataFile:                                     removeDataFile,
            saveDataFileMapping:                                saveDataFileMapping,

            enableRange:                                        enableRange,
            enableRandom:                                       enableRandom,

            selectedMin:                                        selectedMin,
            selectedMax:                                        selectedMax,
            selectedRandom:                                     selectedRandom,
            finalUnivSize:                                      finalUnivSize,

            closeDataMapperInitialStats:                        closeDataMapperInitialStats,
            closeDataMapperStats:                               closeDataMapperStats,
            closeRowFilter:                                     closeRowFilter,
            closeRowFilterControl:                              closeRowFilterControl,
            saveRowFilterSettings:                              saveRowFilterSettings,

            clearMapping:                                       clearMapping,
            patchMosaicMap2:                                    patchMosaicMap2,

            collectDataMapperProcessedVoterSetFiles:            collectDataMapperProcessedVoterSetFiles,
            setDataMapperProcessingVoterSet:                    setDataMapperProcessingVoterSet,
            dataMapperMosaicMessage:                            dataMapperMosaicMessage,
            dataMapperProcessingVoterSet:                       dataMapperProcessingVoterSet,
            getDataMapperProcessingStats:                       getDataMapperProcessingStats,
            getDataMapperVoterSetFirstRecord:                   getDataMapperVoterSetFirstRecord,

            dataMapperFirstRecord:                              dataMapperFirstRecord,
            dataMapperResults:                                  dataMapperResults,
            setDataMapperResults:                               setDataMapperResults,

            dataMapperLoadingPreviousMap:                       dataMapperLoadingPreviousMap,
            mosaicUniverseSize:                                 mosaicUniverseSize,
            setMosaicUniverseSize:                              setMosaicUniverseSize,
            voterSetKey:                                        voterSetKey,
            collectAndUpdateMosaicUniverseCount:                collectAndUpdateMosaicUniverseCount,

            dataMapperMappingsEdited:                           dataMapperMappingsEdited,
            dataMapperFilesAdded:                               dataMapperFilesAdded,

            statsFile:                                          statsFile,
            setStatsFile:                                       setStatsFile,

            newFileInfo:                                        newFileInfo,

            modalColumnMappingOpen:                             modalColumnMappingOpen,

            datamapApplied:                                     datamapApplied,
            setDatamapApplied:                                  setDatamapApplied

        }}>
            {children}
        </DataMapperContext.Provider>
    );

}
