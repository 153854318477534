import { useEffect, useState, useContext } from "react";
import { CampaignContext } from "../../context/CampaignContext";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTreeChart from "highcharts/modules/treemap";
import HighchartsData from "highcharts/modules/data";

HighchartsData(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsTreeChart(Highcharts);
HighchartsData(Highcharts);

const CampaignResponseTypeMsgCounts = () => {
    const { loading, responseStats, responseCategories } = useContext(CampaignContext);
    const [barColors, setBarColors] = useState([]);

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    useEffect(() =>{
        Highcharts.getOptions().colors.push("#293f78")
    })

    useEffect(() =>{
        var colors = [];
        colors.push("#346793");
        colors.push("#4081ba");
        colors.push("#639aca");
        colors.push("#88b3d8");
        colors.push("#aecbe5");
        setBarColors(colors);
    }, [responseStats])

    return (
        <div className={`stats dashboardTile`}>
            <div className="grid">
                {loading == true ?
                    (
                        <div className="componentTwoHighLoading" style={{margin: "0px 0px 0px 0px"}}>
                            <div className="loading loading-bars loading-lg" style={{ marginTop: "100px", marginLeft: "175px",
                                width: "150px", height: "150px"}} />
                            {/*<label>hello World</label>*/}
                        </div>

                    )
                    :
                    (
                        <HighchartsReact highcharts={Highcharts} options={
                            {
                                chart: {
                                    type: 'column'
                                },
                                title: {
                                    text: 'Response Activity per Project Code'
                                },
                                xAxis: {
                                    categories: responseCategories,
                                    crosshair: {
                                        text: true
                                    }
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: 'Response Count'
                                    },
                                    stacklabels: {
                                        enabled: true
                                    }
                                },
                                tooltip: {
                                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                                    footerFormat: '</table>',
                                    shared: true,
                                    useHTML: true
                                },
                                legend: {
                                    enabled: true
                                },

                                plotOptions: {
                                    column: {
                                        stacking: 'normal',
                                        datalabels: {
                                            enabled: true
                                        },
                                        pointPadding: 0,
                                        borderWidth: 0
                                    },
                                },
                                colors: barColors,
                                series: responseStats
                            }

                            // // colorAxis: {
                            // //     minColor: '#FFFFFF',
                            // //     maxColor: Highcharts.getOptions().colors[Highcharts.getOptions().colors.length - 1]
                            // // },
                            // chart: {
                            //     renderTo: 'container',
                            //     type: 'spline'
                            // },
                            // title: {
                            //     text: 'Response Counts By Date'
                            // },
                            // subtitle: {
                            //         text: 'grouped response types ordered by message event date',
                            //         align: 'center'
                            //     },
                            // xAxis: {
                            //      type: 'datetime',
                            //      labels: {
                            //          overflow: 'justify'
                            //      }
                            // },
                            // yAxis: {
                            //     title: 'Reponse Count'
                            // },
                            // plotOptions: {
                            //     spline: {
                            //         lineWidth: 4,
                            //         states: {
                            //             hover: {
                            //                 lineWidth: 5
                            //             }
                            //         },
                            //         marker: {
                            //             enabled: false
                            //         },
                            //         pointInterval: 3600000 * 24, // one day
                            //         pointStart: Date.UTC(respStartYYYY, respStartMM, respStartDD, 0, 0, 0)
                            //     }
                            // },
                            // series:
                            //         //[{
                            //         //     name: 'Hestavollane',
                            //         //     data: [5.4, 5.2, 5.7, 6.3, 5.2, 5.6, 6.1,
                            //         //         5.6, 5.9, 7.1, 8.6, 7.8, 8.6,
                            //         //         8.0, 9.7, 11.2, 12.5, 13.1, 10.6,
                            //         //         10.9, 8.9, 9.5, 7.5, 3.5, 4.2]

                            //         // }, {
                            //         //     name: 'Vik',
                            //         //     data: [0.2, 0.1, 0.1, 0.5, 0.3, 0.2, 0.1,
                            //         //         0.1, 0.1, 0.1, 0.2, 1.1, 1.3,
                            //         //         2.0, 1.5, 1.5, 1.5, 1.4, 1.7,
                            //         //         2.0, 2.9, 2.1, 2.1, 3.5, 2.9]
                            //         // }],
                            //     campaignResponseTypeCounts,
                            //     // [
                            //     // {
                            //     //     //layoutAlgorithm: 'squarified',
                            //     //     //name: 'Message Counts By Project Code',
                            //     //     size: '90%',
                            //     //     data: campaignResponseTypeCounts

                            //     // }
                            // // ]

                        }/>
                    )
                }
            </div>

        </div>
    );
};

export default CampaignResponseTypeMsgCounts;
