import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { AmplifyEventContext } from "./AmplifyEventContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

export const CrystalContext = createContext();

export const CrystalContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { eventCoreInformation, determineNewEventStatus, getExistingEvent } = useContext (AmplifyEventContext);

    const [mmsCrystalPatchVals, setMMSCrystalPatchVals] = useState('');
    const [crystalProcessingImageUpload, setCrystalProcessingImageUpload] = useState(null);

    const [crystalMessage, setCrystalMessage] = useState('');
    const [processingCrystalImage, setProcessingCrystalImage] = useState(false);

    function crystalClearMMSFileInfo(){
        setCrystalProcessingImageUpload(null);
        setProcessingCrystalImage(false);
    }

    useEffect(() => {
        try{
            if(mmsCrystalPatchVals.mms != null){
                if(mmsCrystalPatchVals.mms.toString().length > 0 && mmsCrystalPatchVals.mmsType.toString().length > 0){
                    setProcessingCrystalImage(true);
                    patchForNewMMSDirect(mmsCrystalPatchVals.mms.toString(), mmsCrystalPatchVals.mmsType.toString(), eventCoreInformation.key, mmsCrystalPatchVals.mmsFile);
                }
            }
        }
        catch(error){
            console.log("error preparing to upload a new MMS attachement => ", error);
        }
    }, [mmsCrystalPatchVals])

    async function checkCrystalFileProcessingComplete(getURL){
        var _status = "";
        try{
            const res_fileCheck = await axios.get(
                getURL,
                {headers: { "Content-Type": "" }}
            );

            if(res_fileCheck.status == 200){
                _status = "COMPLETE";
            }
            else{
                _status = "IN PROGRESS";
            }
        }
        catch(error){
            console.log("error trying to collect the file from the s3 repository -> ", error);
            _status = "error";
        }
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
        return _status;
    }

    async function patchForNewMMSDirect(mms, mmsType, mmsEventKey, mmsFileInbound){
        // edr - probably should early return on other missing vars here too
        // but I dont know what those are yet
        if (!mmsEventKey) {
            return;
        }

        // console.log("CrystalContext: entered patchForNewMMSDirect(mms, mmsType, mmsEventKey, mmsFileInbound) (NEEDS TESTING)", mms, mmsType, mmsEventKey, mmsFileInbound)
        //upload the video to the the campaign bucket
        // try{
        //     //TBD: NEED TO ADD CODE HERE TO UPLOAD VIDEOS TO THE CAMPAIGN BUCKET
        // }
        // catch(error){
        //     console.log('there was an error uploading the video to the campaign repository => ', error);
        // }

        //PATCH THE EVENT TO GET THE SIGNED URLS FOR THE EVENT
        var _mMMS = {
            "key": mmsEventKey,
            "media_name": mms,
            "media_type": mmsType
        }

        var _targetPUTUrl;
        await axios.patch(
            `${config.eventapi.url}/api/v1/events/${mmsEventKey}`,
            _mMMS,
            {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
        )
        .catch(function (error) {
            console.log("there was an error moving the prepared file from the campaign directory to the event => ", error);
        })
        .then(function (response) {
            // console.log("CrystalContext: patchForNewMMSDirect() API patch response ", response)
            _targetPUTUrl = response.data.media_put_url ?? null;
        });

        if (_targetPUTUrl) {
            //NOW UPLOAD THE OPTIMIZED FILE TO THE EVENT URL
            await axios.put(
                _targetPUTUrl,
                mmsFileInbound,
                {headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "video/mp4" }}
            )
            .catch(function (error) {
                console.log("there was an error moving the prepared file from the campaign directory to the event => ", error);
            })
            .then(function (response) {
                // console.log("CrystalContext: patchForNewMMSDirect() API put image response ", response)
                _targetPUTUrl = response.data.media_put_url ?? null;
            });

            //FINALLY, BECAUSE OF THE CHANGE TO THE IMAGE, SET THE EVENT BACK TO "PROCESS" STATUS
            var _newStatus = determineNewEventStatus();
            var _mFinal = {
                "key": mmsEventKey,
                "status": _newStatus
            }
            await axios.patch(
                `${config.eventapi.url}/api/v1/events/${mmsEventKey}`,
                _mFinal,
                {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // console.log("CrystalContext: patchForNewMMSDirect() API patch status response ", response)
                _targetPUTUrl = response.data.media_put_url ?? null;
            });

            // console.log("CrystalContext: patchForNewMMSDirect() calling getExistingEvent(mmsEventKey) ", mmsEventKey)
            getExistingEvent(mmsEventKey);
        }

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    //TBD: THIS WILL BE USED ONCE SELF SERVE CRYSTAL PROCESSING IS AVAILABLE
    // async function patchForNewMMS(mms, mmsType, mmsEventKey, mmsFileInbound){
    //     try{
    //         if(mmsType == "video/mp4"){
    //             // ************************************************************************************************************/
    //             // ************************************************************************************************************/
    //             // STUB CODE PRIOR TO MOSAIC BEING READY
    //             // ************************************************************************************************************/
    //             // ************************************************************************************************************/
    //
    //             // console.log("**************************************************************Crystal Context Processing start checks*****************");
    //
    //             //********************************* START CHECKING IF THE FILE WAS SUCCESSFULLY PROCESSED  ********************/
    //
    //             //NOTE s3FileInformtion is NULL for now until the Crystal process is fully defined
    //             setCrystalProcessingImageUpload({id: uuidv4(), mms: mms, mmsType: mmsType, mmsFile: mmsFileInbound, s3FileInformation: null });
    //
    //             //********************************* START CHECKING IF THE FILE WAS SUCCESSFULLY PROCESSED  ********************/
    //         }
    //     }
    //     catch(err){
    //         console.log("there was a terminal error when trying to upload a new MMS attachment => ", err);
    //     }
    //
    //     //check if the token needs to be refreshed
    //     dispatch({ type: "CHECK_AUTH" });
    // }

    return (
        <CrystalContext.Provider value={{

            setMMSCrystalPatchVals:                                     setMMSCrystalPatchVals,
            crystalProcessingImageUpload:                               crystalProcessingImageUpload,
            checkCrystalFileProcessingComplete:                         checkCrystalFileProcessingComplete,

            crystalMessage:                                             crystalMessage,
            setCrystalMessage:                                          setCrystalMessage,
            processingCrystalImage:                                     processingCrystalImage,
            setProcessingCrystalImage:                                  setProcessingCrystalImage,
            crystalClearMMSFileInfo:                                    crystalClearMMSFileInfo

        }}>
            {children}
        </CrystalContext.Provider>
    );
}
