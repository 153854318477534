import { AmplifyEventContext } from "../../context/AmplifyEventContext";

import React, { useState, useContext } from 'react';
import { createEditor, Transforms } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

const TextInput = () => {
    const { updateSMSBody, eventMessageBodyInformation } = useContext(AmplifyEventContext);
    const [draggingItem, setDraggingItem] = useState('');

    const initialValue = [
        {
            type: 'paragraph',
            children: [{ text: eventMessageBodyInformation?.content ? eventMessageBodyInformation.content : ""}],
        },
    ]

    const [editor] = useState(() => withReact(createEditor()))

    function handleInput(TextInput){
        if (!TextInput) {
            return;
        }

        var _sms = '';
        for (var i = 0; i < TextInput.length; i++) {
            var _c = TextInput[i].children[0].text;
            if(_c.length > 0){
                _sms = _sms + _c.trim() + '\n';
            }
            else{
                _sms = _sms + '\n';
            }
        }
        updateSMSBody(_sms);
    }

    //eslint-disable-next-line
    function specifyCaret(object){
        // console.log(object);
    }

    //eslint-disable-next-line
    function specifyPointer(object){
        // console.log(editor.selection.anchor.offset);

        //editor.insertText(" hello world ")
    }

    //eslint-disable-next-line
    function handleOnDrag(event, object){
        setDraggingItem(object);
        // console.log(object);
    }

    //eslint-disable-next-line
    function specifyDragOver(object){
        // console.log(editor);
    }

    //eslint-disable-next-line
    function specifyOnDrop(object){

        if(draggingItem.length > 0){
            const targetRange = ReactEditor.findEventRange(editor, event)

            Transforms.insertText(editor, draggingItem, {at: { path: targetRange.anchor.path, offset: targetRange.anchor.offset},})
            setDraggingItem('');
        }

        handleInput(editor.children);
    }

    //eslint-disable-next-line
    const [optOutList, setOptOutList] = useState(['[%OptOutMessage-Classic%]','[%Double Opt-In/Out%]','[%OptOutMessage-Stop=End%]',
        '[%OptOutMessage-Stop2End%]','[%OptOutMessage-StopToEnd%]']);
    //eslint-disable-next-line
    const [msgList, setMsgList] = useState(['[%URL%]','[%County%]']);
    //eslint-disable-next-line
    const [memberList, setMemberList] = useState(['[%MemberFirstName%]','[%MemberLastName%]','[%MemberName%]']);

  return (
    <div className="flex">
      <div>
        <div>
            <div className="grid">
              <label className="userEditTitle" >Message Body Wildcards:</label>
              <label className="userEditTitle" style={{color: "blue"}}>(Drag and Drop to the Message Body to Add)</label>
            </div>

                <div className="grid">
                {
                    optOutList&&

                    optOutList.map((item, index) => (
                      <div className="draggableItemOptOut mt-2"
                        onDragStart={(e) => handleOnDrag( e, item)}
                        draggable
                        key={index} style={{userSelect: "none"}}>
                          {item}
                      </div>
                      ))
                }
                </div>
                <div className="grid" style={{height: "30px"}}>
                {
                    msgList&&

                    msgList.map((item, index) => (
                      <div className="draggableItemMessage mt-2"
                        onDragStart={(e) => handleOnDrag( e, item)}
                        draggable
                        key={index} style={{userSelect: "none"}}>
                          {item}
                      </div>
                      ))
                }
                </div>
                <div className="grid" style={{height: "45px", marginTop: "45px"}}>
                {
                    memberList&&

                    memberList.map((item, index) => (
                      <div className="draggableItemMember mt-2"
                        onDragStart={(e) => handleOnDrag( e, item)}
                        draggable
                        key={index} style={{userSelect: "none"}}>
                          {item}
                      </div>
                      ))
                }
                </div>
        </div>

      </div>
      <div>
        <div className="flex"><label className="userEditTitle">Message Body:</label></div>
        <Slate editor={editor} initialValue={initialValue} onChange={ value => {handleInput(value)}}>
          <Editable style={{border: "solid gray", borderWidth: "1px", height: "390px",
            width: "400px", borderRadius: "10px", padding: "10px", overflowY: "scroll", fontSize: "14px"}}
            onKeyDown={ event => {specifyCaret(event)}}
            onPointerUp={ event => {specifyPointer(event)}}
            onDragOver={ event => {specifyDragOver(event)}}
            onDrop={ event => {specifyOnDrop(event)}}
            />
        </Slate>
      </div>
    </div>

  );
};

export default TextInput;
