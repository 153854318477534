import { useState, useEffect, useContext } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import { ProfileManagementContext } from "../../context/ProfileManagementContext";
import { useNavigate } from "react-router-dom";

const PasswordManagement = () => {
    const { windowWidth, reportWindowSize, appRenderType } = useContext(ApplicationContext);
    const { generatePWDresetCode, userPWDValidationCode, sendResetEmail } = useContext(ProfileManagementContext);

    const [userName, setUserName] = useState("");
    const [userEMail, setUserEMail] = useState("");

    const [displayType, setDisplayType] = useState("verification_code"); //user_name, verification_code, verification_code_complete

    const [verificationCodeOK, setVerificationCodeOK] = useState('hidden');

    const [readOnlyUserName, setReadOnlyUserName] = useState(false);
    const [readOnlyEMail, setReadOnlyEMail] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        reportWindowSize();
        setUserName("");
        setUserEMail("");
        setVerificationCodeOK('hidden');
        setDisplayType("user_name");
    }, [])

    function updateUserName(enteredVal){
        setUserName(enteredVal);

        if(enteredVal.length > 0){
            setReadOnlyEMail(true);
        }
        else{
            setReadOnlyEMail(false);
        }
    }

    function updateEMail(enteredVal){
        setUserEMail(enteredVal);

        if(enteredVal.length > 0){
            setReadOnlyUserName(true);
        }
        else{
            setReadOnlyUserName(false);
        }
    }

    function checkVerificationCode(enteredVal){
        if(enteredVal.length > 4 && enteredVal.indexOf("-") <= 0){
            var _seg1 = enteredVal.substring(0,4);
            var _seg2 = enteredVal.substring(4, 999);
            enteredVal = _seg1 + "-" + _seg2;
        }

        if(enteredVal == userPWDValidationCode){
            setVerificationCodeOK('visible');
        }
        else
        {
            setVerificationCodeOK('hidden');
        }
    }

    async function sendUserName(){
        var userInfo = {userName: userName, email: userEMail};
        var _resp = null;
        _resp = await generatePWDresetCode(userInfo);
        setUserName(_resp.userName);
        setUserEMail(_resp.EMail);

        if(_resp.Status == "OK"){
            setDisplayType("verification_code");
        }
        else{
            setDisplayType("verification_code_gen_error");
        }
    }

    function sendVerificationCodeEMail(){
        sendResetEmail(userName, userEMail);
        setDisplayType("verification_code_complete");
    }

    function close(){
        navigate("/login");
    }

    function handleBack(){
        setDisplayType("user_name");
    }

    return (
        <div>
            {appRenderType == "desktop"  || appRenderType == "tablet" ?

            <div className="passwordManagementBackground">
                <div className="bg-neutral" style={{height: "150px"}}>
                    <div style={{height: "35px"}}>&nbsp;</div>
                    <div style={{marginLeft: "20px"}}>
                        <img style={{width: "200px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_transaprent.png"/>
                    </div>
                </div>
                <div className="passwordManagementBackgroundBorder" style={{marginLeft: (windowWidth/2)-375 + "px", marginTop: "-30px", zIndex: "99"}}>
                    <div style={{height: "50px"}}></div>
                    <div style={{marginLeft: "290px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="175" height="175" viewBox="0 0 24 24"
                            fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
                            <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                        </svg>
                    </div>

                    { displayType == "user_name" ?
                    <div style={{marginTop: "50px", marginLeft: "130px"}}>
                        <div className="flex">
                            <div style={{width: "130px", marginTop: "12px"}}><label style={{width: "200px", fontSize: "12px"}}>User Name</label></div>
                            <input className="input input-bordered input-md" type="text" id="userName" name="userName" value={userName}
                                placeholder={'gwashington'} style={{width: "300px", marginLeft: "10px"}}
                                onChange={e => updateUserName(e.target.value)} readOnly={readOnlyUserName}/>
                        </div>
                        <div className="flex">
                            <div style={{marginTop: "20px", marginLeft: "50px"}}>
                                <hr style={{width: "150px"}}></hr>
                            </div>
                            <div style={{marginLeft: "20px", marginTop: "8px"}}><label>or</label></div>
                            <div style={{marginTop: "20px", marginLeft: "20px"}}>
                                <hr style={{width: "150px"}}></hr>
                            </div>
                        </div>
                        <div className="flex" style={{marginTop: "10px"}}>
                            <div style={{width: "130px", marginTop: "12px", fontSize: "12px"}}><label>Email Address</label></div>
                            <input className="input input-bordered input-md" type="text" id="emailAddress" name="emailAddress" value={userEMail}
                                placeholder={'gwashington@1776.us'} style={{width: "300px", marginLeft: "10px"}}
                                onChange={e => updateEMail(e.target.value)} readOnly={readOnlyEMail}/>
                        </div>

                         <div className="card-actions justify-center mt-10">
                          <button className="btn btn-neutral btn-wide" onClick={e => sendUserName(e)}>Next</button>
                        </div>
                    </div>
                    : displayType == "verification_code" ?
                    <div style={{marginTop: "50px", marginLeft: "230px"}}>
                        <div className="grid">
                            <div style={{width: "300px", marginTop: "0px"}}><label style={{width: "200px"}}>You will shortly receive a code to the phone number assocaited with your account/email address.</label></div>

                            <hr style={{width: "300px", marginTop: "10px"}}/>

                            <div style={{width: "130px", marginTop: "12px"}}><label style={{width: "200px", fontSize: "12px"}}>Verification Code</label></div>

                            <div className="flex">
                                <input className="input input-bordered input-md" type="text" id="userName" name="userName"
                                    placeholder={'0000-0000'} style={{width: "300px", height: "100px", fontSize: "48px", textAlign: "center"}}
                                    onChange={e => checkVerificationCode(e.target.value)}/>
                                <div style={{marginLeft: "10px", visibility: verificationCodeOK}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                        strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                    </svg>
                                </div>
                            </div>

                        </div>


                        <div className="card-actions mt-10 ml-10">
                            <button className="btn btn-neutral btn-wide" onClick={e => sendVerificationCodeEMail(e)} style={{visibility: verificationCodeOK}}>Send Reset</button>
                        </div>

                        <button className="btn btn-outline bg-blue-200"
                                onClick={handleBack} style={{marginLeft: "-200px", marginTop: "28px", borderRadius: "50px", height: "50px", width: "50px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"
                                stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" style={{marginLeft: "-7px", marginTop: "11px"}}><path d="M15 18l-6-6 6-6"/>
                            </svg>
                        </button>
                    </div>
                    : displayType == "verification_code_gen_error" ?
                    <div>
                        <div className="flex" style={{borderColor: "red", borderWidth: "4px", borderRadius: "10px", width: "600px", marginLeft: "70px", marginTop: "20px"}}>
                            <div style={{width: "500px", marginLeft: "30px", padding: "20px"}}>
                                <div style={{textAlign: "center"}}>
                                    <label style={{width: "500px", fontSize: "28px", fontWeight: "bold"}}>
                                        There was an error generating your verification code.
                                    </label>
                                </div>
                                <hr style={{marginTop: "10px"}}/>
                                <div style={{textAlign: "center", marginTop: "10px"}}>
                                    <label style={{width: "500px", fontSize: "18px"}}>
                                        This could be due to your account being inactive, not being found, or us not having a valid phone number for your account.  Please contact support and we will be happy to help you get past this issue.
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="card-actions justify-center mt-10">
                          <button className="btn btn-neutral btn-wide" onClick={e => close(e)}>Close</button>
                        </div>
                    </div>
                    :
                    <div style={{marginTop: "50px", marginLeft: "130px"}}>
                        <div className="flex">
                            <div style={{width: "500px", marginTop: "12px"}}>
                                <label style={{width: "500px"}}>
                                    Thank you for submitting the user account information and verification code.  If they match for an existing account, you will shortly receive an email with information on next steps.
                                </label>
                            </div>
                        </div>

                        <div className="card-actions justify-center mt-10">
                          <button className="btn btn-neutral btn-wide" onClick={e => close(e)}>Close</button>
                        </div>
                    </div>
                    }

                </div>
                <footer style={{zIndex: "1"}}>
                    <div className="signinFooter bg-neutral align-bottom" style={{maxHeight: "200px", overflow: "hidden"}}>
                        <div className="flex">
                            <div className="mt-8" >
                                <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                            </div>
                            {windowWidth >= 650 ?
                                <div className="signinFooterTagLineRight" >
                                    <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/RUN%20WITH%20US%20-%20INVERSE.png"/>
                                </div>
                            :
                                <div/>
                            }
                        </div>
                    </div>
                </footer>
            </div>
            :
            <div className="bg-neutral" style={{height: "100vh"}}>
                <div className="bg-neutral" style={{height: "150px"}}>
                    <div style={{height: "35px"}}>&nbsp;</div>
                    <div style={{marginLeft: "20px"}}>
                        <img style={{width: "200px"}} src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_transaprent.png"/>
                    </div>
                </div>
                <div className="passwordManagementBackgroundBorder_Phone" style={{marginTop: "-30px", zIndex: "99"}}>
                    <div style={{height: "20px"}}></div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="175" height="175" viewBox="0 0 24 24"
                            fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                            style={{marginLeft: "140px"}}>
                            <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/>
                            <circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                        </svg>
                    </div>

                    { displayType == "user_name2" ?
                    <div style={{marginTop: "50px", marginLeft: "30px"}}>
                        <div className="flex">
                            <div style={{width: "100px", marginTop: "12px"}}><label style={{width: "200px", fontSize: "12px"}}>User Name</label></div>
                            <input className="input input-bordered input-md" type="text" id="userName" name="userName" value={userName}
                                placeholder={'gwashington'} style={{width: "250px", marginLeft: "10px"}}
                                onChange={e => updateUserName(e.target.value)} readOnly={readOnlyUserName}/>
                        </div>
                        <div className="flex">
                            <div style={{marginTop: "20px", marginLeft: "50px"}}>
                                <hr style={{width: "150px"}}></hr>
                            </div>
                            <div style={{marginLeft: "20px", marginTop: "8px"}}><label>or</label></div>
                            <div style={{marginTop: "20px", marginLeft: "20px"}}>
                                <hr style={{width: "150px"}}></hr>
                            </div>
                        </div>
                        <div className="flex" style={{marginTop: "10px"}}>
                            <div style={{width: "100px", marginTop: "12px", fontSize: "12px"}}><label>Email Address</label></div>
                            <input className="input input-bordered input-md" type="text" id="emailAddress" name="emailAddress" value={userEMail}
                                placeholder={'gwashington@1776.us'} style={{width: "250px", marginLeft: "10px"}}
                                onChange={e => updateEMail(e.target.value)} readOnly={readOnlyEMail}/>
                        </div>

                         <div className="card-actions justify-center mt-10">
                           <button className="btn btn-neutral btn-wide" onClick={e => sendUserName(e)}>Next</button>
                        </div>
                    </div>
                    : displayType == "verification_code" ?
                    <div style={{marginTop: "20px", marginLeft: "70px"}}>
                        <div className="grid">
                            <div style={{width: "300px", marginTop: "0px"}}><label style={{width: "200px"}}>You will shortly receive a code to the phone number assocaited with your account/email address.</label></div>

                            <hr style={{width: "300px", marginTop: "10px"}}/>

                            <div style={{width: "130px", marginTop: "12px"}}><label style={{width: "200px", fontSize: "12px"}}>Verification Code</label></div>

                            <div className="flex">
                                <input className="input input-bordered input-md" type="text" id="userName" name="userName"
                                    placeholder={'0000-0000'} style={{width: "300px", height: "100px", fontSize: "48px", textAlign: "center"}}
                                    onChange={e => checkVerificationCode(e.target.value)}/>
                                <div style={{marginLeft: "10px", visibility: verificationCodeOK}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="#00ff4d"
                                        strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                    </svg>
                                </div>
                            </div>

                        </div>


                        <div className="card-actions mt-10 ml-5">
                            <button className="btn btn-neutral btn-wide" onClick={e => sendVerificationCodeEMail(e)} style={{visibility: verificationCodeOK}}>Send Reset</button>
                        </div>

                        <button className="btn btn-outline bg-blue-200"
                                onClick={handleBack} style={{marginLeft: "-200px", marginTop: "28px", borderRadius: "50px", height: "50px", width: "50px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"
                                stroke="#000000" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" style={{marginLeft: "-7px", marginTop: "11px"}}><path d="M15 18l-6-6 6-6"/>
                            </svg>
                        </button>
                    </div>
                    : displayType == "verification_code_gen_error" ?
                    <div>
                        <div className="flex" style={{borderColor: "red", borderWidth: "4px", borderRadius: "10px", width: "400px", marginLeft: "25px", marginTop: "20px"}}>
                            <div style={{width: "500px", marginLeft: "10px", padding: "20px"}}>
                                <div style={{textAlign: "center"}}>
                                    <label style={{width: "500px", fontSize: "20px", fontWeight: "bold"}}>
                                        There was an error generating your verification code.
                                    </label>
                                </div>
                                <hr style={{marginTop: "10px"}}/>
                                <div style={{textAlign: "center", marginTop: "10px"}}>
                                    <label style={{width: "500px", fontSize: "12px"}}>
                                        This could be due to your account being inactive, not being found, or us not having a valid phone number for your account.  Please contact support and we will be happy to help you get past this issue.
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="card-actions justify-center mt-10">
                          <button className="btn btn-neutral btn-wide" onClick={e => close(e)}>Close</button>
                        </div>
                    </div>
                    :
                    <div style={{marginTop: "50px", marginLeft: "75px", width: "300px"}}>
                        <div className="flex">
                            <div style={{width: "500px", marginTop: "12px"}}>
                                <label style={{width: "500px"}}>
                                    Thank you for submitting the user account information and verification code.  If they match for an existing account, you will shortly receive an email with information on next steps.
                                </label>
                            </div>
                        </div>

                        <div className="card-actions justify-center mt-10">
                          <button className="btn btn-neutral btn-wide" onClick={e => close(e)}>Close</button>
                        </div>
                    </div>
                    }

                </div>
                <footer style={{zIndex: "1"}}>
                    <div className="signinFooter bg-neutral align-bottom" style={{maxHeight: "200px", overflow: "hidden"}}>
                        <div className="flex">
                            <div className="mt-8" >
                                <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                            </div>
                            {windowWidth >= 650 ?
                                <div className="signinFooterTagLineRight" >
                                    <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/RUN%20WITH%20US%20-%20INVERSE.png"/>
                                </div>
                            :
                                <div/>
                            }
                        </div>
                    </div>
                </footer>

            </div>
            }
        </div>
    )

}

export default PasswordManagement;
