import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import { ApplicationContext } from "../../context/ApplicationContext";
import PropTypes from "prop-types";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

// Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require ("highcharts/modules/exporting")(Highcharts);

//eslint-disable-next-line
const EventActivityBarChart = ({ company_id }) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { companyID, companyHistoryDaySpan } = useContext(CompanyContext);
    const { appRenderType } = useContext(ApplicationContext);

    //eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [reportTitle, setReportTitle] = useState('Message Activity per Day');
    const [categories, setCategories] = useState(0);
    const [seriesData, setSeriesData] = useState(0);

    // remove the library hyperlink & accessibility
    Highcharts.setOptions({
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    });

    useEffect(() => {
        getMsgActivityPerDayByAccount(companyID);
    }, [companyID, companyHistoryDaySpan]);

    useEffect(() => {
        if(appRenderType == "desktop" || appRenderType == "tablet"){
            setReportTitle("Message Activity per Day");
        }
        else{
            setReportTitle("");
        }
    }, [appRenderType])

    const getMsgActivityPerDayByAccount = async (companyID) => {
        if (!jwt || !companyID || companyID < 0 || !companyHistoryDaySpan) {
            return;
        }

        setLoading(true);
        var msgDates = [];
        var thisSeriesData = [];

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectAmplifyMsgActivityPerDayByAccount?company_id=${companyID}&dayspan=${companyHistoryDaySpan}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            // Highcharts doesn't clear out previous data if new set is empty
            // So clear it out if needed
            if (response.data.values.length == 0) {
                if (seriesData != 0) {
                    for (let k of ['delivered','undelivered','removed']) {
                        thisSeriesData.push({ name: k, data: [0,0,0,0,0] });
                    }
                    setSeriesData(thisSeriesData);
                }
            } else {
                var msgTypes = {};
                response.data.values.forEach(object => {
                    var msgTypeName = object.msgType;
                    if (!(msgTypeName in msgTypes)) {
                        msgTypes[msgTypeName] = [];
                    }

                    msgTypes[msgTypeName].push(parseFloat(object.msgCount));

                    var thisMsgDate = object.eventStart;
                    if (msgDates.includes(thisMsgDate) == false) {
                        msgDates.push(thisMsgDate);
                    }
                });

                for (let k of ['delivered','undelivered','removed']) {
                    thisSeriesData.push({ name: k, data: msgTypes[k] });
                }
            }
        });
        setLoading(false);
        setCategories(msgDates);
        setSeriesData(thisSeriesData);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    return (
            <div className="grid" style={{width: "100%", height:
                appRenderType == "desktop" || appRenderType == "tablet" ?
                "360px"
                :
                "320px"
                ,
                padding: "20px 20px 20px 20px"}}>
                <HighchartsReact highcharts={Highcharts} options={
                    {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: reportTitle,
                        },
                        xAxis: {
                            categories: categories,
                            crosshair: {
                                text: true
                            }
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: 'Message Count'
                            }
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                            footerFormat: '</table>',
                            shared: true,
                            useHTML: true
                        },
                        legend: {
                            enabled: true
                        },

                        plotOptions: {
                            column: {
                                pointPadding: 0.2,
                                borderWidth: 0
                            }
                        },
                        colors:
                            [
                                "#346793", "#639aca", "#aecbe5"
                            ],
                        series: seriesData
                    }
                }/>
            </div>
    );
};

EventActivityBarChart.propTypes = {
    company_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EventActivityBarChart;
